import { Select } from "antd";
import { useContext } from "react";
import { DealListContext } from "../DealListContext";
import { MdArrowDropDown } from "react-icons/md";

const CompanyFilter = () => {
  const { filters, setFilters } = useContext(DealListContext);

  const handleOnChanged = (e) => {
    setFilters((prev) => ({ ...prev, selectedCompanies: e }));
  };

  return (
    <Select
      onChange={handleOnChanged}
      size="large"
      maxTagCount="responsive"
      placeholder="Select companies"
      suffixIcon={
        <MdArrowDropDown style={{ fontSize: 20, color: "#283371" }} />
      }
      style={{width:250}}
      options={filters?.companies}
      defaultValue={filters?.selectedCompanies}
      mode="multiple"
    />
  );
};

export default CompanyFilter;
