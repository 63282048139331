import {
  Avatar,
  Button,
  Card,
  Col,
  Flex,
  Form,
  Input,
  message,
  Modal,
  Row,
  Segmented,
  Select,
  Space,
  Typography,
} from "antd";
import "./CreateCampaignPreviewTest.css";
import { IoMdDesktop, IoMdPhonePortrait } from "react-icons/io";
import { useEffect, useState } from "react";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { EmailTemplateTypes } from "../../../shared/enums";
import { Option } from "antd/es/mentions";
import { UserOutlined } from "@ant-design/icons";
import { HiPlusSm } from "react-icons/hi";
import { removeButtonsFromHtml } from "../../../shared/HtmlOps";
const CreateCampaignPreviewTest = ({ senders, open, setOpen, data }) => {

  const { Text, Title } = Typography;
  const axios = useAxiosPrivate();
  const [showType, setShowType] = useState(1);
  const [html, setHtml] = useState();
  const [loading, setLoading] = useState(false);
  const [senderEmail, setSenderEmail] = useState();
  const [emails, setEmails] = useState();

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    setLoading(true);

    const fetchTemplate = async () => {
      const response = await axios.get(
        `/Templates/Email/Html/${data?.templateId}/${EmailTemplateTypes.UserCreated}`,
        {
          signal: signal,
        }
      );

      setHtml(response.data.html);
      setLoading(false);
    };

    if(data?.templateId){
      fetchTemplate();
    }

    return () => controller.abort();
  }, [data?.templateId]);

  useEffect(() => {
    setSenderEmail(senders?.find((c) => c.id == data?.senderId)?.email);
  }, [data?.senderId, senders]);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    const fetchAuthors = async () => {
      const response = await axios.get(`Users/GetAuthors`, {
        signal: signal,
      });

      setEmails(response.data.data);
      
      setSelectedEmails([response?.data?.data?.find(c => c.id == response.data.yourId)?.email])
    };

    fetchAuthors();

    return () => controller.abort();
  }, []);

  const [showAdd, setShowAdd] = useState(false);

  const [selectedEmails, setSelectedEmails] = useState([]);

  const handleAddEmail = (e) => {
    setEmails((prev) => [
      ...prev,
      {
        firstname: "-",
        lastname: "-",
        email: e.email,
      },
    ]);

    setSelectedEmails((prev) => [...prev, e.email]);
    setShowAdd(false);
  };
  const sendTest = async () => {
    setLoading(true)

    const response = await axios.post(`/Campaign/Email/Send-Test`, {
      campaignId: data?.id,
      emails: selectedEmails
    });

    if(response.data.success){
      setOpen(false)
    }else{
      message.error(response.data.message);
    }

    setLoading(false)

  };
  return (
    <Modal
      centered
      open={open}
      onCancel={() => setOpen(false)}
      width={1000}
      footer={false}
      title={
        <Title style={{ margin: 0 }} level={4}>
          Preview & Test
        </Title>
      }
    >
      <Row gutter={[24, 24]}>
        <Col span={16}>
          <Card
            size="small"
            className="no-shadow preview-test-preview-background"
          >
            <Flex align="start" justify="space-between">
              <Space direction="vertical">
                <Text>
                  <Text strong> From:</Text> <Text>&lt;{senderEmail}&gt;</Text>
                </Text>
                <Flex align="center" justify="start" gap={7}>

                  <Text strong>Subject:</Text> 
                  {data?.subject ? 
                  <div className="zero-margin-padding-children" dangerouslySetInnerHTML={{ __html:removeButtonsFromHtml(data?.subject) }}></div>
                : <Text >* No subject</Text>}
                </Flex>
                <Flex align="center" justify="start" gap={7}>
                  <Text strong>Preview: </Text>
                  {data?.previewText ? 
                  <div className="zero-margin-padding-children" dangerouslySetInnerHTML={{ __html: removeButtonsFromHtml(data?.previewText) }}></div>
                : <Text>* No preview text</Text>}
                </Flex>
              </Space>

              <Segmented
                onChange={(e) => setShowType(e)}
                options={[
                  {
                    label: (
                      <div className="segmented-icon-wrapper">
                        <IoMdPhonePortrait className="segmented-icon" />
                      </div>
                    ),
                    value: 1,
                  },
                  {
                    label: (
                      <div className="segmented-icon-wrapper">
                        <IoMdDesktop className="segmented-icon" />
                      </div>
                    ),
                    value: 2,
                  },
                ]}
              />
            </Flex>

            <div className="preview-test-preview">
              {showType == 1 ? (
                <>
                  <Flex align="center" justify="center">
                    <div className="preview-test-preview-phone">
                      <iframe
                        className="phone-frame-test"
                        srcDoc={html}
                      ></iframe>
                    </div>
                  </Flex>
                </>
              ) : (
                <div className="preview-test-preview-desktop">
                  <iframe className="phone-frame-test" srcDoc={html}></iframe>
                </div>
              )}
            </div>
          </Card>
        </Col>

        <Col span={8}>
          <Card size="small" className="no-shadow">
            <Space className="w-100" direction="vertical">
              <Text className="create-preview-test-header">
                Send test emails to your colleagues
              </Text>
              <Text>
                Search your contacts by email to send them a test mailing:
              </Text>

              <Space direction="vertical" className="w-100" size={0}>
                <Text className="thick-lable">Recipient</Text>

                <Flex align="center" gap={6} wrap="wrap">
                  <Select
                    size="large"
                    value={selectedEmails}
                    onChange={(e) => setSelectedEmails(e)}
                    className="w-100"
                    showSearch
                    optionLabelProp="label"
                    mode="multiple"
                    dropdownRender={(menu) => <div>{menu}</div>}
                    filterOption={(input, option) =>
                      (option?.data ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    placeholder="Select recipients"
                  >
                    {emails?.map((c) => (
                      <Option
                        value={c.email}
                        label={c.email}
                        data={`${c.firstname} ${c.lastname}`}
                      >
                        <Flex align="center" justify="start" gap={6}>
                          <div>
                            <Avatar
                              icon={<UserOutlined />}
                              size="small"
                              src={`${process.env.REACT_APP_FILE_URL}${c.profileImagePath}`}
                            />
                          </div>
                          <Space
                            classNames="sender-email-wrapper"
                            className="w-100"
                            size={0}
                            direction="vertical"
                          >
                            <Text className="sender-email">
                              {c.firstname} {c.lastname}
                            </Text>

                            <Text className="sender-nickname-domain">
                              {c.email}
                            </Text>
                          </Space>
                        </Flex>
                      </Option>
                    ))}
                  </Select>
                  <div>

                  <Button
                    disabled={showAdd}
                    className="flex-button"
                    size="large"
                    onClick={() => setShowAdd(true)}
                    icon={<HiPlusSm className="icon" />}
                    ></Button>
                    </div>
                </Flex>

                {showAdd && (
                  <Card className="add-card no-shadow" size="small">
                    <Form onFinish={handleAddEmail}>
                      <Text>Email</Text>
                      <Form.Item
                        name="email"
                        rules={[
                          {
                            required: true,
                          },
                          {
                            type: "email",
                            message: "The input is not a valid email!",
                          },
                        ]}
                      >
                        <Input size="large" placeholder="Enter test email" />
                      </Form.Item>

                      <Flex
                        className="add-card-operations"
                        align="center"
                        justify="end"
                        gap={6}
                      >
                        <Button onClick={() => setShowAdd(false)}>
                          Cancel
                        </Button>

                        <Button
                          htmlType="submit"
                          type="primary"
                          onClick={() => {}}
                        >
                          Save changes
                        </Button>
                      </Flex>
                    </Form>
                  </Card>
                )}
              </Space>

              <Flex align="center" justify="end">
                <Button
                  type="primary"
                  size="large"
                  loading={loading}
                  onClick={() => sendTest()}
                   className="new-buttons large-font"
                  >
                  Send
                </Button>
              </Flex>
            </Space>
          </Card>
        </Col>
      </Row>
    </Modal>
  );
};

export default CreateCampaignPreviewTest;
