import { useLocation, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Button, Layout, Flex, Avatar, Tooltip } from "antd";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { BiSolidFactory } from "react-icons/bi";
import { ReactComponent as FollowUpIcon } from "../../assets/icons/FollowUpIcon.svg";
import { ReactComponent as CampaignIcon } from "../../assets/icons/campaings.svg";
import { ReactComponent as DealsIcon } from "../../assets/icons/deals.svg";
import { ReactComponent as AudienceIcon } from "../../assets/icons/audience.svg";
import { ReactComponent as DesignIcon } from "../../assets/icons/designs.svg";
import { ReactComponent as SettingsIcon } from "../../assets/icons/settings.svg";
import { ReactComponent as PhoneIcon } from "../../assets/icons/telephone.svg";
import { ReactComponent as LogoIcon } from "../../assets/icons/logo.svg";

import SideMenuIcon from "./SideMenuIcon";
import "./Sidemenu.css";
import { DesingsPageTabs, SettingsPageTabs } from "../../shared/enums";

const SideMenu = () => {
  const [current, setCurrent] = useState();

  const { Sider } = Layout;

  const navigate = useNavigate();
  const location = useLocation();
  const axios = useAxiosPrivate();

  const [tenant, setTenant] = useState();

  useEffect(() => {
    menuItems.forEach((c) => {
      if (c.key == location.pathname) {
        setCurrent(c);
        return;
      }
    });

    const fetchTenant = async () => {
      const response = await axios.get(`/tenant/current`);
      setTenant(response.data);
    };

    fetchTenant();
  }, []);

  const menuItems = [
    {
      label: (
        <SideMenuIcon
          Icon={LogoIcon}
          iconClass={"dashboard"}
          isActive={current?.key == "/"}
        />
      ),
      key: "/",
      url: "/",
      text: "Dashboard",
    },
    {
      label: (
        <SideMenuIcon
          Icon={CampaignIcon}
          iconClass={"campaigns"}
          isActive={current?.key == "/Campaigns"}
        />
      ),
      key: "/Campaigns",
      url: "/Campaigns",
      text: "Campaigns",
    },
    {
      key: "/Designs",
      url: `/Designs?activetab=${DesingsPageTabs.Email}`,
      label: (
        <SideMenuIcon
          iconClass={"design"}
          Icon={DesignIcon}
          isActive={current?.key == "/Designs"}
        />
      ),
      text: "Designs",
    },
    {
      key: "/contacts",
      url: "/contacts",
      label: (
        <SideMenuIcon
          Icon={AudienceIcon}
          iconClass={"audience"}
          isActive={current?.key == "/contacts"}
        />
      ),
      text: "Contacts",
    },
    {
      key: "/Deals",
      url: "/Deals",
      label: (
        <SideMenuIcon
          iconClass="deal"
          Icon={DealsIcon}
          isActive={current?.key == "/Deals"}
        />
      ),
      text: "Leads",
    },
    {
      key: "/Followup",
      url: "/Followup",
      label: (
        <SideMenuIcon
          iconClass="followUp"
          Icon={FollowUpIcon}
          isActive={current?.key == "/Followup"}
        />
      ),
      text: "Follow up",
    },
    {
      key: "/dial/number",
      url: "/dial/number",
      label: (
        <SideMenuIcon
          iconClass="phone"
          Icon={PhoneIcon}
          isActive={current?.key == "/dial/number"}
        />
      ),
      text: "Phone dialer",
    },
  ];

  const settings = {
    key: "/settings",
    url: `/settings?activetab=${SettingsPageTabs.Media}`,
    label: (
      <SideMenuIcon
        Icon={SettingsIcon}
        iconClass={"settings"}
        isActive={current?.key == "/settings"}
      />
    ),
    text: "Settings",
  };
  return (
    <>
      <Sider trigger={null} width={80} className="sider">
        <Flex
          className="auto-overflow full-height"
          vertical
          justify="space-between"
          align="center"
        >
          <Flex
            className="w-100 margin-top-12"
            align="center"
            vertical
            justify="center"
            gap={12}
          >
            {menuItems.map((c, i) => (
              <Tooltip placement="right" key={i} title={c.text}>
                <Button
                  key={i}
                  onClick={() => {
                    setCurrent(c);
                    navigate(c.url);
                  }}
                  className={`${current?.key == c.key ? "active-menu" : ""}`}
                  type="text"
                  size="large"
                  icon={c.label}
                ></Button>
              </Tooltip>
            ))}
          </Flex>

          <Flex vertical align="center" className="bottom-margin-12" gap={14}>
            <Tooltip placement="right" title={settings.text}>
              <Button
                key={"settings"}
                onClick={() => {
                  setCurrent(settings);
                  navigate(settings.url);
                }}
                className={`flex-button ${
                  current?.key == settings.key ? "active-menu" : ""
                } zero-margin-padding head-color`}
                type="text"
                size="large"
                icon={settings.label}
              ></Button>
            </Tooltip>

            <Avatar
              size="large"
              shape="square"
              className="company-avatar"
              icon={<BiSolidFactory />}
              src={`data:${tenant?.logoMimeType};base64,${tenant?.logoBase64}`}
            />
          </Flex>
        </Flex>
      </Sider>
    </>
  );
};

export default SideMenu;
