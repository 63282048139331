import { Button, Dropdown } from "antd";
import { AiFillEdit } from "react-icons/ai";
import { BsTrash3Fill } from "react-icons/bs";
import { IoEllipsisHorizontal } from "react-icons/io5";

const FollowupStepOptions = ({id, onDelete, onUpdate}) => {
    const items = [
        {
            icon: <AiFillEdit className="followup-step-table-options-icon"/>,
            label:<p className="followup-step-table-options-icon-text">Edit selected step</p>,
            onClick: () => {
                onUpdate(id)
            }
        },
        {
            label: <p className="followup-step-table-options-icon-text">Remove selected step</p>,
            danger: true,
            icon: <BsTrash3Fill className="followup-step-table-options-icon"/>,
            onClick: () => onDelete(id)
        }
      ];
    return <>
    <Dropdown trigger={["click"]} menu={{ items }}>
        <Button
          type="text"
          shape="circle"
          className="flex-button"
          icon={
            <IoEllipsisHorizontal className="icon"/>
          }
        />
      </Dropdown>
    </>
}

export default FollowupStepOptions;