export const DealsTabs = {
  Kanban: 1,
  List: 2,
};

export const CardStatus = {
  New: 1,
  Qualifying: 2,
  DemoScheduled: 3,
  PendingCommitment: 4,
  InNegotiation: 5,
  Won: 6,
  Closed: 7,
};

export const DealActions = {
  Note: 1,
  Task: 2,
  File: 3,
};

export const DealActionsHash = {
  Note: "#note",
  Task: "#task",
  File: "#File",
};

export const TaskStatus = {
  ToDo: 1,
  Email: 2,
  Call: 3,
  Meeting: 4,
  Lunch: 5,
  Deadline: 6,
};

export const TaskPriority = {
  Normal: 1,
  High: 2,
};

export const DealLostReason = {
  PriceToHigh: 1,
  OptedForACompetitor: 2,
  JunkLead: 3,
  NotInterested: 4,
  ClientRequirementNotMet: 5,
};

export const DealActivityDanger = {
  Dangerous: 1,
  VeryDangerous: 2,
  Safe: 3,
};

export const DealActivityType = {
  DealCreated: 1,
  DealStageChanged: 2,
  CreatedTask: 3,
  TaskCompleted: 4,
  AddedNote: 5,
  AddedFile: 6,
};

export const CampaignStatusType = {
  All: -1,
  NotStarted: 0,
  OnGoing: 1,
  Finished: 2,
  Invalid: 3,
};

export const OrderDirection = {
  Asc: 1,
  Dsc: 2,
};

export const Policy = {
  LICENCE: 1,
  TERMS: 2,
  PRIVACY: 3,
  AFFILIATES: 4,
};

export const CampaignType = {
  EMAIL: 0,
  WHATSAPP: 1,
  SMS: 2,
};
export const DealCardItems = {
  Contact: 1,
  Amount: 2,
  CreatedDate: 3,
  Products: 4,
  Author: 5,
  Companies: 6,
  ClosedDate: 7,
};

export const EmaiLSenderConfirmation = {
  Confirmed: 1,
  NotConfirmed: 2,
};

export const EmailSenderDefault = {
  Default: 1,
  NotDefault: 2,
};

export const SmartTextType = {
  Fix: 1,
  Expand: 2,
  Summarize: 3,
  Rephrase: 4,
  Friendly: 5,
  Formal: 6,
};

export const StepExecutionType = {
  UserSignedUp: 1,
  UserSubscribed: 2,
  UserUnsubscribed: 3,
  UserDeleted: 4,
  TrialExceded: 5,
};

export const StepExecutionConditionBridge = {
  And: 1,
  Or: 2,
  Not: 3,
};

export const EmailTemplateTypes = {
  Layout: 1,
  Desing: 2,
  UserCreated: 3,
};

export const WhatsappTemplateType = {
  Marketing: 1,
  Utility: 2,
};

export const SettingsPageTabs = {
  Media: "Media",
  Senders: "Senders",
  Developers: "Developers",
  Signatures: "Signatures",
  Team: "Team Managment",
  Prices: "Prices",
  Cupons: "Cupons",
};

export const DesingsPageTabs = {
  Email: "Email",
  Whatsapp: "Whatsapp",
  Sms: "Sms",
  Notification: "Notification",
  Components: "Components",
};

export const NotificationTypes = {
  ContactCreated: 1,
  ContactUpdated:2,
  ContactDeleted:3,
  ContactOwnerset:4,

  CampaignStarted:5,
  CampaignScheduled:6,
  CampaignDeleted:7,

  DealCreated:8,
  DealOwnersAssigned:9,
  DealStatusChanged:10,
  DealWon:11,
  DealLost:12,

  TaskOwnerSet:13,
  TaskMarkedDone:14,
  TaskReopen:15,
  TaskRemoved:16,
  TaskFollowUpCreated:17,

  ProductCreated:18,
  ProductOwnerSet:19,
  ProductRemoved:20,

  CompanyCreated:21,
  CompanyOwnerSet:22,
  CompanyRemoved:23,

  CallMissed:24,
};
