import {
  Avatar,
  Button,
  Card,
  Col,
  Dropdown,
  Flex,
  message,
  notification,
  Pagination,
  Progress,
  Row,
  Space,
  Statistic,
  Tabs,
  Tag,
  Typography,
} from "antd";
import { act, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import ProfileAvatar from "../../../components/ProfileAvatar";
import { DateFormat } from "../../../dateformat";
import {
  MdCall,
  MdEmail,
  MdNavigateBefore,
  MdNavigateNext,
  MdOutlineEqualizer,
  MdOutlineMail,
  MdOutlineTextsms,
  MdOutlineVideoCameraFront,
  MdOutlineWhatsapp,
  MdSms,
} from "react-icons/md";
import { IoEllipsisHorizontal, IoLogoWhatsapp } from "react-icons/io5";
import {
  FaArrowLeft,
  FaFolderOpen,
  FaRegTrashAlt,
  FaTrash,
  FaTrophy,
} from "react-icons/fa";
import { UserOutlined } from "@ant-design/icons";
import { CardStatus } from "../../../shared/enums";
import { AiFillSchedule } from "react-icons/ai";
import { RiGitCommitFill } from "react-icons/ri";
import { FaHandshakeSimple } from "react-icons/fa6";
import { IoMdCloseCircle, IoMdMore } from "react-icons/io";
import QueryString from "qs";
import TableLoading from "../../../shared/TableLoading";
import NoDataFound from "../../../shared/NoDataFound";
import AddTagToContactSider from "../AddTagToContactSider";
import CreateDealDrawer from "../../deals/CreateDealDrawer/CreateDealDrawer";
import ContactCommentsList from "../Comments/ContactCommentsList";
import VerifyContact from "../Comments/VerifyContact";
import SendEmailModal from "../../../shared/SendEmailModal";
import CallContactModal from "./CallContactModal";
import "./ContactDetails.css";
import CallNumberModal from "./CallNumberModal";
import ContactCommucations from "./ContactCommucations";
import ContactDetailsChannels from "./ContactDetailsChannels";
import ContactData from "./ContactData";
import ContactTags from "./ContactTags";
import AssignContactOwnerDrawer from "./AssignContactOwnerDrawer";
import ContactCompanies from "./ContactCompanies";

const ContactDetails = () => {
  const { id } = useParams();
  const { Title, Text } = Typography;
  const axios = useAxiosPrivate();

  const [editTagsOpen, setEditTagsOpen] = useState(false);

  const [details, setDetails] = useState();

  const navigate = useNavigate();

  const [navigation, setNavigation] = useState(null);
  const [responses, setResponses] = useState();
  const [responsesLoading, setResponsesLoading] = useState(false);
  const [responsePagination, setResponsePagination] = useState();
  const [page, setPage] = useState(1);
  const [counts, setCounts] = useState();

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    const fetchCounts = async () => {
      let response = await axios.get(`Contacts/Counts/${id}`, {
        signal: signal,
      });

      setCounts(response.data);
    };

    const fetchNav = async () => {
      let response = await axios.get(`/Contact/Navigation/${id}`, {
        signal: signal,
      });

      setNavigation(response.data);
    };

    const fetchDetails = async () => {
      let response = await axios.get(`/Contact-Details/${id}`, {
        signal: signal,
      });

      setDetails(response.data);
    };

    fetchDetails();
    fetchNav();
    fetchCounts();

    return () => controller.abort();
  }, [id]);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    const fetchResponses = async () => {
      setResponsesLoading(true);
      let response = await axios.get(`/Contact/Responses`, {
        signal: signal,
        params: {
          id: id,
          page: page,
          pageSize: 7,
        },
        paramsSerializer: (params) => {
          return QueryString.stringify(params, {
            arrayFormat: "repeat",
            skipNulls: true,
          });
        },
      });

      setResponses(response.data.items);
      setResponsePagination({
        total: response.data.total,
      });
      setResponsesLoading(false);
    };
    fetchResponses();

    return () => controller.abort();
  }, [page, id]);

  const removeFromDeal = async (dealId) => {
    const response = await axios.post(`/Deal/DeleteContact`, {
      dealId: dealId,
      contactId: id,
    });

    if (response.data.success) {
      setDetails((prev) => ({
        ...prev,
        dealTags: prev.dealTags.filter((c) => c.id !== dealId),
      }));
    } else {
      message.error(response.data.message);
    }
  };
  const [addDealOpen, setAddDealOpen] = useState(false);

  const onDealCreated = async (e) => {
    const response = await axios.post("/Deal/CreateDeal", {
      emailId: e.contacts,
      companyIds: e.companies,
      stage: e.status,
      ownerIds: e.assignedUser,
      amount: e.amount,
      closeDate: e.closeDate,
      title: e.title,
      productIds: e.products,
    });

    if (!response.data.success) {
      message.error(response.data.message);
      return;
    }
    setDetails((prev) => ({
      ...prev,
      dealTags: [
        ...prev.dealTags,
        {
          dealStage: e.status,
          id: response.data.id,
          name: e.title,
          createdAt: response.data.createdOn,
          inThisStageSince: response.data.inStageSince,
        },
      ],
    }));

    setAddDealOpen(false);
  };

  const removeContact = async () => {
    const response = await axios.delete(`/Contacts/${id}`);

    if (response.data.success) {
      notification.open({
        message: (
          <Flex
            style={{ width: "200px", height: "50px" }}
            className="h-100"
            align="center"
            justify="center"
          >
            {" "}
            <Text className="large-font">Contact removed</Text>
          </Flex>
        ),
        showProgress: true,
        placement: "bottom",
        btn: null,
        closeIcon: null,
      });
      navigate(`/contacts`);
    } else {
      message.error(response.data.message);
    }
  };

  const [sendOpen, setSendOpen] = useState(false);
  const [callOpen, setCallOpen] = useState(false);
  const [callNumberOpen, setCallNumberOpen] = useState(false);

  const handleNavigateToWhatsapp = () => {
    const whatsappURL = `https://wa.me/${details?.phone}`;

    window.open(whatsappURL, "_blank");
  };

  const [assignOpen, setAssignOpen] = useState(false);

  const onUpdated = (e) => {
    setDetails((prev) => ({
      ...prev,
      ownerId: e.ownerId,
      ownerName: e.ownerName,
      ownerProfile: e.ownerProfile,
    }));
  };

  const [activeTab, setActiveTab] = useState(1);

  return (
    <>
      <CallNumberModal
        open={callNumberOpen}
        setOpen={setCallNumberOpen}
        fullName={`${details?.firstname} ${details?.lastname}`}
        number={details?.phone}
      />
      <CallContactModal
        open={callOpen}
        setOpen={setCallOpen}
        fullName={`${details?.firstname} ${details?.lastname}`}
        id={id}
        email={details?.email}
      />
      <SendEmailModal open={sendOpen} setOpen={setSendOpen} contactId={id} />

      <CreateDealDrawer
        onSubmit={onDealCreated}
        contactId={id}
        isDrawerOpen={addDealOpen}
        setIsDrawerOpen={setAddDealOpen}
      />

      <AssignContactOwnerDrawer
        contactId={details?.id}
        onUpdated={onUpdated}
        defaultValue={details?.ownerId}
        open={assignOpen}
        setOpen={setAssignOpen}
      />

      <div
        style={{
          position: "sticky",
          top: 0,
          zIndex: 99,
          background: "#fff",
          height: 170,
        }}
      >
        <Flex style={{ marginTop: 12 }} align="center" justify="space-between">
          <Flex align="center" justify="start" gap={12}>
            <Button
              onClick={() => navigate(-1)}
              type="text"
              size="large"
              icon={<FaArrowLeft />}
              className="flex-button"
            ></Button>

            {details && (
              <ProfileAvatar
                name={`${details?.firstname} ${details?.lastname}`}
                size={80}
                font={36}
              />
            )}

            <Space direction="vertical" size={0}>
              <Flex align="center" justify="start" gap={6}>
                <Title className="zero-margin-padding" level={1}>
                  {`${details?.firstname} ${details?.lastname}`}
                </Title>

                {details?.emailStatus == 1 && (
                  <Tag
                    color="green"
                    className="flex-button"
                    style={{ fontWeight: 600, borderWidth: "2px" }}
                  >
                    Safe
                  </Tag>
                )}
              </Flex>

              <Flex align="center" justify="start" gap={6}>
                <Text>
                  Joined{" "}
                  {new Date(details?.createdAt * 1000).toLocaleDateString(
                    "en-us",
                    DateFormat
                  )}
                </Text>
                •
                <Flex align="center" justify="start" gap={6}>
                  <Avatar
                    src={`${process.env.REACT_APP_FILE_URL}${details?.authorProfile}`}
                    icon={<UserOutlined />}
                    size="small"
                  />
                  <Text>
                    Created by{" "}
                    {details?.createdByYou ? "you" : details?.authorName}
                  </Text>
                </Flex>
              </Flex>
            </Space>
          </Flex>

          <Flex align="center" justify="end" gap={6}>
            <Card
              bodyStyle={{ padding: 0 }}
              style={{ boxShadow: "none" }}
              size="small"
            >
              <Flex align="center" justify="space-between" gap={6}>
                <Button
                  disabled={navigation?.contactIndex == 0}
                  type="text"
                  size="large"
                  className="small-flex-button semi-bold"
                  onClick={() =>
                    navigate(`/contacts/${navigation?.oneContactsBefore}`)
                  }
                >
                  <MdNavigateBefore style={{ fontSize: 22 }} />
                  {navigation?.prevName}
                </Button>
                <Text strong>
                  {navigation?.contactIndex} of {navigation?.totalContacts}
                </Text>
                <Button
                  size="large"
                  disabled={
                    navigation?.contactIndex == navigation?.totalContacts
                  }
                  type="text"
                  className="small-flex-button semi-bold"
                  onClick={() =>
                    navigate(`/contacts/${navigation?.oneContactsAfter}`)
                  }
                >
                  {navigation?.nextName}{" "}
                  <MdNavigateNext style={{ fontSize: 22 }} />
                </Button>
              </Flex>
            </Card>
            <Dropdown
              trigger={["click"]}
              menu={{
                items: [
                  {
                    key: 1,
                    label: <p className="drop-down-text">Delete</p>,
                    danger: true,
                    icon: <FaTrash className="drop-down-icon" />,
                    onClick: () => removeContact(),
                  },
                ],
              }}
            >
              <Button
                type="text"
                size="large"
                className="flex-button"
                icon={<IoMdMore className="icon" />}
              />
            </Dropdown>
          </Flex>
        </Flex>

        <Tabs
          size="large"
          tabBarExtraContent={{
            right: (
              <Flex align="center" justify="start" gap={6}>
                <Button
                  className="contact-button"
                  size="large"
                  type="link"
                  onClick={() => setSendOpen(true)}
                >
                  <MdOutlineMail />
                  Email
                </Button>
                <Button
                  className="contact-button"
                  size="large"
                  type="link"
                  onClick={() => setCallOpen(true)}
                >
                  <MdOutlineVideoCameraFront />
                  Video
                </Button>
                <Button
                  className="contact-button"
                  size="large"
                  type="link"
                  onClick={() => setCallNumberOpen(true)}
                >
                  <MdCall />
                  Call
                </Button>

                <Button
                  className="contact-button-whatsapp"
                  size="large"
                  type="link"
                  onClick={handleNavigateToWhatsapp}
                >
                  <MdOutlineWhatsapp />
                  Whatsapp
                </Button>
              </Flex>
            ),
          }}
          items={[
            {
              key: 1,
              label: "Overview",
            },
            {
              key: 2,
              label: "Tags",
            },
          ]}
          activeKey={activeTab}
          onChange={(e) => setActiveTab(e)}
        />
      </div>
      {
        <div style={{ display: activeTab == 1 ? "block" : "none" }}>
          <Space
            style={{ marginTop: 24 }}
            direction="vertical"
            className="w-100"
            size={24}
          >
            <Row gutter={[24, 24]}>
              <Col span={6}>
                <Space
                  style={{
                    position: "sticky",
                    top: 170,
                  }}
                  className="w-100"
                  size={34}
                  direction="vertical"
                >
                  <ContactData details={details} setDetails={setDetails} />

                  <ContactDetailsChannels
                    details={details}
                    setDetails={setDetails}
                  />
                </Space>
              </Col>
              <Col span={12}>
                <Space className="w-100" direction="vertical" size={24}>
                  <Card size="small" className="no-shadow gray-border">
                    <Space direction="vertical" className="w-100">
                      <Row gutter={[24, 24]}>
                        <Col span={6}>
                          <Space direction="vertical" size={0}>
                            <Text>Emails Sent</Text>
                            <Statistic
                              style={{ marginTop: -10 }}
                              value={counts?.emailsSent}
                              from={0}
                              precision={0}
                              valueStyle={{ fontWeight: 600 }}
                            />
                          </Space>
                        </Col>
                        <Col span={6}>
                          <Space direction="vertical" size={0}>
                            <Text>Calls</Text>
                            <Statistic
                              style={{ marginTop: -10 }}
                              value={counts?.calls}
                              from={0}
                              precision={0}
                              valueStyle={{ fontWeight: 600 }}
                            />
                          </Space>
                        </Col>
                        <Col span={6}>
                          <Space direction="vertical" size={0}>
                            <Text>Missed Calls</Text>
                            <Statistic
                              style={{ marginTop: -10 }}
                              value={counts?.missedCalls}
                              from={0}
                              precision={0}
                              valueStyle={{ fontWeight: 600 }}
                            />
                          </Space>
                        </Col>
                        <Col span={6}>
                          <Space direction="vertical" size={0}>
                            <Text>Duration</Text>
                            <Statistic
                              style={{ marginTop: -10 }}
                              value={`${counts?.callDuration}s`}
                              from={0}
                              precision={0}
                              valueStyle={{ fontWeight: 600 }}
                            />
                          </Space>
                        </Col>
                      </Row>
                    </Space>
                  </Card>
                  <Space className="w-100" direction="vertical" size={24}>
                    <Tabs
                      size="large"
                      defaultActiveKey={0}
                      items={[
                        {
                          key: 0,
                          label: "Communications",
                          children: <ContactCommucations />,
                        },

                        {
                          key: 2,
                          label: "Notes",
                          children: <ContactCommentsList id={id} />,
                        },
                        {
                          key: 1,
                          label: "Marketing",
                          children: (
                            <Space
                              className="w-100"
                              direction="vertical"
                              size={0}
                            >
                              {responsesLoading ? (
                                <TableLoading />
                              ) : responses && responses.length > 0 ? (
                                <>
                                  <Space className="w-100" direction="vertical">
                                    <Space
                                      className="w-100"
                                      direction="vertical"
                                      size={0}
                                    >
                                      {responses?.map((c, i) => (
                                        <Card
                                          key={i}
                                          size="small"
                                          style={{
                                            boxShadow: "none",
                                            borderTopLeftRadius:
                                              i == 0 ? 10 : 0,
                                            borderTopRightRadius:
                                              i == 0 ? 10 : 0,
                                            borderBottomLeftRadius:
                                              i == responses.length - 1
                                                ? 10
                                                : 0,
                                            borderBottomRightRadius:
                                              i == responses.length - 1
                                                ? 10
                                                : 0,
                                          }}
                                        >
                                          <Flex
                                            align="center"
                                            justify="space-between"
                                            className="w-100"
                                          >
                                            <Flex
                                              align="center"
                                              justify="start"
                                              gap={6}
                                            >
                                              <div>
                                                <Flex
                                                  align="center"
                                                  justify="center"
                                                  style={{
                                                    width: 35,
                                                    height: 35,
                                                    borderRadius: 40,
                                                    background: "#e1e1e1",
                                                  }}
                                                >
                                                  {c.campaign.campaignType ==
                                                  0 ? (
                                                    <MdEmail
                                                      style={{
                                                        fontSize: 18,
                                                      }}
                                                    />
                                                  ) : c.campaign.type == 1 ? (
                                                    <IoLogoWhatsapp
                                                      style={{
                                                        fontSize: 18,
                                                      }}
                                                    />
                                                  ) : (
                                                    <MdSms
                                                      style={{
                                                        fontSize: 18,
                                                      }}
                                                    />
                                                  )}
                                                </Flex>
                                              </div>
                                              <Space
                                                direction="vertical"
                                                className="w-100"
                                                size={0}
                                              >
                                                <Text
                                                  type="link"
                                                  onClick={() =>
                                                    navigate(
                                                      `/Campaigns/Details/${c.campaign.id}`
                                                    )
                                                  }
                                                  strong
                                                >
                                                  {c.campaign.name}
                                                </Text>
                                                <Text>
                                                  {new Date(
                                                    c.date * 1000
                                                  ).toLocaleDateString(
                                                    "en-us",
                                                    DateFormat
                                                  )}
                                                </Text>
                                              </Space>
                                            </Flex>

                                            <Space
                                              direction="vertical"
                                              space={0}
                                              align="end"
                                            >
                                              <Text strong>{c.status}</Text>

                                              <Text>
                                                {c.status == "dropped"
                                                  ? `1 out of ${c.campaign.dropped}`
                                                  : c.status == "processed"
                                                  ? `1 out of ${c.campaign.processed}`
                                                  : c.status == "click"
                                                  ? `1 out of ${c.campaign.click}`
                                                  : c.status == "delivered"
                                                  ? `1 out of ${c.campaign.delivered}`
                                                  : c.status == "bounce"
                                                  ? `1 out of ${c.campaign.bounce}`
                                                  : c.status == "spamreport"
                                                  ? `1 report out of ${c.campaign.spamreport}`
                                                  : c.status == "deferred"
                                                  ? `1 out of ${c.campaign.deferred}`
                                                  : `1 out of ${c.campaign.open}`}
                                              </Text>
                                            </Space>
                                          </Flex>
                                        </Card>
                                      ))}
                                    </Space>

                                    <Flex align="center" justify="end">
                                      <Pagination
                                        current={page}
                                        total={responsePagination?.total}
                                        showSizeChanger={false}
                                        onChange={(page) => setPage(page)}
                                      />
                                    </Flex>
                                  </Space>
                                </>
                              ) : (
                                <NoDataFound
                                  onAdd={() => navigate("/Campaigns")}
                                  title="No marketing data found"
                                  description="Click the New Campaign button on the campaigns page to create a new email campaign"
                                  addText="Go to campaigns"
                                />
                              )}
                            </Space>
                          ),
                        },
                        {
                          key: 3,
                          label: "Verification",
                          children: <VerifyContact email={details?.email} />,
                        },
                      ]}
                    ></Tabs>
                  </Space>
                </Space>
              </Col>

              <Col span={6}>
                <Space
                  size={24}
                  direction="vertical"
                  className="w-100"
                  style={{
                    position: "sticky",
                    top: 170,
                  }}
                >
                  <Space direction="vertical" size={0} className="w-100">
                    <Title level={4} style={{ margin: 0 }}>
                      Owner
                    </Title>
                    <Card size="small" className="no-shadow gray-border">
                      {details?.ownerId ? (
                        <Flex align="center" justify="space-between">
                          <Flex align="center" justify="start" gap={6}>
                            <div>
                              <Avatar
                                size="large"
                                src={`${process.env.REACT_APP_FILE_URL}${details?.ownerProfile}`}
                                icon={<UserOutlined />}
                              />
                            </div>

                            <Title
                              type="link"
                              onClick={() =>
                                navigate(`/user/details/${details?.ownerId}`)
                              }
                              level={4}
                              className="zero-margin-padding"
                            >
                              {details?.ownerName}
                            </Title>
                          </Flex>

                          <Button
                            onClick={() => setAssignOpen(true)}
                            className="new-buttons large-font"
                          >
                            Change
                          </Button>
                        </Flex>
                      ) : (
                        <Flex align="center" justify="space-between">
                          <Title level={5} className="zero-margin-padding">
                            No owner
                          </Title>
                          <Button
                            onClick={() => setAssignOpen(true)}
                            size="large"
                            type="primary"
                            className="new-buttons large-font"
                          >
                            Assign owner
                          </Button>
                        </Flex>
                      )}
                    </Card>
                  </Space>

                  <Space direction="vertical" className="w-100">
                    <Flex align="center" justify="space-between">
                      <Title level={4} className="zero-margin-padding">
                        Deals
                      </Title>
                      <Button
                        onClick={() => setAddDealOpen(true)}
                        className="new-buttons large-font"
                      >
                        Create
                      </Button>
                    </Flex>

                    {details?.dealTags && details?.dealTags?.length > 0 ? (
                      <Space direction="vertical" className="w-100">
                        {details?.dealTags?.map((c, i) => (
                          <Card
                            key={i}
                            size="small"
                            className="gray-border no-shadow"
                          >
                            <Flex
                              align="center"
                              justify="space-between"
                              gap={6}
                            >
                              <Flex
                                className="w-100"
                                align="center"
                                justify="start"
                                gap={6}
                              >
                                <div>
                                  <Flex
                                    align="center"
                                    justify="center"
                                    style={{
                                      width: 30,
                                      height: 30,
                                      borderRadius: 30,
                                      background: "#e3e3e3",
                                      color: "#1b254b",
                                    }}
                                  >
                                    {c.dealStage == CardStatus.New ? (
                                      <FaFolderOpen style={{ fontSize: 18 }} />
                                    ) : c.dealStage == CardStatus.Qualifying ? (
                                      <MdOutlineEqualizer
                                        style={{ fontSize: 22 }}
                                      />
                                    ) : c.dealStage ==
                                      CardStatus.DemoScheduled ? (
                                      <AiFillSchedule
                                        style={{ fontSize: 22 }}
                                      />
                                    ) : c.dealStage ==
                                      CardStatus.PendingCommitment ? (
                                      <RiGitCommitFill
                                        style={{ fontSize: 22 }}
                                      />
                                    ) : c.dealStage ==
                                      CardStatus.InNegotiation ? (
                                      <FaHandshakeSimple
                                        style={{ fontSize: 22 }}
                                      />
                                    ) : c.dealStage == CardStatus.Won ? (
                                      <FaTrophy style={{ fontSize: 22 }} />
                                    ) : (
                                      <IoMdCloseCircle
                                        style={{ fontSize: 22 }}
                                      />
                                    )}
                                  </Flex>
                                </div>
                                <Space
                                  className="w-100"
                                  direction="vertical"
                                  size={0}
                                >
                                  <Flex
                                    align="start"
                                    justify="space-between"
                                    gap={0}
                                  >
                                    <Title
                                      onClick={() =>
                                        navigate(`/Deals/Details/${c.id}`)
                                      }
                                      level={5}
                                      type="link"
                                      strong
                                      style={{ margin: 0, padding: 0 }}
                                    >
                                      {c.name}
                                    </Title>
                                    <Text strong>
                                      {c.dealStage == CardStatus.New
                                        ? "1 / 6 - New"
                                        : c.dealStage == CardStatus.Qualifying
                                        ? "2 / 6 - Qualifying"
                                        : c.dealStage ==
                                          CardStatus.DemoScheduled
                                        ? "3 / 6 - Demo Scheduled"
                                        : c.dealStage ==
                                          CardStatus.PendingCommitment
                                        ? "4 / 6 - Pending Commitment"
                                        : c.dealStage ==
                                          CardStatus.InNegotiation
                                        ? "5 / 6 - In Negotiation"
                                        : c.dealStage == CardStatus.Won
                                        ? "6 / 6 Won"
                                        : "6 / 6 LOST"}
                                    </Text>
                                  </Flex>
                                  <Text></Text>

                                  <Progress
                                    className="w-100"
                                    strokeColor="#422afb"
                                    percent={c.dealStage * 17}
                                    showInfo={false}
                                  />
                                  <Text>
                                    Opened{" "}
                                    {new Date(
                                      c.createdAt * 1000
                                    ).toLocaleDateString(
                                      "en-us",
                                      DateFormat
                                    )}{" "}
                                    • On this stage since{" "}
                                    {new Date(
                                      c.inThisStageSince * 1000
                                    ).toLocaleDateString("en-us", DateFormat)}
                                  </Text>
                                </Space>
                              </Flex>
                              <Dropdown
                                menu={{
                                  items: [
                                    {
                                      key: 1,
                                      label: "Remove from deal",
                                      icon: <FaRegTrashAlt />,
                                      danger: true,
                                      onClick: () => removeFromDeal(c.id),
                                    },
                                  ],
                                }}
                                trigger={["click"]}
                              >
                                <Button
                                  shape="circle"
                                  className="flex-button"
                                  type="text"
                                  icon={
                                    <IoEllipsisHorizontal
                                      style={{ fontSize: 26 }}
                                    />
                                  }
                                ></Button>
                              </Dropdown>
                            </Flex>
                          </Card>
                        ))}
                      </Space>
                    ) : (
                      <Text>* This contact does not belong on any deals!</Text>
                    )}
                  </Space>

                  <ContactCompanies details={details} setDetails={setDetails} />
                </Space>
              </Col>
            </Row>
          </Space>
        </div>
      }

      {
        <div style={{ display: activeTab == 2 ? "block" : "none" }}>
          <ContactTags
            fullName={`${details?.firstname} ${details?.lastname}`}
          />
        </div>
      }
    </>
  );
};
export default ContactDetails;
