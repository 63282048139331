import React from "react";
import { Avatar } from "antd";
import Colors from "../../../shared/Colors";
const AvatarGroup = ({ items, size }) => {
  const getColor = (index) => {
    return Colors[index % Colors.length];
  };
  return (
    <Avatar.Group
      size={size}
      className="no-pointer-events"
      max={{
        count: 3,
        style: {
          background: "#5f5f5f",
          fontWeight: "bold",
          border: "2px solid #f4f7fe",
        },
      }}
    >
      {items?.map((c, ind) => (
        <Avatar
          style={{
            background: `${getColor(ind)}`,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontWeight: "bold",
            border: `2px solid #f4f7fe`,
          }}
          key={ind}
          size={size}
          src={`${process.env.REACT_APP_FILE_URL}${c.profileUrl}`}
        >
          {c.name[0]}
          {c.lastName[0]}
        </Avatar>
      ))}
    </Avatar.Group>
  );
};

export default AvatarGroup;
