import { Select, Space, Typography } from "antd";
import { useContext } from "react";
import { DealCardItems } from "../../../shared/enums";
import { DealListContext } from "../DealListContext";

const CardItemsFilter = () => {

  const options = [
    {
      value: DealCardItems.Contact,
      label: "Contact"
    },
    {
      value: DealCardItems.Amount,
      label: "Amount"
    },
    {
      value: DealCardItems.CreatedDate,
      label:"Created date"
    },
    {
      value:DealCardItems.Products,
      label:"Products"
    },
    {
      value:DealCardItems.Author,
      label:"Owners"
    },
    {
      value: DealCardItems.Companies,
      label: "Companies"
    },
    {
      value: DealCardItems.ClosedDate,
      label: "Closed date"
    }
  ]

  const {filters, setFilters} = useContext(DealListContext);
  
  const handleOnChanged = (selectedItems) => {
    const updatedCardItems = {};
    options.forEach(option => {
      updatedCardItems[option.value] = selectedItems.includes(option.value);
    });
    
    setFilters(prevFilters => ({
      ...prevFilters,
      cardItems: updatedCardItems
    }));
  };

  const selectedItems = Object.keys(filters.cardItems).map(c => parseInt(c)).filter(key => filters.cardItems[key]);
  
  const {Text} = Typography;

  return <Space className="w-100" direction="vertical" size={0}>
      <Text className="bold">Select which deal data would you like to be present?</Text>
      <Select
        size="large"
        onChange={handleOnChanged}
        options={options}
        defaultValue={selectedItems}
        value={selectedItems}
        placeholder="Select what the card displays"
        mode="multiple"
        className="w-100"
        />
  </Space>
};

export default CardItemsFilter;
