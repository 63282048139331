import {
  Button,
  Card,
  Col,
  Flex,
  Form,
  message,
  Row,
  Space,
  Typography,
} from "antd";
import { useContext, useEffect, useRef, useState } from "react";
import { CreateNotificationTemplateContext } from "./CreateNotificationTemplateContext";
import { FaCheck } from "react-icons/fa";
import { MdClose } from "react-icons/md";
import "./HeaderStep.css";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import NotificationPreview from "./NotificationPreview";
import InputBox from "./InputBox";

const HeaderStep = ({ active, setStep }) => {
  
  
  const { Title, Text } = Typography;
  const { data, setData } = useContext(CreateNotificationTemplateContext);
  const [form] = Form.useForm();

  const [tempHeader, setTempHeader] = useState();

  const axios = useAxiosPrivate();

  const handleCancel = () => {
    setStep();
  };

  const [loading, setLoading] = useState(false);

  const submit = async (e) => {
    setLoading(true);

    const response = await axios.put(`/Notification/Assing/Header`, {
      id: data?.id,
      header: e?.header,
    });

    if (response.data.success) {
      setData((prev) => ({ ...prev, header: e.header }));
      setStep();
    } else {
      message.error(response.data.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    setTempHeader(data?.header);
    form.setFieldValue("header", data?.header);
  }, [data?.header]);

  return (
    <>
      <Card size="small"
        className={`step-card no-shadow first-card ${
          active ? "active-step" : ""
        }`}
      >
        <Flex className="w-100" align="center" justify="space-between">
          
          <Flex align="cesnter" justify="start" gap={12}>
          <div>
            {data?.header ? (
              <div className="step-evaluation-box-success">
                <FaCheck />
              </div>
            ) : (
              <div className="step-evaluation-box-default"></div>
            )}
            </div>
            <Space direction="vertical" size={0}>
              <Title className="step-card-title zero-margin-padding" level={3}>
                Header
              </Title>
              {data?.header ? (
                <Text className="sender-preview-name">{data.header}</Text>
              ) : (
                <Text>Title displayed on top of your notification</Text>
              )}
            </Space>
          </Flex>
          {active ? (
            <Button
              type="text"
              onClick={handleCancel}
              className="flex-button"
              size="large"
              icon={<MdClose className="icon" />}
            ></Button>
          ) : (
            <Button className="create-notification-template-button large-font new-buttons" onClick={() => setStep(1)} size="large">
              Manage header
            </Button>
          )}
        </Flex>

        {active && (
          <div className="header-step-working-area">
            <Form form={form} onFinish={submit}>
              <Row gutter={[12, 12]}>
                <Col span={12}>
                  <Flex
                    className="w-100 h-100"
                    gap={6}
                    vertical
                    justify="center"
                  >
                    <Space direction="vertical" className="w-100" size={0}>
                      <Text className="thick-lable">Desing header</Text>
                        <InputBox name="header" onChange={(e) => setTempHeader(e)} form={form} max={128} rules={[
                          {
                            max:128
                          }
                        ]}/>
                    </Space>
                  </Flex>
                </Col>
                <Col span={12}>
                  <NotificationPreview
                    url={data?.url}
                    icon={`${process.env.REACT_APP_FILE_URL}${data?.path}`}
                    title={tempHeader}
                    message={data?.template}
                  />
                </Col>
              </Row>
            </Form>
            <Flex
              align="center"
              justify="end"
              gap={6}
              className="url-step-working-area-footer"
            >
              <Button
                size="large"
                onClick={() => setStep()}
                className="new-buttons large-font"
                >
                Cancel
              </Button>
              <Button
                size="large"
                type="primary"
                onClick={() => form.submit()}
                loading={loading}
                className="new-buttons large-font"
              >
                Save
              </Button>
            </Flex>
          </div>
        )}
      </Card>
    </>
  );
};


export default HeaderStep;
