import {
  Avatar,
  Button,
  Card,
  Col,
  Flex,
  message,
  Modal,
  Row,
  Select,
  Space,
  Typography,
} from "antd";
import { useEffect, useState } from "react";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import NotificationPreview from "./Create/NotificationPreview";
import { UserOutlined } from "@ant-design/icons";
import { Option } from "antd/es/mentions";
import BrowserNotification from "../../../shared/BrowserNotification";

const NotificationPreviewModal = ({ open, setOpen, id }) => {
  const { Text, Title } = Typography;

  const axios = useAxiosPrivate();

  const [data, setData] = useState();

  const [authors, setAuthors] = useState();

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    const fetchAuthors = async () => {
      const response = await axios.get(
        `/Notification/Possible-Notification-Recievers`,
        {
          signal: signal,
        }
      );

      if (response.data.success) {
        setAuthors(response.data?.items);
        setSelectedEmails([response.data.yourId]);
      }
    };
    const fetch = async () => {
      const response = await axios.get(`/Notification/Templates/${id}`, {
        signal: signal,
      });

      setData(response.data);
    };

    if (id && open) {
      fetch();
      fetchAuthors();
    }
    return () => controller.abort();
  }, [id]);

  const [selectedEmails, setSelectedEmails] = useState([]);

  const [loading, setLoading] = useState(false);

  const submit = async () => {
    setLoading(true);

    const response = await axios.post(`/Notification/Template/Tests`, {
      ids: selectedEmails,
      id: id,
    });

    if (response.data.success) {
      setOpen(false);
    } else {
      message.error(response.data.message);
    }

    setLoading(false);
  };
  return (
    <>
      <Modal
        width={1000}
        open={open}
        footer={false}
        onCancel={() => setOpen(false)}
        title={
          <Title style={{ margin: 0 }} level={4}>
            Preview notification desing
          </Title>
        }
      >
        <Row gutter={[24, 24]}>
          <Col span={16}>
            <NotificationPreview
              icon={`${process.env.REACT_APP_FILE_URL}${data?.path}`}
              url={data?.url}
              title={data?.header}
              message={data?.template}
            />
          </Col>

          <Col span={8}>
            <Card size="small" className="no-shadow">
              <Space direction="vertical" className="w-100" size={24}>
                <Space className="w-100" direction="vertical">
                  <Text className="create-preview-test-header">
                    Send test notification to your colleagues
                  </Text>
                  <Text>
                    Search your contacts by email to send them a test
                    notification:
                  </Text>
                </Space>
                <BrowserNotification />
                <Space direction="vertical" className="w-100" size={0}>
                  <Text className="thick-lable">Recipient</Text>

                  <Flex align="center" gap={6} wrap="wrap">
                    <Select
                      size="large"
                      value={selectedEmails}
                      className="w-100"
                      showSearch
                      onChange={(e) =>
                        setSelectedEmails((prev) => setSelectedEmails(e))
                      }
                      optionLabelProp="label"
                      mode="multiple"
                      dropdownRender={(menu) => <div>{menu}</div>}
                      filterOption={(input, option) =>
                        (option?.data ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      placeholder="Select recipients"
                    >
                      {authors?.map((c) => (
                        <Option
                          value={c.id}
                          label={c.email}
                          data={`${c.name} ${c.lastname}`}
                        >
                          <Flex align="center" justify="start" gap={6}>
                            <div>
                              <Avatar
                                icon={<UserOutlined />}
                                size="small"
                                src={`${process.env.REACT_APP_FILE_URL}${c.profileImagePath}`}
                              />
                            </div>
                            <Space
                              classNames="sender-email-wrapper"
                              className="w-100"
                              size={0}
                              direction="vertical"
                            >
                              <Text className="sender-email">
                                {c.name} {c.lastname}
                              </Text>

                              <Text className="sender-nickname-domain">
                                {c.email}
                              </Text>
                            </Space>
                          </Flex>
                        </Option>
                      ))}
                    </Select>
                    <div></div>
                  </Flex>
                </Space>

                <Flex align="center" justify="end" gap={6}>
                  <Button
                    onClick={submit}
                    size="large"
                    type="primary"
                    loading={loading}
                  >
                    Send
                  </Button>
                </Flex>
              </Space>
            </Card>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default NotificationPreviewModal;
