import { useEffect, useRef, useState } from "react";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import Card from "antd/es/card/Card";
import {
  Button,
  Col,
  Row,
  Space,
  Typography,
  Form,
  Input,
  message,
} from "antd";

const CurrentUser = () => {
  const axiosPrivate = useAxiosPrivate();

  const { Text } = Typography;
  const [form] = Form.useForm();
  const [user, setUser] = useState();

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    axiosPrivate.get("Users/CurrentUser").then((response) => {
      if (response && response.data.success) {
        setUser(response.data.data);
        form.setFieldsValue({
          username: response.data.data.userName,
          firstname: response.data.data.firstname,
          lastname: response.data.data.lastname,
          address: response.data.data.address,
          email: response.data.data.email,
          phone: response.data.data.phoneNumber,
        });
      }
    });
  }, []);

  const handleSubmit = async (e) => {
    setLoading(true);

    const response = await axiosPrivate.post("/Users/UpdateUser", {
      email: e.email,
      userName: e.username,
      firstName: e.firstname,
      lastName: e.lastname,
      location: e.address,
      phoneNumber: e.phone,
    });

    if (response && response.data.success) {
      message.success("Profile updated");
    } else {
      message.error(response.data.message);
    }

    setLoading(false);
  };

  return (
    <>
      {user && (
        <Row gutter={24}>
          <Col xs={24} sm={24} md={6} lg={6} xl={6} xx={6}>
            <CurrentUserProfileImage user={user} />
          </Col>

          <Col xs={24} sm={24} md={18} lg={18} xl={18} xxl={18}>
            <Card title="Account details">
              <Form onFinish={handleSubmit} form={form}>
                <Space style={{ width: "100%" }} direction="vertical">
                  <Row gutter={24}>
                    <Col span={24}>
                      <Text>
                        Username (how your name will appear to other users on
                        the site)
                      </Text>
                      <Form.Item name="username">
                        <Input
                          placeholder="Enter your username"
                          allowClear
                          size="large"
                        />
                      </Form.Item>
                    </Col>

                    <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                      <Text>First name</Text>
                      <Form.Item name="firstname">
                        <Input
                          allowClear
                          size="large"
                          placeholder="Enter your firstname"
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                      <Text>Last name</Text>
                      <Form.Item name="lastname">
                        <Input
                          allowClear
                          size="large"
                          placeholder="Enter your lastname"
                        />
                      </Form.Item>
                    </Col>

                    <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                      <Text>Organization name</Text>
                      <Form.Item>
                        <Input
                          disabled={true}
                          value={user.tenantName}
                          size="large"
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                      <Text>Location</Text>
                      <Form.Item name="address">
                        <Input
                          placeholder="Enter your address"
                          allowClear
                          size="large"
                        />
                      </Form.Item>
                    </Col>

                    <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                      <Text>Email address</Text>
                      <Form.Item name="email">
                        <Input
                          size="large"
                          placeholder="Enter your email"
                          allowClear
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                      <Text>Phone number</Text>
                      <Form.Item name="phone">
                        <Input
                          size="large"
                          placeholder="Enter your phone number"
                          allowClear
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Button
                    size="large"
                    type="primary"
                    loading={loading}
                    htmlType="submit"
                  >
                    Save changes
                  </Button>
                </Space>
              </Form>
            </Card>
          </Col>
        </Row>
      )}
    </>
  );
};

const CurrentUserProfileImage = ({ user }) => {
  const { Text } = Typography;

  const [fileName, setFileName] = useState(user.profileImagePath);

  const axios = useAxiosPrivate();

  const inputRef = useRef();

  const handleFileUpload = () => {
    inputRef.current.click();
  };

  const fileUploaded = async (e) => {
    if (e.target.files.length < 1) {
      return;
    }

    var formData = new FormData();
    formData.append("File", e.target.files[0]);

    const response = await axios.post(
      `Users/UpdateUserProfilePicture`,
      formData
    );

    if (response && response.data.success) {
      setFileName(response.data.data);
      message.success("Done");
    } else {
      message.error(response.data.message);
    }
  };
  return (
    <Card title="Your profile picture">
      <Space align="center" direction="vertical" style={{ width: "100%" }}>
        {fileName && (
          <div
            className=" rounded-circle mb-2 user-edit-profile-image"
            style={{ background: `URL(${process.env.REACT_APP_FILE_URL}${fileName})` }}
          ></div>
        )}
        {!fileName && (
          <div
            className=" rounded-circle mb-2 user-edit-profile-image"
            
          ></div>
        )}
        <Text>JPG or PNG no larger than 5 MB</Text>

        <Button type="primary" size="large" onClick={handleFileUpload}>
          Upload new image
        </Button>

        <input type="file" ref={inputRef} hidden onChange={fileUploaded} />
      </Space>
    </Card>
  );
};

export default CurrentUser;
