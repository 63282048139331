import { Button, Dropdown } from "antd";
import { BsFillTrashFill } from "react-icons/bs";
import { IoDuplicateSharp, IoEllipsisHorizontal } from "react-icons/io5";

const NotificationTemplatresOptions = ({template, setCurrent, openDelete, onDuplicate}) => {
  const items = [
    {
      key: "1",
      label: <p className="drop-down-text">Duplicate</p>,
      icon:<IoDuplicateSharp className="drop-down-icon"/>,
      onClick: () => onDuplicate(template?.id)
    },
    {
      type: 'divider',
    },
    {
      key: "2",
      label: <p className="drop-down-text">Delete</p>,
      danger: true,
      icon: <BsFillTrashFill className="drop-down-icon"/>,
      onClick: () => {
        setCurrent(template);
        openDelete();
      }
    }
  ];
  return (
    <Dropdown menu={{ items }} trigger={["click"]}>
      <Button
        type="text"
        className="flex-button"
        icon={<IoEllipsisHorizontal style={{ fontSize: 26 }}/>}
      />
    </Dropdown>
  );
};

export default NotificationTemplatresOptions;
