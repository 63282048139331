import { useContext } from "react";
import { Flex, Tag } from "antd";
import { CampaignContext } from "./CampaignContext";

const Stats = () => {
  const { stats } = useContext(CampaignContext);

  return (
    <Flex align="center" justify="start">
      <Tag  size="small" className="all-stats-tag default-stat-tag">
        {stats?.all ?? 0} Total
      </Tag>
      <Tag
        color="green"
        size="small"
        className="all-stats-tag"
      >
        {stats?.completed ?? 0} Completed
      </Tag>
      <Tag
        color="orange"
        size="small"
        className="all-stats-tag"
      >
        {stats?.scheduled ?? 0} Scheduled
      </Tag>
      <Tag
        color="red"
        size="small"
        className="all-stats-tag"
      >
        {stats?.draft ?? 0} Drafts
      </Tag>
    </Flex>
  );
};

export default Stats;
