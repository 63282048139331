import { Card, Drawer, Flex, Space, Typography } from "antd";
import { useEffect, useState } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import SimplePagination from "../../shared/SimplePagination";
import AvatarGroup from "../deals/Kanban/AvatarGroup";
import NoDataFound from "../../shared/NoDataFound";
import TableLoading from "../../shared/TableLoading";
import { DateFormat } from "../../dateformat";
import { useNavigate } from "react-router-dom";
import { getStage } from "../../shared/GetDealStage";

const CompanyDeals =({id, open, setOpen}) => {
    const { Text, Title } = Typography;

    const axios = useAxiosPrivate();
  
    const [count, setCount] = useState();
    const [deals, setDeals] = useState();
  
    const [perPage, setPerPage] = useState(25);
    const navigate = useNavigate();
  
    const [page, setPage] = useState(1);
    const [listLoading, setListLoading] = useState(false);
  
    useEffect(() => {
      const fetch = async () => {
        setListLoading(true);
        const response = await axios.post(`/Deal/List`, {
          page: 1,
          pageSize: perPage,
          companyIds: [id],
        });
  
        setCount(response.data.count);
        setDeals(response.data.data);
        setListLoading(false);
      };
  
      if (id) {
        fetch();
      }
    }, [id, page, perPage]);
  
    return (
      <Drawer
        open={open}
        onClose={() => setOpen(false)}
        title="Product deals list"
      >
        <Space direction="vertical" className="w-100">
          <Title level={1} className="zero-margin-padding">
            Deals ({count})
          </Title>
          <Space direction="vertical" className="w-100">
            {listLoading ? (
              <TableLoading />
            ) : !deals || deals.length == 0 ? (
              <NoDataFound />
            ) : (
              <>
                {deals.map((c, i) => (
                  <Card
                    key={i}
                    size="small"
                    className="no-shadow gray-border-hoverable"
                  >
                    <Space className="w-100" direction="vertical" size={24}>
                      <Title
                        className="zero-margin-padding deal-list-title"
                        onClick={() => navigate(`/Deals/Details/${c.id}`)}
                      >
                        {c.title}
                      </Title>
  
                      <Space className="w-100" direction="vertical" size={0}>
                        <Flex align="center" justify="start" gap={6}>
                          {c.closeDate && (
                            <Text>
                              Close Date:{" "}
                              {`${new Date(c.closeDate).toLocaleDateString(
                                "en-US",
                                DateFormat
                              )} • `}
                            </Text>
                          )}
  
                          <Text>
                            Amount:{" "}
                            {c?.amount?.toLocaleString("en-US", {
                              style: "currency",
                              currency: "USD",
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </Text>
                        </Flex>
                        <Text>
                          {c.products?.length > 0 && (
                            <Text>
                              Products:
                              {c.products?.length <= 1
                                ? ` ${c.products[0].name}`
                                : ` ${c.products[0].name}, `}
                            </Text>
                          )}
                          {c.products?.length > 1 ? (
                            <Text>+{c.products?.length - 1} more...</Text>
                          ) : (
                            ""
                          )}
                        </Text>
                      </Space>
  
                      <Flex
                        className="w-100"
                        align="center"
                        justify="space-between"
                      >
                        <AvatarGroup items={c.owners} />
                        {getStage(c.stage)}
                      </Flex>
                    </Space>
                  </Card>
                ))}
  
                <SimplePagination
                  page={page}
                  showChanger
                  setPerPage={setPerPage}
                  pageSize={perPage}
                  total={count}
                  setPage={setPage}
                />
              </>
            )}
          </Space>
        </Space>
      </Drawer>
    );
}

export default CompanyDeals