import { Button, Card, Dropdown, Flex, message, notification, Space, Typography } from "antd";
import { FaRegTrashAlt } from "react-icons/fa";
import { IoEllipsisHorizontal } from "react-icons/io5";
import { DateFormat } from "../../../dateformat";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { useEffect, useState } from "react";
import QueryString from "qs";
import TableLoading from "../../../shared/TableLoading";
import { useParams } from "react-router-dom";
import AddTagToContactSider from "../AddTagToContactSider";
import SimplePagination from "../../../shared/SimplePagination";
import NoDataFound from "../../../shared/NoDataFound";

const ContactTags = ({ fullName }) => {
  const { Text, Title } = Typography;
  const { id } = useParams();
  const axios = useAxiosPrivate();

  const [editTagsOpen, setEditTagsOpen] = useState(false);

  const [tags, setTags] = useState();
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);

  const removeFromTag = async (tagId) => {
    const response = await axios.delete(
      `/Contacts/Remove-From-Tag/${tagId}/${id}`
    );

    if (response.data.success) {
      setTags((prev) => prev.filter((c) => c.id != tagId));
      setTotal(prev => prev - 1)
      notification.open({
        message: (
          <Flex
            style={{ width: "200px", height: "50px" }}
            className="h-100"
            align="center"
            justify="center"
          >
            {" "}
            <Text className="large-font">Assoication removed</Text>
          </Flex>
        ),
        showProgress: true,
        placement: "bottom",
        btn: null,
        closeIcon: null,
      });
    } else {
      message.error(response.data.message);
    }
  };

  const onTagsUpdate = (id, newTags) => {
    setTags(newTags);
    setTotal(newTags?.length)
  };

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    const fetch = async () => {
      setLoading(true);
      const response = await axios.get(`/Contacts/Tags-list`, {
        params: {
          id: id,
          page: page,
          pageSize: pageSize,
        },
        paramsSerializer: (params) => {
          return QueryString.stringify(params, {
            arrayFormat: "repeat",
            skipNulls: true,
          });
        },
        signal: signal,
      });

      setTotal(response.data.total);
      setTags(response.data.items);
      setLoading(false);
    };

    fetch();

    return () => controller.abort();
  }, [page, pageSize]);

  return (
    <>
      <AddTagToContactSider
        onUpdate={onTagsUpdate}
        contact={{
          id: id,
          audiences: tags,
          firstname: `${fullName}`,
        }}
        open={editTagsOpen}
        setOpen={setEditTagsOpen}
      />

      <Space className="w-100" direction="vertical">
        <Flex align="center" justify="space-between">
          <Title level={4} style={{ margin: 0 }}>
            Contact tags ({total ?? 0})
          </Title>
          <Button
            className="new-buttons large-font"
            onClick={() => setEditTagsOpen(true)}
          >
            Edit
          </Button>
        </Flex>
        {loading ? (
          <TableLoading />
        ) : tags && tags?.length > 0 ? (
          <Space direction="vertical" className="w-100">
            {tags?.map((c, i) => (
              <Card key={i} size="small" className="gray-border no-shadow">
                <Flex align="center" justify="space-between">
                  <Space size={0} direction="vertical">
                    <Text strong type="link">
                      {c.name}
                    </Text>
                    <Text>
                      #{c.id} • 
                      Assigned at{" "}
                      {new Date(c.assignedAt).toLocaleDateString(
                        "en-us",
                        DateFormat
                      )}
                    </Text>
                  </Space>
                  <Dropdown
                    menu={{
                      items: [
                        {
                          key: 1,
                          label: (
                            <p className="drop-down-text">Remove from tag</p>
                          ),
                          icon: <FaRegTrashAlt className="drop-down-icon" />,
                          danger: true,
                          onClick: () => removeFromTag(c.id),
                        },
                      ],
                    }}
                    trigger={["click"]}
                  >
                    <Button
                      size="large"
                      className="flex-button"
                      type="text"
                      icon={<IoEllipsisHorizontal style={{ fontSize: 26 }} />}
                    ></Button>
                  </Dropdown>
                </Flex>
              </Card>
            ))}
          <SimplePagination showChanger page={page} pageSize={pageSize} total={total} setPage={setPage} setPerPage={setPageSize}/>
          </Space>
        ) : (
          <NoDataFound description={"Current contact is not associated with any tags"}/>
        )}

      </Space>
    </>
  );
};

export default ContactTags;
