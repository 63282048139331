import { Button, Dropdown } from "antd";
import { AiFillEdit } from "react-icons/ai";
import { BsFillTrashFill } from "react-icons/bs";
import { FaBriefcase, FaCheckSquare } from "react-icons/fa";
import { IoLogoWhatsapp } from "react-icons/io";
import { IoEllipsisHorizontal } from "react-icons/io5";
import { MdCall, MdEmail, MdSms } from "react-icons/md";
import { RiWebcamFill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";

const ContactOptions = ({
  onDealsClicked,
  contact,
  setCurrent,
  openEdit,
  openDelete,
  onSend,
}) => {
  const navigate = useNavigate();

  const items = [
    {
      key: "-3",
      label: <p className="drop-down-text">Deals</p>,
      icon: <FaBriefcase className="drop-down-icon" />,
      onClick: (e) => {
        e.domEvent.stopPropagation();
        onDealsClicked(contact);
      },
    },
    {
      type: "divider",
    },
    {
      key: "-2",
      disabled: true,
      label: <p className="drop-down-text">Verify</p>,
      icon: <FaCheckSquare className="drop-down-icon" />,
      onClick: (e) => {
        e.domEvent.stopPropagation();
        onSend(contact);
      },
    },
    {
      type: "divider",
    },
    {
      key: "-1",
      label: <p className="drop-down-text">Email</p>,
      icon: <MdEmail className="drop-down-icon" />,
      onClick: (e) => {
        e.domEvent.stopPropagation();
        onSend(contact);
      },
    },
    {
      key: "0",
      label: <p className="drop-down-text">Whatsapp</p>,
      icon: <IoLogoWhatsapp className="drop-down-icon" />,
      disabled: true,
      onClick: (e) => {
        setCurrent(contact);
        openEdit();
        e.domEvent.stopPropagation();
      },
    },
    {
      key: "1",
      disabled: true,
      label: <p className="drop-down-text">SMS</p>,
      icon: <MdSms className="drop-down-icon" />,
      onClick: (e) => {
        setCurrent(contact);
        openEdit();
        e.domEvent.stopPropagation();
      },
    },
    {
      type: "divider",
    },
    {
      key: "2",
      disabled: true,
      label: <p className="drop-down-text">Meet</p>,
      icon: <RiWebcamFill className="drop-down-icon" />,
      onClick: (e) => {
        e.domEvent.stopPropagation();
        setCurrent(contact);
        openEdit();
      },
    },
    {
      key: "3",
      label: <p className="drop-down-text">Call</p>,
      icon: <MdCall className="drop-down-icon" />,
      onClick: (e) => {
        e.domEvent.stopPropagation();
        navigate(`/dial/number?number=${contact.phone}`);
      },
    },
    {
      type: "divider",
    },
    {
      key: "4",
      label: <p className="drop-down-text">Edit</p>,
      icon: <AiFillEdit className="drop-down-icon" />,
      onClick: (e) => {
        e.domEvent.stopPropagation();
        setCurrent(contact);
        openEdit();
      },
    },
    {
      key: "5",
      label: <p className="drop-down-text">Delete</p>,
      danger: true,
      icon: <BsFillTrashFill className="drop-down-icon" />,
      onClick: (e) => {
        e.domEvent.stopPropagation();
        setCurrent(contact);
        openDelete();
      },
    },
  ];
  return (
    <Dropdown trigger={["click"]} menu={{ items }}>
      <Button
        type="text"
        size="large"
        className="flex-button"
        onClick={(e) => e.stopPropagation()}
        icon={<IoEllipsisHorizontal style={{ fontSize: 26 }} />}
      />
    </Dropdown>
  );
};

export default ContactOptions;
