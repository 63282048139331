import {
  Button,
  Badge,
  Space,
  Flex,
  Typography,
  Popover,
  Card
} from "antd";
import { useContext, useEffect, useState } from "react";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import bell from "../../../assets/icons/bell.png";
import QueryString from "qs";
import { NotificationTypes } from "../../../shared/enums";
import { DateFormat } from "../../../dateformat";
import SimplePagination from "../../../shared/SimplePagination";
import TableLoading from "../../../shared/TableLoading";
import NoDataFound from "../../../shared/NoDataFound";
import { useNavigate } from "react-router-dom";
import { CurrentUserContext } from "../../../context/CurrentUserContext";

const Notifications = () => {
  const axios = useAxiosPrivate();
  const { Text, Title } = Typography;
  const [items, setItems] = useState([]);
  const pageSize = 10;
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true)
    axios
      .get("/notifications", {
        params: {
          page: page,
          pageSize: pageSize,
        },
        paramsSerializer: (params) => {
          return QueryString.stringify(params, {
            arrayFormat: "repeat",
            skipNulls: true,
          });
        },
      })
      .then((response) => {
        setTotal(response.data.count);
        setItems(response.data.data);

        setLoading(false)
      })
      .catch((error) => {
        console.error("Error fetching notifications:", error);
      });
  }, [page]);



  const [clearing, setClearing] = useState(false)
  const handleClear = async () => {
    setClearing(true)
    const response = await axios.delete('/notifications')

    if (response.data.success) {
      setTotal(0);
      setItems([])
    }

    setClearing(false)
  }

  const handleClearById = async (id) => {
    const response = await axios.delete(`/notifications/${id}`)

    if (response.data.success) {
      setTotal(prev => prev - 1);
      setItems(prev => prev.filter(c => c.id != id));
    }
  }

  return (
    <Popover
      trigger={["click"]}
      content={
        <Space style={{ padding: 20 }} direction="vertical">
          <Flex align="center" justify="space-between">
            <Title className="zero-margin-padding">Notifications</Title>

            {total > 0 &&
              <Button
                className="new-buttons large-font"
                type="link"
                size="small"
                loading={clearing}
                onClick={handleClear}
              >
                Clear all
              </Button>
            }
          </Flex>

          {loading ? <TableLoading /> :
            items && items.length > 0 ? <>
              <Space direction="vertical" className="agents-scroll-wrapper">
                {items.map((c, i) => (
                  <Card size="small" className="no-shadow gray-border" key={i}>
                    {c.notificationType == NotificationTypes.TaskOwnerSet ? (
                      <TaskOwnerSet onClear={handleClearById} item={c} />
                    ) : c.notificationType ==
                      NotificationTypes.TaskMarkedDone ? (
                      <TaskMarkedDone onClear={handleClearById} item={c} />
                    ) : c.notificationType == NotificationTypes.TaskReopen ? (
                      <TaskReopen onClear={handleClearById} item={c} />
                    ) : c.notificationType ==
                      NotificationTypes.TaskFollowUpCreated ? (
                      <TaskFollowUpCreated onClear={handleClearById} item={c} />
                    ) : c.notificationType == NotificationTypes.TaskRemoved ? (
                      <TaskRemoved onClear={handleClearById} item={c} />
                    ) : (
                      c.notificationType == NotificationTypes.ContactDeleted ?
                      <ContactRemoved onClear={handleClearById} item={c} />
                        : c.notificationType == NotificationTypes.ContactOwnerset ?
                      <ContactOwnerSet onClear={handleClearById} item={c} /> :
                          c.notificationType == NotificationTypes.CallMissed ? 
                      <ContactMissedCall item={c} onClear={handleClearById} /> : "")}
                  </Card>
                ))}
              </Space>
              <SimplePagination
                page={page}
                pageSize={pageSize}
                total={total}
                setPage={setPage}
              />
            </> : <div style={{ width: "400px" }}><NoDataFound description="You are all caught up" /></div>}

        </Space>
      }
    >
      <Badge count={total} size="large" offset={[-5, 5]}>
        <Button
          shape="circle"
          type="text"
          size="large"
          style={{ color: "#607282" }}
          className="flex-button"
          icon={<img src={bell} width={22} />}
        />
      </Badge>
    </Popover>
  );
};

const ContactMissedCall = ({ item, onClear }) => {
  const { Text, Title } = Typography;

  const navigate = useNavigate();
  const handleOpen = () => {
    onClear(item.id);
    navigate(`/contacts/${item.call.id}`)
  }

  const handleClear = () => {
    onClear(item.id)
  }


  return (
    <>
      <Space direction="vertical" className="w-100">
        <Space direction="vertical" size={0} className="w-100">
          <Flex justify="space-between" align="center">
            <Title level={4} className="zero-margin-padding">
              Missed call
            </Title>


            <Text className="text-small">
              {new Date(item.dateCreated).toLocaleTimeString(
                "en-us",
                DateFormat
              )}
            </Text>
          </Flex>
          <Text>
            You had a missed call from {item.call.contactName}
          </Text>
        </Space>

        <Flex align="center" justify="end" gap={6}>
          <Button
            size="small"
            type="text"
            className="new-buttons bold"
            onClick={handleClear}
          >
            Clear
          </Button>
          <Button onClick={handleOpen} type="text" size="small" className="new-buttons bold">
            Open
          </Button>
        </Flex>
      </Space>
    </>
  );
}

const ContactOwnerSet = ({ item, onClear }) => {
  const { Text, Title } = Typography;

  const handleClear = () => {
    onClear(item.id)
  }

  const navigate = useNavigate();
  const handleOpen = () => {
    onClear(item.id);
    navigate(`/contacts/${item.contact.id}`)
  }

  const { user } = useContext(CurrentUserContext)

  return (
    <>
      <Space direction="vertical" className="w-100">
        <Space direction="vertical" size={0} className="w-100">
          <Flex justify="space-between" align="center">
            <Title level={4} className="zero-margin-padding">
              Contact
            </Title>


            <Text className="text-small">
              {new Date(item.dateCreated).toLocaleTimeString(
                "en-us",
                DateFormat
              )}
            </Text>
          </Flex>
          <Text>
            {item.author.name} set {user.id == item.contact.owner.id ? 'you' : item.contact.owner.name} as owner for {item.contact.name}.
          </Text>
        </Space>

        <Flex align="center" justify="end" gap={6}>
          <Button
            size="small"
            type="text"
            className="new-buttons bold"
            onClick={handleClear}
          >
            Clear
          </Button>
          <Button onClick={handleOpen} type="text" size="small" className="new-buttons bold">
            Open
          </Button>
        </Flex>
      </Space>
    </>
  );
}

const ContactRemoved = ({ item, onClear }) => {
  const { Text, Title } = Typography;

  const handleClear = () => {
    onClear(item.id)
  }

  return (
    <>
      <Space direction="vertical" className="w-100">
        <Space direction="vertical" size={0} className="w-100">
          <Flex justify="space-between" align="center">
            <Title level={4} className="zero-margin-padding">
              Contact
            </Title>


            <Text className="text-small">
              {new Date(item.dateCreated).toLocaleTimeString(
                "en-us",
                DateFormat
              )}
            </Text>
          </Flex>
          <Text>
            {item.author.name} removed {item.contact.name}.
          </Text>
        </Space>

        <Flex align="center" justify="end" gap={6}>
          <Button
            size="small"
            type="text"
            className="new-buttons bold"
            onClick={handleClear}
          >
            Clear
          </Button>
          <Button disabled type="text" size="small" className="new-buttons bold">
            Open
          </Button>
        </Flex>
      </Space>
    </>
  );
}

const TaskFollowUpCreated = ({ item, onClear }) => {
  const { Text, Title } = Typography;

  const handleClear = () => {
    onClear(item.id)
  }

  const navigate = useNavigate();
  const handleOpen = () => {
    navigate(`/Deals/Details/${item.notificationData.dealId}`)
    onClear(item.id)
  }
  return (
    <>
      <Space direction="vertical" className="w-100">
        <Space direction="vertical" size={0} className="w-100">
          <Flex justify="space-between" align="center">
            <Title level={4} className="zero-margin-padding">
              Task
            </Title>


            <Text className="text-small">
              {new Date(item.dateCreated).toLocaleTimeString(
                "en-us",
                DateFormat
              )}
            </Text>
          </Flex>
          <Text>
            {item.author.name} created followup for task {item.task.name}.
          </Text>
        </Space>

        <Flex align="center" justify="end" gap={6}>
          <Button
            size="small"
            type="text"
            className="new-buttons bold"
            onClick={handleClear}
          >
            Clear
          </Button>
          <Button onClick={handleOpen} type="text" size="small" className="new-buttons bold">
            Open
          </Button>
        </Flex>
      </Space>
    </>
  );
};

const TaskRemoved = ({ item, onClear }) => {
  const { Text, Title } = Typography;

  const handleClear = () => {
    onClear(item.id)
  }

  return (
    <Space direction="vertical" className="w-100">
      <Space direction="vertical" size={0} className="w-100">
        <Flex justify="space-between" align="center">
          <Title level={4} className="zero-margin-padding">
            Task
          </Title>
          <Text className="text-small">
            {new Date(item.dateCreated).toLocaleTimeString(
              "en-us",
              DateFormat
            )}
          </Text>
        </Flex>
        <Text>
          <Text className="text-semi-bold">{item.author.name} removed {item.task.highPriority ? "high priority" : ""}
            {item.task.name}</Text>.
        </Text>
      </Space>
      <Flex align="center" justify="end" gap={6}>
        <Button
          type="text"
          size="small"
          className="new-buttons bold"
          onClick={handleClear}
        >
          Clear
        </Button>
        <Button disabled type="text" size="small" className="new-buttons bold">
          Open
        </Button>
      </Flex>
    </Space>
  );
};

const TaskReopen = ({ item, onClear }) => {
  const { Text, Title } = Typography;

  const handleClear = () => {
    onClear(item.id)
  }

  const navigate = useNavigate();
  const handleOpen = () => {
    onClear(item.id)
    navigate(`/Deals/Details/${item.notificationData.dealId}`)
  }

  return (
    <>
      <Space direction="vertical" className="w-100">
        <Space direction="vertical" size={0} className="w-100">
          <Flex justify="space-between" align="center">
            <Title level={4} className="zero-margin-padding">
              Task
            </Title>

            <Text className="text-small">
              {new Date(item.dateCreated).toLocaleTimeString(
                "en-us",
                DateFormat
              )}
            </Text>
          </Flex>
          <Text>
            {item.author.name} re-opened task {item.task.name}.
          </Text>
        </Space>
        <Flex align="center" justify="end" gap={6}>
          <Button
            type="text"
            size="small"
            onClick={handleClear}
            className="new-buttons bold"
          >
            Clear
          </Button>
          <Button onClick={handleOpen} type="text" size="small" className="new-buttons bold">
            Open
          </Button>
        </Flex>
      </Space>
    </>
  );
};

const TaskMarkedDone = ({ item, onClear }) => {
  const { Text, Title } = Typography;

  const handleClear = () => {
    onClear(item.id)
  }

  const navigate = useNavigate();
  const handleOpen = () => {
    onClear(item.id)
    navigate(`/Deals/Details/${item.notificationData.dealId}`)
  }
  return (
    <>
      <Space direction="vertical" className="w-100">
        <Space direction="vertical" size={0} className="w-100">
          <Flex justify="space-between" align="center">
            <Title level={4} className="zero-margin-padding">
              Task
            </Title>
            <Text className="text-small">
              {new Date(item.dateCreated).toLocaleTimeString(
                "en-us",
                DateFormat
              )}
            </Text>
          </Flex>
          <Text className="text-semi-bold">
            {item.author.name} marked {item.task.name} as done.
          </Text>
        </Space>
        <Flex align="center" justify="end" gap={6}>
          <Button
            type="text"
            size="small"
            onClick={handleClear}
            className="new-buttons bold"
          >
            Clear
          </Button>
          <Button onClick={handleOpen} type="text" size="small" className="new-buttons bold">
            Open
          </Button>
        </Flex>
      </Space>
    </>
  );
};

const TaskOwnerSet = ({ item, onClear }) => {
  const { Text, Title } = Typography;

  const handleClear = () => {
    onClear(item.id)
  }

  const navigate = useNavigate();
  const handleOpen = () => {
    onClear(item.id)
    navigate(`/Deals/Details/${item.notificationData.dealId}`)
  }

  const { user } = useContext(CurrentUserContext)
  return (
    <>
      <Space direction="vertical" className="w-100">
        <Space direction="vertical" size={0} className="w-100">
          <Flex justify="space-between" align="center">
            <Title level={4} className="zero-margin-padding">
              Task
            </Title>

            <Text className="text-small">
              {new Date(item.dateCreated).toLocaleTimeString(
                "en-us",
                DateFormat
              )}
            </Text>
          </Flex>
          <Text className="text-semi-bold">
            {item.author.name} set {item.task.owners?.map((d, i) => (d.id == user.id ? 'you' : `${d.name}`) + (i < item.task.owners?.length - 1 ? ',' : '') + " ")}
            as owners for {item.task?.name}.
          </Text>
        </Space>
        <Flex align="center" justify="end" gap={6}>
          <Button
            size="small"
            type="text"
            className="new-buttons bold"
            onClick={handleClear}
          >
            Clear
          </Button>
          <Button type="text" onClick={handleOpen} size="small" className="new-buttons bold">
            Open
          </Button>
        </Flex>
      </Space>
    </>
  );
};

export default Notifications;
