import {
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import React, { useContext, useMemo } from "react";
import KanbanCard from "./KanbanCard";
import { useDroppable } from "@dnd-kit/core";
import { Typography, Divider, Flex, Skeleton } from "antd";
import { DealListContext } from "../DealListContext";
import InfiniteScroll from 'react-infinite-scroll-component';
import "./KanbanColumn.css"

const KanbanColumn = ({
  column,
  cards,
  setKanbanCards,
  cardDropdownOptions,
  revenue,
  total,
  onFetch,
  onDelete
}) => {
  const { Title, Text } = Typography;
  const { filters } = useContext(DealListContext);

  const cardIds = useMemo(() => cards?.items?.map((cards) => cards.id), [cards]);

  const { setNodeRef } = useDroppable({
    id: column.id,
    data: {
      type: "column",
      column,
    },
  });

  return (
    <div id={`col_${column.id}`}
      ref={setNodeRef}
      className="kanbanColumn kanban-column-border"
    >
      <div>
        <div
          className="kanban-column-wrapper"
        >
            <Title className="zero-bottom-margin"  level={4}>
              {column.title} ({total})
            </Title>
            <Text>{`Est. Revenue: ${revenue} $`}</Text>
          <Divider className="zero-margin-padding w-100" />
        </div>
        {useMemo(() => {
          return (
            <div className="flex-1 flex flex-col sortableContainer">
              <SortableContext
                items={cardIds}
                strategy={verticalListSortingStrategy}
                id="cards"
              >
              <InfiniteScroll
                dataLength={cards.items.length}
                next={() => onFetch(column.id)}
                hasMore={total > cards.items.length}
                loader={<Skeleton.Input className="w-100" style={{height:150, marginTop:12}} active/>}
                scrollableTarget={`col_${column.id}`}>
                {cards?.items?.map((card) => (
                  <KanbanCard
                    card={card}
                    key={card.id}
                    cardDropdownOptions={cardDropdownOptions}
                    setKanbanCards={setKanbanCards}
                    onDelete={onDelete}
                    />
                  ))}
                  </InfiniteScroll>
              </SortableContext>
            </div>
          );
        }, [cards, filters.cardItems])}
      </div>
    </div>
  );
};

export default KanbanColumn;
