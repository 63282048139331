import { Space, Tabs, Typography } from "antd";
import EmaiLSenderLayout from "./EmailSenderLayout";
import SmsSenderListLayout from "./sms/SmsSendersLayout";

const SenderLayout = () => {
  const { Title, Text } = Typography;
  const items = [
    {
      key: 1,
      label: "Email",
      children: <EmaiLSenderLayout />,
    },
    {
        key: 3,
        label: "Phone",
        children: <SmsSenderListLayout/>
    },
    {
      key: 2,
      label: "Whatsapp",
      disabled:true
    }
  ];

  return (
    <Space className="w-100" direction="vertical" size={24}>
      <Space className="w-100" direction="vertical" size={0}>
        <Title level={4} className="zero-margin-padding">
          Senders
        </Title>
        <Text>Select type of sender you want to interact with</Text>
      </Space>
      <Tabs type="card" size="large" items={items} tabPosition="left" />
    </Space>
  );
};

export default SenderLayout;
