import {
  Card,
  Flex,
  Row,
  Space,
  Typography,
  Col,
  InputNumber,
  Button,
  message,
  Divider,
} from "antd";
import { useEffect, useState } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

const ManagePrices = () => {
  const { Title, Text } = Typography;
  const axios = useAxiosPrivate();

  const [pro, setPro] = useState(() => ({
    price: 0,
    loading: false,
  }));

  const [ent, setEnt] = useState(() => ({
    price: 0,
    loading: false,
  }));

  const [email, setEmail] = useState(() => ({
    limits: 0,
    price: 0,
    loading: false,
  }));

  const [whatsapp, setWhatsapp] = useState(() => ({
    limits: 0,
    percentage: 0,
    noChargePrice: 0,
    loading: false,
  }));

  const [sms, setSms] = useState(() => ({
    limits: 0,
    percentage: 0,
    noChargePrice: 0,
    loading: false,
  }));

  useEffect(() => {
    const fetch = async () => {
    
    const response = await axios.get(`/Prices`);

    setPro((prev) => ({ ...prev, price: response.data.pricePer1MemberPRO }));
    setEnt((prev) => ({ ...prev, price: response.data.pricePer1MemberENT }));

    setEmail((prev) => ({
      ...prev,
      limits: response.data.emailLimit,
      price: response.data.emailPrice,
      bonus: response.data.emailBonus,
    }));

    setSms((prev) => ({
      ...prev,
      limits: response.data.smsLimit,
      percentage: response.data.smsPercentage,
      noChargePrice: response.data.smsNoChargePrice,
      bonus: response.data.smsBonus,
    }));

    setWhatsapp((prev) => ({
      ...prev,
      limits: response.data.whatsappLimit,
      percentage: response.data.whatsAppPercentage,
      noChargePrice: response.data.whatsappNoChargePrice,
      bonus: response.data.whatsappBonus,
    }));
  }
  fetch();
  }, []);

  const submitEmail = async () => {
    setEmail((prev) => ({ ...prev, loading: true }));

    const response = await axios.put(
      `/Prices/email/${email.price}/${email.limits}/${email.bonus}`
    );

    if (!response.data.success) {
      message.error(response.data.message);
    }

    setEmail((prev) => ({ ...prev, loading: false }));
  };

  const submitWhatsapp = async () => {
    setWhatsapp((prev) => ({ ...prev, loading: true }));

    const response = await axios.put(
      `/Prices/whatsapp/${whatsapp.percentage}/${whatsapp.limits}/${whatsapp.noChargePrice}/${whatsapp.bonus}`
    );

    if (!response.data.success) {
      message.error(response.data.message);
    }

    setWhatsapp((prev) => ({ ...prev, loading: false }));
  };

  const submitSms = async () => {
    setSms((prev) => ({ ...prev, loading: true }));

    const response = await axios.put(
      `/Prices/sms/${sms.percentage}/${sms.limits}/${sms.noChargePrice}/${sms.bonus}`
    );

    if (!response.data.success) {
      message.error(response.data.message);
    }

    setSms((prev) => ({ ...prev, loading: false }));
  };

  const submitProPrice = async () => {
    setPro((prev) => ({ ...prev, loading: true }));

    const response = await axios.put(`/prices/audience/pro/${pro.price}`);

    if (!response.data.success) {
      message.error(response.data.message);
    }

    setPro((prev) => ({ ...prev, loading: false }));
  };

  const submitEntPrice = async () => {
    setEnt((prev) => ({ ...prev, loading: true }));

    const response = await axios.put(`/prices/audience/ent/${pro.price}`);

    if (!response.data.success) {
      message.error(response.data.message);
    }

    setEnt((prev) => ({ ...prev, loading: false }));
  };

  return (
    <Space direction="vertical" size={24} className="w-100">

<Space direction="vertical" className="w-100">

      <Title style={{ margin:0, marginBottom: 5 }} level={4}>
        Communication
      </Title>
      <Row gutter={[24, 24]}>
        <Col xs={24} sm={24} md={6}>
        <Title level={5}>Email</Title>
          <Card style={{boxShadow:"none"}}>
            <Space direction="vertical" size="large" className="w-100">
              <Space direction="vertical" className="w-100">
                <Space direction="vertical" size={0} className="w-100">
                  <Text strong>Limits</Text>

                  <InputNumber
                    className="w-100"
                    size="large"
                    addonAfter="m"
                    value={email.limits}
                    onChange={(e) =>
                      setEmail((prev) => ({ ...prev, limits: e }))
                    }
                    min={0}
                  />
                </Space>

                <Space direction="vertical" size={0} className="w-100">
                  <Title level={5} style={{ margin: 0 }}>
                    Prices
                  </Title>
                  <InputNumber
                    value={email.price}
                    onChange={(e) =>
                      setEmail((prev) => ({ ...prev, price: e }))
                    }
                    className="w-100"
                    size="large"
                    addonAfter="$"
                  />
                </Space>

                <Space size={0} className="w-100" direction="vertical">
                  <Title level={5} style={{ margin: 0 }}>
                    No charge price
                  </Title>
                  <InputNumber
                    min={0}
                    disabled
                    className="w-100"
                    size="large"
                    addonAfter="$"
                    k
                  />
                </Space>

                <Space size={0} className="w-100" direction="vertical">
                  <Title level={5} style={{ margin: 0 }}>
                    Signup bonus
                  </Title>
                  <InputNumber
                    value={email.bonus}
                    min={0}
                    onChange={(e) =>
                      setEmail((prev) => ({ ...prev, bonus: e }))
                    }
                    className="w-100"
                    size="large"
                  />
                </Space>
              </Space>
              <Flex align="center" justify="end">
                <Button
                  size="large"
                  className="bold-button"
                  type="primary"
                  onClick={() => submitEmail()}
                  loading={email.loading}
                >
                  Save changes
                </Button>
              </Flex>
            </Space>
          </Card>
        </Col>

        <Col xs={24} sm={24} md={6}>
        <Title level={5}>Whatsapp</Title>
          <Card style={{boxShadow:"none"}}>
            <Space direction="vertical" size="large" className="w-100">
              <Space direction="vertical" className="w-100">
                <Space size={0} direction="vertical" className="w-100">
                  <Text strong>Limits</Text>

                  <InputNumber
                    min={0}
                    value={whatsapp.limits}
                    onChange={(e) =>
                      setWhatsapp((prev) => ({ ...prev, limits: e }))
                    }
                    className="w-100"
                    size="large"
                    addonAfter="m"
                  />
                </Space>

                <Space size={0} direction="vertical" className="w-100">
                  <Text strong>Extra percentage</Text>
                  <InputNumber
                    min={0}
                    className="w-100"
                    size="large"
                    addonAfter="%"
                    value={whatsapp.percentage}
                    onChange={(e) =>
                      setWhatsapp((prev) => ({ ...prev, percentage: e }))
                    }
                  />
                </Space>

                <Space size={0} className="w-100" direction="vertical">
                  <Text strong>No charge price</Text>
                  <InputNumber
                    value={whatsapp.noChargePrice}
                    onChange={(e) =>
                      setWhatsapp((prev) => ({ ...prev, noChargePrice: e }))
                    }
                    min={0}
                    className="w-100"
                    size="large"
                    addonAfter="$"
                  />
                </Space>
                <Space size={0} className="w-100" direction="vertical">
                  <Title level={5} style={{ margin: 0 }}>
                    Signup bonus
                  </Title>
                  <InputNumber
                    min={0}
                    className="w-100"
                    size="large"
                    addonAfter=""
                    value={whatsapp.bonus}
                    onChange={(e) =>
                      setWhatsapp((prev) => ({ ...prev, bonus: e }))
                    }
                  />
                </Space>
              </Space>
              <Flex align="center" justify="end">
                <Button
                  className="bold-button"
                  size="large"
                  type="primary"
                  loading={whatsapp.loading}
                  onClick={() => submitWhatsapp()}
                >
                  Save changes
                </Button>
              </Flex>
            </Space>
          </Card>
        </Col>

        <Col xs={24} sm={24} md={6}>
        <Title level={5}>SMS</Title>
          <Card style={{boxShadow:"none"}}>
            <Space direction="vertical" className="w-100" size={"large"}>
              <Space className="w-100" direction="vertical">
                <Space className="w-100" size={0} direction="vertical">
                  <Text strong>Limits</Text>
                  <InputNumber
                    min={0}
                    className="w-100"
                    size="large"
                    addonAfter="m"
                    value={sms.limits}
                    onChange={(e) => setSms((prev) => ({ ...prev, limits: e }))}
                  />
                </Space>
                <Space className="w-100" size={0} direction="vertical">
                  <Text strong>Extra percentage</Text>
                  <InputNumber
                    value={sms.percentage}
                    onChange={(e) =>
                      setSms((prev) => ({ ...prev, percentage: e }))
                    }
                    min={0}
                    className="w-100"
                    size="large"
                    addonAfter="%"
                  />
                </Space>
                <Space className="w-100" size={0} direction="vertical">
                  <Text strong>No charge price</Text>
                  <InputNumber
                    value={sms.noChargePrice}
                    onChange={(e) =>
                      setSms((prev) => ({ ...prev, noChargePrice: e }))
                    }
                    min={0}
                    className="w-100"
                    size="large"
                    addonAfter="$"
                  />
                </Space>
                <Space size={0} className="w-100" direction="vertical">
                  <Title level={5} style={{ margin: 0 }}>
                    Signup bonus
                  </Title>
                  <InputNumber
                    min={0}
                    className="w-100"
                    size="large"
                    value={sms.bonus}
                    onChange={(e) => setSms((prev) => ({ ...prev, bonus: e }))}
                  />
                </Space>
              </Space>

              <Flex align="center" justify="end">
                <Button
                  className="bold-button"
                  size="large"
                  type="primary"
                  loading={sms.loading}
                  onClick={() => submitSms()}
                >
                  Save changes
                </Button>
              </Flex>
            </Space>
          </Card>
        </Col>
      </Row>
      </Space>

      <Title style={{ margin:0, marginBottom: 5 }} level={4}>
        Audience
      </Title>
  
      
      <Row gutter={[24,24]}>
        <Col xs={24} md={6}>
        <Title level={5}>1 PRO member</Title>
          <Card style={{boxShadow:"none"}}>
            <Space className="w-100" direction="vertical" size="large">
              <Space direction="vertical" size={0} className="w-100">
                <Text strong>Price</Text>
                <InputNumber
                  value={pro.price}
                  onChange={(e) => setPro((prev) => ({ ...prev, price: e }))}
                  className="w-100"
                  addonAfter="$"
                  size="large"
                />
              </Space>

              <Flex align="center" justify="end">
                <Button
                  loading={pro.loading}
                  onClick={() => submitProPrice()}
                  size="large"
                  type="primary"
                  className="bold-button"
                >
                  Save changes
                </Button>
              </Flex>
            </Space>
          </Card>
        </Col>
        <Col xs={24} md={6}>
        <Title level={5}>1 ENTERPRISE member</Title>
          <Card style={{boxShadow:"none"}}>
            <Space className="w-100" direction="vertical" size="large">
              <Space direction="vertical" size={0} className="w-100">
                <Text strong>Price</Text>
                <InputNumber
                  value={ent.price}
                  onChange={(e) => setEnt((prev) => ({ ...prev, price: e }))}
                  className="w-100"
                  addonAfter="$"
                  size="large"
                />
              </Space>

              <Flex align="center" justify="end">
                <Button
                  onClick={() => submitEntPrice()}
                  loading={ent.loading}
                  size="large"
                  type="primary"
                  className="bold-button"
                >
                  Save changes
                </Button>
              </Flex>
            </Space>
          </Card>
        </Col>
      </Row>
    </Space>
  );
};

export default ManagePrices;
