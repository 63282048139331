import {
  Button,
  Card,
  Col,
  Flex,
  Form,
  message,
  Row,
  Space,
  Typography,
} from "antd";
import { useContext, useEffect, useRef, useState } from "react";
import { CreateNotificationTemplateContext } from "./CreateNotificationTemplateContext";
import { FaCheck } from "react-icons/fa";
import { MdClose } from "react-icons/md";
import "./HeaderStep.css";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import NotificationPreview from "./NotificationPreview";
import "./MessageStep.css";
import InputBox from "./InputBox";

const MessageStep = ({ active, setStep }) => {
  const [showPicker, setShowPicker] = useState(false);

  const pickerRef = useRef(null);

  const { Title, Text } = Typography;
  const { data, setData } = useContext(CreateNotificationTemplateContext);
  const [form] = Form.useForm();

  const [tempTemplate, setTempTemplate] = useState();

  const axios = useAxiosPrivate();

  const handleCancel = () => {
    setStep();
  };

  const [loading, setLoading] = useState(false);

  const submit = async (e) => {
    setLoading(true);

    const response = await axios.put(`/Notification/Assing/Message`, {
      id: data?.id,
      message: e?.template,
    });

    if (response.data.success) {
      setData((prev) => ({ ...prev, template: e.template }));
      setStep();
    } else {
      message.error(response.data.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    setTempTemplate(data?.template);
    form.setFieldValue("template", data?.template);
  }, [data?.template]);

  const handleSelect = (emoji) => {
    setShowPicker(false);

    let fieldValue = form.getFieldValue("template");

    if (fieldValue) {
      form.setFieldValue("template", `${fieldValue} ${emoji.native}`);
      setTempTemplate(`${fieldValue} ${emoji.native}`);
    } else {
      form.setFieldValue("template", emoji.native);
      setTempTemplate(emoji.native);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (pickerRef.current && !pickerRef.current.contains(event.target)) {
        setShowPicker(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [pickerRef]);

  return (
    <>
      <Card
        size="small"
        className={`step-card no-shadow ${active ? "active-step" : ""}`}
      >
        <Flex className="w-100" align="center" justify="space-between">
          <Flex align="center" justify="start" gap={12}>
            <div>
              {data?.template ? (
                <div className="step-evaluation-box-success">
                  <FaCheck />
                </div>
              ) : (
                <div className="step-evaluation-box-default"></div>
              )}
            </div>
            <Space direction="vertical" size={0}>
              <Title className="step-card-title zero-margin-padding" level={3}>
                Message
              </Title>
              {data?.template ? (
                <Text className="sender-preview-name" ellipsis>
                  {data.template}
                </Text>
              ) : (
                <Text>The message for your notification</Text>
              )}
            </Space>
          </Flex>
          {active ? (
            <Button
              type="text"
              onClick={handleCancel}
              className="flex-button"
              size="large"
              icon={<MdClose className="icon" />}
            ></Button>
          ) : (
            <Button className="create-notification-template-button large-font new-buttons" onClick={() => setStep(3)} size="large">
              Create Message
            </Button>
          )}
        </Flex>

        {active && (
          <div className="message-step-working-area">
            <Form form={form} onFinish={submit}>
              <Row gutter={[12, 12]}>
                <Col span={12}>
                  <Flex
                    className="w-100 h-100"
                    gap={6}
                    vertical
                    justify="center"
                  >
                    <Space direction="vertical" className="w-100" size={0}>
                      <Text className="thick-lable">Desing message</Text>

                      <InputBox
                        name="template"
                        onChange={(e) => setTempTemplate(e)}
                        form={form}
                        max={300}
                        rules={[
                          {
                            max: 300,
                          },
                        ]}
                      />
                    </Space>
                  </Flex>
                </Col>
                <Col span={12}>
                  <NotificationPreview
                    icon={`${process.env.REACT_APP_FILE_URL}${data?.path}`}
                    url={data?.url}
                    title={data?.header}
                    message={tempTemplate}
                  />
                </Col>
              </Row>
            </Form>
            <Flex
              align="center"
              justify="end"
              gap={6}
              className="url-step-working-area-footer"
            >
              <Button
                size="large"
                onClick={() => setStep()}
               className="new-buttons large-font"
              >
                Cancel
              </Button>
              <Button
                size="large"
                type="primary"
                onClick={() => form.submit()}
                loading={loading}
               className="new-buttons large-font"
              >
                Save
              </Button>
            </Flex>
          </div>
        )}
      </Card>
    </>
  );
};

export default MessageStep;
