import {
  DndContext,
  DragOverlay,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import KanbanColumn from "./KanbanColumn";
import {
  SortableContext,
  arrayMove,
  horizontalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CardStatus } from "../../../shared/enums";
import { createPortal, preconnect } from "react-dom";
import KanbanCard from "./KanbanCard";
import KanbanCardAdd from "./KanbanCardAdd";
import KanbanWonDealModal from "./KanbanWonDealModal";
import KanbanLostDealModal from "./KanbanLostDealModal";
import { Card, message } from "antd";
import { useParams } from "react-router-dom";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { DealListContext } from "../DealListContext";
import { SearchContext } from "../../../context/SearchContext";
import DeleteConfirmationModal from "../../../components/DeleteConfirmationModal";

const Kanban = ({ dataToAdd }) => {

  const [cards, setCards] = useState(() => ({
    [CardStatus.New]: {
      page: 1,
      revenue: 0,
      count: 0,
      items: []
    },
    [CardStatus.Qualifying]: {
      page: 1,
      revenue: 0,
      count: 0,
      items: []
    },
    [CardStatus.DemoScheduled]: {
      page: 1,
      revenue: 0,
      count: 0,
      items: []
    },
    [CardStatus.PendingCommitment]: {
      page: 1,
      revenue: 0,
      count: 0,
      items: []
    },
    [CardStatus.InNegotiation]: {
      page: 1,
      revenue: 0,
      count: 0,
      items: []
    },
    [CardStatus.Won]: {
      page: 1,
      revenue: 0,
      count: 0,
      items: []
    },
    [CardStatus.Closed]: {
      page: 1,
      revenue: 0,
      count: 0,
      items: []
    },
  }));

  const { filters } = useContext(DealListContext)
  const { debouncedValue } = useContext(SearchContext)

  const axios = useAxiosPrivate();
  const [activeCard, setActiveCard] = useState(null);
  const [showWonDealModal, setShowWonDealModal] = useState(false);
  const [showLostDealModal, setShowLostDealModal] = useState(false);

  const [lastDragEvent, setLastDragEvent] = useState();
  const initialCardPositionRef = useRef({});
  const overItemsForDeltaRef = useRef({});

  function onDragStart(event) {    
    const { active } = event;
    initialCardPositionRef.current = {
      card: { ...active.data.current.card },
    };

    initialCardPositionRef.current.index = cards[active.data.current.card.status]
    .items  
    .indexOf(active.data.current?.card);

    setActiveCard(active.data.current?.card);
  }

  const onDragEnd = async (event) => {
    overItemsForDeltaRef.current = {};
    const { active, over } = event;
    
    if (!over) return;
    const isActiveAsCard = active.data.current?.type === "card";
    const isOverAsCard = over.data.current?.type === "card";
    const isOverAsColumn = over.data.current.type === "column";

    if (isOverAsCard && isActiveAsCard) {
      const movedToStatus = parseInt(active.data.current.card.status);

      if (
        CardStatus.Won === movedToStatus &&
        initialCardPositionRef.current.card.status != movedToStatus
      ) {
        setLastDragEvent(event);
        setShowWonDealModal(true);
        return;
      }

      if (
        CardStatus.Closed === movedToStatus &&
        initialCardPositionRef.current.card.status != movedToStatus
      ) {
        setLastDragEvent(event)
        setShowLostDealModal(true);
        return;
      }
    }

    if (isActiveAsCard && isOverAsColumn) {
      const movedToStatus = over.data.current.column.id;

      if (
        CardStatus.Won === movedToStatus &&
        initialCardPositionRef.current.card.status != movedToStatus
      ) {
        setLastDragEvent(event)
        setShowWonDealModal(true);
        return;
      }
    }

    const response = await axios.post(`/Deal/UpdateDealPosition`, {
      id: active.data.current.card.id,
      index: active.data.current.sortable.index,
      newStage: parseInt(active.data.current.card.status),
      deals: active.data.current.sortable.items
    });

    let oldStatus = response.data.oldStage;
    let newStatus = response.data.newStage;
    let amount = response.data.amountToAddToNewStage;

    if(oldStatus != newStatus){
      let oldCount = cards[oldStatus].count - 1;

      setCards(prev => ({
        ...prev,
        [oldStatus]: {
          ...prev[oldStatus],
          count: oldCount,
          revenue: prev[oldStatus].revenue - amount
        },
        [newStatus] : {
          ...prev[newStatus],
          count: prev[newStatus].count + 1,
          revenue: prev[newStatus].revenue + amount
        }
      }))
    } 
  }

  const onDragOver = useCallback(
    (event) => {
      const { delta, active, over } = event;
      if (!over) return;
  
      const deltaKey = `${delta.x}:${delta.y}`;
      if (overItemsForDeltaRef.current[deltaKey] !== undefined) {
        return;
      }
  
      const activeCardId = active.id;
      const overCardId = over.id;
      if (activeCardId === overCardId) return;
  
      const isActiveAsCard = active.data.current?.type === "card";
      const isOverAsCard = over.data.current?.type === "card";
      const isOverAsColumn = over.data.current?.type === "column";
  
      if (isActiveAsCard && isOverAsCard) {
        setCards((prevCards) => {
          const newCards = Object.keys(prevCards).reduce((acc, key) => {
            acc[key] = {...prevCards[key]};
            return acc;
          }, {});
  
          const { statusKey: activeStatus, index: activeIndex } = findCardInDictionary(
            newCards,
            activeCardId
          );
  
          const { statusKey: overStatus, index: overIndex } = findCardInDictionary(
            newCards,
            overCardId
          );
  
          if (activeStatus !== overStatus) {
            const [movedCard] = newCards[activeStatus].items.splice(activeIndex, 1);
            movedCard.status = overStatus;
            newCards[overStatus].items.splice(overIndex, 0, movedCard);
          } else {
            newCards[activeStatus].items = arrayMove(
              newCards[activeStatus].items,
              activeIndex,
              overIndex
            );
          }
  
          return newCards;
        });
      }

      if (isOverAsColumn && isActiveAsCard) {
        setCards((prevCards) => {

          const newCards = Object.keys(prevCards).reduce((acc, key) => {
            acc[key] = {...prevCards[key]};
            return acc;
          }, {});
  
          const { statusKey: activeStatus, index: activeIndex } = findCardInDictionary(
            newCards,
            activeCardId
          );
  
          const [movedCard] = newCards[activeStatus].items.splice(activeIndex, 1);
          movedCard.status = over.id;
          newCards[over.id].items.splice(0, 0, movedCard);
  
          return newCards;
        });
      }
  
      overItemsForDeltaRef.current[deltaKey] = over;
    },
    [setCards, overItemsForDeltaRef]
  );

  function findCardInDictionary(cardsDict, cardId) {
    for (const statusKey in cardsDict) {
      const index = cardsDict[statusKey].items.findIndex((c) => c.id === cardId);
      if (index !== -1) {
        return { statusKey, index };
      }
    }
    return { statusKey: null, index: -1 };
  }

  const[currentId, setCurrentId] = useState();

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 3,
      },
    })
  );

  const { authorId } = useParams();

  useEffect(() => {
    const fetch = async () => {

      filters?.selectedStatuses.forEach(async (s, ind) => {
        const response = await axios.post(`/Deal`, {
          page: 1,
          stage: s.value,
          pageSize: 10,
          from: filters?.createdFromDate,
          to: filters?.createdToDate,
          companyIds: filters.selectedCompanies,
          productIds: filters.selectedProducts,
          name: debouncedValue,
          authorId: authorId
        });

        setCards(prev => ({
          ...prev,
          [s.value]: ( 
            {
              page: 1,
              revenue: response.data.totalRevenue,
              count: response.data.total,
              items: response.data.dealResponseDtos.map((c, ind1) => ({
                ind: ind1 + 1,
                title: c.title,
                id: c.id,
                status: c.stage,
                company: c.company,
                companyCount: c.companyCount,
                contact: c.contactCount,
                amount: c.amount,
                owners: c.owners,
                createdOn: c.createdOn,
                closeDate: c.closeDate,
                position: c.position,
                products: c.products?.map(c => ({ name: c.name, id: c.id }))
              }))
            })
        }))
      })
    }
    fetch()
  }, [debouncedValue, filters.selectedCompanies, filters.selectedProducts, filters.selectedStatuses, filters.createdFromDate, filters.createdToDate])

  const fetch1 = async (status, page) => {

      const response = await axios.post(`/Deal`, {
        page: page,
        stage: status,
        pageSize: 10,
        from: filters?.createdFromDate,
        to: filters?.createdToDate,
        companyIds: filters.selectedCompanies,
        productIds: filters.selectedProducts,
        name: debouncedValue,
        authorId: authorId
      });

      return response.data.dealResponseDtos.map((c, ind1) => ({
        ind: c.id,
        title: c.title,
        id: c.id,
        status: c.stage,
        company: c.company,
        companyCount: c.companyCount,
        contact: c.contactCount,
        amount: c.amount,
        owners: c.owners,
        createdOn: c.createdOn,
        closeDate: c.closeDate,
        position: c.position,
        products: c.products?.map(c => ({ name: c.name, id: c.id }))
      }))
  }

  const fetchItems = async (status) => {
      const data = await fetch1(status, cards[status].page + 1);

        setCards(prev => ({
          ...prev,
          [status] : ({
            ...prev[status],
            page:  prev[status].page + 1,
            items: [...prev[status].items, ...data]
          })
        }))    

  };

  const handleDeleteDeal = async (id, toDelete) => {
    if(!toDelete){
      setDeleteOpen(false)
      return;
    }

    const response = await axios.delete(`/Deal/${id}`);

    if(response.data.success){
      
      let stage = response.data.stage;
      setCards(prev => ({
        ...prev,
        [stage]: ({
          ...prev[stage],
          items: prev[stage].items.filter(c => c.id != id),
          count: prev[stage].count - 1,
          revenue: prev[stage].revenue - response.data.amountToRemove,
        })
      }))
    }else{
      message.error(response.data.message)
    }
  }

  const [deleteOpen, setDeleteOpen] = useState(false)

  const onDelete = (id) => {
    setCurrentId(id)
    setDeleteOpen(true)
  }

  return (<>
  
  <DeleteConfirmationModal item={currentId} onDeleteConfirm={handleDeleteDeal} isOpen={deleteOpen} onClose={() => setDeleteOpen(false)}/>

   <Card className="zero-margin-padding no-shadow gray-border">
      {useMemo(() => {
        return (
          <DndContext
            onDragStart={onDragStart}
            sensors={sensors}
            onDragOver={onDragOver}
            onDragEnd={onDragEnd}
            autoScroll={{
              layoutShiftCompensation: false,
              threshold: { x: 0.3, y: 0.3 },
            }}
          >
            <div className="mainKanbanContainer">
              <SortableContext
                items={filters?.selectedStatuses?.map(c => c.value)}
                strategy={horizontalListSortingStrategy}
                id="columns"
              >
                {filters.selectedStatuses.map((column) => (
               <KanbanColumn
                    key={column.value}
                    column={column}
                    onFetch={fetchItems}
                    setKanbanCards={setCards}
                    revenue={cards[column.id].revenue}
                    cards={cards[column.id]}
                    total={cards[column.id].count}
                    onDelete={onDelete}
                  />
                ))}
              </SortableContext>
              {createPortal(
                <DragOverlay>
                  {activeCard && (
                    <KanbanCard card={activeCard} className={"dragging"} />
                  )}
                </DragOverlay>,
                document.body
              )}
            </div>
          </DndContext>
        );
      }, [cards, activeCard, filters.cardItems, filters.selectedStatuses])}

      <KanbanCardAdd setKanbanCards={setCards}
        dataToAdd={dataToAdd} />

      <KanbanWonDealModal
        cards={cards}
        open={showWonDealModal}
        setOpen={setShowWonDealModal}
        setKanbanCards={setCards}
        initialCardPositionRef={initialCardPositionRef}
        dragEvent={lastDragEvent}
      />

      <KanbanLostDealModal
        cards={cards}
        open={showLostDealModal}
        dragEvent={lastDragEvent}
        setOpen={setShowLostDealModal}
        setKanbanCards={setCards}
        initialCardPositionRef={initialCardPositionRef}
      />
    </Card>
    </>
  );
};

export default Kanban;
