import { Button, Space, Typography } from "antd";
import { useNavigate } from "react-router-dom";

const CardError = ({ text, id }) => {
  const { Text } = Typography;
  const navigate = useNavigate();

  return (
    <Space className="w-100" direction="vertical">
      <Text className="create-contact-error-card-text">{text}</Text>

      {id && (
        <Button
          onClick={() => navigate(`/contacts/${id}`)}
          type="link"
          className="bold no-left-padding-margin"
        >
          Visit the contact
        </Button>
      )}
    </Space>
  );
};

export default CardError;
