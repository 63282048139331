import {
  Flex,
  Typography,
  Space,
  Card,
  Button,
  Col,
  Row,
  Empty,
  Timeline,
  DatePicker,
  Popover,
  Collapse,
  Avatar,
} from "antd";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import React, { useEffect, useState } from "react";
import QueryString from "qs";
import TableLoading from "../../../shared/TableLoading";
import { useNavigate, useParams } from "react-router-dom";
import { DateFormat } from "../../../dateformat";
import { VscCallIncoming, VscCallOutgoing } from "react-icons/vsc";
import { MdArrowDropDown, MdOutlineNavigateNext } from "react-icons/md";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import "./ContactCommucations.css";
import { IoMdArrowDropdown } from "react-icons/io";
import dayjs from "dayjs";
import TranscriptionModal from "./TranscriptionModal";
import { UserOutlined } from "@ant-design/icons";

const ContactCommucations = () => {
  const { Title, Text } = Typography;
  const { id } = useParams();

  const axios = useAxiosPrivate();

  const [data, setData] = useState();

  const [total, setTotal] = useState(0);

  const [loading, setLoading] = useState(false);

  const [from, setFrom] = useState(new Date());
  const [to, setTo] = useState(new Date());
  const [search, setSearch] = useState(true);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    const fetch = async () => {
      setLoading(true);
      const response = await axios.get(`/Communications`, {
        params: {
          to: to,
          from: from,
          contactId: id,
        },
        signal: signal,
        paramsSerializer: (params) => {
          return QueryString.stringify(params, {
            arrayFormat: "repeat",
            skipNulls: true,
          });
        },
      });

      setLoading(false);
      setData(response.data.items);
      setTotal(response.data.count);
    };

    fetch();

    return () => controller.abort();
  }, [search]);

  const navigate = useNavigate();

  const handleCallClick = (number) => {
    if (id) {
      navigate(`/dial/number?number=${number}`);
    }
  };

  const [dateFilterLabel, setDateFilterLabel] = useState("Today");

  const [showDatePickers, setShowDatePickers] = useState(false);

  const [popUpShown, setPopUpShown] = useState(false);

  const handleClick = (key, value) => {
    setDateFilterLabel(value);
    setShowDatePickers(false);

    const today = new Date();

    if (key == 1) {
      setFrom(today);
      setTo(today);
      setPopUpShown(false);
      setSearch((prev) => !prev);
    } else if (key == 2) {
      const lastWeek = new Date();
      lastWeek.setDate(today.getDate() - 7);
      setFrom(lastWeek);
      setTo(today);
      setPopUpShown(false);
      setSearch((prev) => !prev);
    } else if (key == 3) {
      const last30Days = new Date();
      last30Days.setDate(today.getDate() - 30);
      setTo(today);
      setFrom(last30Days);
      setPopUpShown(false);
      setSearch((prev) => !prev);
    } else if (key == 4) {
      const thisYear = new Date(today.getFullYear(), 0, 1);

      setFrom(thisYear);
      setTo(today);
      setPopUpShown(false);
      setSearch((prev) => !prev);
    } else if (key == 5) {
      const lastYear = new Date(today.getFullYear() - 1, 0, 1);
      const thisYear = new Date(today.getFullYear(), 0, 1);
      setFrom(lastYear);
      setTo(thisYear);
      setPopUpShown(false);
      setSearch((prev) => !prev);
    } else {
      setShowDatePickers(true);
    }
  };

  const options = [
    {
      label: "Today",
      key: 1,
      onClick: () => handleClick(1, "Today"),
    },
    {
      label: "Last 7 days",
      key: 2,
      onClick: () => handleClick(2, "Last 7 days"),
    },
    {
      label: "Last 30 days",
      key: 3,
      onClick: () => handleClick(3, "Last 30 days"),
    },
    {
      label: `This year (${new Date().getFullYear()})`,
      key: 4,
      onClick: () => handleClick(4, `This year (${new Date().getFullYear()})`),
    },
    {
      label: `Last year (${new Date().getFullYear() - 1})`,
      key: 5,
      onClick: () =>
        handleClick(5, `Last year (${new Date().getFullYear() - 1})`),
    },
    {
      label: "Custom date range",
      key: 6,
      onClick: () => handleClick(6, `Custom`),
    },
  ];

  const clear = () => {
    setFrom();
    setTo();
  };

  const getEmailFullText = async (id) => {
    const response = await axios.get(`Communications/Email-Text/${id}`);

    setData((prev) =>
      prev.map((d) => {
        if (d.items.some((e) => e.emailCommunication?.id === id)) {
          return {
            ...d,
            items: d.items.map((c) =>
              c.emailCommunication?.id === id
                ? {
                  ...c,
                  emailCommunication: {
                    ...c.emailCommunication,
                    emailMessage: response.data.text,
                    longerThan200Characters: false,
                  },
                }
                : c
            ),
          };
        }
        return d;
      })
    );
  };

  const [transOpen, setTransOpen] = useState(false);
  const [transId, setTransId] = useState();

  const transcribe = (id) => {
    setTransId(id);
    setTransOpen(true);
  };

  return (
    <>
      <TranscriptionModal
        open={transOpen}
        setOpen={setTransOpen}
        id={transId}
      />

      <Space
        className="w-100"
        size={24}
        direction="vertical"
        style={{ padding: 10 }}
      >
        <Flex className="w-100" align="center" justify="space-between">
          <Title level={3} className="zero-margin-padding">
            All communications
          </Title>

          <Popover
            open={popUpShown}
            size="small"
            trigger={["click"]}
            content={
              <Row gutter={[24, 24]}>
                <Col span={showDatePickers ? 12 : 24}>
                  <Space direction="vertical" size={0}>
                    {options.map((c, i) => (
                      <Button
                        size="large"
                        onClick={() => handleClick(c.key, c.label)}
                        type="text"
                        className="w-100 communication-date-filter-button"
                      >
                        {c.label}

                        {c.key == 6 && (
                          <MdOutlineNavigateNext className="icon" />
                        )}
                      </Button>
                    ))}
                  </Space>
                </Col>

                {showDatePickers && (
                  <Col
                    span={12}
                    className="communication-date-filter-border-right"
                  >
                    <Flex align="center" className="h-100 w-100">
                      <Space className="w-100" direction="vertical" size={24}>
                        <Space className="w-100" direction="vertical" size={0}>
                          <Text>From</Text>
                          <DatePicker
                            value={from ? dayjs(from) : null}
                            onChange={(e) => setFrom(e.toDate())}
                            size="large"
                          />
                        </Space>

                        <Space className="w-100" direction="vertical" size={0}>
                          <Text>To</Text>
                          <DatePicker
                            value={to ? dayjs(to) : null}
                            onChange={(e) => setTo(e.toDate())}
                            size="large"
                          />
                        </Space>
                        <Flex align="center" justify="end" gap={6}>
                          <Button onClick={clear}>Clear</Button>
                          <Button
                            type="primary"
                            onClick={() => {
                              setSearch((prev) => !prev);
                              setPopUpShown(false);
                            }}
                          >
                            Save
                          </Button>
                        </Flex>
                      </Space>
                    </Flex>
                  </Col>
                )}
              </Row>
            }
          >
            <Button
              onClick={() => setPopUpShown(true)}
              className="communication-date-filter-button"
              size="large"
            >
              {dateFilterLabel} <IoMdArrowDropdown className="icon" />
            </Button>
          </Popover>
        </Flex>

        <Space direction="vertical" className="w-100">
          {loading ? (
            <TableLoading />
          ) : !data || data.length == 0 ? (
            <Card className="no-shadow" size="small">
              <Empty
                imageStyle={{
                  height: 200,
                }}
                description={
                  "No communications found, try calling, sending an email, sms or a video call."
                }
              ></Empty>
            </Card>
          ) : (
            <>
              <Timeline
                className="comunication-timeline"
                items={data?.map((c, i) => ({
                  dot: <div className="comunication-dot"></div>,
                  children: (
                    <Space className="w-100 negative-top-12" direction="vertical">
                      <div>

                        <Title className="zero-margin-padding" level={4}>
                          {new Date(c.day).toLocaleDateString(
                            "en-us",
                            DateFormat
                          )}
                        </Title>

                        <p className="negative-top-12">{c.items.filter(c => c.emailCommunication).length ?? "No"} emails, {c.items.filter(c => c.phoneCallEvent && c.phoneCallEvent.direction == 'outgoing').length ?? "no"} outgoing calls and {c.items.filter(c => c.phoneCallEvent && c.phoneCallEvent.direction == 'incoming').length ?? "no"} incoming</p>
                      </div>

                      {c.items.map((c, i) => (
                        <div key={i} className="collapse-div-communications">
                          <Collapse
                            bordered={false}
                            className="no-shadow"
                            expandIconPosition="end"
                            expandIcon={<MdArrowDropDown />}
                            items={[
                              {
                                label: (
                                  <Flex align="center" justify="space-between">
                                        <Title
                                          className="zero-margin-padding"
                                          level={5}
                                        >
                                          {c.emailCommunication
                                            ? "Email"
                                            : c.phoneCallEvent.direction ==
                                              "incoming"
                                              ? "Incoming Call"
                                              : "Outgoing call"}
                                        </Title>

                                    {c.phoneCallEvent &&
                                      <div>
                                        {!c.phoneCallEvent.answer &&
                                          c.phoneCallEvent.direction ==
                                          "incoming" && (
                                            <div className="communication-error-wrapper">
                                              <Text className="communication-wrapper-text">Missed</Text>
                                            </div>
                                          )}

                                        {c.phoneCallEvent.answer && (
                                          <div className="communication-success-wrapper">
                                            <Text className="communication-wrapper-text">Answered</Text>
                                          </div>
                                        )}


                                        {!c.phoneCallEvent.answer &&
                                          c.phoneCallEvent.direction ==
                                          "outgoing" && (
                                            <div className="communication-error-wrapper">
                                              <Text className="communication-wrapper-text">No answer</Text>
                                            </div>
                                          )}
                                      </div>}
                                    <Text className="time-text-communication">{c.emailCommunication
                                      ? new Date(
                                        c.emailCommunication?.createdAt *
                                        1000
                                      ).toLocaleTimeString()
                                      : new Date(
                                        c.phoneCallEvent.createdAt * 1000
                                      ).toLocaleTimeString()}</Text>
                                  </Flex>
                                ),
                                children: (
                                  <>
                                    {c.emailCommunication && (
                                      <Space
                                        className="w-100"
                                        direction="vertical"
                                        size={12}
                                      >

                                        <Text strong>
                                          {c.emailCommunication.subject
                                            ? c.emailCommunication.subject
                                            : "-"}
                                        </Text>

                                        <Space
                                          className="w-100"
                                          direction="vertical"
                                        >
                                          <div
                                            className="zero-margin-padding-children"
                                            dangerouslySetInnerHTML={{
                                              __html: `${c.emailCommunication
                                                .longerThan200Characters
                                                ? `${c.emailCommunication.emailMessage}...`
                                                : c.emailCommunication
                                                  .emailMessage
                                                }`,
                                            }}
                                          ></div>
                                          {c.emailCommunication
                                            .longerThan200Characters && (
                                              <Flex
                                                align="center"
                                                justify="center"
                                              >
                                                <Button
                                                  className="large-font"
                                                  type="link"
                                                  onClick={() =>
                                                    getEmailFullText(
                                                      c.emailCommunication.id
                                                    )
                                                  }
                                                >
                                                  See full email
                                                </Button>
                                              </Flex>
                                            )}
                                        </Space>
                                        <Flex className="communication-footer" align="center" justify="space-between">
                                          <Space direction="vertical" size={0}>
                                            <Flex align="center" justify="start" gap={6}>
                                              <Avatar src={`${process.env.REACT_APP_FILE_URL}${c.emailCommunication.userProfile}`} icon={<UserOutlined />} size="large" />
                                              <Space direction="vertical" size={0}>
                                                <Text className="text-smaller">Send by</Text>
                                                <p className="bold negative-top-12">{c.emailCommunication.authorName}</p>
                                              </Space>
                                            </Flex>
                                          </Space>

                                          <Space direction="vertical" size={0}>
                                            <Text className="text-smaller">Email used</Text>
                                            <p className="bold negative-top-12">{c.emailCommunication.senderEmail}</p>
                                          </Space>
                                        </Flex>
                                      </Space>
                                    )}

                                    {c.phoneCallEvent && (
                                      <Space
                                        className="w-100"
                                        direction="vertical"
                                      >
                                        <Row>
                                          <Col
                                            span={14}
                                            className="center-table-column"
                                          >
                                            <Flex
                                              align="center"
                                              justify="start"
                                              gap={6}
                                            >
                                              <div>
                                                {!c.phoneCallEvent.answer &&
                                                  c.phoneCallEvent.direction ==
                                                  "incoming" && (
                                                    <div className="call-icon-wrapper">
                                                      <VscCallIncoming className="call-icon call-error" />
                                                    </div>
                                                  )}
                                                {c.phoneCallEvent.answer &&
                                                  c.phoneCallEvent.direction ==
                                                  "incoming" && (
                                                    <div className="call-icon-wrapper">
                                                      <VscCallIncoming className="call-icon call-success" />
                                                    </div>
                                                  )}

                                                {!c.phoneCallEvent.answer &&
                                                  c.phoneCallEvent.direction ==
                                                  "outgoing" && (
                                                    <div className="call-icon-wrapper">
                                                      <VscCallOutgoing className="call-icon call-error" />
                                                    </div>
                                                  )}
                                                {c.phoneCallEvent.answer &&
                                                  c.phoneCallEvent.direction ==
                                                  "outgoing" && (
                                                    <div className="call-icon-wrapper">
                                                      <VscCallOutgoing className="call-icon call-success" />
                                                    </div>
                                                  )}
                                              </div>
                                              <Space
                                                size={0}
                                                direction="vertical"
                                                className="w-100"
                                              >
                                                <Text className="campaign-title">
                                                  {c.phoneCallEvent.direction ==
                                                    "incoming"
                                                    ? "Incoming Call"
                                                    : "Outgoing call"}
                                                </Text>

                                                <Text type="link">
                                                  {`${new Date(
                                                    c.phoneCallEvent.createdAt *
                                                    1000
                                                  ).toLocaleTimeString()}
                                              
                                               • From ${c.phoneCallEvent.toPhoneNumber
                                                    }
                                            
                                                `}
                                                </Text>
                                              </Space>
                                            </Flex>
                                          </Col>

                                          <Col
                                            span={6}
                                            className="center-table-column"
                                          ></Col>
                                          <Col span={4}>
                                            {c.phoneCallEvent.transcribed &&
                                              <Flex
                                                className="w-100 h-100"
                                                align="center"
                                                justify="end"
                                                gap={6}
                                              >
                                              <Button type="link" className="large-font new-buttons" onClick={() => transcribe(c.phoneCallEvent.id)}>View conversation</Button>
                                              </Flex>
                                            }
                                          </Col>
                                        </Row>

                                        {c.phoneCallEvent.recording && <AudioPlayer
                                                src={`data:audio/mp3;base64,${c.phoneCallEvent.recording}`}
                                              />}

                                        <Flex className="communication-footer" align="center" justify="space-between">
                                          <Space direction="vertical" size={0}>
                                            <Flex align="center" justify="start" gap={6}>
                                              <Avatar src={c.phoneCallEvent.direction == "outgoing" ? `${process.env.REACT_APP_FILE_URL}${c.phoneCallEvent.authorProfile}` : null} icon={<UserOutlined />} size="large" />
                                              <Space direction="vertical" size={0}>
                                                <Text className="text-smaller">Initiated by</Text>
                                                <p className="bold negative-top-12">{c.phoneCallEvent.direction == "outgoing" ? `${c.phoneCallEvent.authorName ?? "* Data missing"}` : "Current contact"}</p>
                                              </Space>
                                            </Flex>
                                          </Space>

                                          <Flex align="center" justify="start" gap={6}>
                                          <Space direction="vertical" size={0}>
                                            <Text className="text-smaller">Answered by</Text>
                                            <p className="bold negative-top-12">{c.phoneCallEvent.answer ? (c.phoneCallEvent.direction == "outgoing" ? "Current contact" : c.phoneCallEvent.answeredName ?? "* Data Missing") : "No Answer"}</p>
                                          </Space>
                                            <Avatar src={c.phoneCallEvent.direction == "incoming" ? `${process.env.REACT_APP_FILE_URL}${c.phoneCallEvent.authorProfile}` : null} icon={<UserOutlined />} size="large" />
                                          </Flex>
                                        </Flex>
                                      </Space>
                                    )}
                                  </>
                                ),
                              },
                            ]}
                          ></Collapse>
                        </div>
                      ))}
                    </Space>
                  ),
                }))}
              />
            </>
          )}
        </Space>
      </Space>
    </>
  );
};

export default ContactCommucations;