import {
  Button,
  Card,
  Collapse,
  Dropdown,
  Flex,
  message,
  Popover,
  Space,
  Typography,
} from "antd";
import "./ContactDetailsChannels.css";
import { FaCheckCircle } from "react-icons/fa";
import { IoMdArrowDropdown } from "react-icons/io";
import { FaXmark } from "react-icons/fa6";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";

const ContactDetailsChannels = ({ details, setDetails }) => {
  const { Text, Title } = Typography;

  const axios = useAxiosPrivate();

  const submit = async (
    subtoEmail,
    subToWhatsapp,
    subToSms,
    subToNotification
  ) => {
    const response = await axios.put(`/Contacts/Update-Channels`, {
      subToEmail: subtoEmail,
      subToWhatsapp: subToWhatsapp,
      subToSms: subToSms,
      subToNotification: subToNotification,
      contactId: details.id,
    });

    if (response.data.success) {
    } else {
      message.error(response.data.message);
    }
  };

  const onClick = (type, subbed) => {
    if (type == "email") {
      setDetails((prev) => ({ ...prev, subEmail: subbed }));
      submit(
        subbed,
        details.subWhatsapp,
        details.subSms,
        details.subNotification
      );
    } else if (type == "whatsapp") {
      setDetails((prev) => ({ ...prev, subWhatsapp: subbed }));
      submit(details.subEmail, subbed, details.subSms, details.subNotification);
    } else if (type == "sms") {
      setDetails((prev) => ({ ...prev, subSms: subbed }));
      submit(
        details.subEmail,
        details.subWhatsapp,
        subbed,
        details.subNotification
      );
    } else {
      setDetails((prev) => ({ ...prev, subNotification: subbed }));
      submit(details.subEmail, details.subWhatsapp, details.subSms, subbed);
    }
  };

  return (
    <>
      <Collapse
        items={[
          {
            key: 1,
            label: (
              <Title
                className="zero-margin-padding"
                level={4}
              >{`Channels (${4})`}</Title>
            ),
            children: (
              <Space className="w-100" direction="vertical">
                {(details?.subEmail || details?.subWhatsapp || details?.subNotification || details?.subSms) ? 
                <Flex align="center" justify="start" gap={6}>
                  <FaCheckCircle className="success" />
                  <Text strong>Subscribed</Text>
                </Flex>
                : <Flex align="center" justify="start" gap={6}>
                <FaXmark className="error" />
                <Text strong>Blacklisted</Text>
              </Flex>}

                <CustomSelect
                  handleClick={onClick}
                  type="email"
                  popOverTitle={`Select the Email campaigns subscription status of ${details?.name}`}
                  subbed={details?.subEmail}
                  title="Email campaigns"
                  blockText="This contact can’t receive any email campaigns but still is displayed in your contacts"
                  subText="This contact can receive email campaigns"
                />
                <CustomSelect
                  handleClick={onClick}
                  type="whatsapp"
                  popOverTitle={`Select the WhatsApp campaigns subscription status of ${details?.name}`}
                  subbed={details?.subWhatsapp}
                  title="Whatsapp campaigns"
                  blockText="This contact can’t receive any whatsapp campaigns but still is displayed in your contacts"
                  subText="This contact can receive whatsapp campaigns"
                />
                <CustomSelect
                  handleClick={onClick}
                  type="sms"
                  blockText="This contact can’t receive any sms campaigns but still is displayed in your contacts"
                  subText="This contact can receive sms campaigns"
                  popOverTitle={`Select the SMS campaigns subscription status of ${details?.name}`}
                  subbed={details?.subSms}
                  title="SMS campaigns"
                />
                <CustomSelect
                  handleClick={onClick}
                  type="notification"
                  popOverTitle={`Select the Notification campaigns subscription status of ${details?.name}`}
                  subbed={details?.subNotification}
                  title="Notification campaigns"
                  blockText="This contact can’t receive any notification campaigns but still is displayed in your contacts"
                  subText="This contact can receive notification campaigns"
                />
              </Space>
            ),
          },
        ]}
        defaultActiveKey={[1]}
        showArrow={false}
      />
    </>
  );
};

const CustomSelect = ({
  type,
  title,
  subbed,
  popOverTitle,
  subText,
  blockText,
  handleClick,
}) => {
  const { Title, Text } = Typography;

  return (
    <Popover
      trigger={["click"]}
      content={
        <Space style={{ padding: 12 }} className="w-100" direction="vertical">
          <Title level={5} className="zero-margin-padding">
            {popOverTitle}
          </Title>
          <Card
            className="no-shadow contact-details-channels-select-card"
            size="small"
          >
            <Space size={0} direction="vertical">
              <Space
                className="contact-details-channels-select-card-item"
                direction="vertical"
                size={0}
                onClick={() => handleClick(type, true)}
              >
                <Flex align="start" justify="start" gap={6}>
                  <FaCheckCircle
                    style={{ marginTop: 3 }}
                    className="contact-details-channels-icons success"
                  />
                  <Space direction="vertical" size={0}>
                    <Text className="contact-details-channels-select-type">
                      Subscribed
                    </Text>
                    <Text>{subText}</Text>
                  </Space>
                </Flex>
              </Space>
              <Space
                className="contact-details-channels-select-card-item"
                direction="vertical"
                size={0}
                onClick={() => handleClick(type, false)}
              >
                <Flex align="start" justify="start" gap={6}>
                  <FaXmark
                    style={{ marginTop: 3 }}
                    className="contact-details-channels-icons error"
                  />
                  <Space direction="vertical" size={0}>
                    <Text className="contact-details-channels-select-type">
                      Blocklisted
                    </Text>
                    <Text>{blockText}</Text>
                  </Space>
                </Flex>
              </Space>
            </Space>
          </Card>
        </Space>
      }
    >
      <Button
        size="large"
        className="new-buttons large-font flex-button-3icons"
      >
        {subbed ? (
          <FaCheckCircle className="contact-details-channels-icons success" />
        ) : (
          <FaXmark className="error contact-details-channels-icons" />
        )}
        {title}
        <IoMdArrowDropdown />
      </Button>
    </Popover>
  );
};

export default ContactDetailsChannels;
