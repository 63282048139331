import {
  Button,
  DatePicker,
  Drawer,
  Flex,
  Form,
  Input,
  Space,
  Switch,
  TimePicker,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import TaskTypeSelect from "./FormItems/TaskTypeSelect";
import AssignedUsersSelect from "./FormItems/AssignedUsersSelect";
import TextArea from "antd/es/input/TextArea";
import BrowserNotification from "../../shared/BrowserNotification";
import TaskReminderSelection from "./TaskReminderSelection";
import DealSelect from "./FormItems/DealSelect";

const AddTaskDrawer = ({ open, setOpen, onSubmit, loading, selectDeals }) => {
  const { Text } = Typography;

  const [form] = Form.useForm();

  function onClose() {
    form.resetFields()
    setOpen(false);
  }

  const [useNotifications, setUseNotifications] = useState(false);

  return (
    <Drawer open={open} onClose={onClose} title="Create Task" destroyOnClose>
      <Form layout="vertical" onFinish={onSubmit} form={form}>
        <TaskTypeSelect />

        <Space direction="vertical" className="w-100" size={0}>
          <Text>Task name</Text>
          <Form.Item name={"name"}>
            <Input size="large" placeholder="Please enter a name" />
          </Form.Item>
        </Space>

        <Flex justify="space-between" gap={"10px"}>
          <Space direction="vertical" className="w-100" size={0}>
            <Text>Due Date</Text>
            <Form.Item name={"dueDate"} style={{ flex: 1 }}>
              <DatePicker
                size="large"
                className="w-100"
                style={{ borderRadius: 15 }}
              />
            </Form.Item>
          </Space>
          <Space direction="vertical" className="w-100" size={0}>
            <Text>Time</Text>
            <Form.Item name={"dueTime"} style={{ flex: 1 }}>
              <TimePicker
                style={{ width: "100%", borderRadius: 20 }}
                size="large"
              />
            </Form.Item>
          </Space>
        </Flex>

        <Space direction="vertical" size={0} className="w-100">
          <Flex  justify="start" gap={12}>
            <Space direction="vertical" className="w-100" size={0}>

          <Text>Reminder</Text>
            <Form.Item name="reminder" valuePropName="checked">
              <Switch onChange={(e) => setUseNotifications(e)} />
            </Form.Item>
            </Space>
            {useNotifications && <TaskReminderSelection name="reminderType"/>}
          </Flex>
          {useNotifications && <BrowserNotification />}
        </Space>

        <Space direction="vertical" className="w-100" size={0}>
          <Text>Mark task as high priority</Text>
          <Form.Item name="taskPriority" valuePropName="checked">
            <Switch />
          </Form.Item>
        </Space>

        <Space direction="vertical" size={0} className="w-100">
          <Text>Notes</Text>
          <Form.Item name={"notes"}>
            <TextArea rows={4} size="large" />
          </Form.Item>
        </Space>
        <AssignedUsersSelect setDefault={(e) => form.setFieldValue("assignedUser", [e])}/>
        
        {selectDeals&&<DealSelect/>}
        <Flex justify="end" gap={6} style={{ marginTop: "auto" }}>
          <Button size="large" className="large-font new-button" onClick={onClose}>
            Cancel
          </Button>
          <Button size="large" className="large-font new-button" type="primary" htmlType="submit" loading={loading}>
            Save changes
          </Button>
        </Flex>
      </Form>
    </Drawer>
  );
};

export default AddTaskDrawer;
