import {
  Button,
  Drawer,
  Dropdown,
  Flex,
  Form,
  Input,
  message,
  Select,
  Space,
  Typography,
} from "antd";
import { useCallback, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { useContext } from "react";
import { AudienceContext } from "../AudienceContext";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import "./CreateContactDrawer.css";
import {
  extractEmail,
  extractFullName,
  extractPhoneNumber,
  extractCampaign,
} from "../../../extractors/Extractors";
import CardError from "../CardError";
import { FaPaste } from "react-icons/fa";

const CreateContactDrawer = ({ open, setOpen, onAdd }) => {
  const { Text, Title } = Typography;

  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);

  const { tags } = useContext(AudienceContext);

  const axios = useAxiosPrivate();

  const [emailError, setEmailError] = useState();
  const [phoneError, setPhoneError] = useState();
  const [whatsappError, setWhatsappError] = useState();

  const submit = async (e) => {
    setLoading(true);

    const response = await axios.post(`Contacts`, {
      email: e.email,
      firstname: e.name,
      lastname: e.lastname,
      phoneNumber: e.phone,
      tags: e.tags?.filter((c) => typeof c === "number"),
      newTags: e.tags?.filter((c) => typeof c === "string"),
      whatsappPhoneNumber: e.whatsappPhoneNumber,
    });

    if (response.data.success) {
      onAdd(response.data);
      handleClose();
    } else if (response.data.conflictsWithOtherContact) {
      if (response.data.emailExistsId) {
        setEmailError({
          id: response.data.emailExistsId,
        });
      }

      if (response.data.phoneExistsId) {
        setPhoneError({
          id: response.data.phoneExistsId,
        });
      }

      if (response.data.whatsappExistsId) {
        setWhatsappError({
          id: response.data.whatsappExistsId,
        });
      }
    } else {
      message.error(response.data.message);
    }

    setLoading(false);
  };

  const validateEmailOrPhone = (_, value) => {
    const phone = form.getFieldValue("phone");
    const email = form.getFieldValue("email");

    if (!phone && !email) {
      return Promise.reject(
        new Error("Either email or phone must be provided.")
      );
    }
    return Promise.resolve();
  };

  const handleClose = () => {
    form.resetFields();
    setEmailError();
    setPhoneError();
    setWhatsappError();
    setOpen(false);
  };

  const handleAssignText = (text) => {
    let email = extractEmail(text);
    let phone = extractPhoneNumber(text);
    let fullName = extractFullName(text);
    let campaign = extractCampaign(text);

    if (email) {
      form.setFieldValue("email", email);
    }

    if (phone) {
      form.setFieldValue("phone", phone);
      form.setFieldValue("whatsappPhoneNumber", phone);
    }

    if (fullName) {
      
      if(fullName.firstName){
        form.setFieldValue("name", fullName.firstName);
      }
      if(fullName.lastName){
        form.setFieldValue("lastname", fullName.lastName);
      }
    }

    if (campaign) {
      var tag = tags.find((c) => c.name == campaign);
      if (tag) {
        form.setFieldValue("tags", [tag.id]);
      } else {
        form.setFieldValue("tags", [campaign]);
      }
    }
  };

  const handlePaste = (event) => {
    const pasteData = event.clipboardData.getData("text");
    handleAssignText(pasteData);
  };

  const [isHighlighted, setIsHighlighted] = useState(false);

  const handleDragOver = useCallback((event) => {
    event.preventDefault();
    setIsHighlighted(true);
  }, []);

  const handleDragLeave = useCallback(() => {
    setIsHighlighted(false);
  }, []);

  const handleDrop = useCallback((event) => {
    event.preventDefault();

    const text = event.dataTransfer.getData("text/plain");

    if (text) {
      handleAssignText(text);
    }
    setIsHighlighted(false);
  }, []);

  const handlePasteFromDrowdown = async () => {
    try {
      if (navigator.clipboard && navigator.clipboard.readText) {
        const text = await navigator.clipboard.readText();
        handleAssignText(text);
        return;
      }

      const textarea = document.createElement("textarea");
      document.body.appendChild(textarea);
      textarea.focus();
      document.execCommand("paste");
      handleAssignText(textarea.value);
      document.body.removeChild(textarea);
    } catch (err) {
      console.error("Failed to read clipboard contents: ", err);
      }
  };

  const removePasteProps = (e) => {
    e.stopPropagation();
  };


  const updateWhatsappFromPhone = (val) => {
    let whatsappvalue = form.getFieldValue("whatsappPhoneNumber");

    if(!whatsappvalue){
      form.setFieldValue("whatsappPhoneNumber", val)
    }else{
      if(whatsappvalue.startsWith(val.substring(0, val.length - 1)) && whatsappvalue.length + 1 == val.length){
        form.setFieldValue("whatsappPhoneNumber", val)
      }
    }
  } 

  return (
    <Drawer
      bodyStyle={{
        padding: 0,
        margin: 0,
      }}
      destroyOnClose
      open={open}
      onClose={handleClose}
      title="Create contact"
    >
      <Dropdown
        trigger={["contextMenu"]}
        menu={{
          items: [
            {
              key: 1,
              label: <p className="drop-down-text">Paste copied data</p>,
              icon: <FaPaste className="drop-down-icon" />,
              onClick: () => handlePasteFromDrowdown(),
            },
          ],
        }}
      >
        <div
          style={{
            height: "100%",
            padding: 24,
          }}
          onDrop={handleDrop}
          onDragLeave={handleDragLeave}
          onPaste={handlePaste}
          onDragOver={handleDragOver}
        >
          {isHighlighted ? (
            <div
              className="gray-border"
              style={{
                zIndex: 99,
                background: "whitesmoke",
                borderWidth: "2px",
                borderStyle: "dashed",
                borderRadius: "10px",
                height: "100%",
              }}
            >
              <Flex align="center" justify="center" className="w-100 h-100">
                <Title>DROP HERE</Title>
              </Flex>
            </div>
          ) : (
            <Flex vertical justify="space-between" className="h-100">
              <Form form={form} onFinish={submit}>
                <Space size={24} direction="vertical" className="w-100">
                  <Space direction="vertical" size={0} className="w-100">
                    <Text className="bold">Name</Text>
                    <Form.Item name="name" className="no-error-show">
                      <Input
                        onPaste={removePasteProps}
                        placeholder="Enter name"
                        size="large"
                      />
                    </Form.Item>
                  </Space>
                  <Space direction="vertical" size={0} className="w-100">
                    <Text className="bold">Lastname</Text>
                    <Form.Item name="lastname" className="no-error-show">
                      <Input
                        onPaste={removePasteProps}
                        placeholder="Enter lastname"
                        size="large"
                      />
                    </Form.Item>
                  </Space>
                  <div
                    className={emailError ? "create-contact-error-card" : ""}
                  >
                    <Space direction="vertical" size={0} className="w-100">
                      <Text className="bold">Email</Text>
                      <Form.Item
                        name="email"
                        className="no-error-show"
                        rules={[
                          {
                            type: "email",
                            message: "The input is not a valid email!",
                          },
                          {
                            validator: validateEmailOrPhone,
                          },
                        ]}
                      >
                        <Input
                          onPaste={removePasteProps}
                          placeholder="Enter email"
                          size="large"
                        />
                      </Form.Item>
                      {emailError && (
                        <CardError
                          id={emailError.id}
                          text="This email is already associated with a contact"
                        />
                      )}
                    </Space>
                  </div>
                  <div
                    className={phoneError ? "create-contact-error-card" : ""}
                  >
                    <Space direction="vertical" size={0} className="w-100">
                      <Text className="bold">Mobile number</Text>
                      <Form.Item
                        className="no-error-show"
                        name="phone"
                        rules={[
                          {
                            validator: validateEmailOrPhone,
                          },
                        ]}
                      >
                        <PhoneInput
                          onPaste={removePasteProps}
                          inputStyle={{ width: "100%", height: 50 }}
                          containerStyle={{ width: "100%" }}
                          className="w-100"
                          onChange={updateWhatsappFromPhone}
                        />
                      </Form.Item>
                      {phoneError && (
                        <CardError
                          id={phoneError.id}
                          text="This phone number is already associated with a contact"
                        />
                      )}
                    </Space>
                  </div>
                  <div
                    className={whatsappError ? "create-contact-error-card" : ""}
                  >
                    <Space direction="vertical" size={0} className="w-100">
                      <Text className="bold">Whatsapp number</Text>
                      <Form.Item
                        className="no-error-show"
                        name="whatsappPhoneNumber"
                      >
                        <PhoneInput
                          onPaste={removePasteProps}
                          inputStyle={{ width: "100%", height: 50 }}
                          containerStyle={{ width: "100%" }}
                          className="w-100"
                        />
                      </Form.Item>
                    </Space>
                    {whatsappError && (
                      <CardError
                        id={whatsappError.id}
                        text="This whatsapp number is already associated with a contact"
                      />
                    )}
                  </div>

                  <Space direction="vertical" size={0} className="w-100">
                    <Text className="bold">Tags</Text>

                    <Form.Item name="tags">
                      <Select
                        onPaste={removePasteProps}
                        mode="multiple"
                        showSearch
                        filterOption={(input, option) =>
                          (option?.label ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        options={tags?.map((c) => ({
                          label: c.name,
                          value: c.id,
                        }))}
                        className="w-100"
                        placeholder="Enter tags"
                        size="large"
                      />
                    </Form.Item>
                  </Space>
                </Space>
              </Form>

              <Flex align="center" justify="end" gap={6}>
                <Button
                  className="new-buttons large-font"
                  size="large"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                <Button
                  loading={loading}
                  onClick={() => form.submit()}
                  type="primary"
                  size="large"
                  className="new-buttons large-font"
                >
                  Save changes
                </Button>
              </Flex>
            </Flex>
          )}
        </div>
      </Dropdown>
    </Drawer>
  );
};

export default CreateContactDrawer;
