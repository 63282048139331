import { Button, Flex, Space, Tabs, Typography } from "antd";
import { useState } from "react";
import CreateCampaignModal from "./CreateCampaignModal";
import ListCampaigns from "./ListCampaigns";

const CampaignListsLayout = ({showHeader = true}) => {
  const { Title } = Typography;

  const [addOpen, setAddOpen] = useState(false);

  return (
    <>
      <CreateCampaignModal open={addOpen} setOpen={setAddOpen} />
      <Space direction="vertical" className="w-100">
        {showHeader&&
        <Flex align="center" justify="space-between">
          <Title level={1} className="zero-margin-padding">
            Campaigns
          </Title>

          <Button
            type="primary"
            size="large"
            className="new-buttons large-font"
            onClick={() => setAddOpen(true)}
            >
            Create campaign
          </Button>
        </Flex>
          }

      <Tabs size="large" items={[{
        key: 1,
        label:"Email",
        children: <ListCampaigns/>
      },
      {
        key: 2,
        label:"Whatsapp",
        disabled:true
      },
      {
        key: 4,
        label:"SMS",
        disabled:true
      },
      {
        key: 5,
        label:"Notifications",
        disabled:true
      }]}/>
      </Space>
    </>
  );
};

export default CampaignListsLayout;
