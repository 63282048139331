import { Card, Col, Flex, Row, Space, Tabs, Typography } from "antd";
import "./NotificationPreview.css";
import logo from "../../../../assets/images/logo.png";
import { useEffect, useState } from "react";
import { FaWindows } from "react-icons/fa";

const NotificationPreview = ({ icon, url, title, message }) => {
  const { Text, Title } = Typography;

  const [browserName, setBrowserName] = useState("");

  useEffect(() => {
    const getBrowserName = () => {
      const userAgent = navigator.userAgent;

      if (userAgent.includes("Chrome")) {
        setBrowserName("Google Chrome");
      } else if (userAgent.includes("Firefox")) {
        setBrowserName("Mozilla Firefox");
      } else if (
        userAgent.includes("Safari") &&
        !userAgent.includes("Chrome")
      ) {
        setBrowserName("Safari");
      } else if (userAgent.includes("Edge")) {
        setBrowserName("Microsoft Edge");
      } else if (userAgent.includes("Opera") || userAgent.includes("OPR")) {
        setBrowserName("Opera");
      } else {
        setBrowserName("Unknown Browser");
      }
    };

    getBrowserName();
  }, []);

  const handleOpen = () => {
    window.open(url ? url : "https://www.tapzap.ai", "_blank");
  };

  return (
    <Card size="small" className="notification-preview-main-wrapper no-shadow">
      <Tabs
        size="small"
        items={[
          {
            label: (
              <Flex gap={6} align="center" justify="center">
                <FaWindows /> Windows
              </Flex>
            ),
            key: 1,
          },
          {
            label: "Android",
            key: 2,
            disabled: true,
          },
          {
            label: "IOS",
            key: 3,
            disabled: true,
          },
          {
            label: "MacOS",
            key: 4,
            disabled: true,
          },
        ]}
      />
      <Space className="w-100" direction="vertical" size={0}>
        <div className="notification-preview-wrapper">
          <Row>
            <Col span={4}>
              <img
                src={icon ? icon : logo}
                className="notification-preview-image"
              />
            </Col>
            <Col span={20} className="h-100">
              <Flex
                className="notification-preview-wrapper-flex"
                vertical
                justify="space-between"
              >
                <Space className="w-100" direction="vertical" size={0}>
                  <Title ellipsis level={3} className="zero-margin-padding">
                    {title ? title : "This is my title"}
                  </Title>
                  <Text>
                    {message
                      ? message
                      : "This is just some text that I wanted to send you!"}
                  </Text>
                </Space>

                <Text ellipsis>
                  {browserName} •{" "}
                  <Text class="truncate" type="link" onClick={handleOpen}>
                    {url ? url : "www.app.tapzap.ai"}
                  </Text>
                </Text>
              </Flex>
            </Col>
          </Row>
        </div>
      </Space>
    </Card>
  );
};

export default NotificationPreview;
