import {
  Button,
  Col,
  Flex,
  InputNumber,
  Modal,
  Row,
  Slider,
  Space,
  Typography,
  Card,
  message,
} from "antd";
import { useEffect, useState } from "react";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";

const UpdateAllowances = ({ open, setOpen, id, onAllowanceUpdate }) => {
  const { Text, Title } = Typography;
  const [emailValue, setEmailValue] = useState(() => 0);
  const [whatsappValue, setWhatsappValue] = useState(() => 0);
  const [smsValue, setSmsValue] = useState(() => 0);

  const [loading, setLoading] = useState(() => false);

  const axios = useAxiosPrivate();

  useEffect(() => {
    const fetch = async () => {
    const response = await axios.get(`/Allowance/${id}`)

    if(response.data.success){
      setEmailValue(response.data.email)
      setWhatsappValue(response.data.whatsapp);
      setSmsValue(response.data.sms)
    }else{
      message.error(response.data.message)
      setOpen(false)
    }
  }
  fetch();
  }, [])

  const save = async () => {
    setLoading(true);

    const response = await axios.put(`/Allowance`,{
      userId: id,
      email: emailValue,
      whatsapp: whatsappValue,
      sms: smsValue
    });

    if(response.data.success){
      setOpen(false)
      onAllowanceUpdate(id, emailValue, whatsappValue, smsValue)
    }else{
      message.error(response.data.message)
    }
    setLoading(false);
  };

  return (
    <Modal
      open={open}
      footer={
        <Flex align="center" justify="end" gap={6}>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button type="primary" loading={loading} onClick={() => save()}>
            Save changes
          </Button>
        </Flex>
      }
      onCancel={() => setOpen(false)}
      title={
        <Title style={{ margin: 0 }} level={4}>
          Adjust allowance
        </Title>
      }
    >
      <Row gutter={12}>
        <Col xs={8}>
          <Card style={{ boxShadow: "none" }}>
            <Space align="center" direction="vertical" style={{ width: "100%" }}>
              <Text strong>Email</Text>
             
                  <Slider
                    vertical
                    defaultValue={0}
                    value={emailValue}
                    min={0}
                    max={100}
                    style={{height:150}}
                    onChange={(e) => setEmailValue(e)}
                  />
                  <InputNumber size="large"
                    min={0}
                    max={100}
                    className="w-100"
                    value={emailValue}
                    onChange={(e) => setEmailValue(e)}
                    addonAfter="%"
                  />
            </Space>
          </Card>
        </Col>
        <Col xs={8}>
          <Card style={{ boxShadow: "none" }}>
            <Space align="center" direction="vertical" style={{ width: "100%" }}>
              <Text strong>SMS</Text>
              
                  <Slider
                    defaultValue={0}
                    value={smsValue}
                    vertical
                    min={0}
                    max={100}
                    style={{height:150}}
                    width={100}
                    onChange={(e) => setSmsValue(e)}
                  />
                
                  <InputNumber
                    min={0}
                    max={100}
                    className="w-100"
                    value={smsValue}
                    size="large"
                    onChange={(e) => setSmsValue(e)}
                    addonAfter="%"
                  />
            </Space>
          </Card>
        </Col>
        <Col xs={8}>
          <Card style={{ boxShadow: "none" }}>
            <Space direction="vertical" align="center" style={{ width: "100%" }}>
              <Text strong>Whatsapp</Text>
             
                  <Slider
                    defaultValue={0}
                    value={whatsappValue}
                    min={0}
                    max={100}
                    vertical
                    style={{height:"150px"}}
                    width={100}
                    onChange={(e) => setWhatsappValue(e)}
                  />
                  <InputNumber
                    min={0}
                    max={100}
                    className="w-100"
                    value={whatsappValue}
                    size="large"
                    onChange={(e) => setWhatsappValue(e)}
                    addonAfter="%"
                  />
            </Space>
          </Card>
        </Col>
      </Row>
    </Modal>
  );
};

export default UpdateAllowances;
