import { Card, Flex, Space, Tabs, Typography } from "antd";
import YourApiKeys from "./api/YouApiKeys";
import useAuth from "../../hooks/useAuth";
import UserList from "../users/UserManagement/UserList";
import SignatureList from "../templates/Signatures/SignatureList";
import ManageCoupons from "../prices/ManageCoupons";
import ManagePrices from "../prices/ManagePrices";
import "./ApiProxyLayout.css";
import MediaLayout from "../media/MediaLayout";
import SenderLayout from "../users/Senders/SenderLayout";
import { SettingsPageTabs } from "../../shared/enums";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const ApiProxyLayout = () => {
  const { Title } = Typography;

  const { auth } = useAuth();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const [activeTab, setActiveTab] = useState(queryParams.get("activetab"))

  const items = [
    {
      key: SettingsPageTabs.Media,
      label: "Media",
      children: <MediaLayout />,
    },
    {
      key: SettingsPageTabs.Senders,
      label: "Senders",
      children: <SenderLayout />,
    },
    {
      key: SettingsPageTabs.Developers,
      label: "Developers",
      children: <YourApiKeys />,
    },
    {
      key: SettingsPageTabs.Signatures,
      label: "Signatures",
      children: <SignatureList />,
    },
  ];

  if (auth?.roles?.find((c) => c == "SuperAdmin")) {
    items.push({
      key: SettingsPageTabs.Team,
      label: "Team Managment",
      children: <UserList />,
    });
  }

  if (auth?.roles?.find((c) => c == "ApplicationOwner")) {
    items.push({
      key: SettingsPageTabs.Prices,
      label: "Prices & Limits",
      children: <ManagePrices />,
    });

    items.push({
      key: SettingsPageTabs.Cupons,
      label: "Coupons",
      children: <ManageCoupons />,
    });
  }

  const navigate  = useNavigate();

  const handleChangeTab = (e) => {
    const newParams = new URLSearchParams();
    newParams.set("activetab", e);

    navigate(`/settings?${newParams.toString()}`)

    setActiveTab(e)

  }

  return (
    <Space direction="vertical" className="w-100" size={24}>
      <Title className="zero-margin-padding" level={1}>
        Settings
      </Title>
      <Tabs size="large" items={items} activeKey={activeTab} onChange={handleChangeTab}/>
    </Space>
  );
};

export default ApiProxyLayout;
