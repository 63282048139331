import { createContext, useContext, useEffect, useState } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { SearchContext } from "../../context/SearchContext";
import { useParams } from "react-router-dom";

export const ProductListContext = createContext({
    filters: {
        from: null,
        to:null,
    },
    setFilters: () => {},

    showFilters: false,
    setShowFilters: () => {},

    products: [],
    setProducts:() => {},

    stats: {
        total: 0,
    },
    setStats: () => {},

    page: 1,
    setPage: () => {},

    loading: false,
    setLoading: () => {},
});

export const ProductListContextProvider = ({ children }) => {

    const axios = useAxiosPrivate();
    const [pageSize, setPageSize] =useState(25); 

    const {debouncedValue} = useContext(SearchContext)
    const [showFilters, setShowFilters] = useState(false)
    const [filters, setFilters] = useState(() => {});
    const [stats, setStats] = useState();
    const [products, setProducts] = useState();
    const [page, setPage] = useState(() => 1);
    const [loading, setLoading] = useState(false)

    const {authorId} = useParams();
    useEffect(() => {

    const fetch = async () => {

        setLoading(true);
        const response = await axios.post(`/Product/list`, {
            search: debouncedValue,
            page: page,
            pageSize: pageSize,
            from: filters?.from,
            to: filters?.to,
            authorId: authorId
        });
        
        setProducts(response.data.items);
        setStats(prev => ({
            ...prev, 
            total: response.data.total,
            first: response.data.first,
            last: response.data.last,
            authorProfile: response.data.authorProfile,
            authorName: response.data.authorName
        }))

        setLoading(false);
    }
    fetch();
    }, [page, filters, debouncedValue, pageSize])

    return <ProductListContext.Provider value={{
        showFilters,
        setShowFilters,

        filters,
        setFilters,

        loading,
        setLoading,

        page,
        setPage,

        products,
        setProducts,

        stats,
        setStats,
        pageSize,
        setPageSize
    }}>
        {children}
    </ProductListContext.Provider>
}