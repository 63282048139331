import React, { memo } from "react";
import WonDealModal from "../WonDealModal";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { message } from "antd";
import { CardStatus } from "../../../shared/enums";
import { insert } from '../../../shared/HelperMethods'

const KanbanWonDealModal = memo(
  ({ open, setOpen, cards, setKanbanCards, initialCardPositionRef, dragEvent }) => {

    const axios = useAxiosPrivate();

    function onClose() {
      if(!initialCardPositionRef.current.card){
        return;
      }

      const {card, index} = initialCardPositionRef.current;

      const {status, id} = card;

      setKanbanCards(prev => ({
        ...prev,
        [CardStatus.Won] : ({
          ...prev[CardStatus.Won],
          items: prev[CardStatus.Won].items.filter(c => c.id != id)
        }),

        [status]: ({
          ...prev[status],
          items: insert(prev[status].items, index, card)
        })
      }))

      initialCardPositionRef.current = {};
      setOpen(false);
    }

    const onSubmit = async (e) => {

      const { active } = dragEvent;

      const response = await axios.post(`/Deal/UpdateDealPosition`, {
        id: active.data.current.card.id,
        index: active.data.current.sortable.index,
        newStage: CardStatus.Won,
        deals: active.data.current.sortable.items,
        closeDate: e.closeDate,
        totalRevenue: e.revenue,
        reason: e.closedReason
      });

      let oldStatus = response.data.oldStage;
      let amount = response.data.amountToAddToNewStage;

      if (response.data.success) {
        setKanbanCards(prev => ({
          ...prev,
          [oldStatus]: ({
            ...prev[oldStatus],
            count: prev[oldStatus].count - 1,
            revenue: prev[oldStatus].revenue - amount
          }),
          [CardStatus.Won]: ({
            ...prev[CardStatus.Won],
            count: prev[CardStatus.Won].count + 1,
            revenue: prev[CardStatus.Won].revenue + amount
          })
        }))
        setOpen(false);
      } else {
        message.error(response.data.message)
      }

    }

    return <WonDealModal open={open} onClose={onClose} onSubmit={onSubmit} />;
  }
);

export default KanbanWonDealModal;
