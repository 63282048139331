import {
  Avatar,
  Button,
  Col,
  Flex,
  message,
  Popover,
  Row,
  Space,
  Typography,
} from "antd";
import { FaExpandArrowsAlt } from "react-icons/fa";
import { FaListUl, FaSpellCheck, FaWandMagicSparkles } from "react-icons/fa6";
import { IoMdHappy } from "react-icons/io";
import { TbArrowsShuffle2 } from "react-icons/tb";
import { BiBriefcaseAlt } from "react-icons/bi";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { SmartTextType } from "../shared/enums";
import { useState } from "react";
import { MdOutlineTranslate } from "react-icons/md";

const SmartText = ({ text, setText, size="small" }) => {
  const axios = useAxiosPrivate();

  const { Title, Text } = Typography;

  const [loading, setLoading] = useState(false);

  const generate = async (type) => {
    setLoading(true);

    var response = await axios.post("/SmartThings/generate", {
      type: type,
      text: text,
    });

    if (response.data.success) {
      setText(response.data.text);
    } else {
      message.error(response.data.message);
    }
    setLoading(false);
  };

  return (
    <>
      <Popover
        trigger="click"
        content={
          <>
            <Space className="w-100" size={24} direction="vertical">
              <div>
                <Flex align="center" justify="space-between">
                  <Title level={4} style={{ margin: 0 }}>
                    <Flex align="center" gap={6}>
                      <FaWandMagicSparkles /> Smart Text
                    </Flex>
                  </Title>
                </Flex>
                <Text>Use AI to enhance the quality of your writing.</Text>
              </div>

              <Row gutter={[12, 12]} style={{ maxWidth: 450 }}>
                <Col span={12}>
                  <Button
                    loading={loading}
                    onClick={() => generate(SmartTextType.Fix)}
                    type="text"
                    icon={
                      <Avatar
                        className="flex-button"
                        style={{ background: "#f0fefa" }}
                        size="small"
                        icon={<FaSpellCheck style={{ color: "#0fbc92" }} />}
                      />
                    }
                    className="smart-text-buttons flex-button-start"
                  >
                    Fix Spelling & Grammar
                  </Button>
                </Col>
                <Col span={12}>
                  <Button
                    loading={loading}
                    onClick={() => generate(SmartTextType.Expand)}
                    type="text"
                    icon={
                      <Avatar
                        className="flex-button"
                        style={{ background: "#f0fefa" }}
                        size="small"
                        icon={
                          <FaExpandArrowsAlt style={{ color: "#0fbc92" }} />
                        }
                      />
                    }
                    className="smart-text-buttons flex-button-start"
                  >
                    Expand Text
                  </Button>
                </Col>
                <Col span={12}>
                  <Button
                    loading={loading}
                    onClick={() => generate(SmartTextType.Summarize)}
                    type="text"
                    icon={
                      <Avatar
                        className="flex-button"
                        style={{ background: "#f0fefa" }}
                        size="small"
                        icon={<FaListUl style={{ color: "#0fbc92" }} />}
                      />
                    }
                    className="smart-text-buttons flex-button-start"
                  >
                    Summarize Text
                  </Button>
                </Col>
                <Col span={12}>
                  <Button
                    loading={loading}
                    onClick={() => generate(SmartTextType.Rephrase)}
                    type="text"
                    icon={
                      <Avatar
                        className="flex-button"
                        style={{ background: "#f0fefa" }}
                        size="small"
                        icon={<TbArrowsShuffle2 style={{ color: "#0fbc92" }} />}
                      />
                    }
                    className="smart-text-buttons flex-button-start"
                  >
                    Rephrase Text
                  </Button>
                </Col>
                <Col span={12}>
                  <Button
                    loading={loading}
                    onClick={() => generate(SmartTextType.Friendly)}
                    type="text"
                    icon={
                      <Avatar
                        className="flex-button"
                        style={{ background: "#f0fefa" }}
                        size="small"
                        icon={<IoMdHappy style={{ color: "#0fbc92" }} />}
                      />
                    }
                    className="smart-text-buttons flex-button-start"
                  >
                    Make it Friendly
                  </Button>
                </Col>
                <Col span={12}>
                  <Button
                    loading={loading}
                    onClick={() => generate(SmartTextType.Formal)}
                    type="text"
                    icon={
                      <Avatar
                        className="flex-button"
                        style={{ background: "#f0fefa" }}
                        size="small"
                        icon={<BiBriefcaseAlt style={{ color: "#0fbc92" }} />}
                      />
                    }
                    className="smart-text-buttons flex-button-start"
                  >
                    Make it Formal
                  </Button>
                </Col>
                <Col span={12}>
                  <Button
                    loading={loading}
                    onClick={() =>
                      message.error("Smart Text translate is comming soon!")
                    }
                    type="text"
                    icon={
                      <Avatar
                        className="flex-button"
                        style={{ background: "#f0fefa" }}
                        size="small"
                        icon={
                          <MdOutlineTranslate style={{ color: "#0fbc92" }} />
                        }
                      />
                    }
                    className="smart-text-buttons flex-button-start"
                  >
                    Translate
                  </Button>
                </Col>
              </Row>
            </Space>
          </>
        }
      >
        <div className="ai-button">
          <FaWandMagicSparkles />
          Smart Text
        </div>
      </Popover>
    </>
  );
};

export default SmartText;
