import { Tag } from "antd";

export const getStage = (stage) => {
    if (stage == 1) {
      return (
        <Tag className="deal-list-tag" bordered={false} color="gray">
          New
        </Tag>
      );
    } else if (stage == 2) {
      return (
        <Tag className="deal-list-tag" bordered={false} color="blue">
          Qualifying
        </Tag>
      );
    } else if (stage == 3) {
      return (
        <Tag className="deal-list-tag" bordered={false} color="cyan">
          Demo Scheduled
        </Tag>
      );
    } else if (stage == 4) {
      return (
        <Tag className="deal-list-tag" bordered={false} color="lime">
          Pending Commitment
        </Tag>
      );
    } else if (stage == 5) {
      return (
        <Tag className="deal-list-tag" bordered={false} color="geekblue">
          In Negotiation
        </Tag>
      );
    } else if (stage == 6) {
      return (
        <Tag className="deal-list-tag" bordered={false} color="green">
          Won
        </Tag>
      );
    } else {
      return (
        <Tag className="deal-list-tag" color="red" bordered={false}>
          Lost
        </Tag>
      );
    }
  };