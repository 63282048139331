import {
  Avatar,
  Button,
  Card,
  Flex,
  Space,
  Tabs,
  Typography,
} from "antd";
import { FaArrowLeft } from "react-icons/fa6";
import { IoMdMore } from "react-icons/io";
import { useNavigate, useParams } from "react-router-dom";
import UserDetailsCampaigns from "./UserDetailsCampaigns";
import UserDetailsDesings from "./UserDetailsDesings";
import UserDetailsTasks from "./UserDetailsTasks";
import UserDetailsDeals from "./UserDetailsDeals";
import UserDetailsCommunications from "./UserDetailsCommunications";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import { useEffect, useState } from "react";
import { UserOutlined } from "@ant-design/icons";
import UserDetailsCompanies from "./UserDetailsCompanies";
import UserDetailsProducts from "./UserDetailsProducts";

const UserDetails = () => {
  const { Text, Title } = Typography;

  const navigate = useNavigate();

  const axios = useAxiosPrivate();
  const [data, setData] = useState();

  const { authorId } = useParams();

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    const fetch = async () => {
      const response = await axios.get(`/Members/${authorId}`, {
        signal: signal,
      });

      setData(response.data);
    };

    fetch();

    return () => controller.abort();
  }, []);
  return (
    <Space direction="vertical" className="w-100" size={24}>
      <Flex align="center" justify="space-between">
        <Flex align="center" justify="start" gap={12}>
          <div>
            <Button
              onClick={() => navigate(-1)}
              type="text"
              size="large"
              icon={<FaArrowLeft />}
              className="flex-button"
            ></Button>
          </div>
          <div>
            <Avatar
              style={{ width: 80, height: 80}}
              size={"large"}
              icon={<UserOutlined style={{ fontSize: 36 }} />}
              src={`${process.env.REACT_APP_FILE_URL}${data?.imagePath}`}
            />
          </div>
          <Space direction="vertical" size={0} className="w-100">
            <Title className="zero-margin-padding" level={1}>
              {data?.name} {data?.lastname}
            </Title>
            <Text>
              {data?.email} {data?.phoneNumber}
            </Text>
          </Space>
        </Flex>

        <Flex align="center" justify="end" gap={6}>
          <Button
            type="text"
            size="large"
            className="flex-button"
            icon={<IoMdMore className="icon" />}
          />
        </Flex>
      </Flex>
      <Card size="small" className="no-shadow gray-border">
        <Tabs
          type="card"
          size="large"
          style={{ marginLeft: -10, marginTop: -10 }}
          items={[
            {
              label: "Campaigns",
              key: 1,
              children: <UserDetailsCampaigns />,
            },
            {
              label: "Desings",
              key: 2,
              children: <UserDetailsDesings />,
            },
            {
              label: "Communications",
              key: 5,
              children: <UserDetailsCommunications />,
            },
            {
              label: "Tasks",
              key: 3,
              children: <UserDetailsTasks />,
            },
            {
              label: "Deals",
              key: 4,
              children: <UserDetailsDeals />,
            },
            {
              label: "Products",
              key: 6,
              children: <UserDetailsProducts />,
            },
            {
              label: "Companies",
              key: 7,
              children: <UserDetailsCompanies />,
            },
          ]}
        />
      </Card>
    </Space>
  );
};

export default UserDetails;
