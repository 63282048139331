import { Button, Dropdown } from "antd";
import { BsTrashFill } from "react-icons/bs";
import { FaBan } from "react-icons/fa";
import { IoMdMore } from "react-icons/io";
import DeleteCoupon from "./DeleteCoupon";
import SuspendCoupon from "./SuspendCoupon";

const CouponOptions = ({id, coupon, onDeleted, handleSuspended}) => {
  const items = [
    {
        label:<SuspendCoupon coupon={coupon} handleSuspended={handleSuspended}/>,
        icon: <FaBan/> 
    },
    {
        label: <DeleteCoupon id={id} onDeleted={onDeleted}/>,
        icon: <BsTrashFill/>,
        danger:true
    }
  ]
    return (
    <>
    <Dropdown trigger={["click"]} menu={{ items }}>

      <Button
        shape="circle"
        className="flex-button"
        type="text"
        icon={<IoMdMore style={{ fontSize: 20 }} />}
        ></Button>
        </Dropdown>
    </>
  );
};

export default CouponOptions;
