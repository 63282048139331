import Card from "antd/es/card/Card";
import {
  Flex,
  Space,
  Spin,
  Statistic,
  Typography,
} from "antd";
import CountUp from "react-countup";

const formatter = (value, from = 0) => <CountUp  once={true} start={from} end={value} separator="," />;

const Stat = ({ value, name, color, description, icon, loading, from, showShadow = true }) => {
    const { Text } = Typography;
    return (
      <Card
        size="small" 
        className={!showShadow ? "shadowless" : ""}
      >
        <Spin spinning={loading}>
          <Flex align="center" justify="space-between" gap={6}>
            <Space direction="vertical" size={0}>
              <Text
                style={{ textTransform: "capitalize", fontWeight: 500 }}
              >
                {name}
              </Text>
              <Statistic
                formatter={typeof value === 'string' ? null : formatter}
                style={{ marginTop: "-5px" }}
                value={value}
                from={from}
                precision={0}
                valueStyle={{ fontWeight: 600, fontSize:20 }}
              />
            </Space>

            <Flex
              align="center"
              justify="center"
              style={{
                width: "50px",
                height: "50px",
                background: "#f2f2fd",
                borderRadius: "50px",
              }}
            >
              {icon}
            </Flex>
          </Flex>
        </Spin>
      </Card>
    );
  };
export default Stat