import { Col, Row, Space, Tabs } from "antd";
import React from "react";
import DealDetailsActivites from "./DealDetailsActivites";
import DealDetailsAside from "../DealDetailsAside/DealDetailsAside";
import CardStatusBreadCrumbs from "../../CardStatusBreadCrumbs/CardStatusBreadCrumbs";
import DealDetailsHistory from "./DealDetailsHistory";
import DealDetailsSteps from "./DealDetailsSteps/DealDetailsSteps";
import DealContacts from "../DealDetailsAside/DealContacts/DealContacts";
import DealCompanies from "../DealDetailsAside/DealCompanies/DealCompanies";
import DealProducts from "../DealDetailsAside/DealProducts/DealProducts";

const DealDetailsMain = () => {
  return (
    <>
      <Tabs
        size="large"
        items={[
          {
            key: 1,
            label: "Overview",
            children: (
              <Row gutter={[24, 24]}>
                <Col xs={24} sm={24} md={24} lg={6} xl={6} xxl={6}>
                  <DealDetailsAside />
                </Col>
                <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                  <Space className="w-100" direction="vertical" size="large">
                    <CardStatusBreadCrumbs />
                    <DealDetailsActivites />
                    <DealDetailsSteps />
                    <DealDetailsHistory />
                  </Space>
                </Col>
                <Col xs={24} sm={24} md={24} lg={6} xl={6} xxl={6}>
                <Space className="w-100" direction="vertical" size={24} style={{position:"sticky", top:24}}>

                  <DealContacts />
                  <DealCompanies />
                  <DealProducts />
                </Space>
                </Col>
              </Row>
            ),
          },
        ]}
      />
    </>
  );
};

export default DealDetailsMain;
