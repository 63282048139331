import { Layout } from "antd";
import SideMenu from "./Navigation/SideMenu";
import { Outlet } from "react-router-dom";
import AppFooter from "./Navigation/Footer/Footer";
import ApplyCouponModal from "../pages/audience/subscription/ApplyCouponModal";
import Header from "./Navigation/Header/Header";
import { CurrentUserContextProvider } from "../context/CurrentUserContext";
import { AuthorContextProvider } from "../context/AuthorsContext";
const { Content } = Layout;

const ApplicationLayout = () => (
  <CurrentUserContextProvider>
    <AuthorContextProvider>
      <Layout className="min-max-height">
        <SideMenu />
        <Layout className="min-max-height">
          <Header />
          <Content className="margin-24">
            <Outlet />
          </Content>
          <AppFooter />
        </Layout>
        <ApplyCouponModal />
      </Layout>
    </AuthorContextProvider>
  </CurrentUserContextProvider>
);

export default ApplicationLayout;
