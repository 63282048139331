import { useContext } from "react";
import { ProductListContext } from "../ProductListContext";
import DateFilter from "../../../shared/DateFilter";

const DateToProductFilter = () => {
  const { filters, setFilters } = useContext(ProductListContext);
  return (
    <>
    <DateFilter filters={filters} setFilters={setFilters}/>
    </>
  );
};

export default DateToProductFilter;
