import { Button, Dropdown } from "antd"
import { BsFillTrashFill } from "react-icons/bs";
import { IoEllipsisHorizontal } from "react-icons/io5"

const TemplateComponentListOptions = ({id, onDelete}) => {
    const items = [
        {
          key: "2",
          label: <p className="template-options-text">Delete selected component</p>,
          danger: true,
          onClick: () => onDelete(id),
          icon: <BsFillTrashFill className="template-options-icon"/>,
        },
      ];

    return <>
     <Dropdown   trigger={["click"]} menu={{ items }}>
        <Button
          type="text"
          size="large"
          className="flex-button"
          icon={
            <IoEllipsisHorizontal className="template-options-icon" />
          }
        ></Button>
      </Dropdown>
    </>
}

export default TemplateComponentListOptions