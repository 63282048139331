import {
  Button,
  Card,
  Flex,
  message,
  notification,
  Space,
  Typography,
} from "antd";
import AssignContactCompanyDrawer from "./AssignContactCompanyDrawer";
import { useState } from "react";
import { DateFormat } from "../../../dateformat";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import DeleteConfirmationModal from "../../../components/DeleteConfirmationModal";

const ContactCompanies = ({ details, setDetails }) => {
  const { Title, Text } = Typography;
  const [addOpen, setAddOpen] = useState(false);

  const axios = useAxiosPrivate();

  const [removeOpen, setRemoveOpen] = useState(false);
  const [current, setCurrent] = useState();

  const handleRemoveClick = (id) => {
    setRemoveOpen(true);
    setCurrent(id);
  };

  const remove = async (item, remove) => {
    if(!remove){
        return;
    }
    
    const response = await axios.put(`/Contacts/Remove-company`, {
      emailId: details?.id,
      companyId: item,
    });

    if (response.data.success) {
      notification.open({
        message: (
          <Flex
            style={{ width: "200px", height: "50px" }}
            className="h-100"
            align="center"
            justify="center"
          >
            {" "}
            <Text className="large-font">Assoication removed</Text>
          </Flex>
        ),
        showProgress: true,
        placement: "bottom",
        btn: null,
        closeIcon: null,
      });

      setDetails((prev) => ({
        ...prev,
        contactCompanies: prev?.contactCompanies?.filter((c) => c.id != item),
      }));
    } else {
      message.error(response.data.message);
    }
  };

  const handleDeleteClose = () => {
    setRemoveOpen(false);
  };

  const handleUpdated = (e) => {
    setDetails(prev => ({...prev,
        contactCompanies: e
    }))
  } 

  return (
    <>
      <DeleteConfirmationModal
        item={current}
        onClose={handleDeleteClose}
        isOpen={removeOpen}
        onDeleteConfirm={remove}
      />
      <AssignContactCompanyDrawer
        open={addOpen}
        setOpen={setAddOpen}
        contact={details}
        onUpdated={handleUpdated}
      />
      {!details?.contactCompanies || details?.contactCompanies.length == 0 ? (
        <Card size="small" className="no-shadow gray-border">
          <Space direction="vertical" size="large">
            <Space direction="vertical" size={0}>
              <Title level={4} className="zero-margin-padding">
                Companies
              </Title>

              <Text>No companies associated with this record</Text>
            </Space>
            <Button
              size="large"
              className="new-buttons large-font"
              type="primary"
              onClick={() => setAddOpen(true)}
            >
              Add companies
            </Button>
          </Space>
        </Card>
      ) : (
        <Space direction="vertical" className="w-100">
          <Flex align="center" justify="space-between">
            <Title level={4} className="zero-margin-padding">
              Companies
            </Title>

            <Button
              className="new-buttons large-font"
              onClick={() => setAddOpen(true)}
            >
              Assign
            </Button>
          </Flex>
          {details?.contactCompanies?.map((c, i) => (
            <Card
              size="small"
              key={i}
              className="no-shadow gray-border-hoverable"
            >
              <Flex align="center" justify="space-between">
                <Space size={0} direction="vertical">
                  <Title className="zero-margin-padding" level={5}>
                    {c.name}
                  </Title>
                  <Text>
                    Assigned at{" "}
                    {new Date(c.assignedAt).toLocaleDateString(
                      "en-us",
                      DateFormat
                    )}
                  </Text>
                </Space>

                <Button
                  size="small"
                  danger
                  type="text"
                  onClick={() => handleRemoveClick(c.id)}
                  className="new-buttons large-font"
                >
                  Remove
                </Button>
              </Flex>
            </Card>
          ))}
        </Space>
      )}
    </>
  );
};

export default ContactCompanies;
