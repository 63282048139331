import {
  Button,
  Card,
  Flex,
  Form,
  message,
  Modal,
  Space,
  Typography,
} from "antd";
import { useContext, useEffect, useState, useCallback } from "react";
import { CreateNotificationTemplateContext } from "./CreateNotificationTemplateContext";
import { FaCheck, FaCloudUploadAlt } from "react-icons/fa";
import { MdClose } from "react-icons/md";
import "./HeaderStep.css";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import "./IconStep.css";
import { useDropzone } from "react-dropzone";
import { BsSearch } from "react-icons/bs";
import { GoDownload } from "react-icons/go";

const IconStep = ({ active, setStep }) => {
  const { Title, Text } = Typography;
  const { data, setData } = useContext(CreateNotificationTemplateContext);
  const [form] = Form.useForm();
  const axios = useAxiosPrivate();

  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null);

  const onDrop = useCallback((acceptedFiles) => {
    setFile(acceptedFiles[0]);
  }, []);

  const handleCancel = () => {
    setStep();
  };

  const submit = async () => {
    if (!file) {
      message.error("Please upload a file.");
      return;
    }

    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("id", data?.id);

      const response = await axios.post("/Notification/Assing/Icon", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.status === 200) {
        message.success("File uploaded successfully!");
        setData({ ...data, path: response.data.path });
        setStep();
      }
    } catch (error) {
      message.error("File upload failed.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    form.setFieldValue("template", data?.template);
  }, [data?.template]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
    accept: {
      "image/*": [],
    },
  });

  const [downloading, setDownloading] = useState(false);

  const downlaodIcon = async () => {
    const url = `${process.env.REACT_APP_FILE_URL}${data?.path}`;

    try {
      const response = await fetch(url);
      const blob = await response.blob();

      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.setAttribute("download", data?.path);
      document.body.appendChild(link);
      link.click();

      link.remove();
    } catch (error) {
      console.error("Error downloading the file", error);
    }
  };

  const [imagePreviewOpen, setImagePreviewOpen] = useState(false);
  const [removeOpen, setRemoveOpen] = useState(false);

  const handleRemoved = async () => {
    const response = await axios.put("/Notification/Remove/Icon", {
      id: data?.id,
    });

    if (response.data.success) {
      setStep(4);
      setRemoveOpen(false);
      setData((prev) => ({ ...prev, path: "" }));
    } else {
      message.error(response.data.message);
    }
  };
  return (
    <>
      <RemoveIconModal
        onConfirm={handleRemoved}
        open={removeOpen}
        setOpen={setRemoveOpen}
      />
      <PreviewImageModal
        src={`${process.env.REACT_APP_FILE_URL}${data?.path}`}
        open={imagePreviewOpen}
        setOpen={setImagePreviewOpen}
      />
      <Card
        size="small"
        className={`step-card no-shadow last-card ${
          active ? "active-step" : ""
        }`}
      >
        <Flex className="w-100" align="center" justify="space-between">
          <Flex align="center" justify="start" gap={12}>
            <div>
              {data?.path ? (
                <div className="step-evaluation-box-success">
                  <FaCheck />
                </div>
              ) : (
                <div className="step-evaluation-box-default"></div>
              )}
            </div>
            <Space direction="vertical" size={0}>
              <Title className="step-card-title zero-margin-padding" level={3}>
                Icon
              </Title>
              {data?.path ? (
                <Space direction="vertical" size={0}>
                  <Text className="sender-preview-name" ellipsis>
                    {data.path}
                  </Text>
                  <Flex align="center" justify="start" gap={6}>
                    <Button
                      size="small"
                      className="icon-preview-button"
                      onClick={downlaodIcon}
                      loading={downloading}
                      icon={<GoDownload />}
                    >
                      Download
                    </Button>
                    <Button
                      size="small"
                      className="icon-preview-button"
                      onClick={() => setImagePreviewOpen(true)}
                      icon={<BsSearch />}
                    >
                      Preview
                    </Button>
                  </Flex>
                </Space>
              ) : (
                <Text>The icon displayed on your notification</Text>
              )}
            </Space>
          </Flex>
          {active ? (
            <Button
              type="text"
              onClick={handleCancel}
              className="flex-button"
              size="large"
              icon={<MdClose className="icon" />}
            ></Button>
          ) : (
            <>
              {data?.path ? (
                <Button size="large" danger onClick={() => setRemoveOpen(true)}>
                  Remove Icon
                </Button>
              ) : (
                <Button className="create-notification-template-button large-font new-buttons" onClick={() => setStep(4)} size="large">
                  Upload Icon
                </Button>
              )}
            </>
          )}
        </Flex>

        {active && (
          <div className="icon-work-area">
            <Form form={form} onFinish={submit}>
              <div
                {...getRootProps({
                  className: "dropzone notification-template-icon-uploader",
                })}
              >
                <input {...getInputProps()} />
                {isDragActive ? (
                  <p>Drop the file here...</p>
                ) : (
                  <div
                    className="w-100"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "100%",
                    }}
                  >
                    <FaCloudUploadAlt style={{ fontSize: 80 }} />
                    <Space align="center" direction="vertical" size={0}>
                      <Text style={{ fontSize: 18 }}>
                        Select your file or drag and drop it here
                      </Text>
                      <Text>Only image types are supported</Text>
                    </Space>
                  </div>
                )}
              </div>
              {file && (
                <div style={{ marginTop: "10px" }}>
                  <strong>Selected file:</strong> {file.name}
                </div>
              )}
            </Form>
            <Flex
              align="center"
              justify="end"
              gap={6}
              className="url-step-working-area-footer"
            >
              <Button
                size="large"
                onClick={() => setStep()}
               className="new-buttons large-font"
              >
                Cancel
              </Button>
              <Button
                size="large"
                type="primary"
                onClick={() => form.submit()}
                loading={loading}
                 className="new-buttons large-font"
                >
                Save
              </Button>
            </Flex>
          </div>
        )}
      </Card>
    </>
  );
};

const RemoveIconModal = ({ open, setOpen, onConfirm }) => {
  const { Title, Text } = Typography;

  return (
    <Modal
      open={open}
      onCancel={() => setOpen(false)}
      title={
        <Title style={{ margin: 0 }} level={4}>
          Reset icon confirmation
        </Title>
      }
      footer={
        <Flex align="center" justify="end" gap={6}>
          <Button size="large" onClick={() => setOpen(false)}>
            Cancel
          </Button>
          <Button
            size="large"
            type="primary"
            danger
            onClick={() => onConfirm()}
          >
            Confirm
          </Button>
        </Flex>
      }
    >
      <Text>
        Are you sure you want to reset your icon? You will lose all of your
        current content and modifications.
      </Text>
    </Modal>
  );
};

const PreviewImageModal = ({ open, setOpen, src }) => {
  return (
    <Modal
      footer={false}
      title={<div className="otification-preview-modal-image-title"></div>}
      width={750}
      centered
      open={open}
      onCancel={() => setOpen(false)}
    >
      <div className="notification-preview-modal-image-wrapper">
        <img src={src} className="notification-preview-modal-image" />
      </div>
    </Modal>
  );
};

export default IconStep;
