import {
  Button,
  Card,
  Checkbox,
  Col,
  Divider,
  Dropdown,
  Flex,
  Input,
  Row,
  Select,
  Space,
  Table,
  Tag,
  Typography,
} from "antd";
import { DndContext } from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import { useContext } from "react";
import {
  MdAdd,
  MdArrowBack,
  MdDragIndicator,
  MdLightbulbOutline,
  MdOutlineArrowDropDown,
} from "react-icons/md";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import React from "react";
import { CSS } from "@dnd-kit/utilities";
import { FaRegTrashAlt, FaTrash } from "react-icons/fa";
import { TbArrowsSort } from "react-icons/tb";
import { WhatsAppTemplateContext } from "../../../../context/WhatsAppTemplateContext";
import Save from "./Save";
import SmartText from "../../../../components/SmartText";
const CustomRow = ({ children, ...props }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: props["data-row-key"],
  });
  const style = {
    ...props.style,
    transform: CSS.Transform.toString(
      transform && {
        ...transform,
        scaleY: 1,
      }
    ),
    transition,
    ...(isDragging
      ? {
          position: "relative",
          zIndex: 9999,
        }
      : {}),
  };
  return (
    <tr {...props} ref={setNodeRef} style={{ ...style }} {...attributes}>
      {React.Children.map(children, (child) => {
        if (child.key === "sort") {
          return React.cloneElement(child, {
            children: (
              <MdDragIndicator
                ref={setActivatorNodeRef}
                style={{
                  touchAction: "none",
                  cursor: "move",
                  fontSize: 22,
                }}
                {...listeners}
              />
            ),
          });
        }
        return child;
      })}
    </tr>
  );
};

const Buttons = ({ setCurrent }) => {
  const { template, setTemplate } = useContext(WhatsAppTemplateContext);

  const columns = [
    {
      width: 10,
      key: "sort",
    },
    {
      render: (data, row) => <>{data.type}</>,
    },
    {
      width: 10,
      render: (data, row) => (
        <Button
          className="flex-button"
          shape="circle"
          danger
          type="link"
          onClick={() => handleRemove(data.key, data.typeString)}
          icon={<FaRegTrashAlt />}
        ></Button>
      ),
    },
  ];

  const { Text, Title } = Typography;

  const countOccurrences = (array, element) =>
    array.reduce(
      (count, current) => count + (current.buttonType === element ? 1 : 0),
      0
    );

  const items = [
    {
      key: "1",
      disabled: countOccurrences(template?.quickReplyButtons?.buttons, "mou"),
      label: "Marketing opt-out",
      onClick: () =>
        setTemplate((prev) => ({
          ...prev,
          footer: "Not interested? Tap to stop promotions",
          footerDisabled: true,
          quickReplyButtons: {
            ...prev?.quickReplyButtons,
            buttons: [
              ...prev?.quickReplyButtons?.buttons,
              {
                type: (
                  <AddMarketingOptOutButton
                    viewKey={prev?.quickReplyButtons?.buttons?.length + 1}
                  />
                ),
                key: prev?.quickReplyButtons?.buttons?.length + 1,
                typeString: "pre",
                buttonType: "mou",
                text: "Stop promotions",
              },
            ],
          },
        })),
    },
    {
      key: "2",
      label: "Custom",
      onClick: () =>
        setTemplate((prev) => ({
          ...prev,
          quickReplyButtons: {
            ...prev?.quickReplyButtons,
            buttons: [
              ...prev?.quickReplyButtons?.buttons,
              {
                type: (
                  <Custom
                    viewKey={prev?.quickReplyButtons?.buttons?.length + 1}
                  />
                ),
                key: prev?.quickReplyButtons?.buttons?.length + 1,
                typeString: "pre",
                buttonType: "custom",
                text: "",
              },
            ],
          },
        })),
    },
    {
      type: "divider",
      label: (
        <Divider>
          <p>Your Divider Text Here</p>
        </Divider>
      ),
    },
    {
      key: "3",
      label: "Visit website",
      disabled: countOccurrences(template?.ctaButton?.buttons, "vw") == 2,
      onClick: () =>
        setTemplate((prev) => ({
          ...prev,
          ctaButton: {
            ...prev?.ctaButton,
            buttons: [
              ...prev?.ctaButton?.buttons,
              {
                type: (
                  <AddVisitWebsite
                    viewKey={prev?.ctaButton?.buttons?.length + 1}
                  />
                ),
                key: prev?.ctaButton?.buttons?.length + 1,
                typeString: "cta",
                buttonType: "vw",
                text: "",
              },
            ],
          },
        })),
    },
    {
      key: "4",
      label: "Call phone number",
      disabled: countOccurrences(template?.ctaButton?.buttons, "cpn") == 1,
      onClick: () =>
        setTemplate((prev) => ({
          ...prev,
          ctaButton: {
            ...prev.ctaButton,
            buttons: [
              ...prev.ctaButton.buttons,
              {
                type: (
                  <AddCallPhoneNumber
                    viewKey={prev?.ctaButton?.buttons?.length + 1}
                  />
                ),
                key: prev?.ctaButton?.buttons?.length + 1,
                typeString: "cta",
                buttonType: "cpn",
                text: "",
              },
            ],
          },
        })),
    },
    {
      key: "5",
      label: "Copy offer code",
      disabled: countOccurrences(template?.ctaButton?.buttons, "coc"),
      onClick: () =>
        setTemplate((prev) => ({
          ...prev,
          ctaButton: {
            ...prev.ctaButton,
            buttons: [
              ...prev.ctaButton.buttons,
              {
                type: (
                  <AddCopyOfferCode
                    viewKey={prev?.ctaButton?.buttons?.length + 1}
                  />
                ),
                key: prev?.ctaButton?.buttons?.length + 1,
                typeString: "cta",
                buttonType: "coc",
                text: "Copy offer code",
              },
            ],
          },
        })),
    },
  ];

  const onDragEnd = ({ active, over }) => {
    if (active.id !== over?.id) {
      const activeIndex = template?.quickReplyButtons?.buttons?.findIndex(
        (i) => i.key === active.id
      );
      const overIndex = template?.quickReplyButtons?.buttons?.findIndex(
        (i) => i.key === over?.id
      );

      setTemplate((prev) => ({
        ...prev,
        quickReplyButtons: {
          ...prev.quickReplyButtons,
          buttons: arrayMove(
            prev?.quickReplyButtons?.buttons,
            activeIndex,
            overIndex
          ),
        },
      }));
    }
  };

  const onDragEndCta = ({ active, over }) => {
    if (active.id !== over?.id) {
      {
        const activeIndex = template?.ctaButton?.buttons?.findIndex(
          (i) => i.key === active.id
        );
        const overIndex = template?.ctaButton?.buttons?.findIndex(
          (i) => i.key === over?.id
        );

        setTemplate((prev) => ({
          ...prev,
          ctaButton: {
            ...prev.ctaButton,
            buttons: arrayMove(
              prev?.ctaButton?.buttons,
              activeIndex,
              overIndex
            ),
          },
        }));
      }
    }
  };

  const handleRemove = (id, type) => {
    if (type == "cta") {
      setTemplate((prev) => ({
        ...prev,
        ctaButton: {
          ...prev.ctaButton,
          buttons: prev?.ctaButton?.buttons?.filter((c) => c.key != id),
        },
      }));
    } else {
      const button = template?.quickReplyButtons?.buttons?.find(
        (c) => c.key == id
      );

      setTemplate((prev) => ({
        ...prev,
        footer: button.buttonType == "mou" ? "" : template.footer,
        footerDisabled: false,
        quickReplyButtons: {
          ...prev.quickReplyButtons,
          buttons: prev?.quickReplyButtons?.buttons?.filter(
            (c) => c.key != id
          ),
        },
      }));
    }
  };

  const reArrange = () => {
    const qrOrder = template.quickReplyButtons.order;
    const ctaOrder = template.ctaButton.order;

    setTemplate((prev) => ({
      ...prev,
      quickReplyButtons: {
        ...prev.quickReplyButtons,
        order: ctaOrder,
      },
      ctaButton: {
        ...prev.ctaButton,
        order: qrOrder,
      },
    }));
  };

  const pre = () => {
    return (
      <>
        {template?.quickReplyButtons?.buttons?.length > 0 && (
          <Card
          className="zero-margin-padding shadowless"
            style={{ borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}
            title={
              <Flex align="center" justify="space-between">
                <Title level={4} style={{ margin: 0 }}>
                  Quick reply buttons
                </Title>

                <Button
                  className="flex-button"
                  icon={<TbArrowsSort style={{ fontSize: 18 }} />}
                  type="primary"
                  onClick={() => reArrange()}
                  disabled={template?.ctaButton?.buttons?.length == 0}
                ></Button>
              </Flex>
            }
          >
            <DndContext
              modifiers={[restrictToVerticalAxis]}
              onDragEnd={onDragEnd}
            >
              <SortableContext
                // rowKey array
                items={template?.quickReplyButtons?.buttons?.map((i) => i.key)}
                strategy={verticalListSortingStrategy}
              >
                <Table
                  bordered={false}
                  className="header-less"
                  style={{
                    borderRadius: "20 !important",
                    borderBottom: "none !important",
                  }}
                  components={{
                    body: {
                      row: CustomRow,
                    },
                  }}
                  rowKey="key"
                  columns={columns}
                  dataSource={template?.quickReplyButtons?.buttons}
                  pagination={false}
                />
              </SortableContext>
            </DndContext>
          </Card>
        )}
      </>
    );
  };

  const cta = () => {
    return (
      <>
        {template?.ctaButton?.buttons?.length > 0 && (
          <Card
            className="shadowless zero-margin-padding"
            style={{ borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}
            title={
              <Flex align="center" justify="space-between">
                <Title level={4} style={{ margin: 0 }}>
                  Call to action buttons
                </Title>
                <Button
                  className="flex-button"
                  icon={<TbArrowsSort style={{ fontSize: 18 }} />}
                  type="primary"
                  onClick={() => reArrange()}
                  disabled={template?.quickReplyButtons?.buttons?.length == 0}
                ></Button>
              </Flex>
            }
          >
            <DndContext
              modifiers={[restrictToVerticalAxis]}
              onDragEnd={onDragEndCta}
            >
              <SortableContext
                // rowKey array
                items={template?.ctaButton?.buttons?.map((i) => i.key)}
                strategy={verticalListSortingStrategy}
              >
                <Table
                  components={{
                    body: {
                      row: CustomRow,
                    },
                  }}
                  className="header-less"
                  rowKey="key"
                  columns={columns}
                  dataSource={template?.ctaButton?.buttons}
                  pagination={false}
                />
              </SortableContext>
            </DndContext>
          </Card>
        )}
      </>
    );
  };

  return (
    <Space style={{ width: "100%" }} direction="vertical">
      <Space style={{ width: "100%" }} direction="vertical" size={0}>
        <Flex align="center" gap={10}>
          <Title style={{ margin: 0 }} level={3}>
            Buttons
          </Title>
          <Tag>Optional</Tag>
        </Flex>
        <Text>
          Create buttons that let customers respond to your message or take
          action.
        </Text>
      </Space>

      <Card
        className=" shadowless"
        style={{
          width: "100%",
          marginTop: 10,
          background:  "#f4f7fe",
        }}
      >
        <Space style={{ width: "100%" }} direction="vertical">
          <Title level={3} style={{ margin: 0 }}>
            <MdLightbulbOutline /> We recommend adding the marketing opt-out
            button
          </Title>

          <Text>
            Allow customers to request to opt out of all marketing messages.
            This can help reduce blocks from customers and increase your quality
            rating. Learn more.
          </Text>
        </Space>
      </Card>

      <Dropdown
        trigger={["click"]}
        menu={{
          items,
        }}
        placement="bottomLeft"
        arrow
      >
        <Button className="flex-button" size="large">
          <MdAdd /> Add a button <MdOutlineArrowDropDown />
        </Button>
      </Dropdown>

      <Text >
        <MdLightbulbOutline /> If you add more than 3 buttons, they will appear
        in a list.
      </Text>
      <Space style={{ width: "100%" }} direction="vertical" size="large">
        {template.quickReplyButtons.order == 0 ? (
          <>
            {pre()} {cta()}
          </>
        ) : (
          <>
            {cta()} {pre()}
          </>
        )}
      </Space>

      <Flex align="center" justify="space-between">
        <Button
          className="flex-button"
          type="link"
          icon={<MdArrowBack />}
          onClick={() => setCurrent("3")}
        >
          Back to Footer
        </Button>
        <Save />
      </Flex>
    </Space>
  );
};

const AddCopyOfferCode = ({ viewKey }) => {
  const { Text, Title } = Typography;
  const { template, setTemplate } = useContext(WhatsAppTemplateContext);
  return (
    <Card className="shadowless" style={{ width: "100%" }}>
      <Space style={{ width: "100%" }} direction="vertical">
        <Space size={0} style={{ width: "100%" }} direction="vertical">
          <Title style={{ margin: 0 }} level={4}>
            Add sample offer code
          </Title>
          <Text>
            To help us review your message template, please add an example for
            your offer code.
          </Text>
        </Space>

        <Space size={0} style={{ width: "100%" }} direction="vertical">
          <Text>Offer code</Text>
          <Input
            count={{ show: true, max: 15 }}
            placeholder="Enter sample"
            size="large"
            value={
              template?.ctaButton?.buttons.find((c) => c.key == viewKey)
                ?.offerCode
            }
            onChange={(e) =>
              setTemplate((prev) => ({
                ...prev,
                ctaButton: {
                  ...prev?.ctaButton,
                  buttons: prev?.ctaButton?.buttons?.map((c) =>
                    c.key == viewKey ? { ...c, offerCode: e.target.value } : c
                  ),
                },
              }))
            }
          />
        </Space>
      </Space>
    </Card>
  );
};

const AddCallPhoneNumber = ({ viewKey }) => {
  const { Text, Title } = Typography;

  const { countryCodes, template, setTemplate } = useContext(
    WhatsAppTemplateContext
  );

  return (
    <Card className="shadowless" style={{ width: "100%" }}>
      <Space style={{ width: "100%" }} direction="vertical">
        <Space size={0} style={{ width: "100%" }} direction="vertical">
          <Title style={{ margin: 0 }} level={4}>
            Call phone number
          </Title>
          <Text >
            To help us review your message template, please add an example for
            your offer code.
          </Text>
        </Space>
        <Row gutter={12} style={{ width: "100% !important" }}>
          <Col xs={24} sm={24} md={5} lg={5} xl={5} xxl={5}>
            <Space direction="vertical" style={{ width: "100%" }} size={0}>
              <Text>Button text</Text>
              <Input
                size="large"
                value={
                  template?.ctaButton?.buttons.find(
                    (c) => c.key == viewKey
                  )?.text
                }
                style={{ width: "100% !important" }}
                onChange={(e) =>
                  setTemplate((prev) => ({
                    ...prev,
                      ctaButton: {
                        ...prev?.ctaButton,
                        buttons: prev?.ctaButton?.buttons?.map((c) =>
                          c.key == viewKey ? { ...c, text: e.target.value } : c
                        ),
                      },
                  }))
                }
                suffix={<SmartText text={template?.ctaButton?.buttons.find(
                  (c) => c.key == viewKey
                )?.text} setText={(e) =>
                  setTemplate((prev) => ({
                    ...prev,
                      ctaButton: {
                        ...prev?.ctaButton,
                        buttons: prev?.ctaButton?.buttons?.map((c) =>
                          c.key == viewKey ? { ...c, text: e } : c
                        ),
                      },
                  }))
                }/>}
                count={{
                  show: true,
                  max: 25,
                }}
              />
            </Space>
          </Col>
          <Col xs={24} sm={24} md={5} lg={5} xl={5} xxl={5}>
            <Space direction="vertical" style={{ width: "100%" }} size={0}>
              <Text>Country</Text>
              <Select
                className="w-100"
                size="large"
                value={
                  template?.ctaButton?.buttons.find(
                    (c) => c.key == viewKey
                  )?.countryCode
                }
                onChange={(e) =>
                  setTemplate((prev) => ({
                    ...prev,
                      ctaButton: {
                        ...prev?.ctaButton,
                        buttons: prev?.ctaButton?.buttons?.map((c) =>
                          c.key == viewKey ? { ...c, countryCode: e } : c
                        ),
                      },
                  }))
                }
                options={countryCodes?.map((c) => ({
                  label: (
                    <Space size={5}>
                      <Text strong>({c.code})</Text> <Text>{c.name}</Text>
                    </Space>
                  ),
                  value: c.id,
                }))}
              />
            </Space>
          </Col>
          <Col xs={24} sm={24} md={9} lg={9} xl={9} xxl={9}>
            <Space direction="vertical" style={{ width: "100%" }} size={0}>
              <Text>Phone number</Text>
              <Input
                size="large"
                style={{ width: "100% !important" }}
                count={{
                  show: true,
                  max: 20,
                }}
                onChange={(e) =>
                  setTemplate((prev) => ({
                    ...prev,
                      ctaButton: {
                        ...prev?.ctaButton,
                        buttons: prev?.ctaButton?.buttons?.map((c) =>
                          c.key == viewKey
                            ? { ...c, phoneNumber: e.target.value }
                            : c
                        ),
                      },
                  }))
                }
                value={
                  template?.ctaButton?.buttons.find(
                    (c) => c.key == viewKey
                  )?.phoneNumber
                }
              />
            </Space>
          </Col>
        </Row>
      </Space>
    </Card>
  );
};

const AddVisitWebsite = ({ viewKey }) => {
  const { Text, Title } = Typography;
  const { template, setTemplate } = useContext(WhatsAppTemplateContext);
  
  return (
    <Card className="shadowless" style={{ width: "100%" }}>
      <Space style={{ width: "100%" }} direction="vertical">
        <Space size={0} style={{ width: "100%" }} direction="vertical">
          <Title style={{ margin: 0 }} level={4}>
            Visit website
          </Title>
          <Text>
            To help us review your message template, please add an example for
            your offer code.
          </Text>
        </Space>
        <Row gutter={12} style={{ width: "100% !important" }}>
          <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
            <Space direction="vertical" style={{ width: "100%" }} size={0}>
              <Text>Button text</Text>
              <Input
                value={
                  template?.ctaButton?.buttons.find((c) => c.key == viewKey)
                    ?.text
                }
                onChange={(e) =>
                  setTemplate((prev) => ({
                    ...prev,
                    ctaButton: {
                      ...prev?.ctaButton,
                      buttons: prev?.ctaButton?.buttons?.map((c) =>
                        c.key == viewKey ? { ...c, text: e.target.value } : c
                      ),
                    },
                  }))
                }
                suffix={<SmartText text={ template?.ctaButton?.buttons.find((c) => c.key == viewKey)
                  ?.text} setText={(e) =>
                    setTemplate((prev) => ({
                      ...prev,
                      ctaButton: {
                        ...prev?.ctaButton,
                        buttons: prev?.ctaButton?.buttons?.map((c) =>
                          c.key == viewKey ? { ...c, text: e } : c
                        ),
                      },
                    }))}/>}
                size="large"
                style={{ width: "100% !important" }}
                count={{
                  show: true,
                  max: 25,
                }}
              />
            </Space>
          </Col>
          <Col xs={24} sm={24} md={3} lg={3} xl={3} xxl={3}>
            <Space direction="vertical" style={{ width: "100%" }} size={0}>
              <Text>URL Type</Text>
              <Select
                className="w-100"
                size="large"
                options={[
                  { label: "Dynamic", value: "Dynamic" },
                  { label: "Static", value: "Static" },
                ]}
                value={
                  template?.ctaButton?.buttons.find((c) => c.key == viewKey)
                    ?.urlType
                }
                onChange={(e) =>
                  setTemplate((prev) => ({
                    ...prev,
                    ctaButton: {
                      ...prev?.ctaButton,
                      buttons: prev?.ctaButton?.buttons?.map((c) =>
                        c.key == viewKey ? { ...c, urlType: e } : c
                      ),
                    },
                  }))
                }
              />
            </Space>
          </Col>
          <Col xs={24} sm={24} md={11} lg={11} xl={11} xxl={11}>
            <Space direction="vertical" style={{ width: "100%" }} size={0}>
              <Text>Website URL</Text>
              <Input
                size="large"
                style={{ width: "100% !important" }}
                count={{
                  show: true,
                  max: 2000,
                }}
                value={
                  template?.ctaButton?.buttons.find((c) => c.key == viewKey)
                    ?.webSiteUrl
                }
                onChange={(e) =>
                  setTemplate((prev) => ({
                    ...prev,
                    ctaButton: {
                      ...prev?.ctaButton,
                      buttons: prev?.ctaButton?.buttons?.map((c) =>
                        c.key == viewKey
                          ? { ...c, webSiteUrl: e.target.value }
                          : c
                      ),
                    },
                  }))
                }
              />
            </Space>
          </Col>
        </Row>
      </Space>
    </Card>
  );
};

const Custom = ({ viewKey }) => {
  const { Text, Title } = Typography;

  const { template, setTemplate } = useContext(WhatsAppTemplateContext);

  return (
    <Card className="shadowless" style={{ width: "100%" }}>
      <Space style={{ width: "100%" }} direction="vertical">
        <Space size={0} style={{ width: "100%" }} direction="vertical">
          <Title style={{ margin: 0 }} level={4}>
            Custom
          </Title>
          <Text>
            To help us review your message template, please add an example for
            your offer code.
          </Text>
        </Space>
        <Row gutter={12} style={{ width: "100% !important" }}>
          <Col xs={24} sm={24} md={18} lg={18} xl={18} xxl={18}>
            <Space direction="vertical" style={{ width: "100%" }} size={0}>
              <Text>Button text</Text>
              <Input
                value={
                  template?.quickReplyButtons?.buttons.find(
                    (c) => c.key == viewKey
                  )?.text
                }
                onChange={(e) =>
                  setTemplate((prev) => ({
                    ...prev,
                    quickReplyButtons: {
                      ...prev?.quickReplyButtons,
                      buttons: prev?.quickReplyButtons?.buttons?.map(
                        (c) =>
                          c.key == viewKey ? { ...c, text: e.target.value } : c
                      ),
                    },
                  }))
                }
                size="large"
                style={{ width: "100% !important" }}
                count={{
                  show: true,
                  max: 25,
                }}
                suffix={<SmartText text={template?.quickReplyButtons?.buttons.find(
                  (c) => c.key == viewKey
                )?.text} setText={(e) =>
                  setTemplate((prev) => ({
                    ...prev,
                    quickReplyButtons: {
                      ...prev?.quickReplyButtons,
                      buttons: prev?.quickReplyButtons?.buttons?.map(
                        (c) =>
                          c.key == viewKey ? { ...c, text: e } : c
                      ),
                    },
                  }))}/>}
              />
            </Space>
          </Col>
        </Row>
      </Space>
    </Card>
  );
};

const AddMarketingOptOutButton = ({ viewKey }) => {
  const { Text, Title } = Typography;

  const { template, setTemplate } = useContext(WhatsAppTemplateContext);

  return (
    <Card className="shadowless" style={{ width: "100%" }}>
      <Space style={{ width: "100%" }} direction="vertical">
        <Space size={0} style={{ width: "100%" }} direction="vertical">
          <Title style={{ margin: 0 }} level={4}>
            Marketing-opt-out
          </Title>
          <Text >
            To help us review your message template, please add an example for
            your offer code.
          </Text>
        </Space>
        <Row gutter={12} style={{ width: "100% !important" }}>
          <Col xs={24} sm={24} md={9} lg={9} xl={9} xxl={9}>
            <Space direction="vertical" style={{ width: "100%" }} size={0}>
              <Text>Button text</Text>
              <Input
                value="Stop promotions"
                size="large"
                style={{ width: "100% !important" }}
                disabled
                count={{
                  show: true,
                  max: 25,
                }}
              />
            </Space>
          </Col>
          <Col xs={24} sm={24} md={9} lg={9} xl={9} xxl={9}>
            <Space direction="vertical" style={{ width: "100%" }} size={0}>
              <Text>Footer Text</Text>
              <Input
                size="large"
                value="Not interested? Tap Stop promotions"
                style={{ width: "100% !important" }}
                disabled
              />
            </Space>
          </Col>
        </Row>

        <Checkbox
          size="large"
          checked={
            template?.quickReplyButtons?.buttons.find((c) => c.key == viewKey)
              ?.terms
          }
          onChange={(e) =>
            setTemplate((prev) => ({
              ...prev,
              quickReplyButtons: {
                ...prev?.quickReplyButtons,
                buttons: prev?.quickReplyButtons?.buttons?.map((c) =>
                  c.key == viewKey ? { ...c, terms: e.target.checked } : c
                ),
              },
            }))
          }
        >
          I understand that it's Test WhatsApp Business Account's responsibility
          to stop sending marketing messages to customers who opt out.
        </Checkbox>
      </Space>
    </Card>
  );
};

export {
  Buttons,
  Custom,
  AddMarketingOptOutButton,
  AddCopyOfferCode,
  AddVisitWebsite,
  AddCallPhoneNumber,
};
