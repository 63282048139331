import { Tag } from "antd";
import { useContext } from "react";
import { SmsListContext } from "./SmsListContext";
const SmsStats = () => {
  const { stats } = useContext(SmsListContext);
  return (
    <Tag size="small" className="all-stats-tag default-stat-tag">
      {stats?.total ?? 0} Total
    </Tag>
  );
};

export default SmsStats;
