import { Card, Col, Flex, Pagination, Row, Space, Typography } from "antd";
import { useContext } from "react";
import SetDefaultSender from "./SetDefaultSender";
import TableLoading from "../../../shared/TableLoading";
import { FaCheck } from "react-icons/fa";
import { IoClose } from "react-icons/io5";
import { EmailSenderContext } from "./EmailSenderContext";
import NoDataFound from "../../../shared/NoDataFound";
import "./SenderTable.css";

const SenderTable = () => {
  const { loading, senders, setSenders, page, setPage, stats, setStats } =
    useContext(EmailSenderContext);

  const { Text } = Typography;

  const handleDefaultChanged = (id) => {
    setSenders((prev) =>
      prev.map((d) =>
        d.id == id ? { ...d, default: true } : { ...d, default: false }
      )
    );
  };

  const handleDeleted = (id, newDefault) => {
    setStats((prev) => ({ ...prev, total: prev.total - 1 }));

    if (senders.length - 1 == 0 && page > 1) {
      setPage(page - 1);
    } else {
      setSenders((prev) => prev.filter((c) => c.id != id));
      setSenders((prev) =>
        prev.map((c) => (c.id == newDefault ? { ...c, default: true } : c))
      );
    }
  };

  return (
    <>
      <Space direction="vertical" className="w-100">
        <Card size="small" className="no-shadow">
          <Row>
            <Col span={21}>
              <Text className="column-header">Name</Text>
            </Col>
            <Col span={3}></Col>
          </Row>
        </Card>

        {loading ? (
          <TableLoading />
        ) : !senders || senders.length == 0 ? (
          <Card className="no-shadow" size="small">
            <NoDataFound
              addText="New sender"
              description="Click the New Sender button on the sender/email page to create a new email sender"
              title="You have not created any email senders yet"
            />{" "}
          </Card>
        ) : (
          <>
            {senders?.map((c, i) => (
              <Card size="small" className="no-shadow">
                <Row>
                  <Col span={21} className="center-table-column">
                    <Flex align="center" justify="start" gap={12}>
                      <div>
                        {!c.confirmed && (
                          <div
                            style={{
                              width: 30,
                              height: 30,
                              borderRadius: 30,
                              backgroundColor: "#e74a3b",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <IoClose style={{ color: "white", fontSize: 18 }} />
                          </div>
                        )}
                        {c.confirmed && (
                          <div
                            style={{
                              width: 30,
                              height: 30,
                              borderRadius: 35,
                              backgroundColor: "#1cc88a",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <FaCheck style={{ color: "white" }} />
                          </div>
                        )}
                      </div>
                      <Space direction="vertical" size={0} className="w-100">
                        <Flex align="center" justify="start" gap={6}>
                          <Text className="email-sender-title">
                            {c.fullName}
                          </Text>
                          {c.default && (
                            <Text className="sms-sender-list-default">
                              Default
                            </Text>
                          )}
                        </Flex>
                        <Text>
                          {c.email} • {c.nickName}
                        </Text>
                      </Space>
                    </Flex>
                  </Col>
                  <Col span={3}>
                    <Flex
                      align="center"
                      justify="end"
                      gap={6}
                      className="h-100 w-100"
                    >
                      <SetDefaultSender
                        confirmed={c.confirmed}
                        handleDeleted={handleDeleted}
                        disabled={c.default}
                        id={c.id}
                        handleDefaultChanged={handleDefaultChanged}
                      />
                    </Flex>
                  </Col>
                </Row>
              </Card>
            ))}
            <Flex align="center" justify="end">
              <Pagination
                current={page}
                onChange={(p) => setPage(p)}
                pageSize={7}
                total={stats?.total}
              />
            </Flex>
          </>
        )}
      </Space>
    </>
  );
};

export default SenderTable;
