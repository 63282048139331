import { Layout } from "antd";
import { Outlet } from "react-router-dom";

const FullHeightApplicationLayout = () => {

  return (
    <>
      <Layout style={{ height: "100vh", width: "100%" }}>
       
        <div
          style={{
            height: "100vh",
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <Outlet />
        </div>
      </Layout>
    </>
  );
};
export default FullHeightApplicationLayout;
