import { SmsListContextProvider } from "./SmsListContext";
import SmsTemplates from "./SmsTemplates";

const SmsLayout = ({dataToAdd}) => {
  return (
    <SmsListContextProvider>
      <SmsTemplates dataToAdd={dataToAdd}/>
    </SmsListContextProvider>
  );
};

export default SmsLayout;
