import { createContext, useContext, useEffect, useState } from "react";
import { CampaignType } from "../../shared/enums";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { CampaignStatusType, OrderDirection } from "../../shared/enums";
import { SearchContext } from "../../context/SearchContext";
import QueryString from "qs";
import { useParams } from "react-router-dom";

export const CampaignContext = createContext({
    filters: {
        status: null,
        type: null,
        orderBy: null,
        orderDirection: null,
        search: null,
        from: null,
        to: null
    },
    setFilters: () => {},

    showFilters: false,
    setShowFilters: () => {},

    stats: [{
        all: 0,
        completed: 0,
        invalid: 0,
        draft: 0,
        reach: 0,
        scheduled: 0,
    }],
    setStats: () => {},

    type: CampaignType.EMAIL,
    setType : () => {},

    campaigns: [],
    setCampaings: () => {},
    campaignsLoading: false,
    setCampaingsLoading: () => {},
    count: 0,
    setCount: () => {}, 

    page: 1,
    setPage: () => {},

    pageSize: 25,
    setPageSize: () => {}
})

export const CampaignContextProvider = ({children}) => {
    const [pageSize, setPageSize] = useState(25);

    const {authorId} = useParams();

    const axiosPrivate = useAxiosPrivate();
    const { debouncedValue } = useContext(SearchContext);
   
    const [showFilters, setShowFilters] = useState(() => false);
    const [filters, setFilters] = useState(() => ({
      orderDirection: OrderDirection.Dsc,
      status: CampaignStatusType.All,
      orderBy: "Created",
      type:[
        CampaignType.EMAIL,
        CampaignType.SMS,
        CampaignType.WHATSAPP
      ]
    }))
    
    const [stats, setStats] = useState()
  
    const [campaigns, setCampaings] = useState();
    const [campaignsLoading, setCampaingsLoading] = useState(() => false)
    const [page, setPage] = useState(1)

    const fetchCampaigns = async (page) => {
      
        setCampaingsLoading(true);
    
        const response = await axiosPrivate.get(
          `/Campaign/GetAll`,
          {
            params:{
              author: authorId,
              page: page,
              pageSize: pageSize,
              status: filters?.status,
              goal: filters?.goals,
              orderBy: filters?.orderBy,
              orderDirection: filters?.orderDirection,
              search: debouncedValue,
              types: filters?.type,
              from: filters?.from ? new Date(filters.from) : null,
            to: filters?.to ? new Date(filters.to) : null,
            search: debouncedValue  
          },
          paramsSerializer: params => {
            return QueryString.stringify(params, {arrayFormat: "repeat", skipNulls: true})
          }
          }
        );

        setCampaings(
          response.data.items?.map((c) => ({ ...c, key: c.id }))
        );

        setStats(prev => ({
          ...prev,
          all: response.data.total,
          completed: response.data.completed,
          invalid: response.data.invalid,
          draft: response.data.draft,
          reach: response.data.reach,
          scheduled: response.data.scheduled,
          first: response?.data.first,
          last: response?.data?.last,
          authorImage: response?.data?.authorProfile,
          authorName: response?.data?.authorName
        }));
        setCampaingsLoading(false);
      };

      
      useEffect(() => {
        fetchCampaigns(page);
      }, [filters, debouncedValue, page, pageSize]);
    return (
        <CampaignContext.Provider value={{
            filters,
            setFilters,
            showFilters,
            setShowFilters,

            stats,
            setStats,
            
            campaigns,
            setCampaings,
            campaignsLoading,
            page,
            setPage,

            pageSize,
            setPageSize
        }}>
            {children}
        </CampaignContext.Provider>
    )
}