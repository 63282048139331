import { Button, Dropdown, Flex, Space, Tabs, Typography } from "antd";
import DealsLayout from "./DealsLayout";
import TaskLayout from "../tasks/TaskLayout";
import ProductLayout from "../products/ProductLayout";
import CompanyLayout from "../companies/CompanyLayout";
import AddCompanyModal from "./Modals/AddCompanyModal";
import { useState } from "react";
import AddProduct from "../products/AddProduct";
import AddTaskDrawer from "./AddTaskDrawer";
import CreateDealDrawer from "./CreateDealDrawer/CreateDealDrawer";

const LeadsLayout = () => {
  const { Title } = Typography;

  const [addCompany, setAddCompany] = useState(false);
  const [addProduct, setAddProduct] = useState(false);
  const [addTask, setAddTask] = useState(false);
  const [addDeal, setAddDeal] = useState(false);

  const [productToAdd, setProductToAdd] = useState();
  const onProductAdd = (e) => {
    setProductToAdd(e);
  };

  const [companyToAdd, setCompanyToAdd] = useState();
  const onCompanyAdd = (e) => {
    setCompanyToAdd(e);
  };


  const [taskToAdd, setTaskToAdd] = useState();
  const onTaskAdd = (e) => {
    setTaskToAdd(e);
    setAddTask(false)
  }; 

  const [dealToAdd, setDealToAdd] = useState();
  const onDealAdd = (e) => {
    setDealToAdd(e);
    setAddDeal(false)
  }; 
  return (
    <>
      <AddProduct
        onAdd={onProductAdd}
        show={addProduct}
        setShow={setAddProduct}
      />

      <CreateDealDrawer onSubmit={onDealAdd} isDrawerOpen={addDeal} setIsDrawerOpen={setAddDeal} />
      <AddCompanyModal onAdd={onCompanyAdd} open={addCompany} setOpen={setAddCompany} />
      <AddTaskDrawer selectDeals={true} onSubmit={onTaskAdd} open={addTask} setOpen={setAddTask} />
      <Space direction="vertical" size={24} className="w-100">
        <Flex align="center" justify="space-between">
          <Title className="zero-margin-padding">Leads</Title>

          <Dropdown
            trigger={["click"]}
            menu={{
              items: [
                {
                  key: 1,
                  label: <p className="drop-down-text">Deal</p>,
                  onClick: () => setAddDeal(true),
                },
                {
                  key: 2,
                  label: <p className="drop-down-text">Task</p>,
                  onClick: () => setAddTask(true),
                },
                {
                  key: 3,
                  label: <p className="drop-down-text">Product</p>,
                  onClick: () => setAddProduct(true),
                },
                {
                  key: 4,
                  label: <p className="drop-down-text">Company</p>,
                  onClick: () => setAddCompany(true),
                },
              ],
            }}
          >
            <Button
              type="primary"
              size="large"
              className="new-buttons large-font"
            >
              Create
            </Button>
          </Dropdown>
        </Flex>

        <Tabs
          size="large"
          items={[
            {
              key: 1,
              label: "Deal management",
              children: <DealsLayout dataToAdd={dealToAdd}/>,
            },
            {
              key: 2,
              label: "Tasks",
              children: <TaskLayout dataToAdd={taskToAdd}/>,
            },
            {
              key: 4,
              label: "Products",
              children: <ProductLayout dataToAdd={productToAdd} />,
            },
            {
              key: 5,
              label: "Companies",
              children: <CompanyLayout dataToAdd={companyToAdd}/>,
            },
          ]}
        />
      </Space>
    </>
  );
};

export default LeadsLayout;
