import { createContext, useEffect, useState } from "react";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import { useParams } from "react-router-dom";

export const CreateNotificationTemplateContext = createContext({
  data: "",
  setData: () => {},
});

export const CreateNotificationTemplateContextProvider = ({ children }) => {
  const [data, setData] = useState("");

  const { id } = useParams();

  const axios = useAxiosPrivate();

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    const fetch = async () => {
      const response = await axios.get(`/Notification/Templates/${id}`, {
        signal: signal,
      });

      setData(response.data);
    };

    fetch();
    return () => controller.abort();
  }, []);
  return (
    <CreateNotificationTemplateContext.Provider
      value={{
        data,
        setData,
      }}
    >
      {children}
    </CreateNotificationTemplateContext.Provider>
  );
};
