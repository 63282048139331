import {
  Button,
  DatePicker,
  Divider,
  Flex,
  Form,
  Modal,
  Select,
  Space,
  Typography,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import React from "react";
import { DealLostReason } from "../../shared/enums";

const lostOptions = [
  { value: DealLostReason.PriceToHigh, label: "Price to high" },
  {
    value: DealLostReason.OptedForACompetitor,
    label: "Opted for a competitor",
  },
  { value: DealLostReason.JunkLead, label: "Junk lead" },
  { value: DealLostReason.NotInterested, label: "Not interested" },
  {
    value: DealLostReason.ClientRequirementNotMet,
    label: "Client requirement not met",
  },
];
const LostDealModal = ({ open, onClose, onSubmit }) => {
  const { Title, Text } = Typography;

  return (
    <Modal
      destroyOnClose
      title={
        <Title level={4} className="zero-margin-padding">
          Mark Deal as Lost
        </Title>
      }
      centered
      open={open}
      footer={<></>}
      onCancel={onClose}
    >
      <Divider style={{ margin: "5px 0px" }} />

      <Form layout="vertical" onFinish={onSubmit}>
        <Space size={0} className="w-100" direction="vertical">
          <Text className="bold">Close Date</Text>
          <Form.Item
            name="closeDate"
            rules={[
              {
                required: true,
                message: "Please choose a date!",
              },
            ]}
          >
            <DatePicker
              size="large"
              className="w-100"
            />
          </Form.Item>
        </Space>
        <Space className="w-100" direction="vertical" size={0}>
          <Text className="bold">Reason</Text>
          <Form.Item
            name="reason"
            rules={[
              {
                required: true,
                message: "Please select a reason!",
              },
            ]}
          >
            <Select options={lostOptions} className="w-100" size="large" />
          </Form.Item>
        </Space>
        <Space direction="vertical" size={0} className="w-100">
          <Text className="bold">Additional Comments</Text>
          <Form.Item name="additionalComments">
            <TextArea size="large" style={{ width: "100%" }} />
          </Form.Item>
        </Space>
        <Flex align="center" justify="end" gap={12}>
          <Button className="new-buttons large-font" size="large" onClick={onClose}>Close</Button>
          <Button className="new-buttons large-font" size="large" type="primary" htmlType="submit">
            Mark as lost
          </Button>
        </Flex>
      </Form>
    </Modal>
  );
};

export default LostDealModal;
