import { Button, Drawer, Flex, Form, Select, Space, Typography } from "antd";
import { useContext, useEffect } from "react";
import { AuthorContext } from "../context/AuthorsContext";
import AuthorSelectListItem from "./AuthorSelectListItem";

const SelectOwnerDrawer = ({ defaultValues, open, setOpen, onChange }) => {
  const { Text } = Typography;

  const { authors } = useContext(AuthorContext);

  const [form] = Form.useForm();

  const onSubmit = (e) => {
    onChange(e.owners);
  };

  const handeClose = () => {
    form.resetFields();
    setOpen(false);
  };

  const handleReset = () => {
    form.resetFields();
  };

  useEffect(() => {
    if (open) {
      form.setFieldValue("owners", defaultValues);
    }
  }, [open]);

  return (
    <Drawer title="Owners" open={open} onClose={handeClose}>
      <Flex className="h-100" align="center" justify="space-between" vertical>
        <Form form={form} onFinish={onSubmit}>
          <Space className="w-100" direction="vertical" size={0}>
            <Text className="bold">
              Select which owner contacts would you like to see?
            </Text>
            <Form.Item name="owners" className="no-error-show">
              <Select
                mode="multiple"
                size="large"
                placeholder="All owners"
                className="w-100"
                options={authors?.map(d => ({
                  value: d.value,
                  label: (<AuthorSelectListItem firstname={d.firstname} lastname={d.lastname} profile={d.profile}/>),
                }))}
              />
            </Form.Item>

            <Flex align="center" justify="end">
              <Button onClick={handleReset} type="link" className="bold">
                Reset
              </Button>
            </Flex>
          </Space>
        </Form>

        <Flex gap={6} align="center" justify="end" className="w-100">
          <Button
            onClick={handeClose}
            size="large"
            className="new-buttons large-font"
          >
            Cancel
          </Button>
          <Button
            size="large"
            type="primary"
            className="new-buttons large-font"
            onClick={() => form.submit()}
          >
            Apply filter
          </Button>
        </Flex>
      </Flex>
    </Drawer>
  );
};

export default SelectOwnerDrawer;
