import {
  Button,
  Card,
  Col,
  Flex,
  Form,
  message,
  Row,
  Space,
  Typography,
} from "antd";
import { MdClose } from "react-icons/md";
import Iphone from "../../../shared/Iphone";
import "./Subject.css";
import { FaCheck } from "react-icons/fa";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { useContext, useEffect, useState } from "react";
import { CreateCampaignContext } from "../../../context/CreateCampaignContext";
import { removeButtonsFromHtml } from "../../../shared/HtmlOps";
import HtmlTextbox from "../../../shared/HtmlTextbox";

const Subject = ({ active, setStep }) => {
  const { Text, Title } = Typography;
  const axios = useAxiosPrivate();
  const { subjectAndPreview, setSubjectAndPreview, data, setData } = useContext(
    CreateCampaignContext
  );

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    form.setFieldValue("subject", subjectAndPreview?.subject);
    form.setFieldValue("preview", subjectAndPreview?.preview);
  }, [data]);

  const submit = async (e) => {
    setLoading(true);

    const response = await axios.put(
      "Campaign/Email/Assign/SubjectAndPreview",
      {
        subject: e.subject,
        previewText: e.preview,
        campaignId: data?.id,
      }
    );

    if (response.data.success) {
      setSubjectAndPreview({
        subject: e.subject,
        preview: e.preview,
      });

      setData((prev) => ({
        ...prev,
        subjectFinished: true,
        subject: e.subject,
        previewText: e.preview,
      }));

      setStep();
    } else {
      message.error(response.data.message);
    }

    setLoading(false);
  };


  const [subjectCount, setSubjectCount] = useState(0);
  const [previewCount, setPreviewCount] = useState(0)

  const previewValidator = async (_, value) => {
    if (value && previewCount.length > 200) {
      return Promise.reject(
        new Error("Content can only be up to 200 characters long.")
      );
    }

    return Promise.resolve();
  };

  const subjectValidator = async (_, value) => {
    if (!value) {
      return Promise.reject(new Error("Please enter a subject"));
    }

    if (subjectCount && subjectCount.length < 3) {
      return Promise.reject(
        new Error("Subject should be longer than 3 characters")
      );
    }

    if (subjectCount && subjectCount.length > 200) {
      return Promise.reject(
        new Error("Content can only be up to 200 characters long.")
      );
    }

    return Promise.resolve();
  };

  const tempSubject = "";

  return (
    <>

      <Card
        className={`step-card subject-card no-shadow ${
          active ? "active-step" : ""
        }`}
      >
        <Flex className="w-100" align="center" justify="space-between">
          <Flex align="center" justify="start" gap={12}>
            {data?.subjectFinished ? (
              <div className="step-evaluation-box-success">
                <FaCheck />
              </div>
            ) : (
              <div className="step-evaluation-box-default"></div>
            )}
            <Space direction="vertical" size={0}>
              <Title className="step-card-title zero-margin-padding" level={3}>
                Subject
              </Title>
              {data?.subjectFinished ? (
                <Flex
                  align="center"
                  justify="start"
                  gap={2}
                  className="zero-margin-padding"
                >
                  {subjectAndPreview?.subject ? <>
                  <p
                  className="zero-margin-padding-children"
                  dangerouslySetInnerHTML={{
                    __html: `${removeButtonsFromHtml(subjectAndPreview?.subject)}`,
                  }}
                  ></p>  </> 
                  : <p className="zero-margin-padding-children">No subject</p>
                }

                  <p className="zero-margin-padding-children large-font">
                    &nbsp;•&nbsp;
                  </p>

                  {subjectAndPreview?.preview ? 
                  <p
                  className="zero-margin-padding-children"
                  dangerouslySetInnerHTML={{
                    __html: `${removeButtonsFromHtml(subjectAndPreview.preview)}`,
                  }}
                  ></p>
                 : <p className="zero-margin-padding-children">No preview text</p>}
                </Flex>
              ) : (
                <Text>Add a subject line for this campaign.</Text>
              )}
            </Space>
          </Flex>
          {active ? (
            <Button
              type="text"
              onClick={() => setStep()}
              className="flex-button w-300"
              size="large"
              icon={<MdClose className="icon" />}
            ></Button>
          ) : (
            <Button size="large" className="w-300 large-font new-buttons" onClick={() => setStep(3)}>
              Add subject
            </Button>
          )}
        </Flex>

        {active && (
          <>
            <div className="subject-work-wrapper">
              <Row gutter={[12, 12]}>
                <Col span={12}>
                  <Form form={form} onFinish={submit}>
                    <Space className="w-100" size={24} direction="vertical">
                      <Space className="w-100" direction="vertical" size={0}>
                        <Text className="thick-lable">Subject line*</Text>
                        <HtmlTextbox
                          form={form}
                          rules={[{ validator: subjectValidator }]}
                          fieldName="subject"
                          max={200}
                          setTextCount={setSubjectCount}
                        />
                      </Space>

                      <Space className="w-100" direction="vertical" size={0}>
                        <Text className="thick-lable">Preview text</Text>
                        <HtmlTextbox
                          form={form}
                          fieldName="preview"
                          max={200}
                          rules={[{ validator: previewValidator }]}
                          setTextCount={setPreviewCount}
                        />
                      </Space>
                    </Space>
                  </Form>
                </Col>
                <Col span={12}>
                  <Flex align="center" justify="center" vertical>
                    <Iphone
                      height={280}
                      children={
                        <Space direction="vertical" className="w-100">
                          <div className="w-100 inbox-wrapper">
                            <Text className="sender-inbox-text w-100">
                              Inbox
                            </Text>
                          </div>

                          <div className="sender-inbox-seperator"></div>
                          <Space
                            className="w-100 sender-sender-data-wrapper"
                            direction="vertical"
                            size={0}
                          >
                            <Flex align="center" justify="space-between">
                              <Text className="sender-sender-data-name">
                                Tretek
                              </Text>
                              <Text className="sender-sender-data-time">
                                11:40
                              </Text>
                            </Flex>
                            <Text className="sender-sender-data-subject">
                              {tempSubject?.subject
                                ? tempSubject?.subject
                                : "Message subject..."}
                            </Text>
                            <Text className="sender-sender-data-preview">
                              {tempSubject?.preview
                                ? tempSubject?.preview
                                : "Your preview text..."}
                            </Text>
                          </Space>
                          <div className="sender-inbox-seperator"></div>
                        </Space>
                      }
                    />
                    <Text className="warn-text">
                      Actual email preview may vary depending on the email
                      client.
                    </Text>
                  </Flex>
                </Col>
              </Row>

              <Flex align="center" justify="end" gap={6} className="margin-top">
                <Button
                  size="large"
                  onClick={() => setStep()}
                 className="new-buttons large-font"
                >
                  Cancel
                 
                </Button>
                <Button
                  size="large"
                  type="primary"
                  onClick={() => form.submit()}
                  loading={loading}
                className="new-buttons large-font"
                >
                  Save
                </Button>
              </Flex>
            </div>
          </>
        )}
      </Card>
    </>
  );
};

export default Subject;
