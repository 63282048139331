import { Tag } from "antd";
import { useContext } from "react";
import { CompanyListContext } from "./CompanyListContext";

const CompanyStats = () => {
  const { stats } = useContext(CompanyListContext);

  return (
    <Tag className="all-stats-tag default-stat-tag">
      {stats?.all ?? 0} Total
    </Tag>
  );
};

export default CompanyStats;
