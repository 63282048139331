import { Button, Collapse, Flex, Space, Typography } from "antd";
import "./ContactData.css";
import { FaCog } from "react-icons/fa";
import EditContactDrawer from "../Edit/EditContactDrawer";
import { useState } from "react";

const ContactData = ({ details, setDetails }) => {
  const { Title } = Typography;

  const [editOpen, setEditOpen] = useState(false);

  const handleUpdateClick = (e) => {
    e.stopPropagation();

    setEditOpen(true);
  };

  const onUpdate = (e) => {
    setDetails((prev) => ({
      ...prev,
      firstname: e.firstname,
      lastname: e.lastname,
      email: e.email,
      phone: e.phone,
      whatsappNumber: e.whatsappNumber,
    }));
  };

  return (
    <>
      <EditContactDrawer
        onEdit={onUpdate}
        item={{
          firstname: details?.firstname,
          lastname: details?.lastname,
          email: details?.email,
          phone: details?.phone,
          whatsappNumber: details?.whatsappNumber,
          id: details?.id,
        }}
        open={editOpen}
        setOpen={setEditOpen}
        showTags={false}
      />
      <Collapse
        defaultActiveKey={1}
        items={[
          {
            key: 1,
            label: (
              <Flex align="center" justify="space-between">
                <Title
                  className="zero-margin-padding"
                  level={4}
                >Information</Title>

                <Button
                  onClick={handleUpdateClick}
                  type="link"
                  className="new-buttons large-font no-right-padding-margin"
                >
                  Update
                </Button>
              </Flex>
            ),
            children: (
              <Space className="w-100" direction="vertical">
                <ContactDataDisplay name="Email" value={details?.email} />
                <ContactDataDisplay
                  name="Mobile number"
                  value={details?.phone}
                />
                <ContactDataDisplay
                  name="Whatsapp number"
                  value={details?.whatsappNumber}
                />

                <ContactDataDisplay name="P256dh" value={details?.p256dh} />
                <ContactDataDisplay name="Endpoint" value={details?.endpoint} />
                <ContactDataDisplay
                  name="Authentication"
                  value={details?.auth}
                />
              </Space>
            ),
          },
        ]}
      />
    </>
  );
};

const ContactDataDisplay = ({
  name,
  value,
  showEdit,
  onEditClick,
  onValueClick,
  valueClickable,
}) => {
  const { Text } = Typography;

  return (
    <Flex align="center" justify="space-between">
      <Space direction="vertical" size={0} className="w-100">
        <Text className="bold">{name}</Text>
        <Text type={valueClickable ? "link" : ""} onClick={onValueClick}>
          {value ?? "-"}
        </Text>
      </Space>

      {showEdit && (
        <Button
          onClick={onEditClick}
          type="link"
          icon={<FaCog className="icon" />}
        ></Button>
      )}
    </Flex>
  );
};

export default ContactData;
