import { createContext, useEffect, useState } from "react";
import useAxiosPrivate from "../hooks/useAxiosPrivate";

export const CurrentUserContext = createContext({
  user: {},
  setUser: () => {},
});

export const CurrentUserContextProvider = ({ children }) => {
  const [user, setUser] = useState();

  const axios = useAxiosPrivate();

  const fetch = async () => {
    const response = await axios.get("Users/CurrentUser");
    setUser(response.data.data);
  };

  useEffect(() => {
    fetch();
  }, []);

  return (
    <CurrentUserContext.Provider
      value={{
        user,
      }}
    >
      {children}
    </CurrentUserContext.Provider>
  );
};
