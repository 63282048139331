import {
  Button,
  Drawer,
  Flex,
  Form,
  message,
  notification,
  Space,
  Typography,
} from "antd";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { useEffect, useState } from "react";
import CompaniesMultiselect from "../../deals/FormItems/CompaniesMultiselect";

const AssignContactCompanyDrawer = ({ contact, open, setOpen, onUpdated }) => {
  const [form] = Form.useForm();

  const { Text } = Typography;

  const handleClose = () => {
    form.resetFields();

    setOpen(false);
  };

  useEffect(() => {
    if (contact && open) {
      form.setFieldValue(
        "selectedCompanies",
        contact?.contactCompanies?.map((c) => c.id)
      );
    }
  }, [contact?.contactCompanies, open]);

  const axios = useAxiosPrivate();

  const [loading, setLoading] = useState(false);

  const submit = async (e) => {
    setLoading(true);

    const response = await axios.put(`/Contacts/Assign-company`, {
       emailId: contact?.id,
      companyIds: e.selectedCompanies,
    });

    if (response.data.success) {
      notification.open({
        message: (
          <Flex
            style={{ width: "200px", height: "50px" }}
            className="h-100"
            align="center"
            justify="center"
          >
            <Text className="large-font">Companies associated</Text>
          </Flex>
        ),
        showProgress: true,
        placement: "bottom",
        btn: null,
        closeIcon: null,
      });
      handleClose();
      onUpdated(response.data.items);
    } else {
      message.error(response.data.message);
    }
    setLoading(false);
  };
  return (
    <Drawer open={open} onClose={handleClose} title="Assign contact comapny">
      <Flex vertical className="w-100 h-100" justify="space-between">
        <Space className="w-100" direction="vertical" size={0}>
          <Form form={form} onFinish={submit}>
            <CompaniesMultiselect
              name="selectedCompanies"
              label="Which company is this contact associated with?"
              placeholder="Select companies"
            />
          </Form>
        </Space>
        <Flex align="center" justify="end" gap={6}>
          <Button onClick={handleClose} size="large" className="large-font new-buttons">
            Cancel
          </Button>
          <Button
            size="large"
            onClick={() => form.submit()}
            type="primary"
            loading={loading}
            className="large-font new-buttons"
          >
            Save changes
          </Button>
        </Flex>
      </Flex>
    </Drawer>
  );
};

export default AssignContactCompanyDrawer;
