import { Card, Flex, Space, Typography, Row, Col, Pagination } from "antd";
import { useContext, useEffect, useState } from "react";
import AddSmsTemplate from "./AddSmsTemplate";
import TableLoading from "../../../shared/TableLoading";
import SmsTemplateOptions from "./SmsTemplateOptions";
import { DateFormat } from "../../../dateformat";
import EditSmsTemplate from "./EditSmsTemplate";
import SmsStats from "./SmsStats";
import NoDataFound from "../../../shared/NoDataFound";
import SmsToDateFilter from "./Filters/SmsToDateFilter";
import { SmsListContext } from "./SmsListContext";
import "./SmsTemplates.css";
import SimplePagination from "../../../shared/SimplePagination";

const SmsTemplates = ({dataToAdd}) => {
  const { setPageSize, pageSize, templates, setTemplates, loading, stats, setStats, page, setPage } =
    useContext(SmsListContext);

  const { Text } = Typography;

  const [showAdd, setShowAdd] = useState(() => false);

  const [showUpdate, setShowUpdate] = useState(() => false);
  const [current, setCurrent] = useState();

  const onAdd = (e) => {
    setStats((prev) => ({ ...prev, total: prev.total + 1 }));

    setTemplates((prev) => [
      {
        name: e.name,
        author: e.authorName,
        createdAt: e.createdAt,
        profile: e.authorProfile,
        text: e.text,
        id: e.id,
      },
      ...prev,
    ]);
  };

  const onDelete = (id) => {
    setStats((prev) => ({ ...prev, total: prev.total - 1 }));

    setTemplates((prev) => prev.filter((c) => c.id != id));
  };

  const onUpdate = (e) => {
    setTemplates((prev) =>
      prev.map((c) =>
        c.id == e.id
          ? {
              name: e.name,
              author: e.authorName,
              createdAt: e.createdAt,
              profile: e.authorProfile,
              text: e.text,
              id: e.id,
            }
          : c
      )
    );
  };

  useEffect(() => {
    if(dataToAdd){
      onAdd(dataToAdd)
    }
  },[dataToAdd])

  return (
    <>
      <Space className="w-100" direction="vertical" size={24}>
        <SmsStats />

        <Flex align="center" justify="space-between">
          <SmsToDateFilter />
          <SimplePagination page={page} pageSize={pageSize} total={stats?.total} setPage={setPage}/>
        </Flex>

        <Space className="w-100" direction="vertical">
          {loading ? (
            <TableLoading />
          ) : !templates || templates?.length == 0 ? (
            <NoDataFound
              addText="New sms template"
              onAdd={() => setShowAdd(true)}
              description="Click the New Desing button on the Desings/Sms page to create a new sms desing"
              title="You have not created any sms desings yet"
            />
          ) : (
            <>
              {templates.map((c, i) => (
                <Card
                  key={i}
                  size="small"
                  className="no-shadow gray-border-hoverable"
                >
                  <Row gutter={[6, 6]}>
                    <Col className="center-table-column" span={22}>
                      <Space className="w-100" direction="vertical" size={0}>
                        <Text className="sms-title">{c.name}</Text>
                        <Text>
                        {`#${(page - 1) * pageSize + i + 1} • `} 
                          {new Date(c.createdAt).toLocaleDateString(
                            "en-US",
                            DateFormat
                          )}

                          {c.lastUpdatedAt
                            ? ` • Last edited at ${new Date(
                                c.lastUpdatedAt
                              ).toLocaleDateString("en-us", DateFormat)}`
                            : ""}
                        </Text>
                      </Space>
                    </Col>

                    <Col span={2}>
                      <Flex
                        className="w-100 h-100"
                        align="center"
                        justify="end"
                      >
                        <SmsTemplateOptions
                          id={c.id}
                          onDelete={onDelete}
                          setCurrent={setCurrent}
                          setShowUpdate={setShowUpdate}
                        />
                      </Flex>
                    </Col>
                  </Row>
                </Card>
              ))}

            <SimplePagination page={page} showChanger setPerPage={setPageSize} pageSize={pageSize} total={stats?.total} setPage={setPage}/>
            </>
          )}
        </Space>
      </Space>
      <AddSmsTemplate open={showAdd} setOpen={setShowAdd} onSubmit={onAdd} />

      {showUpdate && (
        <EditSmsTemplate
          open={showUpdate}
          setOpen={setShowUpdate}
          onUpdate={onUpdate}
          id={current}
        />
      )}
    </>
  );
};
export default SmsTemplates;
