import React from 'react'
import "./Deals.css"
import { DealsManagementContextProvider } from '../../context/DealsManagementContext'
import { DealListContextProvider } from './DealListContext'
import DealsManagement from './DealsManagement/DealsManagement'
const DealsLayout = ({dataToAdd}) => {
  return (
    <DealsManagementContextProvider>
      <DealListContextProvider>
        <DealsManagement dataToAdd={dataToAdd}/>
      </DealListContextProvider>
    </DealsManagementContextProvider>
  )
}

export default DealsLayout