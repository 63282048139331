import {
  Button,
  Card,
  DatePicker,
  Drawer,
  Flex,
  Form,
  message,
  Radio,
  Space,
  Typography,
} from "antd";
import "./Schedule.css";
import { useContext, useState } from "react";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { CreateCampaignContext } from "../../../context/CreateCampaignContext";
import { useNavigate } from "react-router-dom";

const Schedule = ({ open, setOpen }) => {
  const { Text, Title } = Typography;
  const { data } = useContext(CreateCampaignContext);
  const [form] = Form.useForm();

  const axios = useAxiosPrivate();

  const [radioValue, setRadioValue] = useState("now");
  const handleRadioChange = (e) => {
    setRadioValue(e.target.value);
  };

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const submit = async (e) => {
    setLoading(true);
    const response = await axios.post(`/Campaign/BeginCampaign`, {
      id: data.id,
      StartNow: radioValue == "now",
      start: e.start,
      end: e.end,
    });

    if (response.data.success) {
      navigate(`/Campaigns/Details/${data.id}`);
    } else {
      message.error(response.data.message);
    }
    setLoading(false);
  };
  return (
    <Drawer
      title="Schedule campaign"
      open={open}
      onClose={() => setOpen(false)}
    >
      <Form form={form} onFinish={submit}>
        <Flex className="h-100" align="center" justify="space-between" vertical>
          <Space className="w-100" size="large" direction="vertical">
            <Space className="w-100" size="large" direction="vertical">
              <Text className="schedule-text">
                When would you like to send the campaign?
              </Text>
              <Radio.Group onChange={handleRadioChange} value={radioValue} className="w-100">
                <Space className="w-100" direction="vertical" size={"large"}>
                  <Radio className="schedule-radio" value="now" size="large">
                    Send now
                  </Radio>
                  <Radio className="schedule-radio" value="later" size="large">
                    Schedule for later
                  </Radio>
                  {radioValue == "later" && (
                    <Card className="no-shadow w-100" size="small">
                      <Space direction="vertical" className="w-100">
                        <Space direction="vertical" size={0} className="w-100">
                          <Text>Start date</Text>
                          <Form.Item
                            name="startDate"
                            rules={[{ required: true }]}
                          >
                            <DatePicker
                              size="large"
                              placeholder="Choose the start date"
                              className="w-100"
                            />
                          </Form.Item>
                        </Space>
                        <Space direction="vertical" size={0} className="w-100">
                          <Text>End date</Text>

                          <Form.Item
                            name="endDate"
                            rules={[
                              {
                                required: true,
                              },
                            ]}
                          >
                            <DatePicker
                              size="large"
                              placeholder="Choose the end date"
                              className="w-100"
                            />
                          </Form.Item>
                        </Space>
                      </Space>
                    </Card>
                  )}
                  <Radio
                    className="schedule-radio"
                    value="best"
                    disabled
                    size="large"
                  >
                    Send at best time
                  </Radio>
                </Space>
              </Radio.Group>
            </Space>
          </Space>

          <Flex gap={6} align="center" justify="end" className="w-100">
            <Button onClick={() => setOpen(false)} size="large" className="new-buttons large-font">
              Cancel
            </Button>

            {radioValue == "now" ? (
              <Button
                loading={loading}
                onClick={() => form.submit()}
                size="large"
                type="primary"
                className="new-buttons large-font"
              >
                Send
              </Button>
            ) : (
              <Button
                loading={loading}
                onClick={() => form.submit()}
                size="large"
                type="primary"
                className="new-buttons large-font"
              >
                Schedule
              </Button>
            )}
          </Flex>
        </Flex>
      </Form>
    </Drawer>
  );
};

export default Schedule;
