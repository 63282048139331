import {
  Button,
  Card,
  Col,
  Flex,
  message,
  Modal,
  Result,
  Row,
  Space,
  Spin,
  Typography,
} from "antd";
import { useEffect, useState } from "react";
import {
  FaArrowLeft,
} from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import TableLoading from "../../../../../shared/TableLoading";
import NoDataFound from "../../../../../shared/NoDataFound";
import useAxiosPrivate from "../../../../../hooks/useAxiosPrivate";
import QueryString from "qs";
import "./BuyPhoneNumber.css";
import BuyPhoneNumberFilterDrawer from "./BuyPhoneNumberFilterDrawer";
import PhoneNumberFeaturesDrawer from "./PhoneNumberFeturesDrawer";
import FiltersDisplay from "./FiltersDisplay";
import { MdOutlineMoneyOff } from "react-icons/md";

const BuyPhoneNumber = () => {
  const { Title, Text } = Typography;

  const navigate = useNavigate();

  const [filters, setFilters] = useState(() => ({
    selectedCountries: [],
    selectedFeatures: [],
    selectedType: null,
    limit: 50,
  }));

  const [numbers, setNumbers] = useState();
  const [loading, setLoading] = useState(false);

  const axios = useAxiosPrivate();

  useEffect(() => {
    setLoading(true);

    const contoller = new AbortController();
    const signal = contoller.signal;
    const fetch = async () => {
      const response = await axios.get("/phone-numbers/buy", {
        signal: signal,
        params: {
          country: filters?.selectedCountries,
          features: filters?.selectedFeatures,
          type: filters?.selectedType,
          limit: filters?.limit,
        },
        paramsSerializer: (params) => {
          return QueryString.stringify(params, {
            arrayFormat: "repeat",
            skipNulls: true,
          });
        },
      });

      setNumbers(response.data.data);
      setLoading(false);
    };

    fetch();

    return () => contoller.abort();
  }, [filters]);

  const [buyOpen, setBuyOpen] = useState();
  const [current, setCurrent] = useState();

  const handleBuyClick = async (c) => {
    setCurrent({
      number: c.phone_number,
      features: c.features,
      type: c.phone_number_type,
      setUpPrice: c.cost_information.upfront_cost,
      monthlyPrice: c.cost_information.monthly_cost,
      currency: c.cost_information.currency,
    });

    setBuyOpen(true);
  };

  const [showFilters, setShowFilters] = useState(false);

  const [showFeatures, setShowFeatures] = useState(false);

  const handleFeatureClick = (c) => {
    setCurrent({
      number: c.phone_number,
      features: c.features,
      type: c.phone_number_type,
      setUpPrice: c.cost_information.upfront_cost,
      monthlyPrice: c.cost_information.monthly_cost,
      currency: c.cost_information.currency,
    });
    setShowFeatures(true);
  };

  return (
    <>
      <PhoneNumberFeaturesDrawer
        number={current}
        open={showFeatures}
        setOpen={setShowFeatures}
      />
      <BuyPhoneNumberFilterDrawer
        open={showFilters}
        setOpen={setShowFilters}
        filters={filters}
        setFilters={setFilters}
      />
      {current && (
        <ConfirmBuyModal open={buyOpen} setOpen={setBuyOpen} number={current} />
      )}
      
        <Space direction="vertical" size={0}>
          <Flex align="center" justify="start" gap={6} wrap="wrap">
            <Button
              icon={<FaArrowLeft />}
              type="text"
              onClick={() => navigate(-1)}
              className="flex-button"
            ></Button>
            <Title
              level={1}
              className="zero-margin-padding"
              style={{ margin: 0 }}
            >
              Available phone numbers
            </Title>
          </Flex>
        </Space>

       
        <FiltersDisplay setShowFilters={setShowFilters} filters={filters} setFilters={setFilters}/>
       
       

        <Space style={{marginTop:12}} className="w-100" direction="vertical">
          {loading ? (
            <TableLoading />
          ) : !numbers || numbers?.length == 0 ? (
            <NoDataFound
              title="No phone numbers found"
              description="Current filters resulted in no phone numbers being found, change the filters and try again."
            />
          ) : (
            <>
              {numbers?.map((c, i) => (
                <div
                  key={i}
                  className="BuyPhoneNumber-table-row div-card gray-border-hoverable"
                >
                  <Space direction="vertical" size={0}>
                    <Text className="BuyPhoneNumber-list-title">
                      {c.phone_number}
                    </Text>
                    <Text className="BuyPhoneNumber-list-created-at">
                      {c.region_information.map((d, s) => (
                        <Text
                          key={s}
                          className="BuyPhoneNumber-list-created-at"
                        >
                          {d.region_name}
                          {s == c.region_information.length - 1 ? "" : ", "}
                        </Text>
                      ))}
                    </Text>
                  </Space>

                  <Space direction="vertical" align="end" size={0}>
                    <Text className="BuyPhoneNumber-price BuyPhoneNumber-text">
                      {` ${
                        Math.round(c.cost_information.upfront_cost, 2) +
                        Math.round(+c.cost_information.monthly_cost, 2)
                      } ${c.cost_information.currency}`}
                    </Text>
                    <Flex
                      align="center"
                      justify="end"
                      className="w-100 h-100"
                      gap={6}
                    >
                      <Button
                        className="new-buttons large-font"
                        onClick={() => handleFeatureClick(c)}
                      >
                        View features
                      </Button>
                      <Button
                        onClick={() => handleBuyClick(c)}
                        type="primary"
                        className="new-buttons large-font"
                      >
                        Buy
                      </Button>
                    </Flex>
                  </Space>
                </div>
              ))}
            </>
          )}
        </Space>
    </>
  );
};

const ConfirmBuyModal = ({ open, setOpen, number }) => {
  const { Title, Text } = Typography;
  const navigate = useNavigate();
  const axios = useAxiosPrivate();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const [noBalance, setNoBalance] = useState(false)

  const buy = async () => {
    setLoading(true);

    const response = await axios.post(`/phone-numbers/buy-number`, {
      number: number.number,
      type: number.type,
      features: number.features?.map((c) => c.name).join(","),
    });

    if(response.data.notEnoughBalance){
      setNoBalance(true)
    }else if (response.data.success) {
      setSuccess(true);
    } else {
      message.error(response.data.message);
    }

    setLoading(false);
  };

  const handleCancel = () => {
    if (success) {
      navigate(`/Senders/sms`);
    } else {
      setOpen(false);
    }
  };

  const handleClose = () => {
    setNoBalance(false)
    setOpen(false)
    setSuccess()
    setLoading(false)
  }

  return (
    <Modal
    destroyOnClose
      open={open}
      onCancel={handleClose}
      footer={
        <>
        {noBalance ? null : <>
        
          {loading || success ? (
            false
          ) : (
            <Flex align="center" justify="center" gap={6}>
              <Button
                className="large-font new-buttons"
                size="large"
                onClick={handleCancel}
              >
                Cancel
              </Button>
              <Button
                size="large"
                type="primary"
                onClick={buy}
                loading={loading}
                className="large-font new-buttons"
              >
                Buy number
              </Button>
            </Flex>
          )}
        </>
      }</>}
    >
      {
        noBalance ? <>
          <Space className="w-100" direction="vertical" align="center">
                  <div className="no-money-modal-wrapper">
                    <MdOutlineMoneyOff />
                  </div>
          
                  <Space size={0} className="w-100" direction="vertical" align="center">
                    <Title className="zero-margin-padding">Insufficient balance </Title>
                    <Text>Please top-up your phone balance to use this feature</Text>
                  </Space>
                  <Flex align="center" justify="center" gap={6}>
                    <Button
                      className="new-buttons large-font"
                      size="large"
                      onClick={handleClose}
                    >
                      Choose another number  
                    </Button>
                  </Flex>
                </Space>
        </> : <>
        
      {success ? (
        <Result
          status="success"
          title="Successfully Purchased Number"
          subTitle={`Phone number ${
            number.number
          } was successfully purchased for ${Math.round(
            parseFloat(number.monthlyPrice) + parseFloat(number.setUpPrice),
            2
          )} ${number.currency}`}
          extra={[
            <Button size="large" onClick={handleCancel}>
              Cancel
            </Button>,
            <Button
              type="primary"
              size="large"
              onClick={() => navigate(`/dial/number`)}
            >
              Call someone
            </Button>,
          ]}
        />
      ) : (
        <>
          <Space size={0} direction="vertical" className="w-100">
            <div className="w-100 text-center">
              <Text>Buy this {number.type} phone number</Text>
            </div>
            <Title
              style={{ marginTop: 0, marginBottom: 0 }}
              className="zero-marign-padding text-center w-100"
            >
              {number.number}
            </Title>
          </Space>
          {loading ? (
            <Flex style={{ height: 80 }} align="center" justify="center">
              {" "}
              <Spin size="large" />
            </Flex>
          ) : (
            <Row gutter={[12, 12]} style={{ marginTop: 24, marginBottom: 24 }}>
              <Col span={8}>
                <Card size="small" className="no-shadow price-boxes">
                  <Space
                    direction="vertical"
                    align="center"
                    className="w-100"
                    size={0}
                  >
                    <Text>Monthly price</Text>
                    <Title className="zero-margin-padding" level={4}>
                      {Math.round(number.monthlyPrice, 2)} {number.currency}
                    </Title>
                  </Space>
                </Card>
              </Col>
              <Col span={8}>
                <Card size="small" className="no-shadow price-boxes">
                  <Space
                    direction="vertical"
                    align="center"
                    className="w-100"
                    size={0}
                  >
                    <Text>Setup price</Text>
                    <Title className="zero-margin-padding" level={4}>
                      {Math.round(number.setUpPrice, 2)} {number.currency}
                    </Title>
                  </Space>
                </Card>
              </Col>
              <Col span={8}>
                <Card size="small" className="no-shadow price-boxes">
                  <Space
                    direction="vertical"
                    align="center"
                    className="w-100"
                    size={0}
                  >
                    <Text>Total price</Text>
                    <Title className="zero-margin-padding" level={4}>
                      {Math.round(
                        parseFloat(number.monthlyPrice) +
                          parseFloat(number.setUpPrice),
                        2
                      )}{" "}
                      {number.currency}
                    </Title>
                  </Space>
                </Card>
              </Col>
            </Row>
          )}
        </>
      )}</>
    }
    </Modal>
  );
};
export default BuyPhoneNumber;
