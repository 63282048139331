import React from "react";
import { Layout } from "antd";
import UserProfileMenu from "./UserProfileMenu";
import Search from "./Search";

const Header = () => {
  const { Header } = Layout;
  return (
    <>
      <Header
        style={{
          minHeight: "50px",
          background: "#ffffff",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "0px 10px",
          margin: 0,
        }}
      >
        <Search />

        <UserProfileMenu />
      </Header>
    </>
  );
};

export default Header;
