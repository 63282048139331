import { createContext, useEffect, useState } from "react";
import { OrderDirection, TaskPriority, TaskStatus } from "../../shared/enums";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useParams } from "react-router-dom";
export const TaskContext = createContext({
  filters: {
    show: false,

    orderBy: "Created",
    orderDirection: OrderDirection.Dsc,

    from: null,
    to: null,

    types: [],

    priorities: [],
  },
  setFilters: () => {},

  stats: {
    total: 0,
    overdue: 0,
    toDo: 0,
    email: 0,
    call: 0,
    meeting: 0,
    lunch: 0,
    deadline: 0,
  },
  setStats: () => {},

  tasks: [],
  setTasks: () => {},

  loading: false,
  setLoading: () => {},

  page: 1,
  setPage: () => {},

  pageSize: 25,
  setPageSize: () => {}
});

export const TaskContextProvider = ({ children }) => {
  const axios = useAxiosPrivate();

  const [pageSize, setPageSize] = useState(25);
  const [tasks, setTasks] = useState(() => []);
  
  const {authorId} = useParams();

  const [page, setPage] = useState(() => 1);

  const [filters, setFilters] = useState({
    show: false,

    orderBy: "Created",
    orderDirection: OrderDirection.Dsc,

    from: null,
    to: null,

    types: [
      TaskStatus.ToDo,
      TaskStatus.Email,
      TaskStatus.Call,
      TaskStatus.Meeting,
      TaskStatus.Lunch,
      TaskStatus.Deadline,
    ],

    priorities: [TaskPriority.High, TaskPriority.Normal],
  });

  const [loading, setLoading] = useState(false);

  const [stats, setStats] = useState(() => ({
    total: 0,
    overdue: 0,
    toDo: 0,
    email: 0,
    call: 0,
    meeting: 0,
    lunch: 0,
    deadline: 0,
  }));

  const fetchTasks = async () => {
  
    setLoading(true);
    const response = await axios.post(`/DealTasks/list`, {
      page: page,
      pageSize: pageSize,

      orderDirection: filters.orderDirection,
      orderBy: filters.orderBy,

      from: filters?.from,
      to: filters?.to,

      types: filters?.types,

      authorId: authorId
    });

    setStats((prev) => ({
      ...prev,
      total: response.data.total,
      overdue: response.data.overdue,
      toDo: response.data.toDo,
      email: response.data.email,
      call: response.data.call,
      meeting: response.data.meeting,
      lunch: response.data.lunch,
      deadline: response.data.deadline,
    }));

    setTasks(response.data.items);
    setLoading(false);
  }

  useEffect(() => {
    fetchTasks()
  },[filters, page, pageSize])

  return (
    <TaskContext.Provider
      value={{
        filters,
        setFilters,

        stats,
        setStats,

        tasks,
        setTasks,

        page,
        setPage,

        loading,

        pageSize,
        setPageSize
      }}
    >
      {children}
    </TaskContext.Provider>
  );
};
