import { createContext, useContext, useEffect, useState } from "react";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { EmaiLSenderConfirmation, EmailSenderDefault, OrderDirection } from "../../../shared/enums";
import { SearchContext } from "../../../context/SearchContext";
import QueryString from "qs";

export const EmailSenderContext = createContext({
    filters: {
        selectedAuthors: [],

        from: null,
        to: null,

        statuses: [],
        defaults: [],

        orderBy: null,
        orderDirection: null
    },
    stats: {
        total: 0,
        confirmed: 0,
        first: null,
        last: null,
    },

    senders: [],
    setSenders: () => { },

    page: 1,
    setPage: () => { },

    loading: false,
    setLoading: () => { }
})

export const EmailSenderContextProvider = ({ children }) => {
   
    const { debouncedValue } = useContext(SearchContext);

    const [filters, setFilters] = useState(() => ({
        orderDirection: OrderDirection.Dsc,
        statuses: [
            EmaiLSenderConfirmation.Confirmed,
            EmaiLSenderConfirmation.NotConfirmed,
        ],
        defaults: [
            EmailSenderDefault.Default,
            EmailSenderDefault.NotDefault
        ],
        orderBy: "Created"}))
        

    const [stats, setStats] = useState();
    const [page, setPage] = useState(() => 1)
    const perPage = 7;
    const [senders, setSenders] = useState(() => []);

    const [loading, setLoading] = useState(() => false);

    const axios = useAxiosPrivate();

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;
    

        const fetchSenders = async () => {
            
            setLoading(true);
    
            const sendersResponse = await axios.get('/sender/getsenders', {
                params:{
    
                    page: page,
                    pageSize: perPage,
                    search: debouncedValue,
                    from: filters?.from ? new Date(filters?.from) : null,
                    to: filters?.to ? new Date(filters.to) : null,
                    orderBy: filters?.orderBy,
                    sortDirection: filters?.orderDirection,
                    default: filters?.defaults?.includes(c => c == EmailSenderDefault.Default),
                    notDefault: filters?.defaults?.includes(c => c == EmailSenderDefault.NotDefault),
                    confirmed: filters?.statuses?.includes(c => c == EmaiLSenderConfirmation.Confirmed),
                    notConfirmed: filters?.statuses?.includes(c => c == EmaiLSenderConfirmation.NotConfirmed)
                },
                signal: signal,
                paramsSerializer: params => {
                    return QueryString.stringify(params, {arrayFormat: "repeat", skipNulls: true})
                  }
            })
    
            setSenders(sendersResponse.data.items)
    
            setStats(prev => ({
                ...prev,
                total: sendersResponse.data.total,
                first: sendersResponse.data.first,
                last: sendersResponse.data.last,
                confirmed: sendersResponse.data.confirmed
            }))
            setLoading(false)
        }

        fetchSenders()

        return () => {
            controller.abort();
          };
    }, [page, filters, debouncedValue])

    return <EmailSenderContext.Provider value={{
        filters,
        setFilters,

        stats,
        setStats,

        senders,
        setSenders,

        page,
        setPage,

        loading,
        setLoading
    }}>
        {children}
    </EmailSenderContext.Provider>
}