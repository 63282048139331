import { Button, Flex, Modal, Space, Typography } from "antd";
import { MdOutlineMoneyOff } from "react-icons/md";
import "./NoBalanceModal.css";
import { useNavigate } from "react-router-dom";

const NoBalanceModal = ({ open }) => {
  const { Title, Text } = Typography;

  const navigate = useNavigate();

  const handleCancel = () => {
    navigate("/");
  };

  return (
    <Modal footer={null} open={open} onCancel={handleCancel}>
      <Space className="w-100" direction="vertical" align="center">
        <div className="no-money-modal-wrapper">
          <MdOutlineMoneyOff />
        </div>

        <Space size={0} className="w-100" direction="vertical" align="center">
          <Title className="zero-margin-padding">Insufficient balance </Title>
          <Text>Please top-up your phone balance to use this feature</Text>
        </Space>
        <Flex align="center" justify="center" gap={6}>
          <Button
            className="new-buttons large-font"
            size="large"
            onClick={handleCancel}
          >
            Go home
          </Button>
        </Flex>
      </Space>
    </Modal>
  );
};

export default NoBalanceModal;
