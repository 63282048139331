import { Button, Card, Flex, List, Space, Typography, message } from "antd";
import React, { useState } from "react";
import useDealsDetailsCtx from "../../../../../hooks/useDealsDetailsCtx";
import { useParams } from "react-router-dom";
import useAxiosPrivate from "../../../../../hooks/useAxiosPrivate";
import AddProductDrawer from "./AddProductDrawer";
import DealProductsOptions from "./DealProductsOptions";
import { FaPen } from "react-icons/fa6";
import DeleteConfirmationModal from "../../../../../components/DeleteConfirmationModal";

const DealProducts = () => {
  const axios = useAxiosPrivate();
  const { Title, Text } = Typography;
  const { products, setProducts } = useDealsDetailsCtx();
  const [showAdd, setShowAdd] = useState(false);
  const { id } = useParams();

  const [deleteOpen, setDeleteOpen] = useState(false);
  const [current, setCurrent] = useState();

  const onDelete = async (prodId, deleteConfirmed) => {
    if (!deleteConfirmed) {
      return;
    }

    const response = await axios.delete(`/Deal/Products/${id}/${prodId}`);

    if (response.data.success) {
      setProducts((prev) => prev.filter((c) => c.id != prodId));
    } else {
      message.error(response.data.message);
    }
  };

  return (
    <>
      <DeleteConfirmationModal
        isOpen={deleteOpen}
        onDeleteConfirm={onDelete}
        onClose={() => setDeleteOpen(false)}
        item={current}
      />
    <Space className="w-100" direction="vertical">
    
      <Flex
        align="center"
        justify="space-between"
        style={{ padding: "0px 10px" }}
      >
        <Title level={4} style={{ margin: 5 }}>
          Associated products
        </Title>
        <Button
          size="large"
          className="new-button large-font"
          onClick={() => setShowAdd(true)}
        >
          Edit
        </Button>
      </Flex>
      <Card
        className="no-head-space zero-margin-padding"
        style={{ boxShadow: "none" }}
      >
        {products?.length == 0 ? (
          <div style={{ padding: 15 }}>
            <Text strong className="text-light">
              No products found
            </Text>
          </div>
        ) : (
          <List
            style={{ borderRadius: 20 }}
            bordered={false}
            dataSource={products}
            renderItem={(item, index) => (
              <List.Item>
                <Space
                  direction="vertical"
                  size={0}
                  style={{ width: "100%", padding: "0px 15px" }}
                >
                  <Flex
                    align="center"
                    justify="space-between"
                    style={{ width: "100%" }}
                  >
                    <Text strong>{item.name}</Text>

                    <DealProductsOptions
                      id={item.id}
                      onConfirm={(e) => {
                        setCurrent(e);
                        setDeleteOpen(true);
                      }}
                    />
                  </Flex>
                </Space>
              </List.Item>
            )}
          />
        )}
        <AddProductDrawer open={showAdd} setOpen={setShowAdd} />
      </Card>
      </Space>
    </>
  );
};

export default DealProducts;
