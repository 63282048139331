import React, { memo } from "react";
import LostDealModal from "../LostDealModal";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { message } from "antd";
import { CardStatus } from "../../../shared/enums";
import { insert } from '../../../shared/HelperMethods'

const KanbanLostDealModal = memo(
  ({ open, setOpen, cards, setKanbanCards, initialCardPositionRef, dragEvent }) => {
    const axios = useAxiosPrivate();

    function onClose() {
      if (!initialCardPositionRef.current.card) {
        return;
      }

      const { card, index } = initialCardPositionRef.current;

      const { status, id } = card;

      setKanbanCards(prev => ({
        ...prev,
        [CardStatus.Closed]: ({
          ...prev[CardStatus.Closed],
          items: prev[CardStatus.Closed].items.filter(c => c.id != id)
        }),

        [status]: ({
          ...prev[status],
          items: insert(prev[status].items, index, card)
        })
      }))

      initialCardPositionRef.current = {};
      setOpen(false);
    }

    const onSubmit = async (e) => {
      const { active } = dragEvent;

      const response = await axios.post(`/Deal/UpdateDealPosition`, {
        id: active.data.current.card.id,
        index: active.data.current.sortable.index,
        newStage: CardStatus.Closed,
        deals: active.data.current.sortable.items,
        closeDate: e.closeDate,
        additionalComments: e.additionalComments,
        lostReason: e.reason,
      });

      let oldStatus = response.data.oldStage;
      let amount = response.data.amountToAddToNewStage;

      if (response.data.success) {
        setKanbanCards(prev => ({
          ...prev,
          [CardStatus.Closed] : ({
            ...prev[CardStatus.Closed],
            count: prev[CardStatus.Closed].count + 1,
            revenue: prev[CardStatus.Closed].revenue + amount
          }),
          [oldStatus] : ({
            ...prev[oldStatus],
            count: prev[oldStatus].count - 1,
            revenue: prev[oldStatus].revenue - amount
          })
        }))
        setOpen(false);
      } else {
        message.error(response.data.message);
      }

    };
    return <LostDealModal open={open} onClose={onClose} onSubmit={onSubmit} />;
  }
);

export default KanbanLostDealModal;
