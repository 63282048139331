import { useContext, useState } from "react";
import { AuthorContext } from "../../../context/AuthorsContext";
import { Avatar, Button, Card, Flex, Popover, Space, Typography } from "antd";
import "./Agents.css";
import Colors from "../../../shared/Colors";
import { useNavigate } from "react-router-dom";
import { UserOutlined } from "@ant-design/icons";
import { MdOutlineArrowDropDown } from "react-icons/md";
import InviteUser from "../../../pages/users/UserManagement/InviteUser";
import { SettingsPageTabs } from "../../../shared/enums";
const Agents = () => {
  const { authors } = useContext(AuthorContext);
  const { Text, Title } = Typography;

  const [inviteOpen, setInviteOpen] = useState(false);

  const getColor = (index) => {
    return Colors[index % Colors.length];
  };

  const navigate = useNavigate();

  const handleAgentClick = () => {
    navigate(`/settings?activetab=${SettingsPageTabs.Team}`);
  };

  const handleUserClick = (id) => {
    navigate(`/user/details/${id}`);
  };

  return (
    <>
      <InviteUser isOpen={inviteOpen} onClose={() => setInviteOpen(false)} />
      <Popover trigger={["click"]}
        content={
          <Space style={{ padding: 20 }} direction="vertical">
            <Title className="zero-margin-padding">
              All agents ({authors?.length ?? 0})
            </Title>

            <Space direction="vertical" className="agents-scroll-wrapper">
              {authors?.map((c, i) => (
                <Card
                  key={i}
                  onClick={() => handleUserClick(c.value)}
                  size="small"
                  className="no-shadow gray-border-hoverable"
                >
                  <Flex align="center" gap={6}>
                    <Avatar
                      size="large"
                      style={{
                        background: getColor(i),
                      }}
                      icon={<UserOutlined />}
                    />
                    <Space direction="vertical" size={0}>
                      <Text className="agent-name">
                        {c.firstname} {c.lastname}
                      </Text>

                      <Text>
                        {c.email} {c.phone}
                      </Text>
                    </Space>
                  </Flex>
                </Card>
              ))}
            </Space>
            <Flex align="center" justify="center" gap={6}>
              <Button
                onClick={handleAgentClick}
                size="large"
                className="new-buttons large-font"
              >
                Open agents
              </Button>
              <Button
                onClick={() => setInviteOpen(true)}
                size="large"
                type="primary"
                className="new-buttons large-font"
              >
                Invite agent
              </Button>
            </Flex>
          </Space>
        }
      >
        <div className="agents-wrapper">
          <Text className="agents-wrapper-text">Agents</Text>
          <Avatar.Group
            className="no-pointer-events"
            max={{
              count: 3,
              style: {
                background: "#5f5f5f",
                fontWeight: "bold",
                border: "2px solid #f4f7fe",
              },
            }}
          >
            {authors?.map((c, i) => (
              <>
                <Avatar
                  key={i}
                  src={`${process.env.REACT_APP_FILE_URL}${c.profile}`}
                  style={{
                    background: `${getColor(i)}`,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontWeight: "bold",
                    border: `2px solid #f4f7fe`,
                  }}
                >
                  {c?.firstname?.length > 0 ? c.firstname[0] : ""}
                  {c?.lastname?.length > 0 ? c.lastname[0] : ""}
                </Avatar>
              </>
            ))}
          </Avatar.Group>
          <MdOutlineArrowDropDown style={{ fontSize: 22 }} />
        </div>
      </Popover>
    </>
  );
};

export default Agents;
