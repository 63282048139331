import {
  Button,
  Card,
  Dropdown,
  Flex,
  message,
  Modal,
  Space,
  Typography,
} from "antd";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CreateCampaignContext } from "../../../context/CreateCampaignContext";
import { FaCheck, FaEye } from "react-icons/fa";
import "./Design.css";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { EmailTemplateTypes } from "../../../shared/enums";
import { MdMoreHoriz } from "react-icons/md";
import AddPersonalizationDrawer from "./AddPersonalizationDrawer";

const Desing = ({ handleOpenPreview }) => {
  const { data, setData } = useContext(CreateCampaignContext);

  const navigate = useNavigate();

  const { Text, Title } = Typography;

  const axios = useAxiosPrivate();
  const [html, setHtml] = useState("");
  const [loading, setLoading] = useState(false);
  const [text, setText] = useState("");

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    setLoading(true);
    const fetch = async () => {
      const response = await axios.get(
        `/Templates/Email/Html/${data.templateId}/${EmailTemplateTypes.UserCreated}`,
        {
          signal: signal,
        }
      );

        const textRepsonse = await axios.get(
          `/Templates/Email/Plain-Text/${data.templateId}`
        );
        
        setText(textRepsonse.data.text);
        setHtml(response.data.html);
      
        setLoading(false);
    };

    if (data?.templateId) {
      fetch();
    }
    return () => abortController.abort();
  }, [data.templateId]);

  const [previewHtmlModalOpen, setPreviewHtmlModalOpen] = useState(false);
  const [removeOpen, setRemoveOpen] = useState(false);
  const [textOpen, setTextOpen] = useState();

  const handleRemoveConfirmed = async () => {
    const response = await axios.put(`/Campaign/Email/Remove-desing`, {
      campaignId: data?.id,
    });

    if (response.data.success) {
      setData((prev) => ({
        ...prev,
        designStepFinished: false,
        isSimple: null,
        templateId: null,
      }));
      setRemoveOpen(false);
    } else {
      message.error(response.data.message);
    }
  };
  return (
    <>
      <PlainTextPreviewModal
        text={text}
        setOpen={setTextOpen}
        open={textOpen}
      />
      <HtmlPreviewModal
        open={previewHtmlModalOpen}
        setOpen={setPreviewHtmlModalOpen}
        html={html}
      />
      <ResetDesingModal
        open={removeOpen}
        setOpen={setRemoveOpen}
        onConfirm={handleRemoveConfirmed}
      />
      <Card className="step-card no-shadow last-card">
        <Flex className="w-100" align="center" justify="space-between">
          <Flex align="center" justify="start" gap={12}>
            {data?.designStepFinished ? (
              <div className="step-evaluation-box-success">
                <FaCheck />
              </div>
            ) : (
              <div className="step-evaluation-box-default"></div>
            )}
            <Space direction="vertical" size={0}>
              <Title className="step-card-title zero-margin-padding" level={3}>
                Design
              </Title>
              {!data?.designStepFinished && (
                <Text>Create your email content.</Text>
              )}
            </Space>
          </Flex>
          {data?.designStepFinished ? (
            <Flex align="center" justify="end" gap={6}>
              <Dropdown
                trigger={["click"]}
                menu={{
                  items: [
                    {
                      label: <p className="desing-options-dropdown">View HTML code</p>,
                      key: 1,
                      onClick: () => setPreviewHtmlModalOpen(true),
                    },
                    {
                      label: <p className="desing-options-dropdown">View the plain text version</p>,
                      key: 3,
                      onClick: () => setTextOpen(true),
                    },
                    {
                      label: <p className="desing-options-dropdown">Reset desing</p>,
                      key: 2,
                      danger: true,
                      onClick: () => setRemoveOpen(true),
                    },
                  ],
                }}
              >
                <Button
                  className="flex-button large-font new-buttons"
                  icon={<MdMoreHoriz className="icon" />}
                  size="large"
                ></Button>
              </Dropdown>
              <Button
                size="large"
                className="w-300 large-font new-buttons"
                onClick={() => {
                  if (data.isSimple) {
                    navigate(`/Designs/Email/Edit/Simple/${data?.templateId}`);
                  } else {
                    navigate(`/Designs/Email/Edit/Advanced/${data?.templateId}`);
                  }
                }}
              >
                Edit design
              </Button>
            </Flex>
          ) : (
            <Button size="large" className="w-300 large-font new-buttons" onClick={() => navigate("Desing")}>
              Start designing
            </Button>
          )}
        </Flex>
        {data?.designStepFinished && (
          <div className="design-template-preview">
            <div className="desing-template-preview">
              <Flex
                className="design-template-preview-overlay"
                align="center"
                justify="center"
              >
                <Button
                  size="large"
                  icon={<FaEye />}
                  className="flex-preview-button"
                  onClick={() => handleOpenPreview()}
                >
                  Preview & Test
                </Button>
              </Flex>
              <iframe className="desing-frame-template" srcDoc={html}></iframe>
            </div>
          </div>
        )}
      </Card>
    </>
  );
};

const PlainTextPreviewModal = ({ text, open, setOpen }) => {
  const { Title, Text } = Typography;

  return (
    <>
      <Modal
        open={open}
        onCancel={() => setOpen(false)}
        title={
          <Title style={{ margin: 0 }} level={4}>
            Plain Text Version
          </Title>
        }
        width={800}
        footer={false}
      >
        <Card size="small" className="no-shadow html-preview-box-wrapper">
          <div className="html-preview-box">
            <Text className="html-preview-box-text">
              {text}
            </Text>
          </div>
        </Card>
      </Modal>
    </>
  );
};

const HtmlPreviewModal = ({ html, open, setOpen }) => {
  const { Title, Text } = Typography;

  return (
    <>
      <Modal
        open={open}
        onCancel={() => setOpen(false)}
        title={
          <Title style={{ margin: 0 }} level={4}>
            Html code
          </Title>
        }
        width={800}
        footer={false}
      >
        <Card size="small" className="no-shadow html-preview-box-wrapper">
          <div className="html-preview-box">
            <Text code={true} className="html-preview-box-text">
              {html}
            </Text>
          </div>
        </Card>
      </Modal>
    </>
  );
};

const ResetDesingModal = ({ open, setOpen, onConfirm }) => {
  const { Text, Title } = Typography;

  return (
    <Modal
      open={open}
      onCancel={() => setOpen(false)}
      title={
        <Title style={{ margin: 0 }} level={4}>
          Reset desing confirmation
        </Title>
      }
      footer={
        <Flex align="center" justify="end" gap={6}>
          <Button size="large" onClick={() => setOpen(false)}>
            Cancel
          </Button>
          <Button
            size="large"
            type="primary"
            danger
            onClick={() => onConfirm()}
          >
            Confirm
          </Button>
        </Flex>
      }
    >
      <Text>
        Are you sure you want to reset your desing? You will lose all of your
        current content and modifications.
      </Text>
    </Modal>
  );
};
export default Desing;
