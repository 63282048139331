import { Button, Card, Flex, Space, Typography } from "antd";
import { IoMdClose } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { ImportContactContext } from "./ImportContactContext";
import UploadFileStep from "./UploadFileStep";
import MapFileStep from "./MapFileStep";
import MapListStep from "./MapListStep";

const ImportSteps = () => {
  const { Title, Text } = Typography;
  const navigate = useNavigate();

  const { step, setStep } = useContext(ImportContactContext);
  return (
    <Space direction="vertical" size={24} className="w-100">
  
        <Flex align="center" justify="space-between">
          <Space direction="vertical" size={0}>
            <Title level={1} style={{ margin: 0 }}>
              Import contacts from a file
            </Title>

            <Text>
              Upload a file containing all your contacts and their information.
              This is particularly useful when you have a large number of
              contacts to import.
            </Text>
          </Space>

          <Button
            onClick={() => navigate(-1)}
            className="flex-button"
            type="text"
            icon={<IoMdClose style={{ fontSize: 20 }} />}
          ></Button>
        </Flex>

      <Space className="w-100" size={0} direction="vertical">
        <UploadFileStep active={step == 1}/>
        <MapFileStep active={step == 2}/>
        <MapListStep active={step == 3}/>
      </Space>
    </Space>
  );
};

export default ImportSteps;
