import { Tag } from "antd";
import { useContext } from "react";
import { ProductListContext } from "./ProductListContext";

const ProductStats = () => {
  const { stats } = useContext(ProductListContext);

  return (
    <Tag className="all-stats-tag default-stat-tag">
      {stats?.total ?? 0} Total
    </Tag>
  );
};
export default ProductStats;
