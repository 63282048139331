import {
  Button,
  Checkbox,
  Drawer,
  Flex,
  Form,
  message,
  Select,
  Space,
  Typography,
} from "antd";
import { useEffect, useState } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

const ProductFollowupDrawer = ({ id, open, setOpen,onUpdate }) => {
  const { Text } = Typography;

  const axios = useAxiosPrivate();

  const [form] = Form.useForm();
  const [followUps, setFollowUps] = useState();

  useEffect(() => {
    const fetch = async () => {
      const response = await axios.get(`/product/${id}`);

      if (response.data.success) {
        form.setFieldValue(
          "autoFollowupEmails",
          response.data.autoFollowupEmails
        );
        form.setFieldValue(
          "autoFollowupNotifications",
          response.data.autoFollowupNotifications
        );
        form.setFieldValue("followUpId", response.data.followUpId);
      }
    };

    if (id) {
      fetch();
    }
  }, [id]);

  useEffect(() => {
    const fetch = async () => {
      let response = await axios.get(`/Followup/List`, {
        params: {
          pageSize: 9999,
          page: 1,
        },
      });

      setFollowUps(
        response.data.data?.map((c) => ({
          value: c.id,
          label: c.name,
        }))
      );
    };
    fetch();
  }, []);

  const [loading, setLoading] = useState(false);

  const submit = async (e) => {
    setLoading(true);

    const response = await axios.put(`/Product/Update/FollowUps`, {
      id: id,
      emails: e.autoFollowupEmails,
      notifications: e.autoFollowupNotifications,
      followUpId: e.followUpId,
    });

    if (response.data.success) {
        setOpen(false)
        onUpdate({
            id: id,
            followUpId:e.followUpId,
            emails: e.autoFollowupEmails,
            notification: e.autoFollowupNotifications
        })
    } else {
      message.error(response.data.message);
    }
    setLoading(false);
  };
  return (
    <>
      <Drawer
        title="Edit product followup settings"
        open={open}
        onClose={() => setOpen(false)}
      >
        <Flex className="h-100" align="center" justify="space-between" vertical>
          <Form onFinish={submit} form={form}>
            <Space className="w-100" direction="vertical" size={24}>
              <Space className="w-100" direction="vertical" size={0}>
                <Text className="bold">
                  What kind of notifications would you like your product
                  subscribers to recieve?
                </Text>
                <Form.Item
                  className="no-error"
                  name="autoFollowupEmails"
                  valuePropName="checked"
                >
                  <Checkbox size="small">Email</Checkbox>
                </Form.Item>

                <Form.Item
                  className="no-error"
                  name="autoFollowupNotifications"
                  valuePropName="checked"
                >
                  <Checkbox>Browser</Checkbox>
                </Form.Item>
              </Space>

              <Space className="w-100" direction="vertical" size={0}>
                <Text className="bold">Followup steps</Text>
                <Form.Item name="followUpId">
                  <Select
                    options={followUps}
                    size="large"
                    className="w-100"
                    placeholder="Select product followup"
                  />
                </Form.Item>
              </Space>
            </Space>
          </Form>

          <Flex gap={6} align="center" justify="end" className="w-100">
            <Button
              onClick={() => setOpen(false)}
              size="large"
              className="new-buttons large-font"
            >
              Cancel
            </Button>
            <Button
              size="large"
              type="primary"
              className="new-buttons large-font"
              loading={loading}
              onClick={() => form.submit()}
            >
              Save changes
            </Button>
          </Flex>
        </Flex>
      </Drawer>
    </>
  );
};

export default ProductFollowupDrawer;
