import { useContext } from "react";
import { DealListContext } from "../DealListContext";
import DateFilter from "../../../shared/DateFilter";

const CreatedDateToFilter = () => {
  const { filters, setFilters } = useContext(DealListContext);

  return <DateFilter filters={filters} setFilters={setFilters} />;
};

export default CreatedDateToFilter;
