import { useContext } from "react";
import { TaskContext } from "../TaskContext";
import DateFilter from "../../../shared/DateFilter";

const TaskToDateFilter = () => {
  const { filters, setFilters } = useContext(TaskContext);

  return <DateFilter setFilters={setFilters} filters={filters} />;
};
export default TaskToDateFilter;
