import {
  Button,
  Card,
  Dropdown,
  Flex,
  message,
  Modal,
  Space,
  Typography,
} from "antd";
import { useContext, useEffect, useState } from "react";
import { FaCheck, FaEye } from "react-icons/fa";
import { MdMoreHoriz } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { CreateFollowupContext } from "./CreateFollowupContext";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { EmailTemplateTypes } from "../../../shared/enums";
import "./EmailStep.css"
import CreateCampaignPreviewTest from "../../campaigns/CreateUpdate/CreateCampaignPreviewTest";

const EmailStep = ({ setStep }) => {
  const { Text, Title } = Typography;
  const axios = useAxiosPrivate();

  const navigate = useNavigate();
  const { details, setDetails } = useContext(CreateFollowupContext);

  const [previewHtmlModalOpen, setPreviewHtmlModalOpen] = useState(false);
  const [removeOpen, setRemoveOpen] = useState(false);
  const [textOpen, setTextOpen] = useState();

  const [text, setText] = useState();
  const [html, setHtml] = useState();

  const handleRemoveConfirmed = async () => {
    const response = await axios.put(`/Followup/Step/Remove-desing`, {
      id: details?.id,
    });

    if (response.data.success) {
      setDetails((prev) => ({
        ...prev,
        email: null,
      }));
      setRemoveOpen(false);
    } else {
      message.error(response.data.message);
    }
  };

  useEffect(() => {
    const fetch = async () => {
      var response = await axios.get(
        `/Templates/Email/Html/${details?.email?.id}/${EmailTemplateTypes.UserCreated}`
      );

      var plainTextResponse = await axios.get(
        `Templates/Email/Plain-Text/${details?.email?.id}`
      );

      setText(plainTextResponse?.data?.text);
      setHtml(response.data.html);
    };

    if (details?.email?.id) {
      fetch();
    }
  }, [details?.email]);

  const [previewOpen, setPreviewOpen] = useState(false);

  const [show, setShow] = useState(false)

  return (
    <>
    {/*<CreateCampaignPreviewTest senders={senders}
        data={data}
        open={previewOpen}
        setOpen={setPreviewOpen}/>*/}
      <PlainTextPreviewModal
        text={text}
        setOpen={setTextOpen}
        open={textOpen}
      />
      <HtmlPreviewModal
        open={previewHtmlModalOpen}
        setOpen={setPreviewHtmlModalOpen}
        html={html}
      />
      <ResetDesingModal
        open={removeOpen}
        setOpen={setRemoveOpen}
        onConfirm={handleRemoveConfirmed}
      />

      <Card size="small" className={`step-card no-shadow first-card`}>
        <Flex className="w-100" align="center" justify="space-between">
          <Flex align="cesnter" justify="start" gap={12}>
            <div>
              {details?.email ? (
                <div className="step-evaluation-box-success">
                  <FaCheck />
                </div>
              ) : (
                <div className="step-evaluation-box-default"></div>
              )}
            </div>
            <Space direction="vertical" size={0}>
              <Title className="step-card-title zero-margin-padding" level={3}>
                Email Design
              </Title>
              {details?.email ? (
                <Flex    align="center" justify="start">

                <Text className="sender-preview-name">
                  {`${details?.email?.name} • `} 
                  <Text className="view-desing-link" type="link" onClick={() => setShow(prev => !prev)}> {show ? "Hide design" : "View design"}</Text>
                </Text>
                </Flex>
              ) : (
                <Text>Email to send to your client</Text>
              )}
            </Space>
          </Flex>
          {details?.email ? (
            <Flex align="center" justify="end" gap={6}>
              <Dropdown
                trigger={["click"]}
                menu={{
                  items: [
                    {
                      label: (
                        <p className="desing-options-dropdown">
                          View HTML code
                        </p>
                      ),
                      key: 1,
                      onClick: () => setPreviewHtmlModalOpen(true),
                    },
                    {
                      label: (
                        <p className="desing-options-dropdown">
                          View the plain text version
                        </p>
                      ),
                      key: 3,
                      onClick: () => setTextOpen(true),
                    },
                    {
                      label: (
                        <p className="desing-options-dropdown">Reset desing</p>
                      ),
                      key: 2,
                      danger: true,
                      onClick: () => setRemoveOpen(true),
                    },
                  ],
                }}
              >
                <Button
                  className="flex-button"
                  icon={<MdMoreHoriz className="icon" />}
                  size="large"
                ></Button>
              </Dropdown>
              <Button
                size="large"
                onClick={() => {
                  if (details?.email?.isSimple) {
                    navigate(
                      `/Designs/Email/Edit/Simple/${details?.email?.id}`
                    );
                  } else {
                    navigate(`/Designs/Email/Edit/Advanced/${details?.email?.id}`);
                  }
                }}
              >
                Edit design
              </Button>
            </Flex>
          ) : (
            <Button onClick={() => navigate(`Design`)} size="large">
              Start designing
            </Button>
          )}
        </Flex>

        {show && <>
            <div className="design-template-preview">
            <div className="desing-template-preview">
              <Flex
                className="design-template-preview-overlay"
                align="center"
                justify="center"
              >
                <Button
                  size="large"
                  icon={<FaEye />}
                  className="flex-preview-button"
                  
                >
                  Preview & Test
                </Button>
              </Flex>
              <iframe className="desing-frame-template" srcDoc={html}></iframe>
            </div>
          </div>
        </>}
      </Card>
    </>
  );
};

const PlainTextPreviewModal = ({ text, open, setOpen }) => {
  const { Title, Text } = Typography;

  return (
    <>
      <Modal
        open={open}
        onCancel={() => setOpen(false)}
        title={
          <Title style={{ margin: 0 }} level={4}>
            Plain Text Version
          </Title>
        }
        width={800}
        footer={false}
      >
        <Card size="small" className="no-shadow html-preview-box-wrapper">
          <div className="html-preview-box">
            <Text className="html-preview-box-text">{text}</Text>
          </div>
        </Card>
      </Modal>
    </>
  );
};

const HtmlPreviewModal = ({ html, open, setOpen }) => {
  const { Title, Text } = Typography;

  return (
    <>
      <Modal
        open={open}
        onCancel={() => setOpen(false)}
        title={
          <Title style={{ margin: 0 }} level={4}>
            Html code
          </Title>
        }
        width={800}
        footer={false}
      >
        <Card size="small" className="no-shadow html-preview-box-wrapper">
          <div className="html-preview-box">
            <Text code={true} className="html-preview-box-text">
              {html}
            </Text>
          </div>
        </Card>
      </Modal>
    </>
  );
};

const ResetDesingModal = ({ open, setOpen, onConfirm }) => {
  const { Text, Title } = Typography;

  return (
    <Modal
      open={open}
      onCancel={() => setOpen(false)}
      title={
        <Title style={{ margin: 0 }} level={4}>
          Reset desing confirmation
        </Title>
      }
      footer={
        <Flex align="center" justify="end" gap={6}>
          <Button size="large" onClick={() => setOpen(false)}>
            Cancel
          </Button>
          <Button
            size="large"
            type="primary"
            danger
            onClick={() => onConfirm()}
          >
            Confirm
          </Button>
        </Flex>
      }
    >
      <Text>
        Are you sure you want to reset your desing? You will lose all of your
        current content and modifications.
      </Text>
    </Modal>
  );
};

export default EmailStep;
