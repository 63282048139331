import {
  Button,
  Col,
  Flex,
  Form,
  Input,
  Row,
  Select,
  Space,
  Typography,
} from "antd";
import logo from "../../../../assets/images/logo.png";
import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { CompanyStepsContext } from "./CompanyStepsContext";
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate'

const FourthStep = () => {
  const [form] = Form.useForm();
  const [countries, setCountries] = useState();
    const axios = useAxiosPrivate();
  const { Title, Text } = Typography;
  const { setCurrentStep, company, setCompany } =
    useContext(CompanyStepsContext);

  useEffect(() => {
    form.setFieldsValue(company);

    const getCountryCodes = async () => {
      let response = await axios.get('/Address/country-codes');
      setCountries(response.data.items?.map(c => ({
        label : c.name,
        value: c.name,
        code: c.a2Code
      })));
    }

    getCountryCodes();

  }, []);

  const onSubmit = (e) => {

    let code = countries.find(c => c.value == e.country)?.code;
    setCompany({
      ...e,
      countryCode:code
    });
    setCurrentStep((prev) => prev + 1);
  };

  return (
    <>
      <Flex align="center" justify="center" style={{ height: "100vh" }}>
        <Space
          size="large"
          direction="vertical"
          style={{ width: "450px", height: "500px" }}
        >
          <Space className="w-100" size={0} direction="vertical">
            <img src={logo} height={35} />
            <Title style={{ margin: 0 }}>
              Now some information about your company
            </Title>
            <Text style={{ fontSize: 16 }}>
              Don't have a company address yet? Enter the address you want to
              use for your business form.
            </Text>
          </Space>

          <Form onFinish={onSubmit} form={form}>
            <Space className="w-100" direction="vertical" size={0}>
              <Text>Address</Text>
              <Form.Item name="address">
                <Input
                  size="large"
                  placeholder="Enter your address"
                  allowClear
                />
              </Form.Item>
            </Space>

            <Row gutter={[24, 24]}>
              <Col span={12}>
                <Space className="w-100" direction="vertical" size={0}>
                  <Text>Zipcode</Text>
                  <Form.Item name="zip">
                    <Input
                      size="large"
                      placeholder="Enter your zip code"
                      allowClear
                    />
                  </Form.Item>
                </Space>
              </Col>
              <Col span={12}>
                <Space className="w-100" direction="vertical" size={0}>
                  <Text>City</Text>
                  <Form.Item name="city">
                    <Input
                      size="large"
                      placeholder="Enter your city"
                      allowClear
                    />
                  </Form.Item>
                </Space>
              </Col>
            </Row>

            <Space className="w-100" direction="vertical" size={0}>
              <Text>Country</Text>
              <Form.Item name="country">
                <Select
                  showSearch
                  options={countries}
                  size="large"
                  placeholder="Enter your country"
                  allowClear
                />
              </Form.Item>
            </Space>
          </Form>

          <Flex className="w-100" justify="end" gap={6}>
            <Button
              size="large"
              type="link"
              onClick={() => setCurrentStep((prev) => prev - 1)}
            >
              Back
            </Button>
            <Button onClick={() => form.submit()} size="large" type="primary">
              Continue
            </Button>
          </Flex>
        </Space>
      </Flex>
    </>
  );
};

export default FourthStep;
