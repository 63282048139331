import { Col, Row } from "antd"
import { useContext } from "react"
import { BiSolidNotification } from "react-icons/bi"
import { MdFirstPage, MdLastPage } from "react-icons/md"
import { TemplateComponentContext } from "./TemplateComponentContext"
import Stat from "../../shared/Stat"

const TemplateComponentStats = () => {
    const {stats} = useContext(TemplateComponentContext)

    return <>
    <Row gutter={[24, 24]}>
      <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={24}>
        <Stat
          showShadow={false}
          value={stats?.total}
          name="Total"
          icon={
            <BiSolidNotification style={{ fontSize: 22, color: "#273270" }} />
          }
          loading={false}
        />
      </Col>
      <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={24}>
        <Stat
          showShadow={false}
          value={stats?.first}
          name="First"
          icon={<MdFirstPage style={{ fontSize: 24, color: "#273270" }} />}
          loading={false}
        />
      </Col>
      <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={24}>
        <Stat
          showShadow={false}
          value={stats?.last}
          name="Last"
          icon={<MdLastPage style={{ fontSize: 24, color: "#273270" }} />}
          loading={false}
        />
      </Col>
      <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={24}>
      <Stat
          showShadow={false}
          value={stats?.simple}
          name="Just template"
          icon={
            <BiSolidNotification style={{ fontSize: 22, color: "#273270" }} />
          }
          loading={false}
        />
      </Col>
    </Row>
    </>
}

export default TemplateComponentStats