import { Button, Flex, Typography } from "antd";
import "./FiltersDisplay.css";

const FiltersDisplay = ({ filters, setShowFilters }) => {
  const { Text } = Typography;
  return (
    <div className="number-filters-card-wrapper">
      <Flex align="center" justify="start" gap={6}>
        {filters?.selectedCountries && (
          <div size="small" className="number-filters-card">
            <Text>Country {filters?.selectedCountries}</Text>
          </div>
        )}
        {filters?.selectedType && (
          <div size="small" className="number-filters-card">
            <Text>Type {filters?.selectedType}</Text>
          </div>
        )}

        {filters?.limit && (
          <div size="small" className="number-filters-card">
            <Text>Limit {filters?.limit}</Text>
          </div>
        )}

        {filters?.selectedFeatures && (
          <div size="small" className="number-filters-card">
            <Text>
              Features{" "}
              {filters?.selectedFeatures?.map((c, i) => (
                <Text key={i}>
                  {c}
                  {i < filters?.selectedFeatures?.length - 1 ? ", " : ""}{" "}
                </Text>
              ))}
            </Text>
          </div>
        )}
      </Flex>

      <Button
        type="link"
        className="large-font new-buttons"
        onClick={() => setShowFilters((prev) => !prev)}
      >
        Edit Filters
      </Button>
    </div>
  );
};

export default FiltersDisplay;
