import { Button, Dropdown, Flex, message } from "antd";
import { BsCloudDownloadFill, BsFillTrash3Fill } from "react-icons/bs";
import { FaRegCopy } from "react-icons/fa";
import { IoEllipsisHorizontal } from "react-icons/io5";

const MediaItemDropDown = ({ id, name, onDelete, onDownload }) => {
  const handleCopy = async (textToCopy) => {
    try {
      await navigator.clipboard.writeText(textToCopy);
      message.success("Download link copied to clipborad!");
    } catch (err) {
      message.error(err);
    }
  };

  const items = [
    {
      label: <p className="drop-down-text">Copy url</p>,
      key: "2",
      icon: <FaRegCopy className="drop-down-icon"/>,
      onClick: () => handleCopy(`${process.env.REACT_APP_FILE_URL}${name}`),
    },
    {
      type: "divider",
    },
    {
      label: <p className="drop-down-text">Delete</p>,
      key: "3",
      danger: true,
      icon: <BsFillTrash3Fill className="drop-down-icon" />,
      onClick: () => onDelete(id),
    },
  ];

  return (
    <>
      <Flex className="w-100 h-100" align="center" justify="end" gap={12}>
        <Button
          type="text"
          onClick={() => onDownload(id)}
          icon={<BsCloudDownloadFill className="icon" />}
          className="flex-button"
        ></Button>
        <Dropdown trigger={["click"]} menu={{ items }}>
          <Button
            type="text"
            className="flex-button"
            icon={<IoEllipsisHorizontal style={{ fontSize: 26 }} />}
          ></Button>
        </Dropdown>
      </Flex>
    </>
  );
};

export default MediaItemDropDown;
