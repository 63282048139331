import axios from "../api/axios";
import useAuth from "./useAuth";

const useRefreshToken = () => {
  const { setAuth } = useAuth();


  const refresh = async () => {

    const response = await axios.post("/Account/refreshtoken", {
    });

    if(response.data.success){

      setAuth((prev) => {
        return {
          ...prev,
          id: parseInt(response?.data?.data?.id),
          roles: response.data.data.roles,
          accessToken: response.data.data.jwToken,
          emailConfirmed: response.data.data.isVerified,
          step: response.data.data.step,
          email: response.data.data.email,
        };
      });
    }
    
    return response?.data?.data?.jwToken;
  };
  return refresh;
};

export default useRefreshToken;
