import {
  Button,
  Drawer,
  Flex,
  Form,
  Input,
  Select,
  Space,
  Typography,
} from "antd";

const AddPersonalizationDrawer = ({ open, setOpen, onAdd }) => {
  const { Text } = Typography;

  const handleAddUserdata = (e) => {
    const uniqueId = Date.now();

    if(e.value == 1){
      onAdd(getButton(uniqueId, e.empty, "Fullname" ))
    }else if(e.value == 2){
      onAdd(getButton(uniqueId, e.empty, "Firstname" ))
    }else if(e.value == 3){
      onAdd(getButton(uniqueId, e.empty, "Lastname" ))
    }else if(e.value == 4){
      onAdd(getButton(uniqueId, e.empty, "Email" ))
    }else if(e.value == 5){
      onAdd(getButton(uniqueId, e.empty, "Phone" ))
    }

    handleClose();
  };

  const getButton = (id, emptyText, text) => `<span id="span-${id}"
   contenteditable="false"
   style="cursor:move !important; font-weight: 500 !important; background-color:#f1f1f1; margin-left:5px; padding:0px 0px 3px 10px; border-radius: 15px;" data-empty="${emptyText}">
   <span style="margin-right:5px">${text}</span>
    <button onclick="document.getElementById('span-${id}').remove()">X
    </button>
  </span>`

  const [form] = Form.useForm();

  const items = [
    {
      label: <p className="drop-down-text">User fullname</p>,
      value: 1,
    },
    {
      label: <p className="drop-down-text">User firstname</p>,
      value: 2,
    },
    {
      label: <p className="drop-down-text">User lastname</p>,
      value: 3,
    },
    {
      label: <p className="drop-down-text">User email</p>,
      value: 4,
    },
    {
      label: <p className="drop-down-text">User phone number</p>,
      value: 5,
    },
  ];

  const handleClose = () => {
    form.resetFields()
    setOpen(false);
  }
  return (
    <Drawer 
    destroyOnClose
      title="Insert personalization"
      open={open}
      onClose={handleClose}
    >
      <Flex
        className="w-100 h-100"
        align="center"
        justify="space-between"
        vertical
      >
        <Form form={form} onFinish={handleAddUserdata}>
          <Space className="w-100" size="large" direction="vertical">
            <Space className="w-100" size={0} direction="vertical">
              <Text className="thick-lable">
                Which attribute would you like to insert?*
              </Text>
              <Form.Item
                name="value"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select
                  placeholder="Choose attribute"
                  size="large"
                  className="w-100"
                  options={items}
                />
              </Form.Item>
            </Space>

            <Space className="w-100" size={0} direction="vertical">
              <Text className="thick-lable">
                Which value should be used if the attribute is empty?
              </Text>

              <Form.Item
                name="empty"
                rules={[
                  {
                    required: true,
                  },
                  {
                    min: 1,
                  },
                  { max: 10 },
                ]}
              >
                <Input
                  size="large"
                  className="w-100"
                  placeholder="Enter value for empty"
                  allowClear
                  count={{
                    show: true,
                    max: 10,
                  }}
                />
              </Form.Item>
            </Space>
          </Space>
        </Form>

        <Flex className="w-100" align="center" justify="end" gap={6}>
          <Button className="new-buttons large-font" onClick={handleClose} size="large">
            Cancel
          </Button>
          <Button className="new-buttons large-font" onClick={() => form.submit()} size="large" type="primary">
            Insert
          </Button>
        </Flex>
      </Flex>
    </Drawer>
  );
};

export default AddPersonalizationDrawer;
