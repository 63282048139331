import { Space } from "antd";
import Audience from "./Audience";
import MoreDetails from "./MoreDetails";
import { useEffect, useState } from "react";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import SmsMoreData from "./SmsMoreData";
import SmsAudience from "./SmsAudience";
import CardLoading from "../../../shared/CardLoading";

const SecondPage = ({ campaign, handlePrint }) => {
  const [data, setData] = useState();

  const axios = useAxiosPrivate();
  const [loading, setLoading] = useState(() => false)

  useEffect(() => {

    const fetch  = async () => {

      setLoading(true)
      var response;
      
      if (campaign.type == 0) {
        response = await axios.get(
          `/Campaign/GetCampaignExtended?Id=${campaign.id}`
        );
      } else {
        response = await axios.get(`/Campaign/${campaign.id}/sms/extended`);
      }
      
      setData(response.data.data);
      setLoading(false)
    }

    fetch();
  }, []);

  return (
    <>
  {loading&&<Space className="w-100" direction="vertical">
  <CardLoading height={200}/>
  <CardLoading height={400}/>
    </Space>}

      {(data && !loading) && (
        <>
          {campaign.type == 0 && (
            <Space direction="vertical" style={{ width: "100%" }}>
              <Audience details={data} handlePrint={handlePrint} />
              <MoreDetails details={data} />
            </Space>
          )}

          {campaign.type == 2 && (
            <Space direction="vertical" style={{ width: "100%" }}>
              <SmsAudience details={data} handlePrint={handlePrint} />              
              <SmsMoreData details={data} />
            </Space>
          )}
        </>
      )}
    </>
  );
};

export default SecondPage;
