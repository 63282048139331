import { createContext, useContext, useEffect, useState } from "react";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { SearchContext } from "../../../context/SearchContext";
import { OrderDirection } from "../../../shared/enums";
import QueryString from "qs";
import { useParams } from "react-router-dom";

export const NotificationTemplatesContext = createContext({
  filters: {
    from: null,
    to: null,
  },
  setFilters: () => {},

  stats: {
    total: 0,
    saved: 0,
  },
  setStats: () => {},

  groups: [],
  setGroups: () => {},

  page: 1,
  setPage: () => {},

  pageSize: 25,
  setPageSize: () => {},

  loading: false,
  setLoading: () => {},
});

export const NotificationTemplatesContextProvider = ({ children }) => {
  const axios = useAxiosPrivate();
  const { debouncedValue } = useContext(SearchContext);
  const [filters, setFilters] = useState(() => ({
    orderDirection: OrderDirection.Dsc,
    orderBy: "Created"
  }));
  const [stats, setStats] = useState();
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [groups, setGroups] = useState();

  const [pageSize, setPageSize] = useState(25);

  const { authorId } = useParams();

  useEffect(() => {
    const fetch = async () => {
      setLoading(true);
      const response = await axios.get("/Notification/Templates/List", {
        params: {
          search: debouncedValue,
          orderBy: filters?.orderBy,
          orderDirection: filters?.orderDirection,
          from: filters?.from ? new Date(filters?.from) : null,
          to: filters?.to ? new Date(filters?.to) : null,
          page: page,
          pageSize: pageSize,
          authorId: authorId,
        },
        paramsSerializer: (params) => {
          return QueryString.stringify(params, {
            arrayFormat: "repeat",
            skipNulls: true,
          });
        },
      });
      setGroups(response.data.items);

      setStats((prev) => ({
        total: response.data.total,
        first: response.data.first,
        last: response.data.last,
        saved: response.data.saved,
      }));

      setLoading(false);
    };
    fetch();
  }, [debouncedValue, filters, page, pageSize]);

  return (
    <NotificationTemplatesContext.Provider
      value={{
        filters,
        setFilters,

        stats,
        setStats,

        page,
        setPage,

        loading,
        setLoading,

        groups,
        setGroups,

        pageSize,
        setPageSize,
      }}
    >
      {children}
    </NotificationTemplatesContext.Provider>
  );
};
