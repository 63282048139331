import { Button } from "antd"
import { useState } from "react"
import useAxiosPrivate from "../../../../../hooks/useAxiosPrivate";

const DownloadDealFileButton = ({ id }) => {

    const [loading, setLoading] = useState(false);

    const axios = useAxiosPrivate();

    const downloadFile = async () => {
        setLoading(true);

        const response = await axios.get(`/DealFile/Download/${id}`, {
            responseType: 'blob',
          });


          let fileName = 'downloaded-file';
          const disposition = response.headers['content-disposition'];
          if (disposition) {
            const fileNameMatch = disposition.match(/filename="?([^"]+)"?/);
            if (fileNameMatch && fileNameMatch.length > 1) {
              fileName = fileNameMatch[1];
            }
          }
    
          // 3. Create a blob from the response data
          const blobUrl = window.URL.createObjectURL(new Blob([response.data]));
    
          // 4. Create a temporary link element and trigger the download
          const link = document.createElement('a');
          link.href = blobUrl;
          link.setAttribute('download', fileName); // set the filename
          document.body.appendChild(link);
          link.click();
    
          // 5. Clean up
          link.remove();
          window.URL.revokeObjectURL(blobUrl);

        setLoading(false)
    }

    return <>
        <Button className="bold" 
            loading={loading} 
            onClick={downloadFile}
            type="link">Download</Button>

    </>
}

export default DownloadDealFileButton