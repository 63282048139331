import React from 'react'
import { MdEmail, MdLunchDining, MdMeetingRoom } from "react-icons/md";
import { FaFlag, FaPhone } from "react-icons/fa6";
import { TaskStatus } from './enums';
import { FaCheckCircle } from 'react-icons/fa';
const getTaskTypeIcon = (type,size,color, cName) => {
    if(type === TaskStatus.ToDo) return <FaCheckCircle className={cName} size={size} style={{color:color}}/>
    if(type === TaskStatus.Call) return <FaPhone className={cName} size={size} style={{color:color}}/>
    if(type === TaskStatus.Deadline) return <FaFlag className={cName} size={size} style={{color:color}}/>
    if(type === TaskStatus.Email) return <MdEmail className={cName}  size={size} style={{color:color}}/>
    if(type === TaskStatus.Lunch) return <MdLunchDining className={cName} size={size} style={{color:color}}/>
    if(type === TaskStatus.Meeting) return <MdMeetingRoom className={cName} size={size} style={{color:color}}/>
 
}

export default getTaskTypeIcon