import { createContext, useEffect, useState } from "react";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import useAuth from "../hooks/useAuth";
import AuthorSelectListItem from "../shared/AuthorSelectListItem";

export const AuthorContext = createContext({
    authors: [],
    setAuthors: () => {},
    you: null,
    setYou: () => {}
})

export const AuthorContextProvider = ({children}) => {
    const {auth} = useAuth();
    const [authors, setAuthors] = useState();
    const [you, setYou] = useState(() => auth.id);

    const axios = useAxiosPrivate();

    useEffect(() => {

        const fetch = async () => {
            const response = await axios.get(`Users/GetAuthors`);
            
            setAuthors(response.data.data.map((d) => ({
                value: d.id,
                firstname: d.firstname,
                lastname:d.lastname,
                profile: d.profileImagePath,
                email: d.email,
                online: d.online,
                label: (<AuthorSelectListItem firstname={d.firstname} lastname={d.lastname} profile={d.profileImagePath}/>),
            })))
        }
        
        fetch();

    }, [])

    const getAuthor = (id) => {
        let author = authors.find(c => c.value == id);

        return author;
    }
    
    return <AuthorContext.Provider value={{
        authors,
        you,
        getAuthor
    }}>
        {children}
    </AuthorContext.Provider>
}