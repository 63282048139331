import { createContext, useContext, useEffect, useState } from "react";
import { OrderDirection } from "../../shared/enums";
import { SearchContext } from "../../context/SearchContext";
import QueryString from "qs";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { DateFormat } from "../../dateformat";

export const TemplateComponentContext = createContext({
  filters: {
    from: null,
    to: null,
    selectedAuthors: [],
    orderBy: "created",
    orderDirection: OrderDirection.Dsc,
  },
  setFilters: () => {},

  stats: {
    total: 0,
    first: null,
    last: null,
    simple: 0,
  },
  setStats: () => {},

  templates: [],
  setTemplates: () => {},

  page: 1,
  setPage: () => {},

  loading: false,
  setLoading: () => {},
});

export const TemplateComponentContextProvider = ({ children }) => {
  
  const [filters, setFilters] = useState(() => ({
    from: null,
    to: null,
    orderBy: "created",
    orderDirection: OrderDirection.Dsc,
  }));


  const [stats, setStats] = useState({
    total: 0,
    first: null,
    last: null,
    simple: 0,
  });
  const [templates, setTemplates] = useState();
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const perPage = 7;
  const axios = useAxiosPrivate();
  const { debouncedValue } = useContext(SearchContext);

  useEffect(() => {
    setLoading(true);
    const controller = new AbortController();
    const signal = controller.signal;
    const fetch = async () => {
      let response = await axios.get(`/Template-Component`, {
        params: {
          search: debouncedValue,
          orderBy: filters?.orderBy,
          direction: filters?.orderDirection,
          page: page,
          pageSize: perPage,
          from: filters?.from ? new Date(filters.from) : null,
          to: filters?.to ? new Date(filters?.to) : null,
        },
        signal: signal,
        paramsSerializer: (params) => {
          return QueryString.stringify(params, {
            arrayFormat: "repeat",
            skipNulls: true,
          });
        },
      });

      setStats((prev) => ({
        total: response.data.total,
        first: response.data.first ? new Date(response.data.first * 1000).toLocaleDateString("en-us", DateFormat) : "-",
        last: response.data.last ? new Date(response.data.last * 1000).toLocaleDateString("en-us", DateFormat) : "-",
        simple: response.data.regular,
      }));

      setTemplates(response.data.items);
      setLoading(false);
    };

    fetch();

    return () => controller.abort();
  }, [filters, page, debouncedValue]);
  
  return (
    <TemplateComponentContext.Provider  value={{
      filters,
      setFilters,
      loading,
      setLoading,
      templates,
      setTemplates,
      page,
      setPage,
      stats,
      setStats,
    }}>
      {children}
    </TemplateComponentContext.Provider>
  );
};
