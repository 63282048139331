import { useContext } from "react";
import { TemplateContext } from "./TemplateContext";
import { Flex, Tag } from "antd";

const TemplateStats = () => {
  const { stats } = useContext(TemplateContext);
  return (
    <>
      <Flex align="center" justify="start">
        <Tag size="small" className="all-stats-tag default-stat-tag">
          {stats?.total ?? 0} Total
        </Tag>
        <Tag size="small" className="all-stats-tag default-stat-tag">
          {stats?.simple ?? 0} Simple
        </Tag>
      </Flex>
    </>
  );
};

export default TemplateStats;
