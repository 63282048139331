import {
  Avatar,
  Badge,
  Button,
  Card,
  Col,
  Flex,
  message,
  Row,
  Space,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import ContactStats from "./ContactStats";
import { MdCall, MdEmail } from "react-icons/md";
import { FiPlus } from "react-icons/fi";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import ContactToDateFilter from "./Filters/ContactToDateFilter";
import { IoClose } from "react-icons/io5";
import { DateFormat } from "../../dateformat";
import { useContext } from "react";
import { AudienceContext } from "./AudienceContext";
import NoDataFound from "../../shared/NoDataFound";
import TableLoading from "../../shared/TableLoading";
import ContactOptions from "./ContactOptions";
import ContactTagsFilter from "./Filters/ContactTagsFilter";
import ProfileAvatar from "../../components/ProfileAvatar";
import DeleteConfirmationModal from "../../components/DeleteConfirmationModal";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import AddTagToContactSider from "./AddTagToContactSider";
import CreateContactDrawer from "./Create/CreateContactDrawer";
import EditContactDrawer from "./Edit/EditContactDrawer";
import "./ContactList.css";
import SendEmailModal from "../../shared/SendEmailModal";
import { AiFillEdit } from "react-icons/ai";
import SimplePagination from "../../shared/SimplePagination";
import { UserOutlined } from "@ant-design/icons";
import AssignContactOwnerDrawer from "./Details/AssignContactOwnerDrawer";
import ContactAuthorFilter from "./Filters/ContactAuthorFilter";
import ContactFilters from "./Filters/ContactFilters";
import ContactDealsDrawer from "./ContactDealsDrawer";

const ContactList = () => {
  const navigate = useNavigate();

  const axios = useAxiosPrivate();

  const {
    perPage,
    setPerPage,
    loading,
    contacts,
    setContacts,
    page,
    setPage,
    stats,
    setStats,
    filters,
    setFilters,
    setTags
  } = useContext(AudienceContext);

  const { Text, Title } = Typography;
  const [current, setCurrent] = useState();
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [addOpen, setAddOpen] = useState(false);
  const [sendOpen, setSendOpen] = useState(false);
  const [dealsOpen, setDealsOpen] = useState(false);

  const handleDelete = async (item, confirmed) => {
    if (!confirmed) {
      return;
    }

    const response = await axios.delete(`/Contacts/${item}`);

    if (response.data.success) {
      setContacts((prev) => prev.filter((c) => c.id != item));
      setStats((prev) => ({ ...prev, total: prev.total - 1 }));
    } else {
      message.error(response.data.message);
    }
  };

  const deleteFromTag = async (tagId, contactId) => {
    const response = await axios.delete(
      `/Contacts/Remove-From-Tag/${tagId}/${contactId}`
    );

    if (response.data.success) {
      setContacts((prev) =>
        prev.map((c) =>
          c.id == contactId
            ? {
                ...c,
                tags: c.tags.filter((t) => t.id != tagId),
              }
            : c
        )
      );
    } else {
      message.error(response.data.message);
    }
  };

  const [editTagsOpen, setEditTagsOpen] = useState(false);

  const onAssigned = (id, newTags) => {
    setContacts((prev) =>
      prev.map((c) =>
        c.id == id
          ? {
              ...c,
              tags: newTags,
            }
          : c
      )
    );
  };

  const onEdit = (item) => {
    setContacts((prev) =>
      prev.map((c) =>
        c.id == item.id
          ? {
              ...c,
              ...item,
            }
          : c
      )
    );
  };


  const handleSendClicked = (c, e) => {
    c.stopPropagation();
    setCurrent(e);
    setSendOpen(true);
  };

  const [assignOpen, setAssignOpen] = useState(false);

  const handleOwnerClick = (e, id) => {
    e.stopPropagation();
    navigate(`/user/details/${id}`);
  };

  const handleAssignClick = (e, c) => {
    e.stopPropagation();
    setCurrent(c);
    setAssignOpen(true);
  };

  const handleOwnerUpdate = (e) => {
    setContacts((prev) =>
      prev.map((c) =>
        c.id == e.contactId
          ? {
              ...c,
              ownerId: e.ownerId,
              ownerName: e.ownerName,
              ownerProfile: e.ownerProfile,
            }
          : c
      )
    );
  };


  const onDealsClicked = (contact) => {
    setCurrent(contact);
    setDealsOpen(true);
  };

  const onAdded = (e) => {
    setStats((prev) => ({
      ...prev,
      total: prev.total + 1,
    }));

    setContacts((prev) => [e, ...prev]);
  };

  return (
    <>
      <ContactDealsDrawer
        id={current?.id}
        open={dealsOpen}
        setOpen={setDealsOpen}
      />
      <AssignContactOwnerDrawer
        defaultValue={current?.ownerId}
        contactId={current?.id}
        onUpdated={handleOwnerUpdate}
        open={assignOpen}
        setOpen={setAssignOpen}
      />
      <SendEmailModal
        contactId={current?.id}
        open={sendOpen}
        setOpen={setSendOpen}
      />
      <DeleteConfirmationModal
        isOpen={deleteOpen}
        onClose={() => setDeleteOpen(false)}
        onDeleteConfirm={handleDelete}
        item={current?.id}
      />

      <EditContactDrawer
        onEdit={onEdit}
        item={current}
        open={editOpen}
        setOpen={setEditOpen}
      />

      <AddTagToContactSider
        onUpdate={onAssigned}
        setOpen={setEditTagsOpen}
        open={editTagsOpen}
        contact={current}
      />

      <CreateContactDrawer
        open={addOpen}
        setOpen={setAddOpen}
        onAdd={onAdded}
      />
      <Flex align="center" justify="space-between">
        <Title className="zero-margin-padding">Contacts</Title>

        <Flex align="center" justify="end" gap={6}>
          <Button
            type="primary"
            size="large"
            onClick={() => setAddOpen(true)}
            className="new-buttons large-font"
          >
            Create contact
          </Button>

          <Button
            type="primary"
            size="large"
            onClick={() => navigate("/contacts/import")}
            className="new-buttons large-font"
          >
            Import contacts
          </Button>
        </Flex>
      </Flex>

      <ContactStats />

      <div
        style={{
          position: "sticky",
          top: 0,
          zIndex: 99,
          background: "#fff",
          height: 50,
          borderBottom: "1px solid hsl(0, 0%, 89%)",
        }}
      >
        <Flex className="h-100" align="center" justify="space-between" gap={6}>
          <Flex align="center" justify="start" gap={6}>
            <ContactToDateFilter />
            <ContactAuthorFilter />
            <ContactFilters filters={filters} setFilters={setFilters} />
            <ContactTagsFilter />
          </Flex>
          <SimplePagination
            page={page}
            pageSize={perPage}
            total={stats?.total}
            setPage={setPage}
          />
        </Flex>
      </div>

      <Space className="w-100" direction="vertical" style={{ marginTop: 12 }}>
        {loading ? (
          <TableLoading />
        ) : !contacts || contacts.length == 0 ? (
          <NoDataFound
            addText="New contacts"
            onAdd={() => navigate("/contacts/import")}
            description="Click the New Contact button on the Contacts page to create a new contact"
            title="You have not created any contacts yet"
          />
        ) : (
          <>
            {contacts?.map((c, ind) => (
              <Card
                size="small"
                className="no-shadow gray-border-hoverable"
                key={ind}
                onClick={() => navigate(`/contacts/${c.id}`)}
              >
                <Row gutter={[24, 24]} style={{ height: "100%" }}>
                  <Col span={12} className="center-table-column">
                    <Flex align="center" justify="start" gap={12}>
                      <Badge
                        size="large"
                        count={c.missedCalls}
                        offset={[-10, 10]}
                      >
                        <ProfileAvatar
                          index={ind}
                          size={75}
                          font={30}
                          name={`${c.firstname} ${c.lastname}`}
                        />
                      </Badge>
                      <Space className="w-100" direction="vertical" size={0}>
                        <Flex gap={6}>
                          <Text className="contact-title">
                            {c.firstname ? c.firstname : "-"}{" "}
                            {c.lastname ? c.lastname : "-"}
                          </Text>
                          <Flex>
                            {c.new && (
                              <Tag color="#108ee9" className="contact-tag-safe">
                                NEW
                              </Tag>
                            )}
                            {c.emailStatus == 1 ? (
                              <Tag color="#1cc88a" className="contact-tag-safe">
                                SAFE
                              </Tag>
                            ) : (
                              ""
                            )}
                          </Flex>
                        </Flex>
                        <Text>
                          {`#${(page - 1) * perPage + ind + 1}`} {c.email}{" "}
                          {`• ${c.phone ?? "No phone"}`}
                        </Text>

                        <Flex align="center" justify="start" gap={6}>
                          <Flex align="center" justify="start" gap={6}>
                            {c?.ownerId ? (
                              <Flex
                                className="contact-list-owner-flex"
                                onClick={(e) => handleOwnerClick(e, c.ownerId)}
                                align="center"
                                justify="start"
                                gap={6}
                              >
                                <Text>Owner: </Text>

                                <Avatar
                                  src={`${process.env.REACT_APP_FILE_URL}${c.ownerProfile}`}
                                  size="small"
                                  icon={<UserOutlined />}
                                />
                                <Text type="link">{c.ownerName}</Text>
                              </Flex>
                            ) : (
                              <Button
                                type="link"
                                className="bold no-left-padding-margin no-right-padding-margin"
                                onClick={(e) => handleAssignClick(e, c)}
                              >
                                Assign owner
                              </Button>
                            )}
                          </Flex>{" "}
                          •
                          <Text ellipsis>
                            {new Date(c.createdAt).toLocaleDateString(
                              "en-us",
                              DateFormat
                            )}

                            {c?.lastUpdate &&
                              ` • Last update ${new Date(
                                c?.lastUpdate
                              ).toLocaleDateString("en-us", DateFormat)}`}
                          </Text>
                        </Flex>
                      </Space>
                    </Flex>
                  </Col>
                  <Col span={8} className="center-table-column">
                    {c.tags?.slice(0, 1).map((d, i) => (
                      <Text key={i} className="contact-tag">
                        <Flex gap={12} align="center" justify="space-between">
                          {d.name}

                          <Button
                            className="flex-button contact-tag-button"
                            danger
                            onClick={(e) => {
                              e.stopPropagation();
                              deleteFromTag(d.id, c.id);
                            }}
                            type="text"
                            size="small"
                            icon={<IoClose className="icon" />}
                          ></Button>
                        </Flex>
                      </Text>
                    ))}
                    {c.tags?.length > 1 ? (
                      <Text className="contact-tag">
                        + {c.tags.length - 1} more...
                      </Text>
                    ) : (
                      ""
                    )}

                    <Button
                      className="flex-button contact-tag-add-button"
                      onClick={(e) => {
                        e.stopPropagation();
                        setCurrent(c);
                        setEditTagsOpen(true);
                      }}
                      icon={<FiPlus className="icon" />}
                    />
                  </Col>
                  <Col span={4}>
                    <Flex
                      gap={6}
                      align="center"
                      justify="end"
                      className="w-100 h-100"
                    >
                      <Tooltip title="Edit">
                        <Button
                          size="large"
                          onClick={(e) => {
                            e.stopPropagation();
                            setCurrent(c);
                            setEditOpen(true);
                          }}
                          icon={
                            <AiFillEdit className="campaign-options-icon" />
                          }
                          className="flex-button"
                          type="link"
                        ></Button>
                      </Tooltip>

                      <Tooltip title="Send email">
                        <Button
                          size="large"
                          icon={<MdEmail className="campaign-options-icon" />}
                          className="flex-button"
                          onClick={(e) => handleSendClicked(e, c)}
                          type="text"
                        ></Button>
                      </Tooltip>

                      <Tooltip title="Call">
                        <Button
                          size="large"
                          icon={<MdCall className="campaign-options-icon" />}
                          className="flex-button"
                          onClick={(e) => {
                            e.stopPropagation();
                            navigate(`/dial/number?number=${c.phone}`);
                          }}
                          type="text"
                        ></Button>
                      </Tooltip>

                      <ContactOptions
                        contact={c}
                        onDealsClicked={onDealsClicked}
                        onSend={handleSendClicked}
                        setCurrent={setCurrent}
                        openEdit={() => setEditOpen(true)}
                        openDelete={() => setDeleteOpen(true)}
                      />
                    </Flex>
                  </Col>
                </Row>
              </Card>
            ))}

            <SimplePagination
              showChanger
              page={page}
              pageSize={perPage}
              total={stats?.total}
              setPage={setPage}
              setPerPage={setPerPage}
            />
          </>
        )}
      </Space>
    </>
  );
};

export default ContactList;
