import { Button, Flex, notification, Typography } from "antd";
import "./Calling.css";
import UserAvatar from "./UserAvatar";
import dialAudio from "../../assets/audio/dial-sound.mp3";
import { useEffect, useRef, useState } from "react";
import { ImPhoneHangUp } from "react-icons/im";
import endCallSound from "../../assets/audio/end-call.mp3";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { MdCall } from "react-icons/md";
import ringtone from "../../assets/audio/ringtone.mp3";

const Calling = ({ beingCalled, onHangup, activeCall }) => {
  const { Text } = Typography;

  const audioRef = useRef(null);
  const [loading, setLoading] = useState(false);

  const axios = useAxiosPrivate();

  const [contact, setContact] = useState();

  useEffect(() => {
    const contrller = new AbortController();
    const signal = contrller.signal;

    const fetch = async () => {
      let number = beingCalled
        ? activeCall?.options?.remoteCallerNumber
        : activeCall?.options?.destinationNumber;
      const response = await axios.get(`/Contact/getbyphone/${number}`, {
        signal: signal,
      });

      if (response.data.success) {
        setContact(response.data);
      }
    };

    fetch();

    return () => contrller.abort();
  }, []);

  useEffect(() => {
    function playAudio() {
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
      }

      const audioSource = beingCalled ? ringtone : dialAudio;
      audioRef.current = new Audio(audioSource);
      audioRef.current.play();
    }

    playAudio();

    const interval = setInterval(playAudio, 5000);

    return () => {
      clearInterval(interval);
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
      }
    };
  }, []);

  const stopAudio = () => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    }
  };

  const handleHangup = () => {
    setLoading(true);
    stopAudio();
    const audio = new Audio(endCallSound);
    audio.play();

    audio.onended = () => {
      onHangup();
    };
  };

  const anwserCall = async () => {
    activeCall.answer();

    const response = await axios.post(`/call/answer`, {
      callSessionId: activeCall.options.telnyxSessionId
    });
  };

  return (
    <>
      <Flex align="center" justify="center" style={{ marginTop: 12 }}>
        <Text strong style={{ fontSize: 18 }}>
          {beingCalled ? "You have a call" : "Calling...."}
        </Text>
      </Flex>
      <Flex className="w-100 h-100" align="center" justify="center">
        {beingCalled ? (
          <UserAvatar
            name={
              contact?.firstname || contact?.lastname
                ? `${contact?.firstname ?? ""} ${contact?.lastname ?? ""}`
                : "Unknown"
            }
            pulse
            phone={activeCall?.options?.remoteCallerNumber}
          />
        ) : (
          <UserAvatar
            name={
              contact?.firstname || contact?.lastname
                ? `${contact?.firstname ?? ""} ${contact?.lastname ?? ""}`
                : "Unknown"
            }
            pulse
            phone={activeCall?.options?.destinationNumber}
          />
        )}
      </Flex>
      <Flex
        gap={12}
        align="center"
        justify="center"
        style={{ marginBottom: 10 }}
      >
        {beingCalled && (
          <Button
            onClick={anwserCall}
            className="accept-call-button"
            size="large"
            type="primary"
          >
            <MdCall />
          </Button>
        )}
        <Button
          loading={loading}
          className="dissmis-call-button"
          onClick={handleHangup}
        >
          <ImPhoneHangUp />
        </Button>
      </Flex>
    </>
  );
};

export default Calling;
