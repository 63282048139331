import {
  NotificationTemplatesContextProvider,
} from "./NotificationTemplatesContext";
import NotificationTemplatesList from "./NotificationTemplatesList";

const NotificationTemplatesLayout = () => {
  return (
    <NotificationTemplatesContextProvider>
      <NotificationTemplatesList />
    </NotificationTemplatesContextProvider>
  );
};

export default NotificationTemplatesLayout;
