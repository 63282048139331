import { Button, Card, Flex, Space, Table, Typography } from "antd";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useEffect, useState } from "react";
import { FiPlus } from "react-icons/fi";
import AddCoupon from "./AddCoupon";
import { BsEyeFill, BsEyeSlashFill } from "react-icons/bs";
import { DateFormat } from "../../dateformat";
import CouponOptions from "./CouponOptions";
import { MdOutlineClose } from "react-icons/md";
import { FaCheck, FaCopy } from "react-icons/fa";
import { TbFlagSearch } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import { IoInfiniteSharp } from "react-icons/io5";
import { IoMdInfinite } from "react-icons/io";

const ManageCoupons = () => {
  const { Title, Text } = Typography;
  const [addModalOpen, setAddModalOpen] = useState(() => false);

  const [page, setPage] = useState(1);
  const perPage = 10;

  const [coupons, setCoupons] = useState();

  const axios = useAxiosPrivate();
  const [totalRows, setTotalRows] = useState(() => 0);
  useEffect(() => {

    const fetch = async () => {

    const response = await axios.get(`/Coupon/${page}/${perPage}`);

    setTotalRows(response.data.count);
    setCoupons(response.data.data.map((c) => ({ ...c, showCode: false })));
    }

    fetch();
  }, [page]);

  const onDeleted = (id) => {
    setCoupons((prev) => prev.filter((c) => c.id != id));
  };

  const handleSuspended = (id) => {
    setCoupons((prev) =>
      prev.map((c) =>
        c.id == id ? { ...c, suspended: !c.suspended } : { ...c }
      )
    );
  };

  const showCode = (id) => {
    setCoupons((prev) =>
      prev.map((c) =>
        c.id == id ? { ...c, showCode: !c.showCode } : { ...c, showCode: false }
      )
    );
  };

  const navigate = useNavigate();

  const columns = [
    {
      title: "Name",
      render: (row) => (
        <Text
          strong
          type="link"
          onClick={() => navigate(`/Manage/Coupon/${row.id}`)}
        >
          {row.name}
        </Text>
      ),
    },
    {
      title: "Code",
      render: (row) => (
        <Flex align="center" justify="start" gap={6}>
          <Button
            shape="circle"
            icon={!row.showCode ? <BsEyeFill /> : <BsEyeSlashFill />}
            onClick={() => showCode(row.id)}
            className="flex-button"
            type="text"
          ></Button>
          {row.showCode && (
            <Text copyable strong>
              {row.code}
            </Text>
          )}
          {!row.showCode && <Text strong>******************************</Text>}
        </Flex>
      ),
    },
    {
      title: "Audience",
      width: 50,
      render: (row) => (
        <Flex align="center" justify="center">
          {!row.worksOnAudience && (
            <div
              style={{
                width: 25,
                height: 25,
                borderRadius: 25,
                backgroundColor: "#e74a3b",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <MdOutlineClose style={{ color: "white", fontSize: 18 }} />
            </div>
          )}
          {row.worksOnAudience && (
            <div
              style={{
                width: 25,
                height: 25,
                borderRadius: 25,
                backgroundColor: "#1cc88a",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <FaCheck style={{ color: "white" }} />
            </div>
          )}
        </Flex>
      ),
    },
    {
      title: "A. Valid",
      render: (row) => (
        <Text strong>
          {row.audienceCouponLifetime == 1
            ? "1 Month"
            : row.audienceCouponLifetime == 2
            ? "6 Months"
            : row.audienceCouponLifetime == 3
            ? "1 Year"
            : row.audienceCouponLifetime == 4
            ? "Forever"
            : "-"}
        </Text>
      ),
    },
    {
      width: 128,
      title: "Usage/Max",
      render: (row) => (
        <Flex align="center" justify="center">
          <Text strong>
            <Flex align="center" justify="center">
              {row.noUsages} /{" "}
              {row.maxUsages ?? (
                <IoMdInfinite style={{ fontSize: 20, marginRight: "5px" }} />
              )}
            </Flex>
          </Text>
        </Flex>
      ),
    },
    {
      title: "Expires",
      render: (row) => (
        <Text strong>
          {row.validTo &&
            new Date(row.validTo).toLocaleDateString("en-US", DateFormat)}
          {!row.validTo && <IoMdInfinite style={{ fontSize: 22 }} />}
        </Text>
      ),
    },
    {
      title: "Live",
      width: 50,
      render: (row) => (
        <Flex align="center" justify="center">
          {row.suspended && (
            <div
              style={{
                width: 25,
                height: 25,
                borderRadius: 25,
                backgroundColor: "#e74a3b",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <MdOutlineClose style={{ color: "white", fontSize: 18 }} />
            </div>
          )}
          {!row.suspended && (
            <div
              style={{
                width: 25,
                height: 25,
                borderRadius: 25,
                backgroundColor: "#1cc88a",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <FaCheck style={{ color: "white" }} />
            </div>
          )}
        </Flex>
      ),
    },
    {
      title: "Amount",
      render: (row) => (
        <Text strong>
          {row.value.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
        </Text>
      ),
    },
    {
      width: 50,
      render: (row) => (
        <CouponOptions
          handleSuspended={handleSuspended}
          coupon={row}
          id={row.id}
          onDeleted={onDeleted}
        />
      ),
    },
  ];

  const onAdd = (e) => {
    setTotalRows((prev) => prev + 1);

    if (coupons.length < perPage) {
      setCoupons((prev) => [
        ...prev,
        {
          name: e.name,
          id: e.id,
          code: e.code,
          suspended: false,
          maxUsages: e.maxUsages,
          noUsages: 0,
          validTo: e.validTo,
          value: e.value,
        },
      ]);
    }
  };

  return (
    <>
      <AddCoupon
        onAdd={onAdd}
        isOpen={addModalOpen}
        setIsOpen={setAddModalOpen}
      />

        <Flex align="center" justify="space-between" style={{marginBottom:5}}>
          <Title style={{ margin: 0 }} level={4}>
            All coupons ({totalRows})
          </Title>

          <Space>
            <Button
              type="primary"
              size="large"
              className="primary-gradient-background flex-button bold-button"
              icon={<FiPlus style={{ fontSize: 20 }} />}
              style={{ borderRadius: 15 }}
              onClick={() => setAddModalOpen(true)}
            >
              New coupon
            </Button>
          </Space>
        </Flex>
  
        <Card style={{boxShadow:"none"}} className="zero-margin-padding">
          <Table
            style={{ marginTop: 1 }}
            columns={columns}
            dataSource={coupons}
            pagination={{
              current: page,
              pageSize: perPage,
              total: totalRows,
              onChange: (p, s) => {
                setPage(p);
              },
            }}
          />
        </Card>
    </>
  );
};

export default ManageCoupons;
