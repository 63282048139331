import { CreateCampaignContextProvider } from "../../../context/CreateCampaignContext";
import { Outlet } from "react-router-dom";
const CreateCampaign = () => {
  return (
    <CreateCampaignContextProvider>
      <Outlet />
    </CreateCampaignContextProvider>
  );
};

export default CreateCampaign;
