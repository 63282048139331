import {
  Button,
  Card,
  Checkbox,
  Drawer,
  Flex,
  Form,
  Select,
  Space,
  Typography,
} from "antd";
import { useState } from "react";
import { IoMdArrowDropright } from "react-icons/io";
import { CardStatus } from "../../../shared/enums";

const ContactFilters = ({ filters, setFilters }) => {
  const { Text } = Typography;

  const [open, setOpen] = useState(false);

  const [form] = Form.useForm();

  const onSubmit = (e) => {
    setFilters(prev => ({
        ...prev,
        ...e
    }))

    setOpen(false)
  };


  const submitForm = () => {
    form.submit()
  }

  return (
    <>
      <Drawer
        title="Filter contacts"
        open={open}
        onClose={() => setOpen(false)}
      >
        <Form form={form} onFinish={onSubmit}>
          <Flex
            className="h-100"
            align="center"
            justify="space-between"
            vertical
            gap={24}
          >
            <Space className="w-100" direction="vertical">
              <Card className="no-shadow w-100 gray-border" size="small">
                <Space className="w-100" direction="vertical">
                  <Text className="bold">Email</Text>
                  <Form.Item
                    className="no-error"
                    name="onlyShowSafe"
                    valuePropName="checked"
                  >
                    <Checkbox>Only show SAFE emails</Checkbox>
                  </Form.Item>
                  <Form.Item
                    className="no-error"
                    name="onlyShowWithEmails"
                    valuePropName="checked"
                  >
                    <Checkbox>Only show contacts with emails</Checkbox>
                  </Form.Item>
                  <Form.Item
                    className="no-error"
                    name="onlyShowNewContacts"
                    valuePropName="checked"
                  >
                    <Checkbox>Only show NEW contacts</Checkbox>
                  </Form.Item>
                </Space>
              </Card>

              <Card className="no-shadow w-100 gray-border" size="small">
                <Space className="w-100" direction="vertical">
                  <Text className="bold">Phone number</Text>
                  <Form.Item
                    className="no-error"
                    name="onlyShowWithPhone"
                    valuePropName="checked"
                  >
                    <Checkbox>Only show contacts with phone numbers</Checkbox>
                  </Form.Item>
                  <Form.Item
                    className="no-error"
                    name="onlyShowWithMissedCalls"
                    valuePropName="checked"
                  >
                    <Checkbox>Only show contacts with missed calls</Checkbox>
                  </Form.Item>
                  <Form.Item
                    className="no-error"
                    name="onlyShowWithAnsweredCalls"
                    valuePropName="checked"
                  >
                    <Checkbox>Only show contacts with answered calls</Checkbox>
                  </Form.Item>
                </Space>
              </Card>

              <Card className="no-shadow w-100 gray-border" size="small">
                <Space className="w-100" direction="vertical">
                  <Text className="bold">Deals</Text>
                  <Form.Item
                    className="no-error"
                    name="onlyShowWithDeals"
                    valuePropName="checked"
                  >
                    <Checkbox>Only show contacts with Deals</Checkbox>
                  </Form.Item>

                  <Form.Item
                    className="no-error"
                    name="dealStages"
                    valuePropName="checked"
                  >
                    <Select
                      className="w-100"
                      size="large"
                      mode="multiple"
                      placeholder="All stages"
                      defaultValue={filters?.dealStages}
                      options={[
                        {
                          label: "New",
                          value: CardStatus.New,
                        },
                        {
                          label: "Qualifying",
                          value: CardStatus.Qualifying,
                        },
                        {
                          label: "Demo Scheduled",
                          value: CardStatus.DemoScheduled,
                        },
                        {
                          label: "Pending Commitment",
                          value: CardStatus.PendingCommitment,
                        },
                        {
                          label: "In Negotiation",
                          value: CardStatus.InNegotiation,
                        },
                        {
                          label: "Won",
                          value: CardStatus.Won,
                        },
                        {
                          label: "Closed",
                          value: CardStatus.Closed,
                        },
                      ]}
                    />
                  </Form.Item>
                </Space>
              </Card>

              <Card className="no-shadow w-100 gray-border" size="small">
                <Space className="w-100" direction="vertical">
                  <Text className="bold">Ordering</Text>
                  <Form.Item
                    className="no-error"
                    name="orderBy"
                    valuePropName="checked"
                  >
                    <Select
                      className="w-100"
                      defaultValue={filters?.orderBy}
                      size="large"
                      placeholder="Order contacts by"
                      options={[
                        {
                          value: "email",
                          label: "Email",
                        },
                        {
                          value: "name",
                          label: "Firstname",
                        },
                        {
                          value: "lastname",
                          label: "Lastname",
                        },
                        {
                          value: "createdAt",
                          label: "Date joined",
                        },
                      ]}
                    />
                  </Form.Item>
                  <Form.Item
                    className="no-error"
                    name="orderDirection"
                    valuePropName="checked"
                  >
                    <Select
                      className="w-100"
                      size="large"
                      placeholder="Order direction"
                      defaultValue={filters?.orderDirection}
                      options={[
                        {
                          value: 1,
                          label: "A-Z",
                        },
                        {
                          value: 2,
                          label: "Z-A",
                        },
                      ]}
                    />
                  </Form.Item>
                </Space>
              </Card>
            </Space>

            <Flex gap={6} align="center" justify="end" className="w-100">
              <Button size="large" className="new-buttons large-font">
                Cancel
              </Button>
              <Button
                size="large"
                type="primary"
                className="new-buttons large-font"
                onClick={submitForm}
              >
                Apply filter
              </Button>
            </Flex>
          </Flex>
        </Form>
      </Drawer>
      <Button
        className="communication-date-filter-button large-font"
        size="large"
        onClick={() => setOpen(true)}
      >
        More filters
        <IoMdArrowDropright className="icon" />
      </Button>
    </>
  );
};

export default ContactFilters;
