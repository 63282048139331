import { CampaignContextProvider } from "./CampaignContext";
import CampaignListsLayout from "./CampaignListsLayout";

const CampaignLayout = ({showHeader = true}) => {

  return (
    <>
      <CampaignContextProvider>
        <CampaignListsLayout showHeader={showHeader}/>
      </CampaignContextProvider>
    </>
  );
};

export default CampaignLayout;
