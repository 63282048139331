import {
  Avatar,
  Button,
  Card,
  Flex,
  Form,
  InputNumber,
  message,
  Space,
  Typography,
} from "antd";
import { FaClock, FaPhoneAlt } from "react-icons/fa";
import { DateFormat } from "../../../dateformat";
import { FiPlus } from "react-icons/fi";
import { useState } from "react";
import { IoMdClose } from "react-icons/io";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { loadStripe } from "@stripe/stripe-js";

const PhoneCredits = ({ balances }) => {
  const [form] = Form.useForm();

  const { Text, Title } = Typography;

  const [adding, setAdding] = useState(false);

  const handleAdd = () => {
    setAdding((prev) => !prev);
  };

  const axios = useAxiosPrivate();

  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    setLoading(true);
    const response = await axios.post(`/mobile-top`, {
      quantity: e.amount,
    });

    if (response.data.success) {
      const { sessionId } = response.data;

      const stripe = await loadStripe(process.env.REACT_APP_PUBLIC_KEY);
      setLoading(false);
      await stripe.redirectToCheckout({ sessionId });
    } else {
      message.error(response.data.message);
    }
  };

  return (
    <>
      <Card size="small" className="no-shadow gray-border">
        <Flex align="center" justify="space-between" gap={6}>
          <Space>
            <Avatar size="large" icon={<FaPhoneAlt />}></Avatar>
            <Space direction="vertical" size={0}>
              <Title style={{ margin: 0 }} level={4}>
                Phone credits
              </Title>
              {adding ? (
                <Flex className="w-100" align="center" justify="start" gap={6}>
                  <Form
                    onFinish={handleSubmit}
                    initialValues={{
                      amount: 1,
                    }}
                    form={form}
                  >
                    <Form.Item name="amount" className="no-error">
                      <InputNumber
                        formatter={(value) => `${value} $`}
                        parser={(value) => value.replace(/\s?\$\s?|(,*)/g, "")}
                        placeholder="Enter value"
                        className="w-100"
                      />
                    </Form.Item>
                  </Form>
                  <Button
                    loading={loading}
                    onClick={() => form.submit()}
                    type="primary"
                    className="large-font new-buttons"
                  >
                    Order now
                  </Button>
                </Flex>
              ) : (
                <Space>
                  <Text>
                    {balances.phone?.toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </Text>
                  {balances.lastPhoneFill && (
                    <>
                      <Text>|</Text>
                      <Text className="icon-flex">
                        <FaClock />
                        {new Date(balances.lastPhoneFill).toLocaleDateString(
                          "en-US",
                          DateFormat
                        )}
                      </Text>
                    </>
                  )}
                </Space>
              )}
            </Space>
          </Space>

          <Button
            className="flex-button"
            size="large"
            shape="circle"
            onClick={handleAdd}
            icon={
              adding ? (
                <IoMdClose style={{ fontSize: 20 }} />
              ) : (
                <FiPlus style={{ fontSize: 20 }} />
              )
            }
          ></Button>
        </Flex>
      </Card>
    </>
  );
};

export default PhoneCredits;
