
const SideMenuIcon = ({Icon, isActive, iconClass}) =>{
    return <>
        <Icon/>

        <style>
        {`
          .${iconClass}-1 { fill: ${isActive ?"#fff" :"#a8b5c6" }; }
          .${iconClass}-2 { fill: ${isActive ? "#ffffff77" : "#61748f"}; }
        `}
      </style>
    </>
}
export default SideMenuIcon