import { Button, Drawer, Flex, Space } from "antd";
import CardItemsFilter from "./Filters/CardItemsFilter";
import StatusFilter from "./Filters/StatusFilter";

const DealSettings = ({ open, setOpen }) => {
  return <Drawer title="Deal settings" open={open} onClose={() => setOpen(false)}>
   <Flex className="h-100" align="center" justify="space-between" vertical>
    <Space className="w-100" direction="vertical" size={24}>
        <CardItemsFilter/>
        <StatusFilter/>
    </Space>

    <Flex gap={6} align="center" justify="end" className="w-100">
            <Button
              onClick={() => setOpen(false)}
              size="large"
              className="new-buttons large-font"
            >
              Close
            </Button>
          </Flex>
    </Flex>
  </Drawer>;
};

export default DealSettings;
