import { Avatar, Col, Row } from "antd";
import { MdCampaign, MdFirstPage, MdLastPage } from "react-icons/md";
import { DateFormat } from "../../dateformat";
import Stat from "../../shared/Stat";
import { UserOutlined } from "@ant-design/icons";
import { useContext } from "react";
import { FollowupContext } from "./FollowupContext";
const FollowupStats = () => {
  const {stats} = useContext(FollowupContext)

  return (
    <Row gutter={[24, 24]}>
      <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={24}>
        <Stat
          name="Total"
          value={stats?.total}
          icon={<MdCampaign style={{ fontSize: 22 }} />}
          loading={false}
        />
      </Col>

      <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={24}>
        <Stat
          value={new Date(stats?.first).toLocaleDateString("en-US", DateFormat)}
          name="First"
          icon={<MdFirstPage style={{ fontSize: 24 }} />}
          loading={false}
        />
      </Col>
      <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={24}>
        <Stat
          value={new Date(stats?.last).toLocaleDateString("en-US", DateFormat)}
          name="Last"
          icon={<MdLastPage style={{ fontSize: 24 }} />}
          loading={false}
        />
      </Col>
      <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={24}>
          <Stat
            value={stats?.authorName}
            name="Most authored"
            icon={<Avatar icon={<UserOutlined/>} size="large" src={`${process.env.REACT_APP_FILE_URL}${stats?.authorProfile}`} />}
            loading={false}
          />
        </Col>
    </Row>
  );
};

export default FollowupStats;
