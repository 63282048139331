import { useContext, useEffect, useState } from "react";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import QueryString from "qs";
import { SearchContext } from "../../../context/SearchContext";
import { EmailTemplateTypes, OrderDirection } from "../../../shared/enums";
import NoDataFound from "../../../shared/NoDataFound";
import {
  Button,
  Card,
  Col,
  Flex,
  Pagination,
  Row,
  Space,
  Typography,
} from "antd";
import "./Gallery.css";
import { MdOutlineZoomOutMap } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import CardsLoading from "./CardsLoading";
import { FaEye } from "react-icons/fa";

const Gallery = ({ openPreview, isForSelect, onUseTemplate }) => {
  const axios = useAxiosPrivate();
  const [page, setPage] = useState(1);
  const perpage = 9;
  const [templates, setTemplates] = useState();
  const { debouncedValue } = useContext(SearchContext);
  const navigate = useNavigate();
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const { Text, Title } = Typography;
  useEffect(() => {
    setLoading(true);
    const controller = new AbortController();
    const signal = controller.signal;

    const fetchTemplates = async () => {
      const response = await axios.get(`/Templates/Email`, {
        params: {
          page: page,
          pageSize: perpage,
          search: debouncedValue,
          orderBy: "createdAt",
          sortDirection: OrderDirection.Asc,
          type: EmailTemplateTypes.Desing,
        },
        signal: signal,
        paramsSerializer: (params) => {
          return QueryString.stringify(params, {
            arrayFormat: "repeat",
            skipNulls: true,
          });
        },
      });

      setLoading(false);
      setTotal(response.data.total);
      setTemplates(response.data.items);
    };

    fetchTemplates();
    return () => controller.abort();
  }, [page, debouncedValue]);

  const handleOpenPreview = (e, id) => {
    e.stopPropagation();
    openPreview(id, EmailTemplateTypes.Desing);
  };

  const handleClick = (id) => {
    navigate(`/Designs/Email/Create/Advanced/${id}`);
  };

  const handleUseTemplate = (e, id) => {
    e.stopPropagation();
    onUseTemplate(id, EmailTemplateTypes.Desing);
  };

  return (
    <div className="main-wrapper">
      {loading ? (
        <CardsLoading num={9} />
      ) : templates && templates.length > 0 ? (
        <Space direction="vertical" size="large">
          <Flex align="center" justify="space-between">
            <Title level={4} className="zero-padding-margin">
              Use pre-created templates
            </Title>
            <Pagination
              total={total}
              current={page}
              onChange={(pPage) => setPage(pPage)}
            />
          </Flex>
          <Row gutter={[12, 12]}>
            {templates.map((c, i) => (
              <Col span={8}>
                <Card
                  className="no-shadow template-card w-100"
                  size="small"
                  onClick={() => handleClick(c.id)}
                >
                  {c.simple ? (
                    <Flex
                      wrap="wrap"
                      align="center"
                      justify="center"
                      className="simple-wrapper"
                    >
                      <Title
                        level={3}
                        className="center-text zero-margin-padding"
                      >
                        No preview available
                      </Title>
                    </Flex>
                  ) : (
                    <div className="preivew-wrapper">
                      <div className="category-overlay">{c.categoryName}</div>
                      <div className="preview-overlay">
                        {isForSelect ? (
                          <>
                            <Flex
                              align="center"
                              justify="center"
                              vertical
                              className="h-100"
                            >
                              <Space direction="vertical">
                                <Button
                                  block
                                  size="large"
                                  type="primary"
                                  onClick={(e) => handleUseTemplate(e, c.id)}
                                >
                                  Use template
                                </Button>
                                <Button
                                  block
                                  size="large"
                                  className="flex-button"
                                  icon={<FaEye />}
                                  onClick={(e) => handleOpenPreview(e, c.id)}
                                >
                                  Preview
                                </Button>
                              </Space>
                            </Flex>
                          </>
                        ) : (
                          <Flex
                            className="margin-10"
                            align="center"
                            justify="end"
                          >
                            <Button
                              size="large"
                              onClick={(e) => handleOpenPreview(e, c.id)}
                              icon={<MdOutlineZoomOutMap className="icon" />}
                              className="flex-button"
                            ></Button>
                          </Flex>
                        )}
                      </div>
                      <img
                        className="img-preview"
                        src={`${process.env.REACT_APP_FILE_URL}${c.preview}`}
                      />
                    </div>
                  )}
                </Card>
              </Col>
            ))}
          </Row>

          <Flex align="center" justify="end">
            <Pagination
              total={total}
              current={page}
              onChange={(pPage) => setPage(pPage)}
            />
          </Flex>
        </Space>
      ) : (
        <NoDataFound
          addText="Add new template"
          title="No email templates avaliable"
          description="You can create templates using the button found on Designs/Email Add desing"
        />
      )}
    </div>
  );
};

export default Gallery;
