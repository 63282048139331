import { createContext, useEffect, useState } from "react";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { useParams } from "react-router-dom";
import {
  AddCallPhoneNumber,
  AddCopyOfferCode,
  AddMarketingOptOutButton,
  AddVisitWebsite,
  Custom,
} from "../pages/templates/Whatsapp/CreateTemplateData/Buttons";
export const WhatsAppTemplateContext = createContext({
  template: null,
  setTemplate: () => {},
});

export const WhatsAppTemplateContextProvider = ({ children }) => {
  const { id } = useParams();

  const axios = useAxiosPrivate();

  const [template, setTemplate] = useState();

  useEffect(() => {

    const fetch = async () => {

      if (id) {
        const templateResponse = await axios.get(`/Whatsapp/template/${id}`);
        const editorState = template.data.data.body;
        
        setTemplate({
        name: templateResponse.data.data.name,
        languageId:templateResponse.data.data.languageId,
        senderId: templateResponse.data.data.senderId,
        code: templateResponse.data.data.code,
        id: templateResponse.data.data.id,
        header: {
          type: templateResponse.data.data.headerType,
          fileType:
          templateResponse.data.data.headerFileType == 1
          ? "Image"
          : templateResponse.data.data.headerFileType == 2
          ? "Video"
          : templateResponse.data.data.headerFileType == 3
          ? "Document"
          : templateResponse.data.data.headerFileType == 4
          ? "Location"
          : "",
          file: null,
          text: templateResponse.data.data.headerText,
        },
        body: editorState,
        footer: templateResponse.data.data.footer,
        footerDisabled:
        templateResponse.data.data.buttons.filter((c) => c.buttonTypes == 1)
        .length > 0,
        ctaButton: {
          order:
          templateResponse.data.data.typeOrder == 1 ? 0 : 1,
          buttons: templateResponse.data.data.buttons
          .filter((c) => c.buttonType == 2)
          .map((c, ind) => {
            return {
              key: ind + 1,
              order: c.order,
              typeString: "cta",
              text: c.text,
              order: c.order,
              webSiteUrl: c.url,
              urlType: c.urlType == 1 ? "Static" : "Dynamic",
              buttonType:
              c.buttonTypes == 3
              ? "vw"
              : c.buttonTypes == 4
              ? "cpn"
              : "coc",
              type:
              c.buttonTypes == 3 ? (
                <AddVisitWebsite viewKey={ind + 1} />
              ) : c.buttonTypes == 4 ? (
                <AddCallPhoneNumber viewKey={ind + 1} />
              ) : (
                <AddCopyOfferCode viewKey={ind + 1} />
              ),
            };
          }),
        },
        quickReplyButtons: {
          order: templateResponse.data.data.typeOrder == 2 ? 0 : 1,
          buttons: templateResponse.data.data.buttons
            .filter((c) => c.buttonType == 1)
            .map((d, ind) => ({
              terms: d.termsAccepted,
              text: d.text,
              key: ind + 1,
              footer: d.footerText,
              order: d.order,
              typeString: "pre",
              type:
              d.buttonTypes == 1 ? (
                <AddMarketingOptOutButton viewKey={ind + 1} />
              ) : (
                <Custom viewKey={ind + 1} />
              ),
              buttonType: d.buttonTypes == 1 ? "mou" : "custom",
              order: d.order,
            })),
          },
          currentStep: 0,
        });
        
        return;
      } else {
        setTemplate({
          name: "",
          languageId:"",
          id: "",
          senderId: "",
          header: {
            type: null,
            fileType: null,
            file: null,
            text: "",
          },
          body: '',
          footer: "",
          footerDisabled: false,
          ctaButton: {
            order: 1,
            buttons: [],
          },
          quickReplyButtons: {
            order: 0,
            buttons: [],
          },
          currentStep: 0,
        });
      }
    }

    fetch();
    }, []);
    
    return (
      <WhatsAppTemplateContext.Provider
      value={{
        template: template,
        setTemplate: setTemplate,
      }}
    >
      {children}
    </WhatsAppTemplateContext.Provider>
  );
};
