const Colors = [
    "#FF5733", // Vivid Orange
    "#33FF57", // Bright Green
    "#5733FF", // Deep Blue
    "#FF33A1", // Magenta
    "#33A1FF", // Sky Blue
    "#A1FF33", // Lime Green
    "#FF8C33", // Sunset Orange
    "#33FF8C", // Mint Green
    "#8C33FF", // Purple
    "#FF338C", // Rose
    "#33A1A1", // Teal
    "#A13333", // Brick Red
    "#33FFA1", // Light Mint
    "#A1A133", // Mustard Yellow
    "#338CA1", // Soft Cyan
    "#FF33FF", // Hot Pink
    "#33FFFF", // Aqua
    "#FFA133", // Tangerine
    "#A133FF", // Lavender
    "#33FFA8", // Pale Green
    "#FFA833", // Amber
    "#A1FF8C", // Chartreuse
    "#8CFF8C", // Pastel Green
    "#8CA1FF", // Periwinkle
    "#FFA1A1", // Coral Pink
    "#A18C33"  // Gold
];

export default Colors