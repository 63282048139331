import React, { useState, useRef, useContext } from "react";
import { Button, Card, Checkbox, Flex, Space, Typography } from "antd";
import { FaCheckCircle, FaCloudUploadAlt, FaLock } from "react-icons/fa";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import { ImportContactContext } from "./ImportContactContext";

const options = [
  {
    value: 0,
    label: "Do not map",
  },
  {
    value: 1,
    label: "Firstname",
  },
  {
    value: 2,
    label: "Lastname",
  },
  {
    value: 3,
    label: "Email",
  },
  {
    value: 4,
    label: "Phone number",
  },
];

const UploadFileStep = ({ active }) => {
  const [extraRows, setExtraRows] = useState(false)
  const {
    setStep,
    fileUploadStep,
    setFileUploadStep,
    fileContainsHeader,
    setFileContainsHeader,
    setDataMapStep,
    dataMapStep
  } = useContext(ImportContactContext);
  const { Title, Text } = Typography;
  const [dragOver, setDragOver] = useState(false);
  const fileInputRef = useRef(null);

  const handleDragOver = (e) => {
    e.preventDefault();
    setDragOver(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setDragOver(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setDragOver(false);

    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      handleFile(e.dataTransfer.files[0]);
      e.dataTransfer.clearData();
    }
  };

  const handleFileChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      handleFile(e.target.files[0]);
    }
  };

  const handleFile = (file) => {
    setFileUploadStep((prev) => ({ ...prev, file: file }));
    const fileExtension = file.name.split(".").pop().toLowerCase();

    if (fileExtension === "csv" || fileExtension === "txt") {
      readCSVFile(file);
    } else if (fileExtension === "xlsx" || fileExtension === "xls") {
      readExcelFile(file);
    } else {
      alert(
        "Unsupported file format. Please upload a CSV, Excel, or text file."
      );
    }
  };
  
  const isHeaderRow = (rows) => {
    setFileContainsHeader(false)
    if (rows.length < 2) return false;

    const firstRow = rows[0];

    if (containsEmailOrPhone(firstRow)) {
        return false;
    }
    return true;
};

const containsEmailOrPhone = (row) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phonePattern = /^\+?[0-9\s\-()]{7,}$/;

    return row.some((cell) => emailPattern.test(cell) || phonePattern.test(cell));
};

const readCSVFile = (file) => {
  const reader = new FileReader();
  reader.onload = (e) => {
      const text = e.target.result;
      const parsedData = Papa.parse(text, { header: false });
      const rows = parsedData.data.filter((row) => row.length > 0); 

      const isHeader = isHeaderRow(rows);

      let cols = [];
      let dataRows = [];

      if (isHeader) {
          setFileContainsHeader(true);
          cols = detectColumnNames(rows[0], rows.slice(1), true);
          dataRows = rows.slice(1, 4);
          setExtraRows(rows.length > 4);
      } else {
          cols = detectColumnNames(rows[0], rows.slice(1), false);
          dataRows = rows.slice(0, 3);
          setExtraRows(rows.length > 3);
      }

      const formattedData = dataRows.map((row) =>
          cols.reduce((obj, header, index) => {
              obj[header] = row[index];
              return obj;
          }, {})
      );

      setFileUploadStep((prev) => ({
          ...prev,
          columns: cols,
          data: formattedData,
          lines: rows?.length,
      }));
  };
  reader.readAsText(file);
};

const readExcelFile = (file) => {
  const reader = new FileReader();
  reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const firstSheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[firstSheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

      const rows = jsonData.filter((row) => row.length > 0);

      const isHeader = isHeaderRow(rows);

      let cols = [];
      let dataRows = [];

      if (isHeader) {
          setFileContainsHeader(true);
          cols = detectColumnNames(rows[0], rows.slice(1), true);
          dataRows = rows.slice(1, 4);
          setExtraRows(rows.length > 4);
      } else {
          cols = detectColumnNames(rows[0], rows.slice(1), false);
          dataRows = rows.slice(0, 3);
          setExtraRows(rows.length > 3);
      }

      const formattedData = dataRows.map((row) =>
          cols.reduce((obj, header, index) => {
              obj[header] = row[index];
              return obj;
          }, {})
      );

      setFileUploadStep((prev) => ({
          ...prev,
          columns: cols,
          data: formattedData,
          lines: rows?.length ?? 0,
      }));
  };
  reader.readAsArrayBuffer(file);
};

const detectColumnNames = (firstRow, subsequentRows, isHeader) => {
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const phonePattern = /^\+?[0-9\s\-()]{7,}$/;

  return firstRow.map((cell, index) => {
      const columnValues = subsequentRows.map((row) => row[index]);
      const isEmailColumn = columnValues.every((value) =>
          emailPattern.test(value)
      );
      const isPhoneColumn = columnValues.every((value) =>
          phonePattern.test(value)
      );

      if (emailPattern.test(cell) || isEmailColumn) {
        setDataMapStep(prev => {
          const containsValueThree = prev.some(item => item.value === 3);
        
          if (containsValueThree) {
            return prev;
          } else {
            return [
              ...prev,
              {
                column: isHeader ? cell : "Email",
                value: 3,
                ind: index,
              },
            ];
          }
        });
          return isHeader ? cell : "Email" ;
      }

      if (phonePattern.test(cell) || isPhoneColumn) {
        setDataMapStep(prev => {
          const containsValueFour = prev.some(item => item.value === 4);
        
          if (containsValueFour) {
            return prev;
          } else {
            return [
              ...prev,
              {
                column: isHeader ? cell : "Phone",
                value: 4,
                ind: index,
              },
            ];
          }
        });
          return isHeader ? cell : "Phone";
      }

      return !isHeader ? `Field ${index + 1}` : cell;
  });
};
  const handleClick = () => {
    fileInputRef.current.click();
  };

  return (
    <Card
      size="small"
      className="no-shadow gray-border"
      style={{ borderBottomRightRadius: 0, borderBottomLeftRadius: 0 }}
    >
      <Flex align="center" justify="space-between">
        <Space direction="vertical" size={0}>
          <Title level={4} style={{ margin: 0 }}>
            {fileUploadStep.confirmed ? (
              <FaCheckCircle style={{ color: "#1cc88a" }} />
            ) : (
              "1."
            )}{" "}
            Upload your file
          </Title>

          {fileUploadStep.confirmed ? (
            <Text>
              {fileUploadStep?.file?.name} : {fileUploadStep?.lines} lines and{" "}
              {fileUploadStep?.columns?.length} columns
            </Text>
          ) : (
            <Text>Select a file containing your contacts to import.</Text>
          )}
        </Space>

        {fileUploadStep?.confirmed ? (
          <Button type="link" size="large" className="large-font" onClick={() => {setStep(1);
            setDataMapStep([])
          }}>
            Edit file
          </Button>
        ) : (
          ""
        )}
      </Flex>

      {active && (
        <Space className="w-100" direction="vertical">
          {!fileUploadStep?.file ? (
            <>
              <Card
                className={`upload-card ${dragOver ? "drag-over" : ""}`}
                style={{ boxShadow: "none" }}
                onClick={handleClick}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
              >
                <div
                  className="w-100"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100%",
                  }}
                >
                  <FaCloudUploadAlt style={{ fontSize: 80 }} />
                  <Space align="center" direction="vertical" size={0}>
                    <Text style={{ fontSize: 18 }}>
                      Select your file or drag and drop it here
                    </Text>
                    <Text>.csv, .xlsx, or .txt</Text>
                  </Space>
                </div>
              </Card>

              <input
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }}
                onChange={handleFileChange}
                accept=".csv,.xlsx,.txt"
              />
              <div style={{ display: "flex", alignItems: "center", gap: 6 }}>
                <Text>
                  <FaLock /> We don't sell, rent, or use your database for any
                  commercial purposes.
                </Text>
                <Text className="underline-link">Read our Privacy Policy</Text>
              </div>
            </>
          ) : (
            <Space direction="vertical" className="w-100">
              <Card
                style={{
                  boxShadow: "none",
                  border: "2px solid #1cc88a",
                  background: "#1cc88a33",
                }}
                size="small"
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Text style={{ fontSize: 18 }}>
                    Your file has been uploaded!
                  </Text>

                  <Button
                    onClick={() =>
                      setFileUploadStep({
                        file: null,
                        columns: [],
                        data: [],
                      })
                    }
                  >
                    Cancel
                  </Button>
                </div>
                <Text>{fileUploadStep?.file?.name}</Text>
              </Card>

              {fileUploadStep?.data.length > 0 && (
                <Space className="w-100" direction="vertical" size="large">
                  <Space className="w-100" direction="vertical">
                    <Flex align="center" justify="space-between">
                      <Text strong style={{ fontSize: 18 }}>
                        Preview of your file
                      </Text>

                      <Checkbox
                        checked={fileContainsHeader}
                        onChange={(e) => {
                          setFileContainsHeader(e.target.checked);
                        }}
                      >
                        File contains header
                      </Checkbox>
                    </Flex>
                    <table
                      border="1"
                      cellPadding="5"
                      cellSpacing="0"
                      className="preview-table"
                    >
                      <thead>
                        <tr>
                          {fileUploadStep?.columns.map((header, index) => (
                            <th key={index}>{header}</th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {fileUploadStep?.data.map((row, index) => (
                          <tr key={index}>
                            {fileUploadStep?.columns.map((col, idx) => (
                              <td key={idx}>{row[col]}</td>
                            ))}
                          </tr>
                        ))}

                        {
                          extraRows && <tr>
                            {fileUploadStep?.columns.map((col, idx) => (
                              <td key={idx}>...</td>
                            ))}
                          </tr>
                        }
                      </tbody>
                    </table>
                  </Space>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "20px",
                    }}
                  >
                    <Button
                      size="large"
                      type="primary"
                      className="new-buttons large-font"
                      onClick={() => {
                        setStep((prev) => prev + 1);

                        setFileUploadStep((prev) => ({
                          ...prev,
                          confirmed: true,
                        }));
                      }}
                    >
                      Confirm your file
                    </Button>
                  </div>
                </Space>
              )}
            </Space>
          )}
        </Space>
      )}
    </Card>
  );
};

export default UploadFileStep;
