import { TaskContextProvider } from "./TaskContext";
import TaskList from "./TaskList";

const TaskLayout = ({dataToAdd}) => {
    return <>
        <TaskContextProvider>
            <TaskList dataToAdd={dataToAdd}/>
        </TaskContextProvider>
    </>
}

export default TaskLayout;