import { Button, Card, Flex, message } from "antd";
import React, { useState, useRef, useEffect } from "react";
import { AiFillSave } from "react-icons/ai";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { Editor } from "@tinymce/tinymce-react";

const SignatureContent = ({ signature, onSuccess }) => {
  const axios = useAxiosPrivate();

  const [editorState, setEditorState] = useState('');
  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
  };

  const submit = async () => {
    const response = await axios.post("/Signature/UpdateSignatureContent", {
      id: signature.id,
      content: editorState,
    });

    if (response.data.success) {
      message.success("Signature content updated");
      onSuccess(response.data.data);
    } else {
      message.error(response.data.message);
    }
  };

  useEffect(() => {
    setEditorState(signature.text);
  }, [signature]);

  return (
    <Card
      title={signature.name}
      className="zero-margin-padding"
      style={{ minHeight: "700px", position: "sticky", top: "85px" }}
    >
       <Editor
          className="subject-text-box"
          apiKey="yrslnepj7mnu9r2x56p8a59mpo8686tnuf3hvph3xull4yxw"
          onEditorChange={onEditorStateChange}
          value={editorState}
          init={{
            plugins: "",
            toolbar: false,
            menubar: false,
           
          }}
        />

      <Flex align="center" justify="end" style={{ padding: 10 }}>
        <Button
          onClick={() => submit()}
          className="flex-button"
          icon={<AiFillSave />}
          style={{ marginRight: 15 }}
          type="primary"
        >
          Submit
        </Button>
      </Flex>
    </Card>
  );
};

export default SignatureContent;
