import { Button, Flex, message, Space, Typography } from "antd";
import logo from "../../../../assets/images/logo.png";
import { useNavigate } from "react-router-dom";
import useAuth from "../../../../hooks/useAuth";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import { useEffect, useState } from "react";

const SecondStep = () => {
  const { Title, Text } = Typography;

  const navigate = useNavigate();
  const { auth } = useAuth();

  const axios = useAxiosPrivate();

  const [loading, setLoading] = useState(false);

  const resendEmail = async () => {
    setLoading(true);

    const response = await axios.post("/Account/Send-Confirm-Email");

    if (response.data.success) {
      message.success("Email was sent!");
    } else {
      message.error("Failed to send email");
    }
    setLoading(false);
  };

  useEffect(() => {
    if(auth?.emailConfirmed){
      navigate(`/Campaigns`)
    }
  },[])

  return (
    <>
      <Flex align="center" justify="center" style={{ height: "100vh" }}>
        <Space
          size="large"
          direction="vertical"
          style={{ width: "450px", height: "500px" }}
        >
          <Space className="w-100" size={0} direction="vertical">
            <img src={logo} height={35} />
            <Title style={{ margin: 0 }}>
              Great! Now let's verify your email address.
            </Title>
            <Text style={{ fontSize: 16 }}>
              To validate your registration, an email has been sent to{" "}
              <Text strong>{auth?.email}</Text> with a link to verify your
              account
            </Text>
          </Space>

          <Text style={{ fontSize: 16 }}>
            If you have not recieved the email after a few minutes, please check
            your spam folder.
          </Text>

          <Button
            onClick={() => resendEmail()}
            loading={loading}
            type="link"
            size="large"
            block
            style={{ fontSize: 16 }}
          >
            Resend email
          </Button>

          <Flex align="center" justify="center">
            <Text
              style={{ fontSize: 16 }}
              type="link"
              className="underline-link"
              onClick={() => navigate("/register")}
            >
              Sign up with another email address
            </Text>
          </Flex>
        </Space>
      </Flex>
    </>
  );
};

export default SecondStep;
