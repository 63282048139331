import {
  Typography,
  Card,
  Space,
  Button,
  Flex,
  Input,
  Form,
  message,
  Drawer,
  InputNumber,
  Select,
} from "antd";
import { useContext, useState } from "react";
import { CreateFollowupContext } from "./CreateFollowupContext";
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import EmailStep from "./EmailStep";
import NotificationStep from "./NotificationStep";
import { MdCheck } from "react-icons/md";
import { IoMdClose } from "react-icons/io";
import { AiFillEdit } from "react-icons/ai";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import SubjectStep from "./SubjectStep";
import {
  StepExecutionConditionBridge,
  StepExecutionType,
} from "../../../shared/enums";

const CreateNewStep = () => {
  const navigate = useNavigate();

  const { step, setStep, details, setDetails } = useContext(
    CreateFollowupContext
  );

  const [scheduleOpen, setScheduleOpen] = useState(false);
  return (
    <>
      <ScheduleDrawer
        followUpId={details?.followUpId}
        stepId={details?.id}
        open={scheduleOpen}
        setOpen={setScheduleOpen}
      />

      <Space className="w-100" direction="vertical" size={24}>
        <Card size="small">
          <Flex align="center" justify="space-between">
            <Flex align="center" justify="start" gap={6}>
              <Button
                onClick={() => navigate(-1)}
                type="text"
                icon={<FaArrowLeft />}
                className="flex-button"
              ></Button>
              <EditableName
                name={details?.name}
                id={details?.id}
                onUpdate={(e) => setDetails((prev) => ({ ...prev, name: e }))}
              />
            </Flex>

            <Button
              onClick={() => setScheduleOpen(true)}
              type="primary"
              size="large"
              className="new-buttons large-font"
            >
              Schedule
            </Button>
          </Flex>
        </Card>

        <Card size="small">
          <Flex align="center" justify="center">
            <Space direction="vertical" size={0} className="step-wrapper">
              <EmailStep setStep={setStep} active={step == 1} />
              {details?.email?.id && (
                <SubjectStep setStep={setStep} active={step == 4} />
              )}
              <NotificationStep setStep={setStep} active={step == 2} />
            </Space>
          </Flex>
        </Card>
      </Space>
    </>
  );
};

const ScheduleDrawer = ({ open, setOpen, stepId, followUpId }) => {
  const { Text, Title } = Typography;

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const axios = useAxiosPrivate();
  const navigate = useNavigate();
  const submit = async (e) => {
    setLoading(true);

    const response = await axios.put("/Followup/Step/Assign/Schedule", {
      id: stepId,
      bridge: e.bridge,
      condition: e.condition,
      days: e.days,
    });

    if (response.data.success) {
      navigate(`/Followup/details/${followUpId}`);
    } else {
      message.error(response.data.message);
    }
    setLoading(false);
  };

  return (
    <>
      <Drawer open={open} onClose={() => setOpen(false)} title="Schedule step">
        <Flex
          className="w-100 h-100"
          align="center"
          justify="space-between"
          vertical
        >
          <Form form={form} onFinish={submit} className="w-100">
            <Space className="w-100" direction="vertical" size={0}>
              <Text className="thick-lable">Days</Text>
              <Form.Item name="days">
                <InputNumber
                  size="large"
                  className="w-100"
                  placeholder="Days until step execution"
                />
              </Form.Item>
            </Space>

            <Space className="w-100" direction="vertical" size={0}>
              <Text className="thick-lable">Condition bridge</Text>
              <Form.Item name="bridge">
                <Select
                  size="large"
                  className="w-100"
                  placeholder="Bridge for both conditions"
                  options={[
                    {
                      value: StepExecutionConditionBridge.And,
                      label: "And",
                    },
                    {
                      value: StepExecutionConditionBridge.Or,
                      label: "Or",
                    },
                    {
                      value: StepExecutionConditionBridge.Not,
                      label: "Not",
                    },
                  ]}
                />
              </Form.Item>
            </Space>

            <Space className="w-100" direction="vertical" size={0}>
              <Text className="thick-lable">Execution condition</Text>
              <Form.Item name="condition">
                <Select
                  size="large"
                  className="w-100"
                  placeholder="Condition to execute step"
                  options={[
                    {
                      value: StepExecutionType.UserSignedUp,
                      label: "User Signed-up",
                    },

                    {
                      value: StepExecutionType.UserSubscribed,
                      label: "User subscribed",
                    },
                    {
                      value: StepExecutionType.UserUnsubscribed,
                      label: "User un-subscribed",
                    },
                    {
                      value: StepExecutionType.UserDeleted,
                      label: "User deleted",
                    },
                    {
                      value: StepExecutionType.TrialExceded,
                      label: "Trial exceded",
                    },
                  ]}
                />
              </Form.Item>
            </Space>
          </Form>

          <Flex className="w-100" align="center" justify="end" gap={6}>
            <Button size="large" onClick={() => setOpen(false)}>
              Cancel
            </Button>
            <Button
              size="large"
              type="primary"
              loading={loading}
              onClick={() => form.submit()}
            >
              Save changes
            </Button>
          </Flex>
        </Flex>
      </Drawer>
    </>
  );
};

const EditableName = ({ id, name, onUpdate }) => {
  const { Title } = Typography;

  const [edit, setEdit] = useState(false);

  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);

  const axios = useAxiosPrivate();

  const handleEditChange = (show) => {
    setEdit(show);
    form.setFieldValue("name", name);
  };

  const submit = async (e) => {
    setLoading(true);

    const response = await axios.put(`/Followup/Step/Name`, {
      name: e.name,
      id: id,
    });

    if (response.data.success) {
      setEdit(false);
      onUpdate(e.name);
    } else {
      message.error(response.data.message);
    }

    setLoading(false);
  };
  return (
    <>
      {edit ? (
        <>
          <Form form={form} onFinish={submit}>
            <Flex align="center" justify="start" gap={6}>
              <Form.Item
                name="name"
                className="no-error"
                rules={[
                  {
                    required: true,
                  },
                  {
                    max: 128,
                  },
                ]}
              >
                <Input
                  size="large"
                  placeholder="Enter name"
                  count={{
                    show: true,
                    max: 128,
                  }}
                />
              </Form.Item>

              <Button
                loading={loading}
                onClick={() => form.submit()}
                type="text"
                className="flex-button icon"
                icon={<MdCheck />}
              ></Button>
              <Button
                type="text"
                onClick={() => handleEditChange(false)}
                className="flex-button icon"
                icon={<IoMdClose />}
              ></Button>
            </Flex>
          </Form>
        </>
      ) : (
        <>
          <Title className="zero-margin-padding" level={4}>
            {name}
          </Title>

          <Button
            size="large"
            type="link"
            onClick={() => handleEditChange(true)}
            className="flex-button"
            icon={<AiFillEdit className="edit-icon" />}
          ></Button>
        </>
      )}
    </>
  );
};

export default CreateNewStep;
