import React, { useEffect, useState } from "react";
import { Button, Card, Space, Typography, Flex } from "antd";
import { CgDanger } from "react-icons/cg";
import { FaBell } from "react-icons/fa";
import useAxiosPrivate from "./../hooks/useAxiosPrivate";

const BrowserNotification = ({onSubscribe}) => {
  const { Title } = Typography;
  const axios = useAxiosPrivate();

  const [notificationStatus, setNotificationStatus] = useState();

  function checkNotificationPermission() {
    if (!("Notification" in window)) {
      setNotificationStatus(
        "This browser does not support desktop notification"
      );
    } else {
      setNotificationStatus(Notification.permission);
    }
  }

  useEffect(() => {
    checkNotificationPermission();
    if (Notification.permission === "granted") {
      subscribeUserToPush();
    }
  }, []);

  const handleGrant = () => {
    Notification.requestPermission()
      .then((permission) => {
        setNotificationStatus(permission);
        if (permission === "granted") {
          subscribeUserToPush();
        }
      })
      .catch((error) => {
        console.error("Error requesting notification permission:", error);
      });
  };

  const subscribeUserToPush = async () => {
    if ("serviceWorker" in navigator && "PushManager" in window) {
      try {
        const registration = await navigator.serviceWorker.register(
          `${process.env.PUBLIC_URL}/sw.js`
        );
        const subscription = await registration.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey: urlBase64ToUint8Array(
            "BDCCPakIVKVGAHQ5UrW-4Cpc944_CfHL_nqrITzxeVsW51IsSCfo2nKR4LziK-7d-QB2HD0DLC8Ss0nd-9fkZVs"
          ),
        });

        const subscriptionJson = {
          url: subscription.endpoint,
          p256dh: btoa(
            String.fromCharCode.apply(
              null,
              new Uint8Array(subscription.getKey("p256dh"))
            )
          ),
          auth: btoa(
            String.fromCharCode.apply(
              null,
              new Uint8Array(subscription.getKey("auth"))
            )
          ),
        };

        await axios.post("/PushSubscription", subscriptionJson);
      } catch (error) {}
    } else {
    }
  };

  return (
    <>
      {notificationStatus === "default" || notificationStatus === "denied" ? (
        <Card size="small" style={{ boxShadow: "none", background: "#e74a3b" }}>
          <Space direction="vertical">
            <Space direction="vertical" size={0}>
              <Flex align="center" justify="start" gap={5}>
                <CgDanger style={{ fontSize: 20, color: "white" }} />
                <Title
                  style={{ margin: 0, padding: 0, color: "white" }}
                  level={5}
                >
                  Browser Notification are disabled.
                </Title>
              </Flex>
            </Space>
            <Button
              type="text"
              style={{ color: "white", background: "#f85b4c" }}
              icon={<FaBell />}
              className="bold-button flex-button"
              onClick={handleGrant}
            >
              Allow browser notifications
            </Button>
          </Space>
        </Card>
      ) : (
        <></>
      )}
    </>
  );
};

const urlBase64ToUint8Array = (base64String) => {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, "+").replace(/_/g, "/");

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
};

export default BrowserNotification;
