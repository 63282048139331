import { useContext } from "react";
import { Flex, Space, Typography } from "antd";
import { CurrentUserContext } from "../../context/CurrentUserContext";
import FollowUp from "./FollowUp";
import YourLastCampaigns from "./YourLastCampaigns";
import Planner from "./Planner";
import Contacts from "./Contacts";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import PhoneCalls from "./PhoneCalls";
import DealIntegration from "./DealsIntegration";

const Home = () => {
  const { Title } = Typography;

  const { user } = useContext(CurrentUserContext);
  return (
    <Space className="w-100" direction="vertical" size={24}>
      <Flex align="center" justify="space-between" >

      <Title level={1} className="zero-margin-padding">
        Hello {user?.firstname}
      </Title>
      </Flex>

      <Planner />
      <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 2 }}>
        <Masonry gutter={"24px"}>
          <YourLastCampaigns />
          <FollowUp />
          <Contacts />
          <PhoneCalls />
          <DealIntegration/>
        </Masonry>
      </ResponsiveMasonry>
    </Space>
  );
};

export default Home;
