import { DatePicker, Flex, Typography } from "antd";
import { useContext } from "react";
import { MdArrowDropDown } from "react-icons/md";
import { FollowupContext } from "../FollowupContext";

const FollowupFromDateFilter = () => {
  const { Text } = Typography;
  const { filters, setFilters } = useContext(FollowupContext);

  return (
    <Flex align="center" justify="start" gap={6}>
      <Text style={{ color: "#a5b6d5" }}>From</Text>
      <DatePicker
        size="large"
        value={filters?.from}
        onChange={(e) => setFilters((prev) => ({ ...prev, from: e }))}
        placeholder="Select created date end"
        className="dates-filter-select"
        suffixIcon={
          <MdArrowDropDown style={{ fontSize: 20, color: "#283371" }} />
        }
      />
    </Flex>
  );
};

export default FollowupFromDateFilter;
