/**
 * Extracts the first email address found in a given string.
 * @param {string} text
 * @returns {string|null} The first email found or null if none found.
 */
export function extractEmail(text) {
    const emailRegex = /[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}/;
    const match = text.match(emailRegex);
    return match ? match[0] : null;
}

/**
 * Extracts the first phone number found in a given string.
 * @param {string} text
 * @returns {string|null} The first phone number found or null if none found.
 */
export function extractPhoneNumber(text) {
    const phoneRegex = /\+?\d{1,3}?[-.\s]?\d{7,12}(?:[-.\s]\d{1,4})*/;
    
    const match = text.match(phoneRegex);
    if (!match) return null;
    
    const candidate = match[0];
    const digitCount = (candidate.match(/\d/g) || []).length;
    
    if (digitCount < 7 || digitCount > 15) {
      return null;
    }
    
    return candidate;
  }

  export function extractFullName(text) {
    const match = text.match(/Full Name:\s+([^\n]+)/);
    if (!match) {
      return { firstName: null, lastName: null };
    }
    
    const fullName = match[1].trim();             
    const parts = fullName.split(/\s+/);          

    const firstName = parts[0] || null;
    const lastName  = parts.length > 1 ? parts[parts.length - 1] : null;
    
    return { firstName, lastName };
  }


  export function extractCampaign(text) {
    const match = text.match(/Campaign:\s+([^\r\n]+)/);
    return match ? match[1].trim() : null;
  }