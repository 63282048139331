import { Button, Flex, Space } from "antd";
import React, { useState } from "react";
import DealStats from "./DealStats";
import CompanyFilter from "./Filters/CompanyFilter";
import ProductFilter from "./Filters/ProductFilter";
import CreatedDateToFilter from "./Filters/CreatedDateToFilter";
import { DealsTabs } from "../../shared/enums";
import { IoMdSettings } from "react-icons/io";
import DealSettings from "./DealSettings";

const Header = ({ activeTab, setActiveTab }) => {
  const handleViewChangeClick = () => {
    setActiveTab((prev) =>
      prev == DealsTabs.List ? DealsTabs.Kanban : DealsTabs.List
    );
  };

  const [settingsOpen, setSettingsOpen] = useState(false);

  return (
    <>
      <DealSettings open={settingsOpen} setOpen={setSettingsOpen} />

      <Space direction="vertical" className="w-100" size={24}>
        {activeTab == DealsTabs.List ?
          <DealStats /> : ""}
        <Flex align="center" gap={6} justify="space-between">
          <Flex align="center" gap={6} justify="start">
            <CompanyFilter />
            <ProductFilter />
            <CreatedDateToFilter />
          </Flex>

          <Flex align="center" gap={6} justify="end">


            <Button
              onClick={handleViewChangeClick}
              size="large"
              className="large-font"
              type="link"
            >
              {activeTab == DealsTabs.List ? "View as table" : "View as list"}
            </Button>

            {activeTab == DealsTabs.Kanban &&
              <Button size="large" className="large-font" type="link" onClick={() => setSettingsOpen(true)}>
                <IoMdSettings />
              </Button>
            }
          </Flex>
        </Flex>
      </Space>
    </>
  );
};

export default Header;
