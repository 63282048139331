import {
  Button,
  Card,
  Flex,
  Space,
  Typography,
  message,
  Row,
  Col,
  Pagination,
  Modal,
} from "antd";
import { useContext, useState } from "react";
import { MdCall } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { SmsSenderListContext } from "./SmsSenderListContext";
import { DateFormat } from "../../../../dateformat";
import TableLoading from "../../../../shared/TableLoading";
import NoDataFound from "../../../../shared/NoDataFound";
import SmsSenderOptions from "./SmsSenderOptions";
import { FaSms } from "react-icons/fa";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import "./SmsSenderList.css";
import DeleteConfirmationModal from "../../../../components/DeleteConfirmationModal";

const SmsSenderList = () => {
  const { Text, Title } = Typography;
  const axios = useAxiosPrivate();

  const {
    stats,
    setStats,
    senders,
    setSenders,
    loading,
    page,
    setPage,
  } = useContext(SmsSenderListContext);


  const navigate = useNavigate();

  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [current, setCurrent] = useState();

  const handleDelete = async () => {
    const response = await axios.delete(`/phone-numbers/${current}`);

    if (response.data.success) {
      setStats((prev) => ({ ...prev, total: prev.total - 1 }));
      setSenders((prev) => prev.filter((c) => c.id != current));
    } else {
      message.error(response.data.message);
    }
  };

  const handleSetDefault = async (id) => {
    const response = await axios.put(`/phone-numbers/set-default`, {
      id: id,
    });

    if (response.data.success) {
      setSenders((prev) =>
        prev.map((c) =>
          c.id == id
            ? {
                ...c,
                default: true,
              }
            : {
                ...c,
                default: false,
              }
        )
      );
    } else {
      message.error(response.data.message);
    }
  };

  const [newNumberOpen, setNewNumberOpen] = useState(false);

  const [assignNameOpen, setAssignNameOpen] = useState(false)

  const onAssignName = () => {
    setAssignNameOpen(true)
  }

  return (
    <>
    <AssignNameModal open={assignNameOpen} setOpen={setAssignNameOpen}/>
      <DeleteConfirmationModal
        isOpen={isDeleteOpen}
        onClose={() => setIsDeleteOpen(false)}
        onDeleteConfirm={handleDelete}
        item={current}
      />

      <NewNumberModal open={newNumberOpen} setOpen={setNewNumberOpen} />
      <Space size={24} direction="vertical" className="w-100 margin-top-12">
        <Flex align="center" justify="space-between" gap={12} wrap="wrap">
          <Space size={0} direction="vertical">
            <Title level={4} style={{ margin: 0 }}>
              Phone numbers ({stats?.total ?? 0})
            </Title>
            <Text>All your phone numbers</Text>
          </Space>

          <Space>
            <Button
              type="primary"
              size="large"
              className="new-buttons large-font"
              onClick={() => setNewNumberOpen(true)}
            >
              New number
            </Button>
          </Space>
        </Flex>

        <Space className="w-100" direction="vertical">
          <Card size="small" className="no-shadow">
            <Row gutter={[6, 6]}>
              <Col span={16}>
                <Text className="column-header">Number</Text>
              </Col>
              <Col span={4}>
                <Text className="column-header">Type</Text>
              </Col>
              <Col span={4}>
                <Flex align="center" justify="end"></Flex>
              </Col>
            </Row>
          </Card>
          {loading ? (
            <TableLoading />
          ) : !senders || senders?.length == 0 ? (
            <Card size="small" className="no-shadow">
              <NoDataFound
                onAdd={() => navigate("/phone-number/buy")}
                description="There is no phone number on your database! To add a new number click the button below!"
                title="No numbers found"
                addText="Add phone number"
              />
            </Card>
          ) : (
            <>
              {senders.map((c, i) => (
                <Card key={i} size="small" className="no-shadow">
                  <Row gutter={[6, 6]}>
                    <Col span={16} className="center-table-column">
                      <Space className="w-100" size={0} direction="vertical">
                        <Flex align="center" justify="start" gap={6}>
                          <Text className="template-name">{c.number}</Text>
                          {c.default && (
                            <Text className="sms-sender-list-default">
                              Default
                            </Text>
                          )}
                        </Flex>

                        <Text>
                          {new Date(c.createdAt * 1000).toLocaleDateString(
                            "en-us",
                            DateFormat
                          )}
                          {c.lastEdited ? (
                            <Text>
                              {" "}
                              • Last edited at{" "}
                              {new Date(c.lastEdited * 1000).toLocaleDateString(
                                "en-us",
                                DateFormat
                              )}
                            </Text>
                          ) : (
                            ""
                          )}
                        </Text>
                      </Space>
                    </Col>
                    <Col span={4} className="center-table-column">
                      <Text className="BuyPhoneNumber-type">{c.type}</Text>
                    </Col>
                    <Col span={4}>
                      <Flex
                        align="center"
                        justify="end"
                        gap={6}
                        className="h-100 w-100"
                      >
                        <Button
                          disabled
                          className="flex-button"
                          size="large"
                          type="text"
                          icon={<FaSms className="icon" />}
                        ></Button>

                        <Button
                          className="flex-button"
                          size="large"
                          type="text"
                          icon={<MdCall className="icon" />}
                          onClick={() =>
                            navigate(
                              `/dial/number?id=${c.id}&number=38345814398`
                            )
                          }
                        ></Button>
                        <SmsSenderOptions
                          showDelete={setIsDeleteOpen}
                          setCurrent={setCurrent}
                          onAssignName={onAssignName}
                          onDelete={handleDelete}
                          id={c.id}
                          onMakeDefault={handleSetDefault}
                        />
                      </Flex>
                    </Col>
                  </Row>
                </Card>
              ))}

              <Flex align="center" justify="end">
                <Pagination
                  total={stats?.total}
                  current={page}
                  pageSize={7}
                  showSizeChanger={false}
                  onChange={(p) => setPage(p)}
                />
              </Flex>
            </>
          )}
        </Space>
      </Space>
    </>
  );
};

const AssignNameModal = ({open, setOpen, id}) => {
  return <Modal open={open} onCancel={() => setOpen(false)}>

  </Modal>
}
const NewNumberModal = ({ open, setOpen }) => {
  //`/phone-number/buy`

  const navigate = useNavigate();

  const { Text, Title } = Typography;

  const submit = (type) => {
    if (type == 1) {
    } else {
      navigate(`/phone-number/buy`);
    }
  };

  return (
    <Modal
      closable={false}
      footer={false}
      open={open}
      onCancel={() => setOpen(false)}
    >
      <Row gutter={[12, 12]}>
        <Col span={12}>
          <Card
            disabled
            className="order-phone-box-disabled no-shadow h-100 order-phone-box"
            size="small"
          >
            <Title level={4} className="zero-margin-padding text-center">
              Port
            </Title>

            <div className="w-100 text-center">
              <Text>
                Use your own phone number for calls, sms chats and campaigns
              </Text>
            </div>
          </Card>
        </Col>

        <Col span={12}>
          <Card
            onClick={() => submit(2)}
            className="no-shadow h-100 order-phone-box"
            size="small"
          >
            <Title level={4} className="zero-margin-padding text-center">
              Buy
            </Title>

            <div className="w-100 text-center">
              <Text>
                Buy a number from tapzap for calls, sms chats and campaigns
              </Text>
            </div>
          </Card>
        </Col>
      </Row>
    </Modal>
  );
};
export default SmsSenderList;
