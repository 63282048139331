import { ImportContactContextProvider } from "./ImportContactContext"
import ImportSteps from "./ImportSteps"

const ImportContactLayout = () => {
    return <>
    <ImportContactContextProvider>
        <ImportSteps/>
    </ImportContactContextProvider>
    </>
}

export default ImportContactLayout