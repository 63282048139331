import { Button, Card, Flex, Space, Typography } from "antd";
import SimplePagination from "../../shared/SimplePagination";
import { DateFormatWithHour } from "../../dateformat";
import { useEffect, useState } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { IoSwapVerticalOutline } from "react-icons/io5";
import QueryString from "qs";

const RecentSms = () => {
  const { Text, Title } = Typography;

  const [sms, setSms] = useState();

  const axios = useAxiosPrivate();
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);

  const [page, setPage] = useState(1);
  const perPage = 7;

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    setLoading(true);
    const fetch = async () => {
      let response = await axios.get(`/Sms/list-by-number`, {
        params: {
          page: page,
          pageSize: perPage,
          numberId: 1002,
        },
        signal: signal,
        paramsSerializer: (params) => {
          return QueryString.stringify(params, {
            arrayFormat: "repeat",
            skipNulls: true,
          });
        },
      });

      setSms(response.data.data);
      setCount(response.data.count);
      setLoading(false);
    };

    fetch();
  }, []);
  const smses = [
    {
      lastText:
        "Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      contactName: "Enver Miftari",
      contactId: 122331,
      date: new Date(),
    },
    {
      lastText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      contactName: "Albin Breznica",
      contactId: 123332,
      date: new Date(),
    },
    {
      lastText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      contactName: "Sheffield Escalera",
      contactId: 121330,
      date: new Date(),
    },
    {
      lastText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      contactName: "Sheffield Escalera",
      contactId: 121330,
      date: new Date(),
    },
    {
      lastText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      contactName: "Sheffield Escalera",
      contactId: 121330,
      date: new Date(),
    },
    {
      lastText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      contactName: "Sheffield Escalera",
      contactId: 121330,
      date: new Date(),
    },
    {
      lastText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      contactName: "Sheffield Escalera",
      contactId: 121330,
      date: new Date(),
    },
  ];
  return (
    <>
      <Card className="w-100 no-round recent-calls-wrapper">
        <Space className="w-100" size={24} direction="vertical">
          <Flex align="center" justify="space-between">
            <Space className="w-100" size={0} direction="vertical">
              <Title level={3} className="zero-margin-padding">
                Recent SMS
              </Title>

              <Text className="dialer-info-text">
                Keep this page open to recieve sms messages.
              </Text>
            </Space>
          </Flex>

          <Space direction="vertical" className="w-100">
            <Flex align="center" justify="space-between">
              <Button
                size="large"
                type="link"
                className="large-font zero-margin-padding"
              >
                Send SMS
              </Button>
              <SimplePagination page={1} pageSize={10} total={1} />
            </Flex>

            {smses.map((c, i) => (
              <Card
                key={i}
                className="no-shadow gray-border-hoverable"
                size="small"
              >
                <Space size={0} direction="vertical" className="w-100">
                  <Flex align="center" justify="space-between">
                    <Text type="link" className="campaign-title">
                      {c.contactName ? c.contactName : "Unknown"}
                    </Text>

                    <Text>
                      {c.date.toLocaleDateString("en-us", DateFormatWithHour)}
                    </Text>
                  </Flex>

                  <Text className="recent-message-last-sms">{c.lastText}</Text>
                </Space>
              </Card>
            ))}
            <SimplePagination page={1} pageSize={10} total={1} />
          </Space>
        </Space>
      </Card>
    </>
  );
};

export default RecentSms;
