import React from "react";
import Colors from "../shared/Colors";

const ProfileAvatar = ({ index = 0, name, size = 30, font = 14 }) => {
  const getInitials = (name) => {
    const namesArray = name?.trim().split(" ");
    if(!namesArray){
      return "--";
    }else if (namesArray?.length === 1) {
      return namesArray[0][0]?.toUpperCase();
    } else {
      return (
        namesArray[0][0]?.toUpperCase() +
        (namesArray[1] ? namesArray[1][0]?.toUpperCase() : "")
      );
    }
  };

  const getColor = () => {
  
    return Colors[index % Colors.length];
  };

  const initials = getInitials(name);
  const backgroundColor = getColor();

  const avatarStyle = {
    backgroundColor: `${backgroundColor}`,
    color: `#fff`,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
    border: `3px solid ${backgroundColor}`,
    width: `${size}px`,
    height: `${size}px`,
    fontSize: `${font}px`,
    fontWeight: 500,
  };

  return (
    <div>
      <div style={avatarStyle}>{initials}</div>
    </div>
  );
};

export default ProfileAvatar;
