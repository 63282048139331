import { Button, Dropdown } from "antd";
import { BsTrashFill } from "react-icons/bs";
import { IoEllipsisHorizontal } from "react-icons/io5";
import { FaChartPie, FaEye, FaPen } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const CampaignOptions = ({ campaign, onDelete, handlePreview }) => {
  const navigate = useNavigate();

  const items = [
    {
      label: <p className="drop-down-text">Performance</p>,
      key: "1",
      icon: <FaChartPie className="drop-down-icon" />,
      disabled: campaign.campaignStaus != 2,
      onClick: () => navigate(`/Campaigns/Details/${campaign.id}`),
    },
    {
      label: <p className="drop-down-text">Preview</p>,
      key: "2",
      icon: <FaEye className="drop-down-icon" />,
      disabled: !campaign.templateId,
      onClick: () => handlePreview(campaign.templateId),
    },
    {
      type: "divider",
    },
    {
      label: <p className="drop-down-text">Edit</p>,
      key: "0",
      icon: <FaPen className="drop-down-icon" />,
      disabled: campaign.campaignStaus == 2,
      onClick: () => navigate(`/campaigns/edit/${campaign.id}`),
    },
    {
      label: <p className="drop-down-text">Delete</p>,
      key: "3",
      danger: true,
      icon: <BsTrashFill className="drop-down-icon" />,
      onClick: () => onDelete(campaign.id),
    },
  ];

  return (
    <>
      <Dropdown trigger={["click"]} menu={{ items }} type="link">
        <Button
          className="flex-button"
          type="text"
          icon={<IoEllipsisHorizontal className="font-size-26" />}
        ></Button>
      </Dropdown>
    </>
  );
};

export default CampaignOptions;
