import { useContext } from "react";
import { FollowupContext } from "../FollowupContext";
import { Segmented, Select, Typography } from "antd";
import { OrderDirection } from "../../../shared/enums";
import { FaArrowDown, FaArrowUp } from "react-icons/fa";
import { MdArrowDropDown } from "react-icons/md";

const FollowupOrderDirectionFilter = () => {
  const { filters, setFilters } = useContext(FollowupContext);
  const { Text } = Typography;

  return (
    <>
      <Select
        size="large"
        default={filters?.orderDirection}
        defaultValue={filters?.orderDirection}
        className="order-by-select"
        style={{width:"150px"}}
        suffixIcon={
          <MdArrowDropDown style={{ fontSize: 20, color: "#283371" }} />
        }
        onChange={(e) => setFilters((prev) => ({ ...prev, orderDirection: e }))}
        options={[
          {
            value: OrderDirection.Asc,
            label: "A - Z",
          },
          {
            value: OrderDirection.Dsc,
            label: "Z - A",
          },
        ]}
      />
    </>
  );
};

export default FollowupOrderDirectionFilter;
