import { Button, Card, Col, Flex, Row, Select, Space, Typography } from "antd";
import "./Sender.css";
import { FaCheck } from "react-icons/fa";
import { MdClose } from "react-icons/md";
import Iphone from "../../../shared/Iphone";
import { useContext, useEffect, useState } from "react";
import { CreateCampaignContext } from "../../../context/CreateCampaignContext";
import { Option } from "antd/es/mentions";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import ErrorBox from "../../../shared/ErrorBox";

const Sender = ({ active, setStep }) => {
  const { Text, Title } = Typography;

  const axios = useAxiosPrivate();

  const { senders, sendersLoading, sender, setSender, data, setData } =
    useContext(CreateCampaignContext);

  const [tempSender, setTempSender] = useState(sender);
  const [confirming, setConfirming] = useState(false);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    setTempSender(sender);
  }, [sender]);

  function getDomainFromEmail(email) {
    const domain = email.split("@")[1];
    return domain;
  }

  const [error, setError] = useState();

  const handleChange = (e) => {
    let selectedSender = senders.find((c) => c.id == e);

    if (!selectedSender.confirmed) {
      setError({
        children: (
          <Button
            loading={confirming}
            onClick={() => confirmSender(tempSender?.id)}
            type="primary"
            danger
            size="large"
          >
            Confirm selected sender
          </Button>
        ),
        title: "Sender not confirmed",
        description:
          "To ensure that emails can be sent successfully, the sender's email address must first be confirmed. Please verify the email address associated with the sender before proceeding.",
      });
    } else {
      setError();
    }
    setTempSender(selectedSender);
  };

  const confirmSender = (id) => {
    setConfirming(true);

    setConfirming(false);
  };

  const handleCancel = () => {
    setStep();
  };

  const save = async () => {
    setSaving(true);
    const response = await axios.put(`/Campaign/Email/Assign/Senders`, {
      campaignId: data?.id,
      senderId: tempSender.id,
    });

    if (response.data.success) {
      const selectedSender = senders.find((c) => c.id == tempSender.id);

      setData((prev) => ({ ...prev, senderFinished: true, senderId: tempSender.id }));
      setSender(selectedSender);
    } else {
      setError({
        title: "Something went wrong!",
        description: "Something went wrong please try again later!",
        children: (
          <Button
            loading={confirming}
            onClick={() => confirmSender(tempSender?.id)}
            type="primary"
            danger
            size="large"
          >
            Confirm selected sender
          </Button>
        ),
      });
    }

    setSaving(false);
  };
  return (
    <Card
      className={`step-card no-shadow first-card ${
        active ? "active-step" : ""
      }`}
    >
      <Flex className="w-100" align="center" justify="space-between">
        <Flex align="center" justify="start" gap={12}>
          {data?.senderFinished ? (
            <div className="step-evaluation-box-success">
              <FaCheck />
            </div>
          ) : (
            <div className="step-evaluation-box-default"></div>
          )}
          <Space direction="vertical" size={0}>
            <Title className="step-card-title zero-margin-padding" level={3}>
              Sender
            </Title>
            {sender ? (
              <Text className="sender-preview-name">
                {sender.email} - {sender.nickname}
              </Text>
            ) : (
              <Text>Who will send the campaign</Text>
            )}
          </Space>
        </Flex>
        {active ? (
          <Button
            type="text"
            onClick={handleCancel}
            className="flex-button w-300"
            size="large"
            icon={<MdClose className="icon" />}
          ></Button>
        ) : (
          <Button onClick={() => setStep(1)} size="large" className="w-300 large-font new-buttons">
            Manage sender
          </Button>
        )}
      </Flex>

      {active && (
        <>
          {error && (
            <ErrorBox
              title={error.title}
              text={error.description}
              children={error.children}
            />
          )}
          <div className="sender-work-wrapper">
            <Row gutter={[12, 12]}>
              <Col span={12}>
                <Flex
                  align="center"
                  justify="center"
                  vertical
                  className="sender-data-wrapper"
                >
                  <Space direction="vertical" className="w-100">
                    <Space direction="vertical" size={0} className="w-100">
                      <Text className="thick-lable">Email address</Text>
                      <Select
                        optionLabelProp="label"
                        showSearch
                        value={tempSender?.id}
                        defaultValue={sender?.id}
                        loading={sendersLoading}
                        onChange={handleChange}
                        dropdownRender={(menu) => <div>{menu}</div>}
                        filterOption={(input, option) =>
                          (option?.data ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        size="large"
                        placeholder="Select sender"
                        className="w-100"
                      >
                        {senders?.map((c) => (
                          <Option value={c.id} label={c.email} data={c.email}>
                            <Space
                              classNames="sender-email-wrapper"
                              className="w-100"
                              size={0}
                              direction="vertical"
                            >
                              <Text className="sender-email">{c.email}</Text>

                              <Space>
                                <Flex align="center" justify="start" gap={6}>
                                  {c.confirmed ? (
                                    <div className="sender-confirmation confirmed-sender"></div>
                                  ) : (
                                    <div className="sender-confirmation not-confirmed-sender"></div>
                                  )}
                                  <Text className="sender-nickname-domain">
                                    {c.nickname} - {getDomainFromEmail(c.email)}
                                  </Text>
                                </Flex>
                              </Space>
                            </Space>
                          </Option>
                        ))}
                      </Select>
                    </Space>
                    <Flex align="center" justify="end">
                      <Button
                         className="new-buttons large-font"
                        type="primary"
                        disabled
                      >
                        Add sender
                      </Button>
                    </Flex>
                  </Space>
                </Flex>
              </Col>
              <Col span={12}>
                <Flex align="center" justify="center" vertical>
                  <Iphone
                    height={180}
                    children={
                      <Space direction="vertical" className="w-100">
                        <div className="w-100 inbox-wrapper">
                          <Text className="sender-inbox-text w-100">Inbox</Text>
                        </div>

                        <div className="sender-inbox-seperator"></div>
                        <Space
                          className="w-100 sender-sender-data-wrapper"
                          direction="vertical"
                          size={0}
                        >
                          <Flex align="center" justify="space-between">
                            <Text className="sender-sender-data-name">
                              {tempSender?.nickname ?? "Your sender nickname"}
                            </Text>
                            <Text className="sender-sender-data-time">
                              11:40
                            </Text>
                          </Flex>
                          <Text className="sender-sender-data-subject">
                            Message subject...
                          </Text>
                          <Text className="sender-sender-data-preview">
                            Your preview text...
                          </Text>
                        </Space>
                      </Space>
                    }
                  />

                  <Text className="warn-text">
                    Actual email preview may vary depending on the email client.
                  </Text>
                </Flex>
              </Col>
            </Row>

            <Flex className="margin-top" align="center" justify="end" gap={6}>
              <Button
                size="large" className="new-buttons large-font"
                onClick={() => setStep()}
              >
                Cancel
              </Button>
              <Button
                size="large"
                type="primary"
                 className="new-buttons large-font"
                loading={saving}
                onClick={save}
                disabled={!tempSender?.confirmed}
              >
                Save
              </Button>
            </Flex>
          </div>
        </>
      )}
    </Card>
  );
};

export default Sender;
