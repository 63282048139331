import { createContext, useContext, useEffect, useState } from "react";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { useNavigate, useParams } from "react-router-dom";
import { message } from "antd";

export const CreateCampaignContext = createContext({
  data: "",
  currentStep: 0,
  setData: () => {},
  setCurrentStep: () => {},

  senders: [],
  setSenders: () => {},
  sendersLoading: false,
  setSendersLoading: () => {},
  sender: "",
  setSender: () => {},

  subjectAndPreview: {
    subject: "",
    preview: "",
  },
  setSubjectAndPreview: () => {},
});

export const CreateCampaignContextProvider = ({ children }) => {
  const [subjectAndPreview, setSubjectAndPreview] = useState({
    subject: "",
    preview: "",
  });
  const navigate = useNavigate();

  const axios = useAxiosPrivate();
  const { id } = useParams();

  const [currentStep, setCurrentStep] = useState(0);

  const [data, setData] = useState({
    id: "",
    name: "",
    subject: "",
    templateId: "",
    senderId: "",
    tags: [],
    senderFinished: false,
    subjectFinished: false,
    designStepFinished: false,
    recipientsStepFinished: false,
    totalRecipients: 0,
    onlySendToVerifiedContacts: false,
    isSimple: false
  });

  const [senders, setSenders] = useState();
  const [sendersLoading, setSendersLoading] = useState(false);
  const [sender, setSender] = useState();

  useEffect(() => {
    if (!id) {
      return;
    }
    const controller = new AbortController();
    const signal = controller.signal;

    const fetchAll = async () => {
      setSendersLoading(true);

      const response = await axios.get(`/Campaigns/Email/${id}`, {
        signal: signal,
      });
      const data = response?.data;

      setSubjectAndPreview({
        subject: data.subject,
        preview: data.previewText,
      });

      setData({
        id: data.id,
        name: data.name,
        subject: data.subject,
        previewText: data.previewText,
        templateId: data.templateId,
        senderId: data.senderId,

        tags: data.tags,

        senderFinished: data.senderStepFinished,
        subjectFinished: data.subjectStepFinished,
        designStepFinished: data.designStepFinished,
        recipientsStepFinished: data.recipientsStepFinished,
        totalRecipients: data.totalRecipients,
        onlySendToVerifiedContacts: data.onlySendToVerifiedContacts,

        isSimple: data.isSimple
      });

      let senderResponse = await axios.get(`/Campaign/Email/Senders`, {
        signal: signal,
      });

      setSenders(senderResponse.data.items);
      setSendersLoading(false);

      if (data.senderId) {
        setSender(
          senderResponse.data?.items?.find((c) => c.id == data.senderId)
        );
      } else {
        setSender(senderResponse.data.items.find((c) => c.default));
      }
    };
    fetchAll();

    return () => controller.abort();
  }, []);

  const assingTemplateToCampaign = async (id) => {
    const response = await axios.put("/Campaign/Email/Assign-Template", {
      campaignId: data?.id,
      templateId: id,
    });

    if (response.data.success) {
      setData((prev) => ({
        ...prev,
        templateId: id,
        designStepFinished: true,
        isSimple: response.data.isSimple
      }));
      navigate(-1);
    } else {
      message.error(response.data.message);
    }
  };

  return (
    <CreateCampaignContext.Provider
      value={{
        data: data,
        currentStep: currentStep,
        setData: setData,
        setCurrentStep: setCurrentStep,

        senders,
        setSenders,
        sendersLoading,
        setSendersLoading,
        sender,
        setSender,

        subjectAndPreview,
        setSubjectAndPreview,

        assingTemplateToCampaign,
      }}
    >
      {children}
    </CreateCampaignContext.Provider>
  );
};
