import { useEffect, useState } from "react";

const Timer = () => {
    const [seconds, setSeconds] = useState(0);

    useEffect(() => {
      const interval = setInterval(() => {
        setSeconds(seconds => seconds + 1);
      }, 1000);
  
      return () => clearInterval(interval);
    }, []);
  
    const formatTime = () => {
      const hours = Math.floor(seconds / 3600);
      const minutes = Math.floor((seconds % 3600) / 60);
      const secondsLeft = seconds % 60;
  
      return `${padTime(hours)}:${padTime(minutes)}:${padTime(secondsLeft)}`;
    };
  
    const padTime = time => {
      return time.toString().padStart(2, '0');
    };

    return <>
    {formatTime()}
    </>
}

export default Timer