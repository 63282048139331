import { Card, Col, Button, Flex, Row, Space, Typography, Avatar, Pagination, message } from "antd";
import TemplateComponentStats from "./TemplateComponentStats";
import { useContext, useState } from "react";
import TemplateComponentFromFilter from "./Filters/TemplateComponentFromFilter";
import TemplateComponentToFilter from "./Filters/TemplateComponentToFilter";
import { MdFilterAlt, MdFilterAltOff } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import CreateTemplateComponentModal from "./CreateTemplateComponentModal";
import TemplateComponentOrderByFilter from "./Filters/TemplateComponentOrderByFilter";
import TemplateComponentOrderDirectionFilter from "./Filters/TemplateComponentOrderDirectionFilter";
import { TemplateComponentContext } from "./TemplateComponentContext";
import { UserOutlined } from "@ant-design/icons";
import { IoClose } from "react-icons/io5";
import { DateFormat } from "../../dateformat";
import "./TemplateComponentList.css"
import TableLoading from "../../shared/TableLoading";
import NoDataFound from "../../shared/NoDataFound";
import { AiFillEdit } from "react-icons/ai";
import { FaEye } from "react-icons/fa";
import TemplateComponentListOptions from "./TemplateComponentListOptions";
import DeleteConfirmationModal from "../../components/DeleteConfirmationModal";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import TemplateComponentPreviewModal from "./TemplateComponentPreviewModal";

const TemplateComponentList = () => {
  const { Text, Title } = Typography;

  const axios = useAxiosPrivate();

  const [showFilters, setShowFilters] = useState(false);

  const navigate = useNavigate();

  const [addOpen, setAddOpen] = useState(false);

  const { filters, setFilters, loading, templates, setTemplates,stats, setStats,page, setPage } = useContext(TemplateComponentContext);

  const[current, setCurrent] = useState() 
  const [deleteOpen, setDeleteOpen] = useState(false)

  const handleDelete = async () => {
    const response = await axios.delete(`/Template-Component/${current}`);

    if(response.data.success){
      setStats(prev => ({...prev, total: prev.total - 1}));
      setTemplates(prev => prev.filter(c => c.id != current))
    }else{
      message.error(response.data.message);
    }
  }

  const handleDeleteClicked = (id) => {
    setCurrent(id);
    setDeleteOpen(true)
  }

  const [previewOpen, setPreviewOpen] = useState(false)

  const handlePreviewClick = (id) => {
    setCurrent(id)
    setPreviewOpen(true)
  }
  return (
    <>
    <DeleteConfirmationModal
        isOpen={deleteOpen}
        onClose={() => setDeleteOpen(false)}
        onDeleteConfirm={handleDelete}
        item={current}
      />

      <TemplateComponentPreviewModal id={current} open={previewOpen} setOpen={setPreviewOpen}/>
      <CreateTemplateComponentModal open={addOpen} setOpen={setAddOpen} />
      <Space direction="vertical" size={24} className="w-100">
        <TemplateComponentStats />
        <Card size="small">
          <Flex align="center" justify="space-between" gap={12} wrap="wrap">
            <Title level={4} style={{ margin: 0 }}>
              Email design components
            </Title>

            <Space>
              <Button
                size="large"
                 className="new-buttons large-font"
                onClick={() => setShowFilters(!showFilters)}
              >
                Filter
                {!showFilters ? (<MdFilterAlt />
                ) : (<MdFilterAltOff />
                )}
              </Button>
              <Button
                type="primary"
                size="large"
                onClick={() => setAddOpen(true)}
                 className="new-buttons large-font"
              >
                Create component
              </Button>
            </Space>
          </Flex>

          {showFilters && (
            <div style={{ margin: 24 }}>
              <Row gutter={[24, 24]}>
                <Col xs={24} sm={24} md={5} lg={5} xl={5} xxl={5}>
                  <TemplateComponentFromFilter />
                </Col>
                <Col xs={24} sm={24} md={5} lg={5} xl={5} xxl={5}>
                  <TemplateComponentToFilter />
                </Col>
              </Row>
            </div>
          )}
        </Card>

        <Flex
          wrap="wrap"
          align="center"
          justify="space-between"
          className="w-100"
          gap={24}
        >
          <Flex align="center" justify="start" gap={6} wrap="wrap">
            <div></div>
            {filters?.from && (
              <div className="filter-group-item">
                {`${new Date(filters?.from).toLocaleDateString(
                  "en-us",
                  DateFormat
                )}`}{" "}
                <Button
                  shape="circle"
                  type="text"
                  onClick={() =>
                    setFilters((prev) => ({ ...prev, from: null }))
                  }
                  icon={<IoClose style={{ fontSize: 18 }} />}
                  className="flex-button"
                ></Button>
              </div>
            )}
            {filters?.to && (
              <div className="filter-group-item">
                {`${new Date(filters?.to).toLocaleDateString(
                  "en-us",
                  DateFormat
                )}`}{" "}
                <Button
                  shape="circle"
                  type="text"
                  onClick={() => setFilters((prev) => ({ ...prev, to: null }))}
                  icon={<IoClose style={{ fontSize: 18 }} />}
                  className="flex-button"
                ></Button>
              </div>
            )}
          </Flex>

          <Space>
            <TemplateComponentOrderByFilter />
            <TemplateComponentOrderDirectionFilter />

            <Button shape="circle" size="large" type="primary">
              GO
            </Button>
          </Space>
        </Flex>

        <Space className="w-100" direction="vertical">
          <Card size="small">
            <Row gutter={[6, 6]}>
              <Col span={18}>
                <Text className="column-header">Name</Text>
              </Col>
              <Col span={2}>
              <Text className="column-header">Type</Text>
              </Col>
              <Col span={4}>
                <Flex align="center" justify="end">
                  <Text className="column-header">Tool</Text>
                </Flex>
              </Col>
            </Row>
          </Card>

          {loading ? (
            <TableLoading />
          ) : !templates || templates?.length == 0 ? (
            <Card size="small">
              <NoDataFound
                description="There is no desing found on your database! To add a new email desing click the button below!"
                title="No email designs component found"
                addText="Add desing"
              />
            </Card>
          ) : (
          <>
          {templates?.map((c, i) => (
                <Card key={i} size="small">
                  <Row gutter={[6, 6]}>
                    <Col span={18}>
                      <Space direction="vertical" size={0}>
                        <Text
                          type="link"
                          className="template-name"
                          onClick={() => navigate(`/Templates/Create/Component/${c.id}`)}
                        >
                          {c.name}
                        </Text>
                        <Text className="template-updated">
                          {new Date(c.createdAt * 1000).toLocaleDateString(
                            "en-us",
                            DateFormat
                          )}
                          {c.lastEdited ? (
                            <Text className="template-updated">
                              {" "}
                              • Last edited at{" "}
                              {new Date(c.lastEdited * 1000).toLocaleDateString(
                                "en-us",
                                DateFormat
                              )}
                            </Text>
                          ) : (
                            ""
                          )}
                        </Text>
                      </Space>
                    </Col>
                    <Col span={2} className="center-table-column">
                          <Text className="component-type-text">
                            {c.type == 1 ? "User" : c.type == 2 ? "Product" : "Component"}
                          </Text>
                    </Col>
                    <Col span={4}>
                      <Flex
                        gap={12}
                        className="h-100"
                        align="center"
                        justify="end"
                      >
                        <Button
                          size="large"
                          className="flex-button"
                          type="text"
                          onClick={() => navigate(`/Templates/Create/Component/${c.id}`)}
                          icon={
                            <AiFillEdit className="template-options-icon" />
                          }
                        ></Button>
                        <Button
                          size="large"
                          className="flex-button"
                          type="text"
                          icon={<FaEye className="template-options-icon" />}
                          onClick={() => handlePreviewClick(c.id)}
                        ></Button>
                        
                        <TemplateComponentListOptions id={c.id} onDelete={handleDeleteClicked}/>
                      </Flex>
                    </Col>
                  </Row>
                </Card>
              ))}

              <Card size="small">
                <Flex align="center" justify="end">
                  <Pagination
                    total={stats?.total}
                    current={page}
                    pageSize={7}
                    onChange={(p) => setPage(p)}
                  />
                </Flex>
              </Card>
          </>)}
          </Space>

      </Space>
    </>
  );
};

export default TemplateComponentList;
