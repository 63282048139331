import { Form, Select, Space, Typography } from "antd";
import React, { useContext, useEffect } from "react";
import { AuthorContext } from "../../../context/AuthorsContext";
import AuthorSelectListItem from "../../../shared/AuthorSelectListItem";

const AssignedUsersSelect = ({
  name = "assignedUser",
  label = "Owner",
  required = true,
  setDefault,
  placeholder,
}) => {
  const { Text } = Typography;

  const { authors, you } = useContext(AuthorContext);

  useEffect(() => {
    if (setDefault) {
      setDefault(you);
    }
  }, []);

  return (
    <>
      <Space direction="vertical" size={0} className="w-100">
        <Text className="bold">{label}</Text>
        <Form.Item name={name} rules={[{ required: required }]}>
          <Select
            mode="multiple"
            size="large"
            placeholder={placeholder}
            options={authors?.map(d => ({
              value: d.value,
              label: (<AuthorSelectListItem firstname={d.firstname} lastname={d.lastname} profile={d.profile}/>),
            }))}
          ></Select>
        </Form.Item>
      </Space>
    </>
  );
};

export default AssignedUsersSelect;
