import { Button, Flex, Tooltip, Typography, Form } from "antd";
import { useEffect, useRef, useState } from "react";
import AddPersonalizationDrawer from "../pages/campaigns/CreateUpdate/AddPersonalizationDrawer";
import UseAiDrawer from "../pages/campaigns/CreateUpdate/UseAIDrawer";
import { MdEmojiEmotions } from "react-icons/md";
import { FaUser } from "react-icons/fa";
import { Editor } from "@tinymce/tinymce-react";
import "./HtmlTextbox.css"
import Picker from '@emoji-mart/react'
import data from '@emoji-mart/data'

const HtmlTextbox = ({ form, fieldName, rules, max, setTextCount }) => {
    const { Text } = Typography;

    const [showPicker, setShowPicker] = useState(false);

    const pickerRef = useRef(null);

    const [editorContent, setEditorContent] = useState(
        form.getFieldValue(fieldName) ? form.getFieldValue(fieldName) : ``
    );

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (pickerRef.current && !pickerRef.current.contains(event.target)) {
                setShowPicker(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [pickerRef]);

    const [count, setCount] = useState(form.getFieldValue(fieldName)?.length ?? 0);

    const editorRef = useRef(null);

    const handleSelect = (emoji) => {
        setShowPicker(false);

        let fieldValue = form.getFieldValue(fieldName);
      
        if (fieldValue) {
            form.setFieldValue(fieldName, `${fieldValue}${emoji.native}`);
            setEditorContent(`${editorContent}${emoji.native}`);
        } else {
            form.setFieldValue(fieldName, emoji.native);
            setEditorContent(emoji.native);
        }
    };

    const [aiOpen, setAiOpen] = useState(false);

    const handleAiSave = (e) => {
        setAiOpen(false);
        setEditorContent(e);
        form.setFieldValue(fieldName, e);
    };

    const handleEditorChange = (content, editor) => {
        form.setFieldValue(fieldName, content);
        form.validateFields([fieldName]);
        setEditorContent(form.getFieldValue(fieldName));

        const textContent = editor.getContent({ format: 'text' });
        setCount(textContent.length);
        setTextCount(textContent.length)
    };

    const handleEditorBlur = () => {
    };

    const [personalisationOpen, setPersonalisationOpen] = useState(false);

    const handlePersonalisation = (e) => {

        form.setFieldValue(fieldName, `${form.getFieldValue(fieldName)} ${e}`);
        form.validateFields([fieldName]);
        setEditorContent(form.getFieldValue(fieldName));
    }

    const [active, setActive] = useState(false);

    return (
        <>
            <AddPersonalizationDrawer
                open={personalisationOpen}
                setOpen={setPersonalisationOpen}
                onAdd={handlePersonalisation}
            />

            <UseAiDrawer
                open={aiOpen}
                onSave={handleAiSave}
                setOpen={setAiOpen}
                text={editorContent}
            />

            <div className="subject-text-box-wrapper">
                <Form.Item
                    rules={rules}
                    name={fieldName}
                    className="hidden-form-item"
                ></Form.Item>
                <div className="htmltextbox-editor-wrapper">
                    <Editor
                    onInit={(evt, editor) => (editorRef.current = editor)}
                        onBlur={handleEditorBlur}
                        className="subject-text-box"
                        apiKey="yrslnepj7mnu9r2x56p8a59mpo8686tnuf3hvph3xull4yxw"
                        onEditorChange={handleEditorChange}
                        value={editorContent}
                        onActivate={() => setActive(true)}
                        onDeactivate={() => setActive(false)}
                        init={{
                            plugins: "",
                            toolbar: false,
                            menubar: false,
                            height: 100,
                            statusbar: false,
                            extended_valid_elements: 'span[id|style|contenteditable],button[onclick]',
                            allow_script_urls: true,
                            setup: (editor) => {
                                editor.on("keydown", (e) => {
                                    if (e.keyCode === 13) {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        return false;
                                    }
                                });
                            },

                            content_style: `button {
                                border-radius: 100px;
                                border:2px solid rgba(255, 147, 135, 1);
                                outline: none;
                                background:rgba(255, 147, 135, .5);
                                color: rgb(255, 50, 50);
                                font-weight: bold;
                                cursor:pointer;
                            }`
                        }}
                    />
                    <div className={`htmltextbox-editor-selector ${active ? 'htmltextbox-editor-selector-active' : ''}`}></div>

                </div>
                <Flex className="margin-right" align="center" justify="end" gap={6}>
                    <Button
                        onClick={() => setAiOpen(true)}
                        className="use-ai-button"
                        size="small"
                        type="text"
                    >
                        Use AI
                    </Button>
                    <div>
                        <Tooltip title="Add emoji">
                            <Button
                                onClick={() => setShowPicker((prev) => !prev)}
                                icon={<MdEmojiEmotions className="subject-icon emoji-icon" />}
                                type="text"
                                className="flex-button"
                            ></Button>
                        </Tooltip>
                        {showPicker && (
                            <div className="emoji-selector" 
                                ref={pickerRef}>
                                <Picker data={data}
                                    theme="light"
                                    onEmojiSelect={handleSelect}
                                />
                        </div>
                        )}
                    </div>
                    <Tooltip title="Add personalisation">
                        <Button
                            type="text"
                            className="flex-button"
                            onClick={() => setPersonalisationOpen(true)}
                            icon={<FaUser className="subject-icon" />}
                        ></Button>
                    </Tooltip>
                    <Text style={{ width: 60, textAlign: "right" }}>
                        {count}/{max ?? 200}
                    </Text>
                </Flex>
            </div>
            <Form.Item shouldUpdate className="no-error-show">
                {() => {
                    const errors = form?.getFieldError(fieldName);
                    return (
                        errors?.length > 0 && (
                            <div style={{ color: "#e74a3b", fontWeight: 500 }}>
                                * {errors[0]}
                            </div>
                        )
                    );
                }}
            </Form.Item>
        </>
    );
}

export default HtmlTextbox;
