import { Button, Card, Drawer, Flex, Space, Typography } from "antd";
import {
  FaAmbulance,
  FaFax,
  FaGlobeAmericas,
  FaPhoneAlt,
  FaSms,
} from "react-icons/fa";
import { MdHd, MdMms } from "react-icons/md";

const PhoneNumberFeaturesDrawer = ({ number, open, setOpen }) => {
  const { Text } = Typography;

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Drawer
      title={`${number?.number} details`}
      open={open}
      onClose={handleClose}
    >
      <Flex justify="space-between" vertical className="w-100 h-100">
        <Space direction="vertical">
          <Space direction="vertical" className="w-100" size={0}>
            <Text className="bold">Type</Text>
            <Card className="no-shadow gray-border" size="small">
              <Text>{number?.type}</Text>
            </Card>
          </Space>

          <Space direction="vertical" className="w-100" size={0}>
            <Text className="bold">Features</Text>
           
              <Space direction="vertical" className="w-100">
                {number?.features?.map((f, d) => (
                  <>
                    {f.name == "voice" ? (
                      <PhoneFeatureBox icon={<FaPhoneAlt />} name="Voice" />
                    ) : f.name == "fax" ? (
                      <PhoneFeatureBox icon={<FaFax />} name="FAX" />
                    ) : f.name == "hd_voice" ? (
                      <PhoneFeatureBox icon={<MdHd />} name="HD voice" />
                    ) : f.name == "emergency" ? (
                      <PhoneFeatureBox
                        icon={<FaAmbulance />}
                        name="Emergency"
                      />
                    ) : f.name == "sms" ? (
                      <PhoneFeatureBox icon={<FaSms />} name="SMS" />
                    ) : f.name == "international_sms" ? (
                      <PhoneFeatureBox
                        icon={<FaGlobeAmericas />}
                        name="International SMS"
                      />
                    ) : (
                      <PhoneFeatureBox icon={<MdMms />} name="MMS" />
                    )}
                  </>
                ))}
              </Space>
          </Space>
        </Space>
        <Flex className="w-100" align="center" justify="end">
          <Button
            onClick={handleClose}
            size="large"
            className="new-buttons large-font"
          >
            Close
          </Button>
        </Flex>
      </Flex>
    </Drawer>
  );
};

const PhoneFeatureBox = ({ name, icon }) => {
  const { Text } = Typography;

  return (
    <Card size="small" className="no-shadow gray-border">
      <Flex align="center" justify="start" gap={6}>
        {icon}
        <Text>{name}</Text>
      </Flex>
    </Card>
  );
};

export default PhoneNumberFeaturesDrawer;
