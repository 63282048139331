import { Select } from "antd";
import { MdArrowDropDown } from "react-icons/md";

const OrderBySelect = ({ options, handleChanged, defaultValue }) => {
  return (
    <>
      <Select
        size="large"
        style={{ width: "300px"}}
        options={options}
        defaultValue={defaultValue}
        placeholder="sort by"
        className="order-by-select"
        suffixIcon={<MdArrowDropDown style={{fontSize:20, color:"#283371"}} />}
        onChange={(e) => handleChanged(e)}
      />
    </>
  );
};
export default OrderBySelect;
