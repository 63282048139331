import { Skeleton, Space } from "antd";

const TableLoading = ({ height = 70, width="100%" }) => {
  return (
    <Space style={{ width: width, padding: 10 }} direction="vertical">
      <Skeleton.Input active block style={{ height: height }} />
      <Skeleton.Input active block style={{ height: height }} />
      <Skeleton.Input active block style={{ height: height }} />
      <Skeleton.Input active block style={{ height: height }} />
      <Skeleton.Input active block style={{ height: height }} />
    </Space>
  );
};

export default TableLoading;
