import { Button, Drawer, Flex, Select, Space, Typography } from "antd";
import { useState } from "react";

const AddPersonalisationDrawer = ({ open, setOpen, onSave }) => {
  const { Text } = Typography;

  const [selectedOption, setSelectedOption] = useState();

  const options = [
    { value: '-firstname-', label:'Firstname'},
    { value: '-lastname-', label:'Lastname'},
    { value: '-fullname-', label:'Fullname'},
    { value: '-email-', label:'Email'},
    { value: '-phone-', label:'Phone'}
  ];

  const handleSave = () => {
    onSave(selectedOption);
  };

  return (
    <>
      <Drawer
        title="Add personalisation"
        open={open}
        onClose={() => setOpen(false)}
      >
        <Flex
          className="w-100 h-100"
          align="center"
          justify="space-between"
          vertical
        >
          <Space className="w-100" direction="vertical" size={0}>
            <Text className="bold">
              Which attribute would you like to insert?*
            </Text>
            <Select
              value={selectedOption}
              size="large"
              className="w-100"
              placeholder="Select the placeholder"
              options={options}
              onChange={(e) => setSelectedOption(e)}
            />
          </Space>

          <Flex className="w-100" align="center" justify="end" gap={6}>
            <Button size="large" onClick={() => setOpen(false)}>
              Cancel
            </Button>
            <Button onClick={handleSave} size="large" type="primary">
              Save changes
            </Button>
          </Flex>
        </Flex>
      </Drawer>
    </>
  );
};

export default AddPersonalisationDrawer;
