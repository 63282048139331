import { Button, Flex, Space, Tabs, Typography } from "antd";
import TemplateListLayout from "../newemailtemplates/TemplateListLayout";
import NotificationTemplatesLayout from "./Notifications/NotificationTemplatesLayout";
import SmsLayout from "./Sms/SmsLayout";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CreateNewNotificationTemplateModal from "./Notifications/CreateNewNotificationTemplateModal";
import AddSmsTemplate from "./Sms/AddSmsTemplate";
import { DesingsPageTabs } from "../../shared/enums";

const TemplatesLayout = ({showHeader = true}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { Title } = Typography;
  const queryParams = new URLSearchParams(location.search);

  const [currentKey, setCurrentKey] = useState(!showHeader ? DesingsPageTabs.Email : queryParams.get("activetab"));


  const [disabled, setDisabled] = useState(false);

  const [addNotificationOpen, setAddNotificationOpen] = useState(false);
  const [addSmsOpen, setAddSmsOpen] = useState(false);

  const handleAddClick = () => {
    setDisabled(false);

    if (currentKey == DesingsPageTabs.Email) {
      navigate(`/Designs/Email/Create`);
    } else if (currentKey == DesingsPageTabs.Whatsapp) {
      setDisabled(true);
    } else if (currentKey == DesingsPageTabs.Sms) {
      setAddSmsOpen(true);
    } else if (currentKey == DesingsPageTabs.Notification) {
      setAddNotificationOpen(true);
    }
  };

  const [dataToAdd, setDataToAdd] = useState();
  const handleSmsAdded = (e) => {
    setDataToAdd(e);
  };

  const handleTabChanged = (e) => {
    setCurrentKey(e);
    
    if(showHeader){
      navigate(`/Designs?activetab=${e}`)
    }
  }

  return (
    <>
      <AddSmsTemplate
        open={addSmsOpen}
        setOpen={setAddSmsOpen}
        onSubmit={handleSmsAdded}
      />
      <CreateNewNotificationTemplateModal
        open={addNotificationOpen}
        setOpen={setAddNotificationOpen}
      />
      <Space direction="vertical" className="w-100" size={24}>
        {showHeader&&<Flex align="center" justify="space-between">
          <Title level={1} className="zero-margin-padding">
            Designs
          </Title>

          <Button
            type="primary"
            size="large"
            disabled={disabled}
            className="new-buttons large-font"
            onClick={handleAddClick}
          >
            Create desing
          </Button>
        </Flex>}
        <Tabs
          size="large"
          className="negative-top"
          onChange={handleTabChanged}
          activeKey={currentKey}
          items={[
            {
              key: DesingsPageTabs.Email,
              label: "Email",
              children: <TemplateListLayout />,
            },
            {
              key:DesingsPageTabs.Whatsapp,
              label: "Whatsapp",
              disabled: true,
            },
            {
              key: DesingsPageTabs.Sms,
              label: "SMS",
              children: <SmsLayout dataToAdd={dataToAdd} />,
            },
            {
              key: DesingsPageTabs.Notification,
              label: "Notifications",
              children: <NotificationTemplatesLayout />,
            },
          ]}
        />
      </Space>
    </>
  );
};

export default TemplatesLayout;
