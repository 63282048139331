import { Card, Flex, Typography } from "antd";
import "./CreateYourOwn.css";
import { BsFileEarmarkRichtext } from "react-icons/bs";
import { HiOutlineTemplate } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import { TbReplace  } from "react-icons/tb";
import CreateTemplateComponentModal from "../../templateComponents/CreateTemplateComponentModal";
import { useState } from "react";

const CreateYourOwn = ({ isForSelect, returnUrl }) => {
  const { Title, Text } = Typography;
  const navigate = useNavigate();

  const [addComponentOpen, setAddComponentOpen] = useState(false);

  return (
    <>

    <CreateTemplateComponentModal open={addComponentOpen} setOpen={setAddComponentOpen}/>
      <Flex align="center" justify="center" gap={12}>
        <Card
          onClick={() => {
            if (isForSelect) {
              navigate(`/Designs/Email/Create/Simple${returnUrl}`);
            } else {
              navigate(`/Designs/Email/Create/Simple`);
            }
          }}
          className="item-card"
          size="small"
        >
          <Flex
            className="item-wrapper"
            align="center"
            vertical
            justify="space-between"
          >
            <Title level={3} className="zero-margin-padding center-text">
              Simple & Personalized
            </Title>
            <BsFileEarmarkRichtext className="create-your-own-icon" />
            <Text className="center-text text">
              Use the rich text editor to create simple emails
            </Text>
          </Flex>
        </Card>

        <Card
          className="item-card"
          size="small"
          onClick={() => {
            if (isForSelect) {
              navigate(`/Designs/Email/Create/Advanced${returnUrl}`);
            } else {
              navigate(`/Designs/Email/Create/Advanced`);
            }
          }}
        >
          <Flex
            className="item-wrapper"
            align="center"
            vertical
            justify="space-between"
          >
            <Title level={3} className="zero-margin-padding center-text">
              Advanced Templates
            </Title>
            <HiOutlineTemplate className="create-your-own-icon" />
            <Text className="center-text text">
              Create templates using our advanced editor.
            </Text>
          </Flex>
        </Card>
      </Flex>
    </>
  );
};



export default CreateYourOwn;
