import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Flex,
  Modal,
  Row,
  Tooltip,
  Typography,
  message,
} from "antd";
import "./UserRoles.css"
import { FaInfo } from "react-icons/fa6";
const UserRoles = ({ isOpen, setIsOpen, userId, userRoles, setUserRoles }) => {
  const axiosPrivate = useAxiosPrivate();

  const { Title } = Typography;

  const handleRolesChanged = async (roleid, e) => {
    var checked = e.target.checked;

    var removing = false;
    var response;
    if (checked) {
      response = await axiosPrivate.post("Users/AddToRole", {
        userId: userId,
        roleId: roleid,
      });
    } else {
      response = await axiosPrivate.post("Users/RemoveFromRole", {
        userId: userId,
        roleId: roleid,
      });
      removing = true;
    }

    if (response && response.data.success) {
      if (!removing) {
        setUserRoles([
          ...userRoles,
          {
            roleId: roleid,
          },
        ]);
      } else {
        setUserRoles(userRoles.filter((u) => u.roleId != roleid));
      }
    } else {
      message.error(response.data.message);
    }
  };
  return (
    <>
      <Modal
        open={isOpen}
        title={
          <Title style={{ margin: 0 }} level={4}>
            User authorizations
          </Title>
        }
        onCancel={() => setIsOpen(false)}
        footer={false}
      >
        <Row gutter={12}>
          <Col xs={12}>
            <UserRole
              userId={userId}
              roleid={1}
              name="Marketing Manager"
              description="The marketing manager oversees the development and implementation of marketing strategies to promote products or services. They analyze market trends, manage budgets, and coordinate with various teams to ensure campaigns are executed effectively. This role often involves setting goals, measuring performance, and adjusting strategies based on data insights."
              checked={userRoles.some((r) => r.roleId == 1)}
              roleChanged={handleRolesChanged}
            />
          </Col>
          <Col xs={12}>
            <UserRole
              userId={userId}
              roleid={2}
              name="Content Strategist"
              description="A content strategist is responsible for planning, creating, and managing content that aligns with the company's brand and marketing goals. They conduct audience research, define content themes, and ensure that all materials (blogs, social media posts, videos) are engaging and optimized for search engines. This role often involves collaboration with writers, designers, and SEO specialists."
              checked={userRoles.some((r) => r.roleId == 2)}
              roleChanged={handleRolesChanged}
            />
          </Col>
        </Row>

        <Row gutter={12} style={{ marginTop: 12 }}>
          <Col xs={12}>
            <UserRole
              userId={userId}
              roleid={3}
              name="Social Media Manager"
              description="The social media manager develops and executes social media strategies to build brand awareness and engage with audiences. They create and schedule posts, interact with followers, and monitor social media trends. This role requires strong communication skills and an understanding of analytics to measure the effectiveness of social media campaigns."
              checked={userRoles.some((r) => r.roleId == 3)}
              roleChanged={handleRolesChanged}
            />
          </Col>
          <Col xs={12}>
            <UserRole
              userId={userId}
              roleid={4}
              name="Data Analyst"
              description="A data analyst in a marketing company collects and interprets data related to market trends, customer behavior, and campaign performance. They use analytical tools to generate reports that inform marketing strategies and help optimize campaigns. This role is crucial for making data-driven decisions and improving overall marketing effectiveness"
              checked={userRoles.some((r) => r.roleId == 4)}
              roleChanged={handleRolesChanged}
            />
          </Col>
        </Row>
      </Modal>
    </>
  );
};

const UserRole = ({
  name,
  roleid,
  roleChanged,
  checked,
  userId,
  description,
}) => {
  return (
    <>
      <Card size="small" bodyStyle={{padding:0, margin:0}} style={{ boxShadow: "none", height:50 }}>
        <Flex align="center" justify="space-between" style={{height:50, margin: "0px 10px"}}>
          <Checkbox className="authorization-name" checked={checked} onChange={(e) => roleChanged(roleid, e)}>
            {name}
          </Checkbox>

          <Tooltip title={description}>
            <Button shape="circle" className="flex-button" icon={<FaInfo />}></Button>
          </Tooltip>
        </Flex>
      </Card>
    </>
  );
};

export default UserRoles;
