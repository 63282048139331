import {
  Button,
  Card,
  Flex,
  Form,
  Input,
  message,
  Space,
  Typography,
} from "antd";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { useState } from "react";
import CardError from "../CardError";

const CreateTag = ({ setShow, onAdd }) => {
  const { Title, Text } = Typography;

  const axios = useAxiosPrivate();

  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);

  const [tagExists, setTagExists] = useState(false);

  const submit = async (e) => {
    setLoading(true);

    const response = await axios.post("Tag", {
      name: e.name,
    });

    if (response.data.success) {
      onAdd(response.data);
      setShow(false);
    } else if (response.data.tagExists) {
      setTagExists(true);
    } else {
      message.error(response.data.message);
    }
    setLoading(false);
  };
  return (
    <Card size="small" className="no-shadow">
      <Space direction="vertical" className="w-100">
        <Title className="zero-margin-padding" level={5}>
          Add new tag
        </Title>

        <Form form={form} onFinish={submit}>
          <Space className="w-100" size={0} direction="vertical">
            <Text>Name</Text>
            <div className={tagExists ? "create-contact-error-card" : ""}>
              <Form.Item
                name="name"
                className="no-error-show"
                rules={[
                  {
                    required: true,
                    message: "Plase provide a name",
                  },
                  {
                    max: 100,
                  },
                ]}
              >
                <Input
                  size="large"
                  placeholder="Enter tag name"
                  count={{
                    show: true,
                    max: 100,
                  }}
                />
              </Form.Item>

              {tagExists && (
                <CardError text="This tag already exists, try a different name." />
              )}
            </div>
          </Space>

          <Flex align="center" justify="end" gap={6} style={{marginTop:12}}>
            <Button
              onClick={() => setShow(false)}
              className="new-buttons large-font"
            >
              Cancel
            </Button>

            <Button
              loading={loading}
              onClick={() => form.submit()}
              className="new-buttons large-font"
              type="primary"
            >
              Save changes
            </Button>
          </Flex>
        </Form>
      </Space>
    </Card>
  );
};

export default CreateTag;
