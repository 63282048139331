import { useContext } from "react";
import { Flex, Tag } from "antd";
import { AudienceContext } from "./AudienceContext";

const ContactStats = () => {
  const { stats } = useContext(AudienceContext);

  return (
    <>
      <Flex
        align="center"
        justify="start"
        style={{ marginTop: 12, marginBottom: 12 }}
      >
        <Tag className="all-stats-tag default-stat-tag">
          {stats?.total ?? 0} Total
        </Tag>

        <Tag color="green" className="all-stats-tag">
          {stats?.safe ?? 0} Safe
        </Tag>

        <Tag color="gray" className="all-stats-tag">
          {stats?.unknown ?? 0} Unknown
        </Tag>

        <Tag color="orange" className="all-stats-tag">
          {stats?.invalid ?? 0} Invalid
        </Tag>

        <Tag color="red" className="all-stats-tag">
          {stats?.risky ?? 0} Risky
        </Tag>
      </Flex>
    </>
  );
};

export default ContactStats;
