import { createContext, useEffect, useState } from "react";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import QueryString from "qs";
import { useNavigate, useParams } from "react-router-dom";
import { message } from "antd";

export const CreateFollowupContext = createContext({
  details: {},
  setDetails: () => {},

  step: 0,
  setStep: () => {},

  emailTemplate: null,
  setEmailTemplate: () => {},

  notificationTemplate: null,
  setNotificationTemplate: () => {},

  senders: [],
  setSenders: () => {},
});

export const CreateFollowupContextProvider = ({ children }) => {
  const axios = useAxiosPrivate();

  const { stepId } = useParams();

  const [details, setDetails] = useState();

  const [step, setStep] = useState(0);
  const [emailTemplate, setEmailTemplate] = useState();
  const [notificationTemplate, setNotificationTemplate] = useState();
  const [senders, setSenders] = useState();

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    const fetch = async () => {
      let stepResponse = await axios.get(`/Followup/Step/${stepId}`, {
        signal: signal,
      });

      setDetails(stepResponse.data);

      let response = await axios.get(`/Sender/GetSenders`, {
        params: {
          page: 1,
          pageSize: 9999,
          orderBy: "name",
          sortDirection: 1,
          from: null,
          to: null,
        },
        paramsSerializer: (params) => {
          return QueryString.stringify(params, {
            arrayFormat: "repeat",
            skipNulls: true,
          });
        },
        signal: signal,
      });

      setSenders(response.data.items);
    };

    fetch();

    return () => controller.abort();
  }, []);

  const navigate = useNavigate();

  const assingEmailTemplateToStep = async (id) => {
    const response = await axios.put(`/Followup/Step/Assign/Email`, {
      id: stepId,
      templateId: id,
    });

    if (response.data.success) {
      let template = await axios.get(`/Template/${id}`);

      setDetails((prev) => ({
        ...prev,
        email: {
          id: id,
          name: template?.data?.name,
          isSimple: template?.data?.simple,
        },
      }));
      navigate(-1);
    } else {
      message.error(response.data.message);
    }
  };
  return (
    <CreateFollowupContext.Provider
      value={{
        details,
        setDetails,

        step,
        setStep,

        emailTemplate,
        setEmailTemplate,

        notificationTemplate,
        setNotificationTemplate,

        senders,
        setSenders,

        assingEmailTemplateToStep,
      }}
    >
      {children}
    </CreateFollowupContext.Provider>
  );
};
