import {
  Card,
  Col,
  Flex,
  Row,
  Space,
  Typography,
  message,
  Avatar,
  Button,
  Tag,
} from "antd";
import { useContext, useEffect, useState } from "react";
import TableLoading from "../../shared/TableLoading";
import { DateFormat } from "../../dateformat";
import UpdateProduct from "./UpdateProduct";
import ProductListOptions from "./ProductListOptions";
import NoDataFound from "../../shared/NoDataFound";
import { ProductListContext } from "./ProductListContext";
import DateToProductFilter from "./Filters/DateToProductFilter";
import ProductStats from "./Stats";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import "./ProductList.css";
import SimplePagination from "../../shared/SimplePagination";
import { IoMdSettings } from "react-icons/io";
import ProductFollowupDrawer from "./ProductFollowupDrawer";
import Colors from "../../shared/Colors";
import ProductDeals from "./ProductDeals";
import { FaBriefcase } from "react-icons/fa6";

const ProductList = ({ dataToAdd }) => {
  const axios = useAxiosPrivate();
  const [followUpOpen, setFollowUpOpen] = useState(false);

  const {
    stats,
    setStats,
    page,
    setPage,
    loading,
    products,
    setProducts,
    pageSize,
    setPageSize,
  } = useContext(ProductListContext);

  const { Text } = Typography;

  const [showEdit, setShowEdit] = useState(false);

  const [current, setCurrent] = useState();

  useEffect(() => {
    if (dataToAdd) {
      onAdd(dataToAdd);
    }
  }, [dataToAdd]);

  const onAdd = (prod) => {
    setStats((prev) => ({ ...prev, total: prev.total + 1 }));

    setProducts((prev) => [prod, ...prev]);
  };

  const onDelete = (id) => {
    setStats((prev) => ({ ...prev, total: prev.total - 1 }));

    setProducts((prev) => prev.filter((c) => c.id != id));
  };

  const onUpdate = (prod) => {
    setProducts((prev) =>
      prev.map((c) =>
        c.id == prod.id
          ? {
              ...c,
              name: prod.name,
              autoFollowupEmails: prod.autoFollowupEmails,
              autoFollowupNotifications: prod.autoFollowupNotifications,
              owners: prod.owners,
            }
          : c
      )
    );
  };

  const handleFollowupUpdate = async (id, followUpId) => {
    let response = await axios.put("/Product/Update/Followup", {
      id: id,
      followUpId: followUpId,
    });

    if (response.data.success) {
      setProducts((prev) =>
        prev.map((c) =>
          c.id == id
            ? {
                ...c,
                followUpId: followUpId,
              }
            : c
        )
      );
    } else {
      message.error(response.data.message);
    }
  };

  const handleOpenFollowupDrawer = (prod) => {
    setCurrent(prod);
    setFollowUpOpen(true);
  };

  const onUpdateFollowUpSettings = (e) => {
    setProducts((prev) =>
      prev.map((c) =>
        c.id == e.id
          ? {
              ...c,
              autoFollowupNotifications: e.autoFollowupNotifications,
              autoFollowupEmails: e.autoFollowupEmails,
              followUpId: e.followUpId,
            }
          : c
      )
    );
  };

  const getColor = (index) => {
    return Colors[index % Colors.length];
  };

  const [dealsOpen, setDealsOpen] = useState(false);

  const handleOpenDealDrawer = (id) => {
    setCurrent(id)
    setDealsOpen(true)
  }
  return (
    <>
      <ProductFollowupDrawer
        onUpdate={onUpdateFollowUpSettings}
        id={current}
        open={followUpOpen}
        setOpen={setFollowUpOpen}
      />

      <ProductDeals id={current} open={dealsOpen} setOpen={setDealsOpen}/>

      <Space direction="vertical" size={24} style={{ width: "100%" }}>
        <ProductStats />

        <Flex align="center" justify="space-between">
          <DateToProductFilter />
          <SimplePagination
            page={page}
            pageSize={pageSize}
            setPerPage={setPageSize}
            total={stats?.total}
            setPage={setPage}
          />
        </Flex>

        <Space direction="vertical" className="w-100">
          {loading ? (
            <TableLoading />
          ) : !products || products.length == 0 ? (
            <Card size="small">
              <NoDataFound
                title="You have not created any products yet"
                description="Click the new product button on Dealing/Product to create a new product"
                addText="New Product"
              />
            </Card>
          ) : (
            <>
              {products?.map((c, i) => (
                <Card
                  size="small"
                  key={i}
                  className="no-shadow gray-border-hoverable"
                >
                  <Row gutter={[6, 6]}>
                    <Col span={20} className="center-table-column">
                      <Space size={0} className="w-100" direction="vertical">
                        <Text className="product-title">{c.name}</Text>
                        <Text>
                        {`#${(page - 1) * pageSize + i + 1} • `}
                          {new Date(c.createdAt).toLocaleDateString(
                            "en-us",
                            DateFormat
                          )}
                          {c.lastEdit &&
                            ` • Last edit ${new Date(
                              c.lastEdit
                            ).toLocaleDateString("en-us", DateFormat)}`}
                        </Text>
                        {c?.tagName && <Text ellipsis>{c?.tagName}</Text>}

                          {(!c.owners || c.owners.length == 0) && <Tag className="no-owners-tag" color="red">No owners</Tag>}
                        {(c.owners && c.owners.length > 0 )&& (
                        <Avatar.Group
                          className="no-pointer-events"
                          max={{
                            count: 3,
                            style: {
                              background: "#5f5f5f",
                              fontWeight: "bold",
                              border: "2px solid #f4f7fe",
                            },
                          }}
                        >
                          {c.owners?.map((d, ind) => (
                            <Avatar
                              key={d.ind}
                              style={{
                                background: `${getColor(ind)}`,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                fontWeight: "bold",
                                border: `2px solid #f4f7fe`,
                              }}
                              src={`${process.env.REACT_APP_FILE_URL}${d.profileUrl}`}
                            >
                              {d?.firstname[0]}
                              {d?.lastname[0]}
                            </Avatar>
                          ))}
                        </Avatar.Group>
                      )}
                      </Space>
                    </Col>
                    <Col span={4}>
                      <Flex
                        gap={6}
                        align="center"
                        justify="end"
                        className="h-100"
                      >
                         <Button
                          size="large"
                          className="flex-button"
                          type="text"
                          onClick={() => handleOpenDealDrawer(c.id)}
                          icon={
                            <FaBriefcase className="campaign-options-icon" />
                          }
                        ></Button>

                        <Button
                          size="large"
                          className="flex-button"
                          type="text"
                          onClick={() => handleOpenFollowupDrawer(c.id)}
                          icon={
                            <IoMdSettings className="campaign-options-icon" />
                          }
                        ></Button>
                        <ProductListOptions
                          id={c.id}
                          onDelete={onDelete}
                          setShowEditing={(e) => setShowEdit(e)}
                          setCurrent={(e) => setCurrent(e)}
                        />
                      </Flex>
                    </Col>
                  </Row>
                </Card>
              ))}
              <SimplePagination
                page={page}
                showChanger
                pageSize={pageSize}
                setPerPage={setPageSize}
                total={stats?.total}
                setPage={setPage}
              />
            </>
          )}
        </Space>
      </Space>
      {showEdit && (
        <UpdateProduct
          show={showEdit}
          setShow={setShowEdit}
          id={current}
          onUpdate={onUpdate}
        />
      )}
    </>
  );
};

export default ProductList;
