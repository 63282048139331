import { Button, Card, Checkbox, Flex, Modal, Space, Typography } from "antd";
import { useContext, useState } from "react";
import { IoMdBulb } from "react-icons/io";
import { ImportContactContext } from "./ImportContactContext";

const SaveImportModal = ({ open, setOpen, onConfirm, loading }) => {
  const { Title, Text } = Typography;

  const { doNotImportInvalid, setDoNotImportInvalid } =
    useContext(ImportContactContext);

  return (
    <>
      <Modal
        open={open}
        title={
          <Title style={{ margin: 0 }} level={4}>
            Import contacts
          </Title>
        }
        onCancel={() => setOpen(false)}
        footer={
          <Flex justify="end" gap={6} align="center">
            <Button onClick={() => setOpen(false)}>Cancel</Button>
            <Button type="primary" loading={loading} onClick={() => onConfirm()}>Save changes</Button>
          </Flex>
        }
      >
        <Space className="w-100" direction="vertical">
          <Checkbox
            checked={doNotImportInvalid}
            onChange={(e) => setDoNotImportInvalid(e.target.checked)}
          >
            Remove invalid leads
          </Checkbox>
          <Text>
            If checked, imported leads will be automaticaly verified, This will
            use email credits per email lead verified!
          </Text>

          {doNotImportInvalid ? (
            <Card
              size="small"
              style={{
                boxShadow: "none",
                border: "2px solid #1cc88a",
                background: "#1cc88a33",
              }}
            >
              <Flex className="w-100" gap={10} align="start" justify="start">
                <IoMdBulb style={{ marginTop: 5, fontSize: 18 }} />
                <Space size={0} direction="vertical" className="w-100">
                  <Text strong>Invalid leads wont be imported</Text>
                  <Text>• Duplicate leads will be imported.</Text>
                  <Text>
                    • If a lead does not meet the required data format the
                    import will fail.
                  </Text>
                  <Text>• The verification process might take some time.</Text>
                </Space>{" "}
              </Flex>
            </Card>
          ) : (
            <Card
              size="small"
              style={{
                boxShadow: "none",
                border: "2px solid #e74a3b",
                background: "#e74a3b33",
              }}
            >
              <Flex className="w-100" gap={10} align="start" justify="start">
                <IoMdBulb style={{ marginTop: 5, fontSize: 18 }} />
                <Space size={0} direction="vertical" className="w-100">
                  <Text strong>Invalid leads will be imported</Text>
                  <Text>• Duplicate leads will be imported.</Text>
                  <Text>
                    • If a lead does not meet the required data format the
                    import will fail.
                  </Text>
                  <Text>• Invalid leads may cause domain reputation degradation.</Text>
                </Space>{" "}
              </Flex>
            </Card>
          )}
        </Space>
      </Modal>
    </>
  );
};

export default SaveImportModal;
