import { Button, message } from "antd";
import { MdOutlineArrowForward } from "react-icons/md";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import { useContext, useState } from "react";
import { WhatsAppTemplateContext } from "../../../../context/WhatsAppTemplateContext";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom/dist";

const Save = () => {
  const { template } = useContext(WhatsAppTemplateContext);

  const {id} = useParams();

  const axios = useAxiosPrivate();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const handleSave = async () => {
    
        let buttons = [];

        template.quickReplyButtons.buttons.forEach((button,ind) => {
            buttons.push({
                termsAccepted: button.terms,
                text: button.text,
                buttonType: 1,
                buttonTypes: button.buttonType == "mou" ? 1 : 2,
                footerText: button.footer,
                order: ind
            })
        });

        template.ctaButton.buttons.forEach((button, ind) => {
            buttons.push({
                termsAccepted: false,
                text: button.text,
                buttonType: 2,
                buttonTypes: button.buttonType == "vw" ? 3 :  button.buttonType == "cpn" ? 4 : 5,
                urlType: button.urlType == "Dynamic" ? 2:1,
                url : button.webSiteUrl,
                offerCode: button.offerCode,
                phoneNumber: button.phoneNumber,
                countryCodeId: button.countryCode,
                order: ind
            })   
        });

        var templateToAdd = {
            id: template.id,
            name: template.name,
            body:template.body,
            footer: template.footer,
            headerText:template.header.text,
            headerType:template.header.type,
            headerFileType:template.header.fileType == "Image" ? 2 : template.header.fileType == "Video" ? 3 : template.header.fileType == "Document" ? 4 : template.header.fileType == "Location" ? 5 : 1,
            typeOrder:template.ctaButton.order == 0 ? 1 : 2,
            buttons: buttons,
            senderId: template.senderId,
            languageId: template.languageId
        }
    
   
    setLoading(true)
    var response;
    if(!id){
      response = await axios.post("/Whatsapp/template", {template: templateToAdd});

    }else{
      response = await axios.put("/Whatsapp/template", {template: templateToAdd});
    }

    if (response.data.success) {
      navigate("/Templates/Whatsapp");
    } else {
      message.error(response.data.message);
    }

    setLoading(false)
  };

  return (
    <>
      <Button
        type="primary"
        icon={<MdOutlineArrowForward />}
        className="flex-button"
        loading={loading}
        onClick={() => handleSave()}
      >
        Submit
      </Button>
    </>
  );
};

export default Save;
