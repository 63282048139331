import { Button, Col, DatePicker, Flex, Popover, Row, Space, Typography } from "antd";
import dayjs from "dayjs";
import { useState } from "react";
import { IoMdArrowDropdown } from "react-icons/io";
import { MdOutlineNavigateNext } from "react-icons/md";

const DateFilter = ({filters, setFilters}) => {
    const { Text } = Typography;

    const [dateFilterLabel, setDateFilterLabel] = useState();
    const [popUpShown, setPopUpShown] = useState(false);
    const [showDatePickers, setShowDatePickers] = useState(false);
  
    const clear = () => {
      setFilters((prev) => ({
        ...prev,
        from: null,
        to: null,
      }));
    };
  
    const handleClick = (key, value) => {
      setDateFilterLabel(value);
      setShowDatePickers(false);
  
      const today = new Date();
  
      if(key == 0){
        setFilters((prev) => ({
          ...prev,
          from: new Date(1970, 1, 1),
          to: today,
        }));
        setPopUpShown(false);
      }
      else if (key == 1) {
        setFilters((prev) => ({
          ...prev,
          from: today,
          to: today,
        }));
        setPopUpShown(false);
      } else if (key == 2) {
        const lastWeek = new Date();
        lastWeek.setDate(today.getDate() - 7);
        setFilters((prev) => ({
          ...prev,
          from: lastWeek,
          to: today,
        }));
        setPopUpShown(false);
      } else if (key == 3) {
        const last30Days = new Date();
        last30Days.setDate(today.getDate() - 30);
        setFilters((prev) => ({
          ...prev,
          from: last30Days,
          to: today,
        }));
        setPopUpShown(false);
      } else if (key == 4) {
        const thisYear = new Date(today.getFullYear(), 0, 1);
        setFilters((prev) => ({
          ...prev,
          from: thisYear,
          to: today,
        }));
        setPopUpShown(false);
      } else if (key == 5) {
        const lastYear = new Date(today.getFullYear() - 1, 0, 1);
        const thisYear = new Date(today.getFullYear(), 0, 1);
        setFilters((prev) => ({
          ...prev,
          from: lastYear,
          to: thisYear,
        }));
        setPopUpShown(false);
      } else {
        setShowDatePickers(true);
      }
    };
  
    const options = [
      {
        label: "All time",
        key: 0,
        onClick: () => handleClick(1, "Today"),
      },
      {
        label: "Today",
        key: 1,
        onClick: () => handleClick(1, "Today"),
      },
      {
        label: "Last 7 days",
        key: 2,
        onClick: () => handleClick(2, "Last 7 days"),
      },
      {
        label: "Last 30 days",
        key: 3,
        onClick: () => handleClick(3, "Last 30 days"),
      },
      {
        label: `This year (${new Date().getFullYear()})`,
        key: 4,
        onClick: () => handleClick(4, `This year (${new Date().getFullYear()})`),
      },
      {
        label: `Last year (${new Date().getFullYear() - 1})`,
        key: 5,
        onClick: () =>
          handleClick(5, `Last year (${new Date().getFullYear() - 1})`),
      },
      {
        label: "Custom date range",
        key: 6,
        onClick: () => handleClick(6, `Custom`),
      },
    ];

    return (
      <Popover
        placement="bottom"
        open={popUpShown}
        
        size="small"
        trigger={["click"]}
        content={
          <Flex align="center" >
           <Space direction="vertical" size={0} style={{width:200, borderRight:"1px solid #f1f1f1"}}>
                {options.map((c, i) => (
                  <Button
                    size="large"
                    onClick={() => handleClick(c.key, c.label)}
                    type="text"
                    className="w-100 communication-date-filter-button"
                  >
                    {c.label}
  
                    {c.key == 6 && <MdOutlineNavigateNext className="icon" />}
                  </Button>
                ))}
              </Space>
  
            {showDatePickers && (
              <div span={12} className="communication-date-filter-border-right">
                <div style={{margin:10}}>

                <Flex align="center" className="h-100 w-100" justify="space-between" vertical>
                  <Space className="w-100" direction="vertical" size={0}>
                      <Text className="bold">From</Text>
                      <DatePicker
                        value={filters?.from ? dayjs(filters?.from) : null}
                        onChange={(e) =>
                          setFilters((prev) => ({ ...prev, from: e.toDate() }))
                        }
                        className="w-100"
                        size="large"
                      />
                    </Space>
  
                    <Space className="w-100" direction="vertical" size={0}>
                      <Text className="bold">To</Text>
                      <DatePicker
                        value={filters?.to ? dayjs(filters?.to) : null}
                        onChange={(e) =>
                          setFilters((prev) => ({ ...prev, to: e.toDate() }))
                        }
                         className="w-100"
                        size="large"
                      />
                    </Space>

                    <Flex style={{marginTop:10}} align="center" justify="end" gap={6} className="w-100">
                      <Button className="large-font new-buttons" size="large" onClick={clear}>Clear</Button>
                      <Button
                      size="large"
                        className="large-font new-buttons"
                        type="primary"
                        onClick={() => {
                          setPopUpShown(false);
                        }}
                      >
                        Save
                      </Button>
                    </Flex>
                </Flex>
                </div>

              </div>
            )}
          </Flex>
        }
      >
        <Button
          onClick={(e) => {
            e.stopPropagation();
            setPopUpShown(true);
          }}
          className="communication-date-filter-button large-font"
          size="large"
  
        >
          {dateFilterLabel ?? "All time"} <IoMdArrowDropdown className="icon" />
        </Button>
      </Popover>
    );
  };
export default DateFilter