import {
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { EmailEditor } from "react-email-editor";
import {
  Button,
  Dropdown,
  Flex,
  Form,
  Input,
  Modal,
  Space,
  Typography,
} from "antd";
import { FaArrowLeft, FaEye } from "react-icons/fa";
import "./CreateTemplate.css";
import { EmailEditorOptions } from "../../../shared/Options";
import { DateFormat } from "../../../dateformat";
import TemplateComponentPreviewModal from "../../templateComponents/TemplateComponentPreviewModal";
import { DesingsPageTabs } from "../../../shared/enums";

const CreateTemplate = () => {
  const axiosPrivate = useAxiosPrivate();

  const [templateStyle, setTemplateStyle] = useState("");
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const returnUrl = searchParams.get("returnUrl");
  const cmpId = searchParams.get("cmpId");
  const stepId = searchParams.get("stepId");

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    const fetch = async () => {
      const response = await axiosPrivate.get(`/Template/${id}`, {
        signal: signal,
      });

      setTemplateStyle(response.data.style);
    };
    fetch();
    return () => controller.abort();
  }, []);

  const [form] = Form.useForm();

  const emailEditorRef = useRef(null);

  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);

  const navigate = useNavigate();

  const handleReady = async () => {
    if (loaded) {
      return;
    }
    setLoaded(true);
    emailEditorRef.current.editor.loadDesign(JSON.parse(templateStyle));
  };

  const handleSave = async (e) => {
    setLoading(true);
    await emailEditorRef.current.editor.exportHtml(async (data) => {
      const { design, html } = data;

      var formData = new FormData();

      formData.append("Html", html);
      if (cmpId) {
        formData.append("CmpId", cmpId);
      }
      if (stepId) {
        formData.append("StepId", stepId);
      }

      formData.append("Name", e.name);
      formData.append("Style", JSON.stringify(design));

      var response = await axiosPrivate.post("/Template", formData);

      if (!response || !response.data.success) {
        return;
      }

      setLoading(false);

      if (returnUrl) {
        navigate(`/${returnUrl}`);
      } else {
        navigate(`/Designs?activetab=${DesingsPageTabs.Email}`);
      }
    });
  };

  const [saveOpen, setSaveOpen] = useState(false);

  const handleFileUpload = async (file, done) => {
    try {
      const formData = new FormData();
      formData.append("file", file.attachments[0]);

      const response = await axiosPrivate.post(
        "/Templates/Email/Upload-file",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (!response.data.success) {
        throw new Error("Upload failed");
      }
      const imageUrl = response.data.path;

      done({ progress: 100, url: `${process.env.REACT_APP_FILE_URL}${imageUrl}` });
    } catch (error) {
      console.error("Image upload failed:", error);
      done({ progress: 100, error: "Image upload failed" });
    }
  };

  const onLoad = () => {
    emailEditorRef.current.editor.registerCallback("image", (file, done) => {
      handleFileUpload(file, done);
    });
  };


  return (
    <>
      <CreateTemplateModal
        open={saveOpen}
        form={form}
        setOpen={setSaveOpen}
        adding={loading}
        onAdd={handleSave}
      />
      <div className="create-advanced-template-wrapper">
        <Flex
          className="create-advanced-template-inner-wrapper"
          align="center"
          justify="space-between"
          gap={6}
        >
          <Button
            onClick={() => navigate(-1)}
            size="large"
            className="bold flex-button margin-left-10"
            icon={<FaArrowLeft />}
          ></Button>

          <Flex align="center" justify="end" gap={6}>
            <Button
              size="large"
              type="primary"
              style={{
                marginRight: 10,
              }}
              className="bold"
              onClick={() => setSaveOpen(true)}
            >
              Save changes
            </Button>
          </Flex>
        </Flex>
      </div>

      {id ? (
        <>
          {templateStyle && (
            <EmailEditor
              ref={emailEditorRef}
              design={templateStyle}
              onReady={handleReady}
              options={EmailEditorOptions}
              onLoad={onLoad}
              style={{ flex: 1 }}
            />
          )}
        </>
      ) : (
        <EmailEditor
          ref={emailEditorRef}
          onReady={handleReady}
          style={{ flex: 1 }}
          options={EmailEditorOptions}
          onLoad={onLoad}
        />
      )}
    </>
  );
};

const CreateTemplateModal = ({ open, setOpen, adding, onAdd, form }) => {
  const { Title, Text } = Typography;

  return (
    <Modal
      footer={
        <Flex align="center" justify="end" gap={6}>
          <Button size="large" className="new-button large-font" onClick={() => setOpen(false)}>Cancel</Button>
          <Button size="large" className="new-button large-font" loading={adding} type="primary" onClick={() => form.submit()}>
            Save changes
          </Button>
        </Flex>
      }
      open={open}
      onCancel={() => setOpen(false)}
      title={
        <Title style={{ margin: 0 }} level={4}>
          Save template changes
        </Title>
      }
    >
      <Form onFinish={onAdd} form={form}>
        <Text className="bold">Name</Text>
        <Form.Item
          name="name"
          rules={[
            { required: true, message: "A name is required" },
            {
              max: 50,
              message: "Name should be at most 50 characters long",
            },
          ]}
        >
          <Input
            size="large"
            placeholder="Enter a name for your design"
            allowClear
            count={{
              show: true,
              max: 50,
            }}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateTemplate;
