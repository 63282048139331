import {
  Button,
  Flex,
  Form,
  Input,
  message,
  Modal,
  Space,
  Typography,
} from "antd";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { useState } from "react";
import CardError from "../CardError";

const AddTagModal = ({ open, setOpen, onAdd }) => {
  const { Title, Text } = Typography;

  const [form] = Form.useForm();

  const axios = useAxiosPrivate();
  const [loading, setLoading] = useState(false);

  const [tagExists, setTagExists] = useState(false);

  const submit = async (e) => {
    setLoading(true);

    const response = await axios.post("Tag", {
      name: e.name,
    });

    if (response.data.success) {
      onAdd(response.data);
      onClose()
    } else if (response.data.tagExists) {
      setTagExists(true);
    } else {
      message.error(response.data.message);
    }
    setLoading(false);
  };


  const onClose = () => {
    form.resetFields();
    setTagExists(false)
    setOpen(false);
  }
  return (
    <>
      <Modal
        title={
          <Title level={4} className="zero-margin-padding">
            Create a new tag
          </Title>
        }
        open={open}
        onCancel={onClose}
        destroyOnClose
        footer={
          <Flex align="center" justify="end" gap={6}>
            <Button
              className="new-buttons large-font"
              size="large"
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              size="large"
              className="new-buttons large-font"
              type="primary"
              onClick={() => form.submit()}
              loading={loading}
            >
              Save changes
            </Button>
          </Flex>
        }
      >
        <Form form={form} onFinish={submit}>
          <Space className="w-100" size={0} direction="vertical">
            <Text className="bold">Name</Text>
            <div className={tagExists ? "create-contact-error-card" : ""}>
              <Form.Item
                name="name"
                className="no-error-show"
                rules={[
                  {
                    required: true,
                  },
                  {
                    max:61
                  }
                ]}
              >
                <Input
                  size="large"
                  placeholder="Enter tag name"
                  allowClear
                  count={{
                    show: true,
                    max: 61,
                  }}
                />
              </Form.Item>

              {tagExists && <CardError text="This tag already exists, try a different name." />}
            </div>
          </Space>
        </Form>
      </Modal>
    </>
  );
};

export default AddTagModal;
