import { Space } from "antd";
import React from "react";
import DealDetailsData from "./DealDetailsData";

const DealDetailsAside = () => {
  return (
    <Space className="w-100" size={24} direction="vertical" style={{position:"sticky", top:24}} >
      <DealDetailsData /> 
    </Space>
  );
};

export default DealDetailsAside;
