import {
  Avatar,
  Button,
  Divider,
  Flex,
  Form,
  Select,
  Space,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { FaUser } from "react-icons/fa";

const ContactsMultiselect = ({
  name = "contacts",
  label = "Associate Deal to Contacts",
  placeholder
}) => {
  const axios = useAxiosPrivate();
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);

  const { Text } = Typography;

  useEffect(() => {
    const fetch = async () => {

    setLoading(true);
    const response = await axios.get("/Deal/GetPossibleContactsForDeal");
    setOptions(
      response.data?.dealOwners?.map((c) => ({
        label: (
          <>
            <Flex align="center" justify="start" gap={6}>
              <Avatar size="small" icon={<FaUser />} />
              <Space size={0} className="w-100" direction="vertical">
                <Text strong>
                  {c.name} {c.lastName}
                </Text>
                <Text>{c.email}</Text>

              </Space>
            </Flex>
          </>
        ),
        value: c.userId,
        data: `${c.name} ${c.lastName} ${c.email}`
      }))
    );
    setLoading(false);
  }
  fetch()
  }, []);

  return (
    <>
      <Space direction="vertical" size={0} className="w-100">
        <Text>{label}</Text>
        <Form.Item name="contacts">
          <Select
            mode="multiple"
            size="large"
            loading={loading}
            placeholder={placeholder}
            showSearch
            filterOption={(input, option) =>
              (option?.data ?? "").toLowerCase().includes(input.toLowerCase())
            }
            options={options}
          ></Select>
        </Form.Item>
      </Space>
    </>
  );
};

export default ContactsMultiselect;
