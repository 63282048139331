import { useContext } from "react";
import { NotificationTemplatesContext } from "../NotificationTemplatesContext";
import DateFilter from "../../../../shared/DateFilter";

const NotificationTemplatesToDateFilter = () => {
  const { filters, setFilters } = useContext(NotificationTemplatesContext);
  
  return (
    <DateFilter filters={filters} setFilters={setFilters}/>
  );
};

export default NotificationTemplatesToDateFilter;
