import { Button, Dropdown } from "antd";
import { IoMdMore } from "react-icons/io";
import { FaBriefcase, FaClone, FaLock, FaLockOpen } from "react-icons/fa6";
import { BsFillTrashFill } from "react-icons/bs";
import { BiSolidAdjustAlt } from "react-icons/bi";
import UnblockUser from "./UnblockUser";
import BlockUser from "./BlockUser";
import DeleteUser from "./DeleteUser";
import { IoEllipsisHorizontal } from "react-icons/io5";

const UserOptions = ({ userobject, user, handleBan, setSenderModalOpen,  setRolesOpen, handleUnban, onDelete,  setAllowanceOpen, setCurrentUser }) => {
  const items = [
    {
      label: <p className="drop-down-text">Allowance</p>,
      icon: <BiSolidAdjustAlt className="drop-down-icon"/>,
      onClick: () => {
        setAllowanceOpen(true)
        setCurrentUser(user?.id)
      }
    },
    {
      onClick: () => {
        setSenderModalOpen(true);
        setCurrentUser(user.id)
      },
      label: <p className="drop-down-text">Create sender</p>,
      icon:  <FaClone className="drop-down-icon"/>,
      disabled: userobject.isSender,
    },
    {
      label: user?.lockoutEnabled ? (
        <UnblockUser handleUnblocked={handleUnban} user={user.id} />
      ) : (
        <BlockUser handleBlocked={handleBan} user={user.id} />
      ),
      icon: user?.lockoutEnabled ? <FaLockOpen className="drop-down-icon"/> : <FaLock className="drop-down-icon"/>,
    },
    /*{
      label: <p className="drop-down-text">Assign roles</p>,
      icon: <FaBriefcase className="drop-down-icon"/>,
      onClick: () =>{
        setRolesOpen(true);
        setCurrentUser(user?.id)
      }
    },*/
    {
      type:"divider"
    },
    {
      danger: true,
      label: <DeleteUser user={user.id} handleDelete={onDelete} />,
      icon: <BsFillTrashFill className="drop-down-icon"/>,
    },
  ];

  return (
    <Dropdown trigger={["click"]} menu={{ items }} type="link">
      <Button
        size="large"
        className="flex-button"
        type="text"
        icon={<IoEllipsisHorizontal style={{ fontSize: 26 }} />}
      ></Button>
    </Dropdown>
  );
};

export default UserOptions;
