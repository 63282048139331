import {
  Button,
  Card,
  Col,
  Flex,
  Input,
  Radio,
  Row,
  Select,
  Space,
  Tag,
  Typography,
  Upload,
} from "antd";
import { useContext, useState } from "react";
import { FaFileImage, FaFilePdf, FaVideo } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import { MdOutlineArrowForward } from "react-icons/md";
import { UploadOutlined } from "@ant-design/icons";
import { WhatsAppTemplateContext } from "../../../../context/WhatsAppTemplateContext";
import SmartText from "../../../../components/SmartText";

const Header = ({ setCurrent }) => {
  const { template, setTemplate } = useContext(
    WhatsAppTemplateContext
  );
  const { Text, Title } = Typography;

  return (
    <>
      {template && (
        <Space style={{ width: "100%" }} direction="vertical">
          <Space style={{ width: "100%" }} direction="vertical" size={0}>
            <Flex align="center" gap={10}>
              <Title style={{ margin: 0 }} level={3}>
                Header
              </Title>
              <Tag>Optional</Tag>
            </Flex>
            <Text>
              Add a title or choose which type of media you'll use for this
              header.
            </Text>
          </Space>

          <Row gutter={12}>
            <Col xs={24} sm={24} md={5} lg={5} xl={5} xxl={5}>
              <Select
                size="large"
                onChange={(e) => setTemplate(prev => ({...prev, header:{...prev.header, type:e}}))}
                style={{ width: "100%" }}
                value={template?.header?.type}
                defaultValue={template?.header?.type}
                options={[
                  {
                    value: 1,
                    label: "None",
                  },
                  {
                    value: 2,
                    label: "Text",
                  },
                  {
                    value: 3,
                    label: "Media",
                  },
                ]}
              />
            </Col>
            {template.header?.type == "2" && <TextHeader />}
            {template.header?.type == "3" && <MediaHeader />}
          </Row>
          <Flex align="center" justify="end">
            <Button
              type="primary"
              className="flex-button"
              onClick={() => setCurrent("2")}
              icon={<MdOutlineArrowForward />}
            >
              Continue to body
            </Button>
          </Flex>
        </Space>
      )}
    </>
  );
};

const TextHeader = () => {
  const { template, setTemplate } = useContext(
    WhatsAppTemplateContext
  );

  return (
    <>
      <Col xs={24} sm={24} md={19} lg={19} xl={19} xxl={19}>
        <Input
          size="large"
          placeholder="Enter header text"
          allowClear
          value={template.header.text}
          onChange={(e) => setTemplate(prev => ({
            ...prev, 
            header:{...prev.header, text: e.target.value}
          }))}
          count={{
            show: true,
            max: 50,
          }}
          suffix={<SmartText text={template.header.text} setText={(e) => setTemplate(prev => ({
            ...prev, 
            header:{...prev.header, text: e}
          }))}/>}
        />
      </Col>
    </>
  );
};

const MediaHeader = () => {
  const { template, setTemplate } = useContext(
    WhatsAppTemplateContext
  );

  const [mediaType, setMediaType] = useState(
    template.header.fileType
  );

  const { Title, Text } = Typography;

  const update = (newValue) => {
    setMediaType(newValue);
    setTemplate((prev) => ({
      ...prev,
        header: { ...prev.header, fileType: newValue },
    }));
  };

  return (
    <>
      <Radio.Group
        style={{ width: "100%" }}
        value={template.header.fileType}
        onChange={(e) => update(e.target.value)}
      >
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <Space style={{ marginTop: 10, width: "100%" }} direction="vertical">
            <Row gutter={12}>
              <Col xs={24} sm={24} md={12} lg={6} xl={6} xxl={6}>
                <Card
                  className="shadowless"
                  style={{ boxShadow: "none !important" }}
                >
                  <Flex gap={12} align="center" justify="space-between">
                    <Flex align="center" justify="start" gap={12}>
                      <div
                        style={{
                          width: "55px",
                          height: "55px",
                          flexShrink: 0,
                          background:  "#f4f7fe",
                          borderRadius: "55px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <FaFileImage
                          style={{ fontSize: 26, color: "#422afb" }}
                        />
                      </div>
                      <Space direction="vertical" size={0}>
                        <Title style={{ margin: 0 }} level={3}>
                          Image
                        </Title>
                        <Text className="text-light">
                          Provide an image.
                        </Text>
                      </Space>
                    </Flex>

                    <Radio size="large" value="Image" />
                  </Flex>
                </Card>
              </Col>

              <Col xs={24} sm={24} md={12} lg={6} xl={6} xxl={6}>
                <Card className="shadowless">
                  <Flex align="center" justify="space-between" gap={12}>
                    <div
                      style={{
                        width: "55px",
                        height: "55px",
                        flexShrink: 0,
                        background: "#f4f7fe",
                        borderRadius: "55px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <FaVideo style={{ fontSize: 26, color: "#422afb" }} />
                    </div>
                    <Space
                      direction="vertical"
                      style={{ width: "100%" }}
                      size={0}
                    >
                      <Title style={{ margin: 0 }} level={3}>
                        Video
                      </Title>
                      <Text className="text-light">
                        Provide a video.
                      </Text>
                    </Space>
                    <Radio size="large" value="Video" />
                  </Flex>
                </Card>
              </Col>
              <Col xs={24} sm={24} md={12} lg={6} xl={6} xxl={6}>
                <Card className="shadowless">
                  <Flex align="center" justify="space-between" gap={12}>
                    <div
                      style={{
                        width: "55px",
                        height: "55px",
                        flexShrink: 0,
                        background:  "#f4f7fe",
                        borderRadius: "55px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <FaFilePdf style={{ fontSize: 26, color: "#422afb" }} />
                    </div>
                    <Space
                      direction="vertical"
                      style={{ width: "100%" }}
                      size={0}
                    >
                      <Title style={{ margin: 0 }} level={3}>
                        Document
                      </Title>
                      <Text className="text-light">
                        Provide a document.
                      </Text>
                    </Space>
                    <Radio size="large" value="Document" />
                  </Flex>
                </Card>
              </Col>
              <Col xs={24} sm={24} md={12} lg={6} xl={6} xxl={6}>
                <Card className="shadowless">
                  <Flex align="center" justify="space-between" gap={12}>
                    <div
                      style={{
                        width: "55px",
                        height: "55px",
                        flexShrink: 0,
                        background: "#f4f7fe",
                        borderRadius: "55px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <FaLocationDot
                        style={{ fontSize: 26, color: "#422afb" }}
                      />
                    </div>
                    <Space
                      direction="vertical"
                      style={{ width: "100%" }}
                      size={0}
                    >
                      <Title style={{ margin: 0 }} level={3}>
                        Location
                      </Title>
                      <Text className="text-light">Provide location.</Text>
                    </Space>
                    <Radio size="large" value="Location" />
                  </Flex>
                </Card>
              </Col>
            </Row>
          </Space>
        </Col>
      </Radio.Group>
      {(template.header.fileType == "Video" ||
        template.header.fileType == "Image" ||
        template.header.fileType == "Document") && (
        <UploadFile type={template.header.fileType} />
      )}
    </>
  );
};

const UploadFile = ({ type }) => {
  const { Text, Title } = Typography;
  return (
    <Card
      className="shadowless"
      style={{
        width: "100%",
        marginTop: 10,
        background:"#f4f7fe",
      }}
    >
      <Space style={{ width: "100%" }} direction="vertical">
        <Title level={3} style={{ margin: 0 }}>
          Samples for header content
        </Title>

        <Text>
          To help us review your content, provide examples of the variables or
          media in the header. Do not include any customer information. Cloud
          API hosted by Meta reviews templates and variable parameters to
          protect the security and integrity of our services.
        </Text>

        <Space>
          <Text style={{ fontSize: 16 }}>{type}</Text>
          <Upload>
            <Button icon={<UploadOutlined />}>
              {type == "Image" && "Choose PNG or JPG File"}
              {type == "Video" && "Choose MP4 File"}
              {type == "Document" && "Choose PDF File"}
            </Button>
          </Upload>
        </Space>
      </Space>
    </Card>
  );
};
export default Header;
