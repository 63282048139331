import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { EmailEditor } from "react-email-editor";
import { Button, Flex, Form, Typography, Input, message, Modal } from "antd";

const EditTemplate = () => {
  const [loading, setLoading] = useState(false);
  const emailEditorRef = useRef(null);
  let { id } = useParams();

  const [saveOpen, setSaveOpen] = useState(false);
  const [form] = Form.useForm();

  const axiosPrivate = useAxiosPrivate();

  const [templateStyle, setTemplateStyle] = useState("");

  const [loaded, setLoaded] = useState(false);

  const navigate = useNavigate();

  const handleSave = async (e) => {
    setLoading(true);

    emailEditorRef.current.editor.exportHtml(async (data) => {
      const { design, html } = data;

      var formData = new FormData();

      formData.append("Id", id);
      formData.append("Html", html);
      formData.append("Name", e.name);
      formData.append("Style", JSON.stringify(design));
      formData.append("Description", e.description);

      var response = await axiosPrivate.put("/Template", formData);

      setLoaded(false);
      if (!response || !response.data.success) {
        message.error(response.data.message);
        return;
      }
      navigate(-1);
    });
  };

  const onLoaded = () => {
    if (loaded) {
      return;
    }
    setLoaded(true);
    emailEditorRef.current.editor.loadDesign(JSON.parse(templateStyle));
  };

  useEffect(() => {
    const fetch = async () => {

    const response = await axiosPrivate.get(`/Template/${id}`);
    setTemplateStyle(response.data.style);

    form.setFieldsValue({
      name: response.data.name,
    });
  }
  fetch();
  }, []);

  return (
    <>
      <SaveModal
        adding={loading}
        open={saveOpen}
        setOpen={setSaveOpen}
        form={form}
        onSave={handleSave}
      />
      {templateStyle && (
        <EmailEditor
          ref={emailEditorRef}
          design={templateStyle}
          onReady={onLoaded}
          style={{ flex: 1 }}
        />
      )}

      <div
        style={{
          height: "60px",
          width: "100%",
        }}
      >
        <Flex
          style={{
            background: "#fff",
            height: "60px",
            borderTop: `1px solid #cbd5e0`,
          }}
          align="center"
          justify="space-between"
          gap={6}
        >
            <div></div>
          <Flex align="center" justify="end" gap={6}>
            <Button
              className="new-buttons large-font"
              size="large"
              onClick={() => navigate(-1)}
            >
              Cancel
            </Button>
            <Button
              size="large"
              type="primary"
              style={{
                marginRight: 10,
              }}
              className="new-buttons large-font"
              onClick={() => setSaveOpen(true)}
            >
              Save changes
            </Button>
          </Flex>
        </Flex>
      </div>
    </>
  );
};

const SaveModal = ({ open, setOpen, onSave, form, adding }) => {
  const { Title, Text } = Typography;

  return (
    <>
      <Modal
        open={open}
        onCancel={() => setOpen(false)}
        footer={
          <Flex align="center" justify="end" gap={6}>
            <Button size="large" onClick={() => setOpen(false)}>Cancel</Button>
            <Button
              loading={adding}
              type="primary"
              size="large"
              onClick={() => form.submit()}
            >
              {" "}
              Save changes
            </Button>
          </Flex>
        }
        title={
          <Title style={{ margin: 0 }} level={4}>
            Save template changes
          </Title>
        }
      >
        <Form form={form} onFinish={(e) => onSave(e)}>
          <Form.Item
            name="name"
            rules={[
              {
                required: true,
                message: "Please provide a name!",
              },
              {
                max: 50,
                message: "Name should be at most 50 characters long",
              },
            ]}
          >
            <Input
              size="large"
              placeholder="Enter a name for your design"
              allowClear
              count={{
                show: true,
                max: 50,
              }}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
export default EditTemplate;
