import { Flex, Select, Typography } from "antd";
import { useContext } from "react";
import { BsCalendarDateFill } from "react-icons/bs";
import { MdTitle } from "react-icons/md";
import { FollowupContext } from "../FollowupContext";
import OrderBySelect from "../../../shared/OrderBySelect";

const FollowupOrderByFilter = () => {
    const {Text} = Typography
  const { filters, setFilters } = useContext(FollowupContext)

    const options = [
        {
          value: "Created",
          label: "Follw up created date"
          
        },
        {
          value: "Name",
          label: "Follow up name"
          ,
        },
      ];
      const handleChanged = (e) => {
        setFilters(prev => ({
          ...prev,
          orderBy: e
        }))
      }
    return <>
    <OrderBySelect
      options={options}
      defaultValue={filters?.orderBy}
      handleChanged={(e) => handleChanged(e)}
    />
  </>
}

export default FollowupOrderByFilter;