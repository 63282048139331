import {
  Button,
  Flex,
  Form,
  Input,
  message,
  Modal,
  Space,
  Typography,
} from "antd";
import { useState } from "react";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { useNavigate } from "react-router-dom";

const CreateNewNotificationTemplateModal = ({ open, setOpen }) => {
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const { Title, Text } = Typography;

  const axios = useAxiosPrivate();
  const navigate = useNavigate();

  const submit = async (e) => {
    setLoading(true);

    let response = await axios.post("/Notification/Templates/Create-Template", {
      name: e.Name,
    });

    if (response.data.success) {
      navigate(`/Templates/Notification/Edit/${response.data.id}`);
    } else {
      message.error(response.data.message);
    }
    setLoading(false);
  };

  return (
    <>
      <Modal
        title={
          <Title style={{ margin: 0 }} level={4}>
            Create a notification desing
          </Title>
        }
        open={open}
        onCancel={() => setOpen(false)}
        footer={
          <Flex align="center" justify="end" gap={6}>
            <Button size="large" onClick={() => setOpen(false)}>
              Cancel
            </Button>
            <Button
              size="large"
              type="primary"
              onClick={() => form.submit()}
              loading={loading}
            >
              Save changes
            </Button>
          </Flex>
        }
      >
        <Form form={form} onFinish={submit}>
          <Space direction="vertical" size={0} className="w-100">
            <Text>Name</Text>

            <Form.Item
              name="Name"
              rules={[
                {
                  required: true,
                },
                {
                  max: 128,
                },
                {
                  min: 3,
                },
              ]}
            >
              <Input
                size="large"
                placeholder="Desing name"
                count={{
                  show: true,
                  max: 128,
                }}
              />
            </Form.Item>
          </Space>
        </Form>
      </Modal>
    </>
  );
};

export default CreateNewNotificationTemplateModal;
