import {
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { OrderDirection } from "../../shared/enums";
import { SearchContext } from "../../context/SearchContext";
import { AuthorContext } from "../../context/AuthorsContext";
import QueryString from "qs";
import { useParams } from "react-router-dom";

export const CompanyListContext = createContext({
  filters: {

    from: null,
    to: null,

    orderBy: null,
    orderDirection: null,
  },
  setFilters: () => {},

  showFilters: false,
  setShowFilters: () => {},

  stats: {},
  setStats: () => {},

  companies: [],
  setCompanies: () => {},

  page: 1,
  setPage: () => {},

  pageSize: 25,
  setPageSize: () => {},

  loading: false,
  setLoading: () => {},
});

export const CompanyListContextProvider = ({ children }) => {

  const {authorId} = useParams();

  const { debouncedValue } = useContext(SearchContext);

  const [filters, setFilters] = useState(() => {});
  const [stats, setStats] = useState();
  const [showFilters, setShowFilters] = useState();

  const [companies, setCompanies] = useState();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);

  const [loading, setLoading] = useState(false);

  const axios = useAxiosPrivate();

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    const fetch = async () => {
      setLoading(true);

      const response = await axios.get("/Companies/List", {
        params:{
            page: page,
            pageSize: pageSize,
            search: debouncedValue,
            from: filters?.from ? new Date(filters.from) : null,
            to: filters?.to ? new Date(filters?.to) : null,
            authorId: authorId
        },
        signal: signal,
        paramsSerializer: (params) => {
            return QueryString.stringify(params, {
              arrayFormat: "repeat",
              skipNulls: true,
            });
          },
      });
      setCompanies(response.data.items);
      setStats((prev) => ({
        ...prev,
        all: response.data.total,
        first: response.data.first,
        last: response.data.last,
        authorName: response.data.authorName,
        authorImage: response.data.authorProfile,
      }));

      setLoading(false);
    };

    fetch();

    return () => {
        controller.abort();
      };
  }, [page, filters, debouncedValue, pageSize]);

  return (
    <CompanyListContext.Provider
      value={{
        filters,
        setFilters,

        stats,
        setStats,

        showFilters,
        setShowFilters,

        companies,
        setCompanies,

        page,
        setPage,

        loading,
        setLoading,

        pageSize,
        setPageSize
      }}
    >
      {children}
    </CompanyListContext.Provider>
  );
};
