import { Modal, Typography } from "antd";
import { useEffect, useState } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import TableLoading from "../../shared/TableLoading";

const TemplateComponentPreviewModal = ({ open, setOpen, id }) => {
  const { Title } = Typography;

  const axios = useAxiosPrivate();
  const [loading, setLoading] = useState(false)

  const [html, setHtml] = useState("")

  useEffect(() => {
    if(!id){
        return;
    }

    const controller = new AbortController();
    const signal = controller.signal;

    const fetch = async () => {
        const response = await axios.get(`/Template-Component/${id}`, {
            signal: signal
        });

        setHtml(response.data.html)
    }

    fetch();

    return () => controller.abort()
  }, [id])
  return (
    <Modal
      footer={false}
      title="Template preview"
      open={open}
      size="small"
      width={1000}
      onCancel={() => setOpen(false)}
    >
         {loading ? (
        <>
          <TableLoading />
        </>
      ) : (
        <div
          className="preview-modal-content"
          dangerouslySetInnerHTML={{ __html: html }}
        ></div>
      )}
    </Modal>
  );
};

export default TemplateComponentPreviewModal;
