import { MeidaContextProvider } from "./MediaContext"
import MediaList from "./MediaList";

const MediaLayout = () => {
    return <>
        <MeidaContextProvider>
            <MediaList/>
        </MeidaContextProvider>
    </>
}

export default MediaLayout;