import { Button, Drawer, Flex, Form, message, Select, Space, Typography } from "antd";
import { useContext, useState } from "react";
import { AuthorContext } from "../../../context/AuthorsContext";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import AuthorSelectListItem from "../../../shared/AuthorSelectListItem";

const AssignContactOwnerDrawer = ({
  open,
  setOpen,
  defaultValue,
  contactId,
  onUpdated,
}) => {
  const { Text } = Typography;
  const { authors } = useContext(AuthorContext);

  const axois = useAxiosPrivate();
  const [loading, setLoading] = useState(false);

  const update = async (e) => {
    setLoading(true)
    const response = await axois.put(`/Contacts/Assign-Owner`, {
        contactId: contactId,
        ownerId: e.ownerId
    });

    if(response.data.success){
        let owner = authors?.find(c => c.value == e.ownerId)

        onUpdated({
            contactId: contactId,
            ownerName: `${owner?.firstname} ${owner?.lastname}`,
            ownerProfile: owner?.profile,
            ownerId: owner?.value
        })
        setOpen(false)
    }else{
        message.error(response.data.message)
    }

    setLoading(false)
  };

  const [form] = Form.useForm();

  return (
    <Drawer open={open} destroyOnClose onClose={() => setOpen(false)} title="Contact owner">

      <Flex className="h-100" align="center" justify="space-between" vertical>
      <Form form={form} onFinish={update}>
        <Space className="w-100" direction="vertical" size={0}>
          <Text className="bold">
            Select which agent would you like to be responsible for this
            contact?
          </Text>
          <Form.Item name="ownerId">

          <Select
            className="w-100"
            defaultValue={defaultValue}
            size="large"
            placeholder={"Select agent"}
            options={authors?.map(d => ({
              value: d.value,
              label: (<AuthorSelectListItem firstname={d.firstname} lastname={d.lastname} profile={d.profile}/>),
            }))}
            ></Select>
            </Form.Item>
        </Space>

            </Form>
        <Flex gap={6} align="center" justify="end" className="w-100">
          <Button
            onClick={() => setOpen(false)}
            size="large"
            className="new-buttons large-font"
          >
            Cancel
          </Button>
          <Button
            size="large"
            onClick={() => form.submit()}
            type="primary"
            loading={loading}
            className="new-buttons large-font"
          >
            Save changes
          </Button>
        </Flex>
      </Flex>
    </Drawer>
  );
};

export default AssignContactOwnerDrawer;
