import { Outlet } from "react-router-dom";
import { CreateNotificationTemplateContextProvider } from "./CreateNotificationTemplateContext";

const CreateNotificationTemplateLayout = () => {
  return (
    <>
      <CreateNotificationTemplateContextProvider>
        <Outlet />
      </CreateNotificationTemplateContextProvider>
    </>
  );
};

export default CreateNotificationTemplateLayout;
