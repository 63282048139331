import { useContext, useState } from "react";
import { AudienceContext } from "../AudienceContext";
import { Avatar, Button, Card, Typography } from "antd";
import { AuthorContext } from "../../../context/AuthorsContext";
import { IoMdArrowDropright } from "react-icons/io";
import SelectOwnerDrawer from "../../../shared/SelectOwnerDrawer";
import Colors from "../../../shared/Colors";

const ContactAuthorFilter = () => {
  const [ownerOpen, setOwnerOpen] = useState(false);
  const { filters, setFilters } = useContext(AudienceContext);
  const { getAuthor } = useContext(AuthorContext);

  const handleChanged = (e) => {
    setFilters((prev) => ({
      ...prev,
      selectedOwners: e,
    }));

    setOwnerOpen(false);
  };

  const getColor = (index) => {
  return Colors[index % Colors.length];
};

  return (
    <>
      <SelectOwnerDrawer
        defaultValues={filters?.selectedOwners}
        onChange={handleChanged}
        open={ownerOpen}
        setOpen={setOwnerOpen}
      />

      {filters?.selectedOwners ? (
        <Button onClick={() => setOwnerOpen(true)} size="large" className="large-font"> Owners: 
          <Avatar.Group className="no-pointer-events" max={{
              count: 5,
              style: {
                background: "#5f5f5f",
                fontWeight: "bold",
                border: "2px solid #f4f7fe",
              },
            }}>
              {filters?.selectedOwners?.map((c,i) =>  {
                
                let author = getAuthor(c);

                return <Avatar
                  key={i}
                  src={`${process.env.REACT_APP_FILE_URL}${author?.profile}`}
                  style={{
                    background: `${getColor(i)}`,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontWeight: "bold",
                    border: `2px solid #f4f7fe`,
                  }}
                >
                  {author?.firstname?.length > 0 ? author.firstname[0] : ""}
                  {author?.lastname?.length > 0 ? author.lastname[0] : ""}
                </Avatar>})}
          </Avatar.Group>
        </Button>
      ) : (
        <Button
          onClick={() => setOwnerOpen(true)}
          className="communication-date-filter-button large-font"
          size="large"
        >
          All owners
          <IoMdArrowDropright className="icon"/>
        </Button>
      )}
    </>
  );
};

export default ContactAuthorFilter;
