import React, { memo, useContext, useEffect } from 'react'
import useAxiosPrivate from '../../../hooks/useAxiosPrivate'
import { message } from 'antd';
import useDealsManagementCtx from "../../../hooks/useDealsManagementCtx";
import { CardStatus } from '../../../shared/enums';

const KanbanCardAdd = memo(({setKanbanCards, dataToAdd}) => {
    const { setLoading } = useDealsManagementCtx();
    
    const axios = useAxiosPrivate();

    const onCardAdd = async (e) =>{
        setLoading(true)
        const response = await axios.post('/Deal/CreateDeal', {
            emailId:e.contacts,
            companyIds: e.companies,
            stage:e.status,
            ownerIds: e.assignedUser,
            amount:e.amount,
            closeDate:e.closeDate,
            title: e.title,
            productIds: e.products

        });

        if(response.data.success){

            const {data} = response;

            setKanbanCards(prev => ({
                ...prev,
                [data.stage]: ({
                    count: prev[data.stage].count + 1,
                    revenue : (prev[data.stage].revenue ?? 0) + (data.amount ?? 0),
                    items: [{
                        title: response.data.title,
                        id: response.data.id,
                        status: response.data.stage,
                        company: response.data.company,
                        companyCount: response.data.companyCount,
                        contact: response.data.contactCount,
                        color: "red",
                        amount: response.data.amount,
                        owners: response.data.owners,
                        closeDate: response.data.closeDate,
                        position: response.data.position,
                        createdOn: response.data.createdOn
                    }, ...prev[data.stage].items]
                })
            }))
        }else{
            message.error(response.data.message)
        }

        setLoading(false)
    }

    useEffect(() => {
        if(dataToAdd){
            onCardAdd(dataToAdd)
        }
    },[dataToAdd])
  return <></>
})

export default KanbanCardAdd