import { Button, Card, Col, Flex, Row, Space, Typography } from "antd";
import { RiUserFollowFill } from "react-icons/ri";
import "./FollowUp.css";
import { useNavigate } from "react-router-dom";

const FollowUp = () => {
  const { Text, Title } = Typography;

  const navigate = useNavigate();
  const handleFollowupClick = (e) => {
    if (e) {
      e.stopPropagation();
    }

    navigate(`/Followup`);
  };

  const handleIntroductionClick = (e) => {
    if (e) {
      e.stopPropagation();
    }

    window.location.href = "https://documentation.tapzap.ai";
  };
  return (
    <Card onClick={handleFollowupClick} className="home-follow-up" size="small">
      <Row gutter={[24, 24]}>
        <Col span={4}>
          <Flex className="w-100 h-100" align="center" justify="center">
            <RiUserFollowFill className="home-follow-up-icon" />
          </Flex>
        </Col>
        <Col span={20}>
          <Space className="w-100" size={24} direction="vertical">
            <Space className="w-100" size={0} direction="vertical">
              <Title className="zero-margin-padding" level={4}>
                Deliver the right message at the right time
              </Title>
              <Text>
                Strengthen connections with follow up messages, from welcome
                email to subscribed and un-subscribed emails.
              </Text>
            </Space>

            <Flex align="center" justify="start" gap={6}>
              <Button
                onClick={handleFollowupClick}
                size="large"
                type="primary"
                className="bold"
              >
                Create Follow up
              </Button>
              <Button
                onClick={handleIntroductionClick}
                type="link"
                size="large"
                className="bold"
              >
                Get introduced to follow ups
              </Button>
            </Flex>
          </Space>
        </Col>
      </Row>
    </Card>
  );
};

export default FollowUp;
