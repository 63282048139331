import { Button, Flex, Form, Tooltip, Typography } from "antd";
import { useEffect, useRef, useState } from "react";
import { FaUser } from "react-icons/fa";
import { MdEmojiEmotions } from "react-icons/md";
import "./HeaderStep.css";
import Picker from "@emoji-mart/react";
import TextArea from "antd/es/input/TextArea";

const InputBox = ({ form, name, rules, max, onChange, onPersonalisation }) => {
  const { Text } = Typography;
  const [count, setCount] = useState(() => form?.getFieldValue(name)?.length);

  const [showPicker, setShowPicker] = useState(false);

  const pickerRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (pickerRef.current && !pickerRef.current.contains(event.target)) {
        setShowPicker(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [pickerRef]);

  const handleSelect = (emoji) => {
    setShowPicker(false);

    let fieldValue = form.getFieldValue(name);

    if (fieldValue) {
      form.setFieldValue(name, `${fieldValue} ${emoji.native}`);
      onChange(`${fieldValue} ${emoji.native}`);
      setCount(`${fieldValue} ${emoji.native}`.length);
    } else {
      form.setFieldValue(name, emoji.native);
      onChange(emoji.native);
      setCount(emoji.native.length);
    }
  };

  const [aiOpen, setAiOpen] = useState(false);
  return (
    <>
      <div className="subject-text-box-wrapper">
        <Form.Item rules={rules} name={name} className="no-error-show">
          <TextArea
            onChange={(e) => {
              setCount(e.target.value.length);
              onChange(e.target.value);
            }}
            size="large"
            className="subject-text-box"
          ></TextArea>
        </Form.Item>

        <Flex className="margin-right" align="center" justify="end" gap={6}>
          <Button
            onClick={() => setAiOpen(true)}
            className="use-ai-button"
            size="small"
            type="text"
          >
            Use AI
          </Button>
          <div>
            <Tooltip title="Add emoji">
              <Button
                onClick={() => setShowPicker((prev) => !prev)}
                icon={<MdEmojiEmotions className="subject-icon emoji-icon" />}
                type="text"
                className="flex-button"
              ></Button>
            </Tooltip>
            {showPicker && (
              <div className="emoji-selector" ref={pickerRef}>
                <Picker
                  set="apple"
                  theme="light"
                  onEmojiSelect={handleSelect}
                />
              </div>
            )}
          </div>
          <Tooltip title="Add personalisation">
            <Button
              type="text"
              className="flex-button"
              onClick={() => onPersonalisation()}
              icon={<FaUser className="subject-icon" />}
            ></Button>
          </Tooltip>
          <Text>
            {count ?? 0}/{max ?? 200}
          </Text>
        </Flex>
      </div>
      <Form.Item shouldUpdate className="no-error-show">
        {() => {
          const errors = form?.getFieldError(name);
          return (
            errors?.length > 0 && (
              <div style={{ color: "red" }}>{errors[0]}</div>
            )
          );
        }}
      </Form.Item>
    </>
  );
};

export default InputBox