import {
  Button,
  Drawer,
  Flex,
  Form,
  Input,
  InputNumber,
  Select,
  Space,
  Switch,
  Typography,
} from "antd";
import { useEffect, useState } from "react";
import useAxiosPrivate from "../../../../../hooks/useAxiosPrivate";
import {
  FaAmbulance,
  FaFax,
  FaGlobeAmericas,
  FaPhoneAlt,
  FaSms,
} from "react-icons/fa";
import { MdHd, MdMms } from "react-icons/md";
import { IoMdArrowDropdown } from "react-icons/io";

const features = [
  {
    name: "Voice",
    icon: <FaPhoneAlt />,
    value: "voice",
  },
  {
    name: "Fax",
    icon: <FaFax />,
    value: "fax",
  },
  {
    name: "HD voice",
    icon: <MdHd />,
    value: "hd_voice",
  },
  {
    name: "Emergency",
    icon: <FaAmbulance />,
    value: "emergency",
  },
  {
    name: "Sms",
    icon: <FaSms />,
    value: "sms",
  },
  {
    name: "International sms",
    icon: <FaGlobeAmericas />,
    value: "international_sms",
  },
  {
    name: "MMS",
    icon: <MdMms />,
    value: "mms",
  },
];

const types = [
  {
    value: "local",
    label: "Local",
  },
  {
    value: "toll_free",
    label: "Toll Free",
  },
  {
    value: "mobile",
    label: "Mobile",
  },
  {
    value: "national",
    label: "National",
  },
  {
    value: "shared_cost",
    label: "Shared cost",
  },
];

const phoneNumberOptions = [
  {
    value: "contains",
    label: "Contains",
  },
  {
    value: "starts_with",
    label: "Starts with",
  },
  {
    value: "ends_with",
    label: "Ends with",
  },
];

const BuyPhoneNumberFilterDrawer = ({ open, setOpen, filters, setFilters }) => {
  const { Text } = Typography;

  const [countries, setCountries] = useState();

  const axios = useAxiosPrivate();

  useEffect(() => {
    const contoller = new AbortController();
    const signal = contoller.signal;

    const fetch = async () => {
      const countryResponse = await axios.get(`/Address/country-codes`, {
        signal: signal,
      });

      setCountries(
        countryResponse.data.items.map((c) => ({
          value: c.code,
          label: c.name,
        }))
      );
    };

    fetch();

    return () => contoller.abort();
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {}, []);

  const [form] = Form.useForm();

  const handleSubmit = (e) => {
    setFilters((prev) => ({
      ...prev,
      ...e,
    }));

    handleClose()
  };

  return (
    <Drawer title="Filter phone numbers" open={open} onClose={handleClose}>
        <Flex className="w-100 h-100" vertical justify="space-between">

        <Space size={24} className="w-100" direction="vertical">
      <Form form={form} onFinish={handleSubmit}>
          <Space size={0} direction="vertical" className="w-100">
            <Text className="bold">Country</Text>

            <Form.Item name="selectedCountries">
              <Select
                className="w-100"
                size="large"
                options={countries}
                showSearch
                filterOption={(input, option) =>
                  option?.label.toLowerCase().includes(input.toLowerCase())
                }
                placeholder="Select phone number country"
              />
            </Form.Item>
          </Space>

          <Space size={0} direction="vertical" className="w-100">
            <Text className="bold">Features</Text>

            <Form.Item name="selectedFeatures">
              <Select
                className="w-100"
                maxTagCount="responsive"
                size="large"
                placeholder="Any feature"
                mode="multiple"
                options={features.map((c) => ({
                  label: (
                    <Flex
                      className="w-100 h-100"
                      align="center"
                      justify="start"
                      gap={6}
                    >
                      {c.icon} <Text>{c.name}</Text>
                    </Flex>
                  ),
                  value: c.value,
                }))}
              />
            </Form.Item>
          </Space>
          <Space size={0} direction="vertical" className="w-100">
            <Text className="bold">Type</Text>

            <Form.Item name="selectedType">
              <Select
                className="w-100"
                size="large"
                placeholder="All types"
                options={types}
              />
            </Form.Item>
          </Space>

          <Space size={0} direction="vertical" className="w-100">
            <Text className="bold">Result Limit</Text>
            <Form.Item name="limit">
              <InputNumber
                className="w-100"
                size="large"
                min={10}
                placeholder="50"
              />
            </Form.Item>
          </Space>

              </Form>
        
        </Space>

          <Flex className="w-100" align="center" justify="end" gap={6}>
            <Button
              className="new-buttons large-font"
              size="large"
              onClick={handleClose}
            >
              Close
            </Button>
            <Button
              className="new-buttons large-font"
              size="large"
              type="primary"
              onClick={() => form.submit()}
            >
              Search
            </Button>
          </Flex>
        </Flex>
    </Drawer>
  );
};

export default BuyPhoneNumberFilterDrawer;
